import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppImages from "../../../../../../constants/images";
import {
  sharePromptWithUsers,
  setLikeByUserSelection,
  setFavouriteByUserSelection,
  updateViewStatusById,
  addComments,
  getPromptData,
} from "../../../services/promptManagementService";
import { ExtractCurlParameters } from "../../../../promptManagement/shared/curlConverter";

import CommentModal from "../commentModal";
import {
  getInitials,
  hasGroupAccess,
} from "../../../../../../services/commonService";
import { capitalizeFirstLetter } from "../../../../../../services/commonService";
import loaderContext from "../../../../../../context/loader/loaderContext";
import ShareUserList from "../shareUserList";
import showMessage from "../../../../../common/message";
import UserImage from "../../../../../common/userImage/index";
import { UserContext } from "../../../../../../context/user/userContext";

const PromptCard = (props) => {
  const { prompt, onFavouriteClick } = props;
  const navigate = useNavigate();
  const { state } = useContext(UserContext);
  const securityGroups = state?.user?.userProfile?.msalSecurityGroup;
  const [showAddComment, setShowAddComment] = useState(false);
  const [comments, setComments] = useState([]);
  const [commentCount, setCommentCount] = useState(0);
  const [comment, setComment] = useState("");
  const [isShowShareUserPopUp, setIsShowShareUserPopUp] = useState(false);

  const [like, setLike] = useState(false);
  let [likeCount, setLikeCount] = useState(0);
  const maxItemsToShow = process.env.REACT_APP_CARD_LIMIT;
  const areasToShow = prompt?.tags?.slice(0, maxItemsToShow);
  const remainingCount = prompt?.tags?.length - maxItemsToShow;
  const { updateLoaderStatus } = useContext(loaderContext);

  useEffect(() => {
    getRefreshedData(prompt?.Id);
  }, [prompt?.CommentCount, prompt?.LikeCount, prompt?.IsLiked]);

  const handleFavouriteClick = (val) => {
    const data = {
      PromptUserInputId: prompt?.Id,
      Status: !val,
    };
    onFavouriteClick(val, prompt?.Id);
    setFavouriteByUserSelection(data);
  };

  const handleSkillChatButtonClick = (prompt) => {
    const access = hasGroupAccess(securityGroups, prompt.SecurityGroupId);
    if (!access) {
      showMessage(
        "You don't have access to " + prompt.SkillStoreName + " bot.",
        -2
      );
      return;
    }
    navigate("/chat", {
      state: {
        isMKSChat: prompt?.IsImportedPrompt ? true : false,
        chatMessage: prompt?.IsImportedPrompt
          ? prompt?.PromptSystemGeneratedInputs?.length > 0
            ? prompt?.PromptSystemGeneratedInputs[0].PromptText
            : prompt?.PromptSystemGeneratedInputs?.PromptText
          : prompt?.SkillStoreName,
        skill: getSkillData(prompt),
        navigateFromHome: false,
        isAdditivesFound: getIsAdditives(prompt),
        isFileType: getIsFile(prompt),
        promptText:
          prompt?.PromptSystemGeneratedInputs?.length > 0
            ? prompt?.PromptSystemGeneratedInputs[0].PromptText
            : prompt?.PromptSystemGeneratedInputs?.PromptText,
      },
    });
  };

  const getSkillData = (prompt) => {
    const params = ExtractCurlParameters(
      prompt?.ChatbotConfiguration?.CurlRequestString
    );

    const skill = {
      Id: prompt.SkillStoreId,
      Name: prompt.SkillStoreName,
      ChatbotConfiguration: prompt.ChatbotConfiguration,
      aiUrl: params.url,
      headers: params.headers,
      aiFlag: "",
      apiKey: "",
      messageBody: params.body,
      ShortDescription: prompt?.SkillStoreShortDescription,
      userMessage: prompt?.ChatbotConfiguration?.UserMessageFormat,
      AssistantMessageFormat:
        prompt?.ChatbotConfiguration?.AssistantMessageFormat,
    };
    return skill;
  };

  const getIsAdditives = (prompt) => {
    if (
      prompt?.ChatbotConfiguration?.ChatbotUIView?.toLowerCase() === "additives"
    )
      return true;
    else return false;
  };

  const getIsFile = (prompt) => {
    if (prompt?.ChatbotConfiguration?.InputType?.toLowerCase() === "file")
      return true;
    else return false;
  };

  // Add Comment Section Start Here
  const handleAddComment = () => {
    setShowAddComment(true);
  };

  const handleCancelClick = () => {
    setShowAddComment(false);
  };

  const handelOnChange = (val) => {
    setComment(val);
  };

  const getRefreshedData = async (id) => {
    setComment("");
    setLikeCount(0);
    const promptData = await getPromptData(id);
    if (
      promptData?.comments !== null &&
      promptData?.comments !== undefined &&
      promptData?.comments?.length > 0
    ) {
      setComments(promptData?.comments);
    }
    else
    {
      setComments([]);
    }
    setCommentCount(promptData?.CommentCount);
    setLikeCount(promptData?.LikeCount);
    setLike(promptData?.IsLiked);
  };

  const handleSaveComment = () => {
    const data = {
      PromptUserInputId: prompt?.Id,
      Comment: comment === "" || comment === undefined ? "" : comment,
    };

    if (data.Comment !== "" && data.Comment !== undefined) {
      addComments(data).then((resp) => {
        getRefreshedData(prompt?.Id);
      });
    }
  };

  const handleSaveChildComment = (id, comment) => {
    const data = {
      ParentCommentId: id,
      PromptUserInputId: prompt?.Id,
      Comment: comment === "" || comment === undefined ? "" : comment,
    };

    if (data.Comment !== "" && data.Comment !== undefined) {
      addComments(data).then((resp) => {
        getRefreshedData(prompt?.Id);
      });
    }
  };

  // Add Comment Section End Here
  const handleCardClick = async () => {
    updateLoaderStatus(true);
    const promptUserInputId = prompt?.Id;
    const response = await updateViewStatusById(promptUserInputId);
    updateLoaderStatus(false);
    if (response !== undefined && response !== null) {
      navigate("/updatePrompt", { state: { prompt: prompt } });
    }
  };

  // Helper function to nest comments based on ParentCommentId
  const nestComments = (commentsData) => {
    const commentMap = {};

    // Map each comment by its ID
    commentsData.forEach((comment) => {
      commentMap[comment.Id] = { ...comment, replies: [] };
    });

    const nestedComments = [];

    // Arrange the comments as parent and children
    commentsData.forEach((comment) => {
      if (comment.ParentCommentId === null) {
        nestedComments.push(commentMap[comment.Id]);
      } else {
        commentMap[comment.ParentCommentId]?.replies.push(
          commentMap[comment.Id]
        );
      }
    });

    return nestedComments.sort(
      (a, b) => new Date(b.CommentDate) - new Date(a.CommentDate)
    );
  };

  const handleShareIconClick = () => {
    setIsShowShareUserPopUp(true);
  };

  const handelShareUserListPopUp = () => {
    setIsShowShareUserPopUp(false);
  };

  const promptInitials = (title) => {
    let titleWords = title.split(" ");
    if (titleWords.length > 1) {
      return getInitials(titleWords[0] + " " + titleWords[1]);
    } else {
      return getInitials(titleWords[0]);
    }
  };

  const onSaveSharedUser = async (ids) => {
    setIsShowShareUserPopUp(false);
    const res = await sharePromptWithUsers(prompt?.Id, ids);
    if (res !== null && res !== undefined) {
      showMessage(res?.Status, 1);
    }
  };

  const handleLikeClick = (val) => {
    const data = {
      PromptUserInputId: prompt?.Id,
      Status: !val,
    };
    setLike(!val);
    setLikeByUserSelection(data);
    likeCount = val ? likeCount - 1 : likeCount + 1;
    setLikeCount(likeCount);
  };

  return (
    <div className="ai-collection-link-block">
      <div className="ai-collection-info-block">
        <div className="ai-block-header" onClick={handleCardClick}>
          {prompt?.CardImage_Base64 ? (
            <img
              loading="lazy"
              alt=""
              src={prompt?.CardImage_Base64}
              className="ai-block-icon card-img"
            />
          ) : (
            <div className="prompt-user-img">
              <span>{promptInitials(prompt?.Title)}</span>
            </div>
          )}

          <h3 className="h3 small">{prompt?.Title}</h3>
        </div>
        <div className="keyword-block" onClick={handleCardClick}>
          {areasToShow.map((a) => (
            <div key={a.Id} className="skill-keyword">
              {a.Name}
            </div>
          ))}
          {remainingCount > 0 && (
            <div className="skill-keyword">+{remainingCount}</div>
          )}
        </div>
        <p className="paragraph small" onClick={handleCardClick}>
          {prompt?.ShortDescription}
        </p>
        <div className="prompt-creator-block" onClick={handleCardClick}>
          <div className="prompt-user-block w-inline-block">
            <div className="prompt-user-img">
              <UserImage
                userImageBytes={prompt?.userImage}
                userImageMimeType={prompt?.userImageMimeType}
                userInitials={getInitials(
                  prompt?.CreatedByFirstName + " " + prompt?.CreatedByLastName
                )}
              ></UserImage>
            </div>
            <div className="prompt-user-name">
              {capitalizeFirstLetter(prompt?.CreatedByFirstName) +
                " " +
                capitalizeFirstLetter(prompt?.CreatedByLastName)}
            </div>
          </div>
          <div className="collection-views-block">
            <div className="view-active-background">
              <div className="views-icon"></div>
            </div>
            <div>{prompt?.ViewCount}</div>
          </div>
        </div>

        <div
          onClick={() => handleFavouriteClick(prompt?.IsStared)}
          className={`${
            prompt?.IsStared ? "favourite-button-active" : "favourite-button"
          } w-inline-block`}
        ></div>
      </div>
      <div className="ai-button-wrapper">
        <div className="share-action-wrapper">
          <div
            key=""
            onClick={() => handleAddComment()}
            className="prompt-action-link w-inline-block"
          >
            {prompt?.IsCommented ? (
              <img
                src={AppImages.CommentIconDark}
                alt=""
                className="prompt-action-icon"
              />
            ) : (
              <img
                src={AppImages.CommentIcon}
                alt=""
                className="prompt-action-icon"
              />
            )}
            <div className="prompt-action-count">{commentCount}</div>
          </div>
          {!prompt?.IsPublic && (
            <div className="flexible-icon">
              <img
                src={AppImages.shareIcon}
                alt=""
                height="15px"
                onClick={handleShareIconClick}
              />
            </div>
          )}
          <div
            onClick={() => handleLikeClick(like)}
            className="prompt-action-link w-inline-block"
          >
            <img
              src={like ? AppImages.ThumbLikeIconDark : AppImages.ThumbLikeIcon}
              alt=""
              className="prompt-action-icon"
            />
            <div className="prompt-action-count">{likeCount}</div>
          </div>

          <CommentModal
            isOpen={showAddComment}
            onClose={handleCancelClick}
            onSave={handleSaveComment}
            commentData={nestComments(comments)}
            onChange={handelOnChange}
            onSaveChildComment={handleSaveChildComment}

          />
          {isShowShareUserPopUp && (
            <ShareUserList
              handelAppPopUp={handelShareUserListPopUp}
              onSaveSharedUser={onSaveSharedUser}
              prompt={prompt}
            />
          )}
        </div>
        <div
          onClick={() => handleSkillChatButtonClick(prompt)}
          className="button  w-button"
        >
          Use Prompt
        </div>
      </div>
    </div>
  );
};

export default PromptCard;
