import React, { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  updateUsedStatusById,
  getSkillStoreDataById,
} from "../../../services/skillStoreService";
import { hasGroupAccess } from "../../../../../../services/commonService";
import { UserContext } from "../../../../../../context/user/userContext";
import showMessage from "../../../../../common/message/index";
import { getSkillStoresParams } from "../../../../shared/skillParams";
import DeleteSkillConfirmation from "../deleteSkillConfirmation";
import { usePermissions } from "../../../../../common/customHooks/usePermissions";

const SkillStoreDetail = (props) => {
  const {
    AppImages,
    IsThirdPartyAITool,
    skillDetails,
    selectedSkill,
    updateOriginalData,
  } = props;
  const { isSuperAdmin, canUpdate, canDelete, isUserIdExist } = usePermissions(selectedSkill?.Id);

  const navigate = useNavigate();
  const { state } = useContext(UserContext);
  const [isShowDeleteConfirmation, setIsShowDeleteConfirmation] =
    useState(false);
  const securityGroups = state?.user?.userProfile?.msalSecurityGroup;
  const handleUseSkillToNavigate = async () => {
    const access = hasGroupAccess(
      securityGroups,
      selectedSkill.SecurityGroupId
    );
    if (!access) {
      showMessage(
        "You don't have access to " + selectedSkill.Name + " bot.",
        -2
      );
      return;
    }
    const skillStoreId = selectedSkill?.Id;
    const response = await updateUsedStatusById(skillStoreId);
    const isAdditives =
      selectedSkill?.ChatbotConfiguration?.ChatbotUIView?.toLowerCase() ===
      "additives"
        ? true
        : false;

    const isFile =
      selectedSkill?.ChatbotConfiguration?.InputType?.toLowerCase() === "file"
        ? true
        : false;
    navigate("/chat", {
      state: {
        skill: getSkillStoresParams(selectedSkill),
        isAdditivesFound: isAdditives,
        isFileType: isFile,
        isMKSChat: false,
        message: "",
        isFileTyple: false,
      },
    });
  };

  // const getSkillData = (skillInfo) => {
  //   const params = ExtractCurlParameters(
  //     skillInfo.ChatbotConfiguration?.CurlRequestString
  //   );
  //   const skill = {
  //     Id: skillInfo.Id,
  //     Name: skillInfo.Name,
  //     ChatbotConfiguration: skillInfo.ChatbotConfiguration,
  //     aiUrl: params.url,
  //     headers: params.headers,
  //     headersV2: params.headersV2,
  //     method: params.method,
  //     aiFlag: "",
  //     apiKey: "",
  //     messageBody: params.body,
  //     userMessage: skillInfo.ChatbotConfiguration.UserMessageFormat,
  //     AssistantMessageFormat:
  //       skillInfo.ChatbotConfiguration.AssistantMessageFormat,
  //     ShowCitation: skillInfo.ChatbotConfiguration.ShowCitation,
  //   };
  //   return skill;
  // };

  const handleURLRedirect = (url) => {
    const skillStoreId = skillDetails?.Id;
    updateUsedStatusById(skillStoreId).then((res) => {
      getSkillStoreDataById(skillStoreId).then((res) => {
        updateOriginalData(res.UsedCount);
      });
    });
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const deleteSkill = () => {
    setIsShowDeleteConfirmation(true);
  };

  const handleClosePopup = () => {
    setIsShowDeleteConfirmation(false);
  };

  return (
    <div className="skill-detail-wrapper">
      <div className="skill-detail-left ">
        <div className="skill-block-header">
          <div className="skill-icon-block">
            <img src={AppImages.ChatAIImage} alt="" className="ai-block-icon" />
          </div>

          <div className="skill-heading">
            <h2>{skillDetails?.Name}</h2>
            <div className="skill-checkbox-wrapper">
              <img
                src={AppImages.CheckGreenImage}
                loading="lazy"
                alt=""
                className="check-icon"
                title="Scope"
              />
              <div>{skillDetails?.SkillScope}</div>
            </div>
          </div>
        </div>

        <div className="skill-flex-col ">
          <div className="skill-card-small">
            <p className="skill-subtitle">Accuracy:</p>
            <p className="skill-subtitle-info">{skillDetails?.Acurracy}%</p>
          </div>
          <div className="skill-card-small">
            <p className="skill-subtitle">Avg. Execution Time:</p>
            <p className="skill-subtitle-info">
              {skillDetails?.AvgExecutionTime} Seconds
            </p>
          </div>
          <div className="skill-card-small">
            <p className="skill-subtitle">Version:</p>
            <p className="skill-subtitle-info">{skillDetails?.VersionName}</p>
          </div>
          <div className="skill-card-small">
            <p className="skill-subtitle">Used</p>
            <p className="skill-subtitle-info">{skillDetails?.UsedCount}</p>
          </div>
        </div>
        <div className="skill-desc">
          <h3 className="skill-subheading">Description</h3>
          <p>{skillDetails?.Description}</p>
        </div>
      </div>
      <div className="skill-detaill-right-full">
        <div className="use-skill-row-wrap">
        {
              ((isSuperAdmin && canUpdate) || isUserIdExist) && 
          <div className="edit-button-icon">
            <Link
              className="edit-icon"
              to={
                IsThirdPartyAITool ? "/updatethirdpartyaitools" : "/updateSkill"
              }
              state={{ id: selectedSkill?.Id }}
            ></Link>
            <Link
              to={
                IsThirdPartyAITool ? "/updatethirdpartyaitools" : "/updateSkill"
              }
              state={{ id: selectedSkill?.Id }}
              className=""
            >
              Edit
            </Link>
          </div>
          }
          {
          ((isSuperAdmin && canDelete) || isUserIdExist) && 
          <div className="trash-button-icon">
          <div className="trash-icon" onClick={() => deleteSkill()}></div>
          <Link onClick={() => deleteSkill()} to="">
            Delete
          </Link>
           </div>
          }
           
        </div>
        <div className="skill-detaill-right-section">
          <div className="use-skill-row">
            {skillDetails?.IsThirdPartyAITool &&
              skillDetails?.ChatBotURL !== null && (
                <div
                  onClick={() => handleURLRedirect(skillDetails?.ChatBotURL)}
                  className="button light w-button text-center"
                >
                  Use Skill
                </div>
              )}
            {skillDetails?.IsThirdPartyAITool &&
              skillDetails?.ChatBotURL === null && (
                <div className="disabled-element">
                  <div className="button light w-button text-center disabled-div">
                    Use Skill
                  </div>
                </div>
              )}
            {skillDetails?.IsThirdPartyAITool === false && (
              <div
                onClick={() => handleUseSkillToNavigate()}
                className="button light w-button text-center"
              >
                Use Skill
              </div>
            )}
            <div className="infowrap">
              <div className="infotext">How can i access this skill?</div>
            </div>
          </div>

          <h3>Applicability</h3>
          <div className="skill-info">
            <div className="skill-subtitle">Department</div>
            <div className="skill-subtitle-info">
              {skillDetails?.DepartmentName}
            </div>
          </div>
          <div className="skill-info">
            <div className="skill-subtitle">Function</div>
            <div className="keyword-block">
              {skillDetails?.functions?.map((func) => (
                <div key={func?.Id} className="skill-keyword">
                  {func?.Name}
                </div>
              ))}
            </div>
          </div>
          <div className="skill-info">
            <div className="skill-subtitle">Sub Function</div>
            <div className="keyword-block">
              {skillDetails?.subFunctions?.map((func) => (
                <div key={func?.Id} className="skill-keyword">
                  {func?.Name}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {isShowDeleteConfirmation && (
        <DeleteSkillConfirmation
          handleClosePopup={handleClosePopup}
          skillStoreId={selectedSkill?.Id}
          IsThirdPartyAITool={IsThirdPartyAITool}
        />
      )}
    </div>
  );
};

export default SkillStoreDetail;
