import React from "react";
import TabLink from "../tabLink";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";

const PromptResults = ({
  showTab,
  setShowTab,
  chatbotResponse,
  promptStepOneDataOriginal,
}) => {
  const handleNullValues = (value) => {
    if (value === undefined || value === null) return "";
    else {
      return value;
    }
  };

  return (
    <>
      <div className="dash-section p20 prompt-step-two">
        <div className="prompt-tabs w-tabs">
          <div
            className="tabs-menu h-flex head-wrapper w-tab-menu test-tab"
            role="tablist"
          >
            <TabLink
              tabId="1"
              href="#"
              isSelected={showTab === 1}
              onClick={() => setShowTab(1)}
            >
              Result
            </TabLink>
            <TabLink
              tabId="2"
              href="#"
              isSelected={showTab === 2}
              onClick={() => setShowTab(2)}
            >
              Prompts Input
            </TabLink>
          </div>
          <div className="w-tab-content">
            {showTab === 1 && (
              <>
                <div className="result-tab-content">
                  <ReactMarkdown
                    remarkPlugins={[remarkGfm]}
                    components={{
                      ol: ({ children }) => (
                        <ol style={{ paddingLeft: "1.5rem", margin: "0" }}>
                          {children}
                        </ol>
                      ),
                      li: ({ children }) => (
                        <li
                          style={{
                            whiteSpace: "normal",
                            wordBreak: "break-word",
                          }}
                        >
                          {children}
                        </li>
                      ),
                    }}
                    rehypePlugins={[rehypeRaw]}
                  >
                    {chatbotResponse.description}
                  </ReactMarkdown>
                </div>
              </>
            )}

            {showTab === 2 && (
              <>
                <div className="input-tab-content">
                  <h3 className="chathead">Usecase Information</h3>
                  <p>
                    {promptStepOneDataOriginal?.ShortDescription === undefined
                      ? handleNullValues(promptStepOneDataOriginal?.description)
                      : handleNullValues(
                          promptStepOneDataOriginal?.ShortDescription
                        )}
                  </p>
                </div>
                <div className="input-tab-content">
                  <h3 className="chathead">Input Information</h3>
                  <p>
                    {promptStepOneDataOriginal?.ShortDescription === undefined
                      ? handleNullValues(
                          promptStepOneDataOriginal?.promptText1
                        ) +
                        " " +
                        handleNullValues(promptStepOneDataOriginal?.promptText2)
                      : handleNullValues(
                          promptStepOneDataOriginal?.PromptBackground
                        ) +
                        " " +
                        handleNullValues(
                          promptStepOneDataOriginal?.DesiredPromptOutput
                        )}{" "}
                  </p>
                </div>
                <div className="input-tab-content">
                  <h3 className="chathead">Format Information</h3>
                  <p>
                    {" "}
                    {promptStepOneDataOriginal?.ShortDescription === undefined
                      ? handleNullValues(promptStepOneDataOriginal?.promptText3)
                      : handleNullValues(
                          promptStepOneDataOriginal?.SourceOnInputOutput
                        )}
                  </p>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PromptResults;
