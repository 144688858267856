import React, { useEffect, useState, useRef } from "react";

const SearchByFunction = (props) => {
  const { searchFunctionData, onSearchFunctionSelection } = props;

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedOptionsName, setSelectedOptionsName] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [functionData, setFunctionData] = useState([]);
  const allCheckboxRef = useRef(null);

  useEffect(() => {
    const allFunctionData = searchFunctionData || [];
    setFunctionData(allFunctionData);
    setSelectedOptions([]);
    setSelectedOptionsName([]);
    setIsAllSelected(false);
  }, [searchFunctionData]);

  // Handle individual checkbox change
  const handleSelectChange = (item) => {
    let updatedSelectedOptions;
    let updatedSelectedOptionName;

    if (selectedOptions.includes(item.Id)) {
      // Remove item if already selected
      updatedSelectedOptions = selectedOptions.filter((id) => id !== item.Id);
      updatedSelectedOptionName = selectedOptionsName.filter(
        (name) => name !== item.Name
      );
    } else {
      // Add item if not selected
      updatedSelectedOptions = [...selectedOptions, item.Id];
      updatedSelectedOptionName = [...selectedOptionsName, item.Name];
    }

    setSelectedOptions(updatedSelectedOptions);
    setSelectedOptionsName(updatedSelectedOptionName);
    onSearchFunctionSelection(updatedSelectedOptions);

    // Update "All" checkbox status based on current selection
    if (updatedSelectedOptions.length === functionData.length) {
      // If all items are selected, fully check the "All" checkbox
      setIsAllSelected(true);
      if (allCheckboxRef.current) {
        allCheckboxRef.current.indeterminate = false;
      }
    } else if (updatedSelectedOptions.length === 0) {
      // If no items are selected, uncheck the "All" checkbox
      setIsAllSelected(false);
      if (allCheckboxRef.current) {
        allCheckboxRef.current.indeterminate = false;
      }
    } else {
      // If some but not all items are selected, set "All" to indeterminate
      if (allCheckboxRef.current) {
        allCheckboxRef.current.indeterminate = true;
      }
    }
  };

  // Handle "All" checkbox change
  const handleSelectAllChange = () => {
    if (isAllSelected) {
      // Uncheck all checkboxes when "All" is unchecked
      setSelectedOptions([]);
      setIsAllSelected(false);
      if (allCheckboxRef.current) {
        allCheckboxRef.current.indeterminate = false;
      }
      onSearchFunctionSelection([]);
      setSelectedOptionsName([]);
    } else {
      // Select all checkboxes when "All" is checked
      const allIds = functionData?.map((item) => item.Id);
      const allNames = functionData?.map((item) => item.Name);
      setSelectedOptions(allIds);
      setSelectedOptionsName(allNames);
      setIsAllSelected(true);
      if (allCheckboxRef.current) {
        allCheckboxRef.current.indeterminate = false;
      }
      onSearchFunctionSelection(allIds);
    }
  };

  const isSelected = (itemId) => selectedOptions.includes(itemId);

  return (
    <div className="custom-dropdown">
      <div className="dropdown-button">
        {selectedOptionsName.length === 0 && <span>Select Functions</span>}
        
        <span title={selectedOptionsName} className="all-selected-item">
          {selectedOptionsName.length > 0 &&
            selectedOptionsName.slice(0, 1).map((name, index) => (
              <span className="selected-val" key={index}>
                {name}
              </span>
            ))}
          {selectedOptions?.length > 1 && (
            <span className="more-tags">+{selectedOptions?.length - 1}</span>
          )}
        </span>
        <span className="down-arrow"></span>
      </div>
      {functionData?.length > 0 && (
        <ul className="dropdown-menu-custom">
          {functionData?.length > 1 && (
            <li>
              <label>
                <input
                  type="checkbox"
                  ref={allCheckboxRef}
                  value="All"
                  checked={isAllSelected}
                  onChange={handleSelectAllChange}
                />
                All
              </label>
            </li>
          )}
          {functionData?.map((item) => (
            <li key={item.Id}>
              <label>
                <input
                  type="checkbox"
                  value={item.Id}
                  checked={isSelected(item.Id)}
                  onChange={() => handleSelectChange(item)}
                />
                {item.Name}
              </label>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SearchByFunction;
