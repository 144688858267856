import React from "react";
import { Link } from "react-router-dom";

const BreadCrumb = ({ editPromptTitle }) => {
  return (
    <div className="breadcrumb">
      <Link className="visited home-link" to="/home">
        
      </Link>
      <Link className="visited normal-link" to="/promptmanagement">
        Prompt Management
      </Link>
      <div className="normal-link">
        {editPromptTitle !== undefined &&
        editPromptTitle !== null &&
        editPromptTitle !== ""
          ? editPromptTitle
          : "Design Your Prompt"}
      </div>
    </div>
  );
};

export default BreadCrumb;
