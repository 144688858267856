import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ChatBox from "../chatBox";
import { UserContext } from "../../../../../../context/user/userContext";
import MksChatContext from "../../../../context/mksContext";
import { DisableFollowupQuestions } from "../../../services/chatService.js";

const ChatTerminalHeader = (props) => {
  const { isMKSChatSelected, chatMessage, setIsSkillChat, isFileType } = props;

  const [isMKSChat, setIsMKSChat] = useState(isMKSChatSelected);
  const [message, setMessage] = useState(chatMessage);
  const [defaultSkillWelMessage, setDefaultSkillWelMessage] = useState(false);
  const [isClearChat, setIsClearChat] = useState(false);
  const [isChatVisible, setIsChatVisible] = useState(false);
  const [openPdfViewer, setOpenPdfViewer] = useState(false);
  const [isFileTypeChat, setIsFileTypeChat] = useState(isFileType);
  const [isMKSProcessing, setIsMKSProcessing] = useState(false);
  const [isSkillProcessing, setIsSkillProcessing] = useState(false);
  const [disableRegenerateButton, setDisableRegenerateButton] = useState(true);
  const [disableStopGenerateButton, setDisableStopGenerateButton] =
    useState(true);
  const [showFollowupQuestion, setShowFollowupQuestion] = useState(false);
  const [disableFolloupButton, setDisableFolloupButton] = useState(false);
  const [selectedSkill, setSelectedSkill] = useState(null);

  var context = useContext(MksChatContext);
  const { isMksChatEnable, isSkillChatEnable } = context;

  const navigate = useNavigate();

  useEffect(() => {
    if (isMksChatEnable && isMKSChat) handleMKSChat();
    else {
      handleSkillChat();
    }
  }, [isMksChatEnable]);

  const handleMKSChat = () => {
    if (!isMKSChat) {
      setIsMKSChat(true);
      setMessage("");
      setDefaultSkillWelMessage(true);
      setOpenPdfViewer(false);
      setIsFileTypeChat(false);
      setDisableRegenerateButton(true);
      setDisableStopGenerateButton(true);
      navigate("/chat", {
        state: {
          isMKSChat: true,
          message: "",
          isFileTyple: false,
        },
      });
    }
  };
  const handleSkillChat = () => {
    if (isMKSChat) {
      setIsMKSChat(false);
      setMessage("");
      setDefaultSkillWelMessage(true);
      setIsFileTypeChat(false);
      setDisableRegenerateButton(true);
      setDisableStopGenerateButton(true);
      navigate("/chat", {
        state: {
          isMKSChat: false,
          message: "",
          isFileTyple: false,
        },
      });
    }
  };

  const showHideClearChatBtn = (isChatVisible) => {
    setIsChatVisible(isChatVisible);
  };

  const clearChat = () => {
    setIsClearChat(true);
    setIsSkillProcessing(false);
    setIsMKSProcessing(false);
    setOpenPdfViewer(false);
    setDisableRegenerateButton(true);
    setDisableStopGenerateButton(true);
  };

  const disabledFollowupButton = () => {
    const data = {
      SkillStoreId: selectedSkill.Id,
      Status: disableFolloupButton ? false : true,
    };
    DisableFollowupQuestions(data);
    setDisableFolloupButton(disableFolloupButton ? false : true);
  };

  return (
    <>
      {isMksChatEnable && isSkillChatEnable && (
        <div className="chat-terminal-header">
          <div
            className={`${
              !isMKSProcessing && isSkillProcessing ? "disabled-element" : ""
            }`}
          >
            <div
              onClick={() => handleMKSChat()}
              aria-current="page"
              className={`terminal-button w-button ${
                !isMKSProcessing && isSkillProcessing ? "disabled-div" : ""
              } ${isMKSChat ? "w--current" : ""} `}
            >
              MKS Chat
            </div>
          </div>
          <div
            className={`${
              isMKSProcessing && !isSkillProcessing ? "disabled-element" : ""
            }`}
          >
            <div
              onClick={() => handleSkillChat()}
              className={`terminal-button w-button ${
                isMKSProcessing && !isSkillProcessing ? "disabled-div" : ""
              } ${!isMKSChat ? "w--current" : ""} `}
            >
              Skills Chat
            </div>
          </div>
        </div>
      )}
      {isChatVisible && (
        <>
          <div
            className={`${
              isMKSProcessing || isSkillProcessing ? "disabled-element" : ""
            }`}
          >
            <div
              className={`clear-chatbtn ${
                isMKSProcessing || isSkillProcessing ? "disabled-div" : ""
              }`}
            >
              <div
                onClick={() => clearChat()}
                className="trash-icon"
                data-title="Clear Chat"
              ></div>
            </div>
          </div>
        </>
      )}
      {showFollowupQuestion && (
        <div
          className={`${
            isMKSProcessing || isSkillProcessing ? "disabled-element" : ""
          }`}
        >
          <div
            className={`show-followup-btn ${
              isMKSProcessing || isSkillProcessing ? "disabled-div" : ""
            }`}
          >
            <div
              onClick={() => disabledFollowupButton()}
              className={`follow-up-icon ${
                disableFolloupButton ? "follow-up-icon-blur" : ""
              }`}
              data-follow="Follow up Question"
            ></div>
          </div>
        </div>
      )}

      {(isMksChatEnable || isSkillChatEnable) && (
        <ChatBox
          chatMessage={message}
          isMKSChat={isMKSChat}
          setIsSkillChat={setIsSkillChat}
          defaultSkillWelMessage={defaultSkillWelMessage}
          setDefaultSkillWelMessage={setDefaultSkillWelMessage}
          isFileType={isFileType}
          setIsClearChat={setIsClearChat}
          isClearChat={isClearChat}
          isChatVisible={isChatVisible}
          showHideClearChatBtn={showHideClearChatBtn}
          openPdfViewer={openPdfViewer}
          setOpenPdfViewer={setOpenPdfViewer}
          setIsFileTypeChat={setIsFileTypeChat}
          isFileTypeChat={isFileTypeChat}
          isMKSProcessing={isMKSProcessing}
          setIsMKSProcessing={setIsMKSProcessing}
          isSkillProcessing={isSkillProcessing}
          setIsSkillProcessing={setIsSkillProcessing}
          setDisableRegenerateButton={setDisableRegenerateButton}
          setDisableStopGenerateButton={setDisableStopGenerateButton}
          disableRegenerateButton={disableRegenerateButton}
          disableStopGenerateButton={disableStopGenerateButton}
          showFollowupQuestion={showFollowupQuestion}
          setDisableFolloupButton={setDisableFolloupButton}
          disableFolloupButton={disableFolloupButton}
          setShowFollowupQuestion={setShowFollowupQuestion}
          setSelectedSkill={setSelectedSkill}
        />
      )}
    </>
  );
};

export default ChatTerminalHeader;
