import React from "react";

const PromptSteps = (props) => {
  const { currentStep } = props;

  return (
    <div className="prompt-steps">
      <div className="prompt-step">
        <div className="prompt-step-name">Write a Prompt</div>
        <div className="prompt-step-desc">
          Explain your use case and set your preference
        </div>
        <div className="p-dot-wrapper p-done">
          <div className="p-dot"></div>
        </div>
      </div>
      <div className="prompt-step">
        <div className="prompt-step-name">Prompt Review</div>
        <div className="prompt-step-desc">Review generated prompt</div>
        <div
          className={`p-dot-wrapper ${
            currentStep > 1 ? "p-done" : "p-current"
          }`}
        >
          <div className="p-dot"></div>
        </div>
      </div>
      <div className="prompt-step">
        <div className="prompt-step-name">Test Prompt</div>
        <div className="prompt-step-desc">Review prompt response</div>
        <div
          className={`p-dot-wrapper ${
            currentStep > 2 ? "p-done" : "p-current"
          }`}
        >
          <div className="p-dot"></div>
        </div>
      </div>
      <div className="prompt-step">
        <div className="prompt-step-name">Finalize Prompt</div>
        <div className="prompt-step-desc">Finalize and save the prompt</div>
        <div
          className={`p-dot-wrapper ${
            currentStep > 3 ? "p-done" : ""
          }`}
        >
          <div className="p-dot"></div>
        </div>
      </div>
    </div>
  );
};

export default PromptSteps;
