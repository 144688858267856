import axiosApiInstance from "../../../../api/axiosApiClient";
import { InternalAPIError } from "../../../../utils/apiErrors";
import { handleApiError } from "../../../../utils/globalErrorHandler";

const BASE_MKSSKILLSTORE_API_URL = process.env.REACT_APP_MKS_API_URL;

export const getNotificationsData = async () => {
  try {
    const response = await axiosApiInstance.get(
      `${BASE_MKSSKILLSTORE_API_URL}notification/GetNotifications`
    );

    if (response.status === 200) {
      return response.data;
    } else {
      handleApiError(new InternalAPIError());
    }
  } catch (error) {
    handleApiError(new InternalAPIError());
  }
};

export const updateNotificationReadStatusById = async (id) => {
  try {
    const response = await axiosApiInstance.put(
      `${BASE_MKSSKILLSTORE_API_URL}notification/UpdateNotificationReadStatus?notificationId=${id}`
    );
    if (response.status === 200) {
      return response.data;
    } else {
      handleApiError(new InternalAPIError());
    }
  } catch (error) {
    handleApiError(new InternalAPIError());
  }
};
