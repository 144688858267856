import React, { useContext, useEffect, useRef, useState } from "react";

import AppImages from "../../../../../../constants/images";
import ChatPrompt from "../chatBox/chatPrompt";
import { clearPromptMessageSeesion } from "../../../../../../services/commonService";

import { useUserName } from "../../../../../common/customHooks/useUserName";
import { useLocation } from "react-router-dom";
import PdfURLContext from "../../../../../../context/pdfURL/pdfURLContext";
import ChatHistory from "../chatHistory";
import axios from "axios";
import FileChatbot from "./fileChatbot";
import TextChatbot from "./textChatbot";
import { useHeaderContext } from "../../../../../../context/user/headerContext";
import showMessage from "../../../../../common/message";
import { getDefaultChatbotConfig } from "../../../../shared/defaultChatBotConfig/defaultConfigService";
import { filterTopMessages } from "../../../../promptManagement/shared/promptConverter";

const ChatBox = ({
  isMKSChat,
  chatMessage,
  setIsSkillChat,
  defaultSkillWelMessage,
  setDefaultSkillWelMessage,
  setIsClearChat,
  isClearChat,
  isChatVisible,
  showHideClearChatBtn,
  openPdfViewer,
  setOpenPdfViewer,
  setIsFileTypeChat,
  isFileTypeChat,
  isMKSProcessing,
  setIsMKSProcessing,
  isSkillProcessing,
  setIsSkillProcessing,
}) => {
  const [messages, setMessages] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const [lastDisplayMessage, setLastDisplayMessage] = useState("");
  const { initials, fullName } = useUserName();
  const bottomPanelRef = useRef(null);
  const [isRunning, setIsRunning] = useState(false);
  const intervalRef = useRef(null);
  const [currentMessage, setCurrentMessage] = useState("");
  const [showChatLoader, setShowChatLoader] = useState(false);
  bottomPanelRef.current?.scrollIntoView({ behavior: "smooth" });
  const [runningIndex, setRunningIndex] = useState(0);
  const [fileName, setFileName] = useState("");
  const { state } = useLocation();
  const { url, setUrl } = useContext(PdfURLContext);
  const [skill, setSkill] = useState(state?.skill);
  const [promptText, setPromptText] = useState(state?.promptText);
  const [navigateFromHome, setNavigateFromHome] = useState(
    state?.navigateFromHome
  );
  const [displayLastCitations, setDisplayLastCitations] = useState([]);
  const [selectedAdditives, setSelectedAdditives] = useState([]);
  const [uploadedFilesFromChat, setUploadedFilesFromChat] = useState([]);
  const [clearUploadedFile, setClearUploadedFile] = useState(false);
  const [selectedCitation, setSelectedCitation] = useState([]);
  const [disableStopGenerateButton, setDisableStopGenerateButton] =
    useState(true);
  const { setHeaderState } = useHeaderContext();
  const [resetAdditives, setResetAdditives] = useState(false);
  const [mksDefaultConfiguration, setMksDefaultConfiguration] = useState();
  const [mksUserMessages, setMksUserMessages] = useState([]);
  const [genericAPIUserMessages, setGenericAPIUserMessages] = useState([]);
  const [resumeCitations, setResumeCitations] = useState([]);
  const [uploadedFileDetails, setUploadedFileDetails] = useState([]);
  const [isBlackBoxAdded, setIsBlackBoxAdded] = useState(false);
  const [blackBoxAdditives, setBlackBoxAdditives] = useState("");
  const [init, setInit] = useState(false);
  const [disableRegenerateButton, setDisableRegenerateButton] = useState(true);
  const [lastDisplayFileMessage, setLastDisplayFileMessage] = useState([]);

  useEffect(() => {
    setMessages([]);
    if (isMKSChat) {
      setSkill(null);
      getSessionStorageMessage(true);
      setHeaderState({
        headerValue: "",
        ShortDescription: "",
      });
      getDefaultChatbotConfig().then((data) => {
        setMksDefaultConfiguration(data);
      });
    }
  }, [isMKSChat]);

  useEffect(() => {
    if (!isMKSChat && skill !== null && skill !== undefined) {
      getSessionStorageMessage(false);
      setHeaderState({
        headerValue: "",
        ShortDescription: "",
      });
    }
  }, [skill]);

  useEffect(() => {
    if (bottomPanelRef.current) {
      bottomPanelRef.current.scrollIntoView({ behavior: "smooth" });
    }
    showHideClearChatBtn(messages?.length === 0 ? false : true);
    if (
      messages?.length > 0 &&
      !isMKSChat &&
      skill !== null &&
      skill !== undefined
    ) {
      setHeaderState({
        headerValue: skill.Name,
        ShortDescription: skill.ShortDescription,
      });
    } else if (messages?.length > 0 && isMKSChat) {
      setHeaderState({
        headerValue: "MKS Chat",
        ShortDescription: "",
      });
    } else if (messages?.length === 0) {
      setHeaderState({
        headerValue: "",
        ShortDescription: "",
      });
    }
  }, [messages]);

  useEffect(() => {
    if (isClearChat) {
      if (isMKSChat) {
        clearMKSData();
      } else {
        clearSKILLData();
      }
      setMessages([]);
    }
    setIsClearChat(false);
  }, [isClearChat]);

  const getSessionStorageMessage = (typeMKSChat) => {
    let localMessage = sessionStorage.getItem("messages") || "";
    if (localMessage === "") localMessage = [];
    else localMessage = JSON.parse(localMessage);
    if (localMessage.length === 0) {
    } else {
      setDisplayLastCitations([]);
      if (typeMKSChat) {
        const data = localMessage.filter((item) => item.chatType === true);

        if (data.length > 0) {
          setLastDisplayMessage(data[data.length - 1].text);
          setMessages(data);
        } else {
          setMessages([]);
        }
      } else {
        const data = localMessage.filter(
          (item) => !item.chatType && item.skill === skill?.Name
        );
        if (data.length > 0) {
          setLastDisplayMessage(data[data.length - 1].text);
          setDisplayLastCitations(data[data.length - 1].citations);
          setMessages(data);
        }
      }
    }
  };

  const setMessageToSession = (value) => {
    let localMessage = sessionStorage.getItem("messages") || "";
    if (localMessage === "") {
      localMessage = [];
    } else {
      localMessage = JSON.parse(localMessage);
    }
    localMessage.push(value);
    sessionStorage.setItem("messages", JSON.stringify(localMessage));
  };

  const clearMKSData = () => {
    try {
      // Retrieve and parse the "messages" from sessionStorage
      let localMessage = sessionStorage.getItem("messages");
      if (!localMessage) {
        localMessage = []; // Default to an empty array if no messages exist
      } else {
        localMessage = JSON.parse(localMessage); // Parse the JSON string
      }

      // Filter out messages without `chatType`
      if (Array.isArray(localMessage) && localMessage.length > 0) {
        const data = localMessage.filter((item) => !item.chatType);

        // Clear the old messages from sessionStorage
        clearPromptMessageSeesion();
        sessionStorage.setItem("messages", JSON.stringify(data));
      }
    } catch (error) {
      console.error("Error in clearMKSData:", error);
    }
  };

  const clearSKILLData = () => {
    try {
      // Retrieve and parse the "messages" from sessionStorage
      let localMessage = sessionStorage.getItem("messages");
      if (!localMessage) {
        localMessage = []; // Default to an empty array if no messages exist
      } else {
        localMessage = JSON.parse(localMessage); // Parse the JSON string
      }

      // Filter out messages without `chatType`
      if (Array.isArray(localMessage) && localMessage.length > 0) {
        const data = localMessage.filter((item) => item.skill !== skill?.Name);

        // Clear the old messages from sessionStorage
        clearPromptMessageSeesion();

        sessionStorage.setItem("messages", JSON.stringify(data));
      }
    } catch (error) {
      console.error("Error in clearMKSData:", error);
    }
  };

  const handleAIGenericResponse = async (inputText) => {
    // if (inputText !== undefined && inputText !== null) {
    //   inputText = inputText
    //     .replace(/\n/g, "\\n") // Escape newlines
    //     .replace(/\r/g, "\\r") // Escape carriage returns
    //     .replace(/\\/g, "\\\\");
    // }

    if (!isMKSChat) {
      if (skill?.ChatbotConfiguration?.FileType === null) {
        handleLoadGenericApiConfigurations(inputText);
        setIsSkillProcessing(true);
        setIsMKSProcessing(false);
      } else if (isFileTypeChat) {
        handleFileUploadApiConfigurations(inputText);
      }
    } else {
      handleMksAIResponse(inputText);
      setIsSkillProcessing(false);
      setIsMKSProcessing(true);
    }
  };

  const convertCollectionData = (collection) => {
    if (collection[0].length !== undefined) {
      return collection[0]
        .map((item) => `${item.Value}=${item.checked}`)
        .join("&");
    } else {
      return collection
        .map((item) => `${item.Value}=${item.checked}`)
        .join("&");
    }
  };

  // const validateFiles = (additivess) => {
  //   if (uploadedFilesFromChat.length === 0) {
  //     showMessage("Please upload a file", -2);
  //     setIsSkillProcessing(true);
  //     setIsMKSProcessing(true);
  //     return false;
  //   }
  //   if (additivess.some((item) => item.checked === true)) {
  //     return true;
  //   } else {
  //     setSelectedAdditives([]);
  //     setIsSkillProcessing(true);
  //     setIsMKSProcessing(true);
  //     showMessage("Please select at least one additive", -2);
  //     return false;
  //   }
  // };

  const handleFileUploadApiConfigurations = async () => {
    let uploadedContent = [];
    let citations = [];
    let response = "";
    setUploadedFileDetails([]);

    let additivess = selectedAdditives.filter(
      (item) => item.Name !== "Translate"
    );

    const selectedAdditivesArray = additivess.map((item) => item.Value);

    setIsSkillProcessing(true);
    setIsMKSProcessing(false);

    let blackBox = selectedAdditives.filter(
      (item) => item.Name === "Blackbox"
    )[0];
    let blackBoxAdded = false;
    if (blackBox.checked) {
      blackBoxAdded = true;
      setIsBlackBoxAdded(true);
    } else {
      blackBoxAdded = false;
      setIsBlackBoxAdded(false);
    }

    setShowChatLoader(true);
    const userMessage = {
      text: "",
      type: "user",
      imageURL: AppImages.profilePic,
      citations: [],
      files: uploadedFilesFromChat,
      skill: skill?.Name,
    };
    setMessages((prev) => [...prev, userMessage]);
    //setMessageToSession(userMessage);
    const aiResponse = {
      text: "loader",
      type: "reply",
      imageURL: AppImages.chatAIIcon,
      citations: [],
      file: [],
    };
    setMessages((prev) => [...prev, aiResponse]);
    setBlackBoxAdditives("");
    let counter = 0;
    setLastDisplayFileMessage([]);
    let uploadedAllFiles = [];
    setClearUploadedFile(true);
    uploadedFilesFromChat.forEach(async (file) => {
      const queryString = convertCollectionData(selectedAdditives);
      let url = skill.aiUrl.replace("$additives", queryString);
      setShowChatLoader(true);
      setDisplayLastCitations([]);
      setIsTyping(true);
      const FormData = require("form-data");
      // const fs = require("fs");
      let data = new FormData();
      data.append("file", file);

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: removeTrailingSlash(url),
        headers: skill.headers,
        data: data,
      };
      const axiosApiInstance = axios.create();
      axiosApiInstance.post(config.url, data, config).then((resp) => {
        uploadedContent.push(resp.data);

        uploadedAllFiles.push({
          fileName: file.name,
          jsonOutPut: JSON.stringify(resp.data),
          selectedAdditivesArray: selectedAdditivesArray,
          isBlackBoxAdded: blackBoxAdded,
        });

        counter = counter + 1;

        if (uploadedContent.length === uploadedFilesFromChat.length) {
          response = JSON.stringify(uploadedContent).toString();
          const botMessage = {
            text: response,
            type: "reply",
            imageURL: AppImages.chatAIIcon,
            citations: citations,
            files: uploadedFilesFromChat,
            skill: skill?.Name,
            uploadedFileDetails: uploadedAllFiles,
            selectedAdditivesArray: selectedAdditivesArray,
            isBlackBoxAdded: blackBoxAdded,
          };
          //setMessageToSession(botMessage);
          setMessages((prev) => [
            ...prev.filter((p) => p.text !== "loader"),
            botMessage,
          ]);
          setLastDisplayFileMessage(uploadedAllFiles);
          setBlackBoxAdditives(response);
          setShowChatLoader(false);
          setUploadedFilesFromChat([]);
          setUploadedFileDetails([...uploadedAllFiles]);
          setResetAdditives((prev) => !prev);
          setSelectedAdditives([]);
          setIsTyping(false);
        }
        setClearUploadedFile(true);
      });
      setClearUploadedFile((clearUploadedFile) => !clearUploadedFile);
    });

    // axios.all(apiRequst).then(
    //   axios.spread((response1, response2, response3, response4, response5) => {
    //     uploadedContent.push(response1.data);
    //     uploadedContent.push(response2.data);
    //     uploadedContent.push(response3.data);
    //     uploadedContent.push(response4.data);
    //     uploadedContent.push(response5.data);
    //   })
    // );
  };

  const handleLoadGenericApiConfigurations = async (inputText) => {
    if (!inputText?.trim()) {
      return;
    }

    setShowChatLoader(true);
    setLastDisplayMessage("");
    setDisplayLastCitations([]);
    const userMessage = {
      text: inputText,
      type: "user",
      imageURL: AppImages.profilePic,
      citations: [],
      chatType: isMKSChat,
      skill: skill?.Name,
    };
    setMessageToSession(userMessage);
    setMessages((prev) => [...prev, userMessage]);

    let formatedInputText = inputText
      .replace(/\n/g, "\\n") // Escape newlines
      .replace(/\r/g, "\\r") // Escape carriage returns
      .replace(/\\/g, "\\\\");
    formatedInputText = formatedInputText.replace(
      /"([^"]*?)"(?=\s|[:,}])/g,
      '\\"$1\\"'
    );

    let userMessageData = skill.userMessage.replace(
      "$Message",
      `"${formatedInputText}"`
    );
    let lastMessageCount =
      skill.lastMessageCount == null ? 0 : skill.lastMessageCount;

    let parsedUserMessageData = JSON.parse(userMessageData);

    const finalRequestBody = [];
    let isStreamAPI = true;
    let oldgenericAPIUserMessages = genericAPIUserMessages;

    let previousSentMessages = filterTopMessages(
      genericAPIUserMessages,
      parseInt(lastMessageCount)
    );

    if (genericAPIUserMessages.length > 0) {
      previousSentMessages.forEach((msg) => {
        finalRequestBody.push(msg);
      });
    }

    finalRequestBody.push(parsedUserMessageData);
    let msgs = JSON.stringify(finalRequestBody);
    msgs = msgs.substring(1, msgs.length - 1);

    let userMessageModel2 = skill.messageBody.replace("$UserMessage", msgs);
    oldgenericAPIUserMessages.push(parsedUserMessageData);
    userMessageModel2 = JSON.parse(userMessageModel2);
    isStreamAPI = userMessageModel2.stream;
    userMessageModel2 = JSON.stringify(userMessageModel2);

    const aiResponse = {
      text: "loader",
      type: "reply",
      imageURL: AppImages.chatAIIcon,
      citations: [],
      chatType: isMKSChat,
      skill: skill?.Name,
    };
    setMessages((prev) => [...prev, aiResponse]);

    let response = "";
    const responseA = await fetch(removeTrailingSlash(skill.aiUrl), {
      method: "post",
      headers: skill.headers,
      body: userMessageModel2,
    });
    if (!responseA.ok || !responseA.body) {
      throw responseA.statusText;
    }

    const reader = responseA.body.getReader();
    const decoder = new TextDecoder("utf-8");
    const loopRunner = true;
    let citations = [];
    while (loopRunner) {
      try {
        const { value, done } = await reader.read();
        if (done) {
          break;
        }
        let decodedChunk = decoder.decode(value, { stream: true });

        let replacedChunk = decodedChunk.replace("data: ", "");
        let findContext =
          replacedChunk.indexOf("context") &&
          replacedChunk.indexOf("citations");
        if (findContext > -1) {
          try {
            citations =
              JSON.parse(replacedChunk)?.choices[0]?.delta?.context?.citations;
          } catch (error) {}
        }

        if (isStreamAPI === true) {
          let splitChunk = replacedChunk.split("\n\ndata:").slice(0, -1);
          let datas = JSON.parse(JSON.stringify(splitChunk));
          let counter = 0;
          datas.forEach((da) => {
            let jsonArray = null;
            try {
              jsonArray = JSON.parse(da);
              if (jsonArray.choices.length > 0) {
                const text = jsonArray.choices[0].delta?.content || "";
                if (counter < 2 && (text.match(/\n/) || []).length) {
                  return;
                }
                response = response + text;
                setIsTyping(true);
                counter++;
              }
            } catch (parseError) {
              console.log(
                "Error parsing JSON array item:",
                parseError,
                " Raw Item ",
                da
              );
            }
          });
        } else {
          setIsTyping(false);
          let datas = JSON.parse(replacedChunk);
          citations = datas?.choices[0]?.message?.context?.citations;
          response = datas?.choices[0]?.message?.content;
          // datas.forEach((da) => {
          //   let jsonArray = null;
          //   try {
          //     jsonArray = JSON.parse(da);
          //     if (jsonArray.choices.length > 0) {
          //       const text = jsonArray.choices[0].message?.content || "";
          //       if (counter < 2 && (text.match(/\n/) || []).length) {
          //         return;
          //       }
          //       response = response + text;
          //       setIsTyping(true);
          //       counter++;
          //     }
          //   } catch (parseError) {
          //     console.log(
          //       "Error parsing JSON array item:",
          //       parseError,
          //       " Raw Item ",
          //       da
          //     );
          //   }
          // });
        }
      } catch (readError) {
        console.log("Error reading the response stream:", readError);
        break; // Exit the loop if reading fails
      }

      setShowChatLoader(false);
    }
    const botMessage = {
      text: response,
      type: "reply",
      imageURL: AppImages.chatAIIcon,
      citations: citations,
      chatType: isMKSChat,
      skill: skill?.Name,
    };

    let assistantMessageModel = skill.AssistantMessageFormat.replace(
      "$replyMsg",
      `"${response}"`
    );
    let assistantMessage = {};
    try {
      assistantMessage = JSON.parse(assistantMessageModel);
    } catch (error) {
      assistantMessage = JSON.parse(
        skill.AssistantMessageFormat.replace("$replyMsg", `"$"`)
      );
      if (assistantMessage.content[0].type === undefined) {
        assistantMessage.content = response;
        assistantMessage.role = "assistant";
      } else {
        assistantMessage.content[0].text = response;
        assistantMessage.role = "assistant";
      }
    }

    oldgenericAPIUserMessages.push(assistantMessage);
    setGenericAPIUserMessages([...oldgenericAPIUserMessages]);
    setMessageToSession(botMessage);
    setMessages((prev) => [
      ...prev.filter((p) => p.text !== "loader"),
      botMessage,
    ]);
    setLastDisplayMessage("");
    setDisplayLastCitations([]);
    typeText(response, citations);
  };

  useEffect(() => {
    if (!isRunning && init) {
      messages.map((msg, index) => {
        if (messages.length - 1 === index) {
          msg.text = lastDisplayMessage;
          msg.citations = displayLastCitations;
        }
        return msg;
      });
      setMessages(messages);
    }
  }, [isRunning]);

  const handleMksAIResponse = async (inputText) => {
    if (!inputText?.trim()) {
      return;
    }

    if (mksDefaultConfiguration !== undefined) {
      setShowChatLoader(true);
      setLastDisplayMessage("");
      setDisplayLastCitations([]);

      let requestBody = mksDefaultConfiguration.body;
      const userMessage = {
        text: inputText,
        type: "user",
        imageURL: AppImages.profilePic,
        chatType: isMKSChat,
        skill: "",
      };
      setMessageToSession(userMessage);
      setMessages((prev) => [...prev, userMessage]);

      const aiResponse = {
        text: "loader",
        type: "reply",
        imageURL: AppImages.chatAIIcon,
        chatType: isMKSChat,
        skill: "",
      };
      setMessages((prev) => [...prev, aiResponse]);

      // requestBody = JSON.parse(
      //   requestBody.replace("$Message", `"${inputText}"`)
      // );
      const formatedInputText = inputText
        .replace(/\n/g, "\\n") // Escape newlines
        .replace(/\r/g, "\\r") // Escape carriage returns
        .replace(/\\/g, "\\\\");

      let userMessageModel = mksDefaultConfiguration.userMessage.replace(
        "$Message",
        `"${formatedInputText}"`
      );

      let lastMessageCount = mksDefaultConfiguration.lastMessageCount;
      // let parsedUserMessageModel = JSON.parse(userMessageModel);

      let oldmksUserMessages = mksUserMessages;
      const finalRequestBody = [];

      let previousSentMessages = filterTopMessages(
        mksUserMessages,
        parseInt(lastMessageCount)
      );

      if (mksUserMessages.length > 0) {
        previousSentMessages.forEach((msg) => {
          finalRequestBody.push(msg);
        });
      }
      finalRequestBody.push(JSON.parse(userMessageModel));
      let finalString = JSON.stringify(finalRequestBody);
      finalString = finalString.substring(1, finalString.length - 1);
      requestBody = requestBody.replace("$UserMessage", finalString);

      oldmksUserMessages.push(JSON.parse(userMessageModel));

      let response = "";

      try {
        const responseA = await fetch(
          removeTrailingSlash(mksDefaultConfiguration.url),
          {
            method: mksDefaultConfiguration.method,
            headers: mksDefaultConfiguration.headers,
            body: requestBody,
          }
        );

        if (!responseA.ok || !responseA.body) {
          throw new Error(
            `Request failed: ${responseA.statusText || "No response body"}`
          );
        }
        const reader = responseA.body.getReader();
        const decoder = new TextDecoder();
        const loopRunner = true;
        while (loopRunner) {
          try {
            // Read each chunk in the stream
            const { value, done } = await reader.read();
            if (done) {
              break;
            }

            // Decode the chunk and handle potential errors
            const decodedChunk = decoder.decode(value, { stream: true });
            let datas;

            try {
              // Process the decoded chunk
              datas = JSON.parse(
                JSON.stringify(
                  decodedChunk
                    .replace("data: ", "")
                    .split("\n\ndata:")
                    .slice(0, -1)
                )
              );
            } catch (decodeError) {
              console.log("Error processing decoded chunk:", decodeError);
              continue; // Skip this chunk if JSON parsing fails
            }

            // Process each item in the parsed data
            let counter = 0;
            datas.forEach((da) => {
              let jsonArray = null;
              try {
                jsonArray = JSON.parse(da);
                if (jsonArray.choices.length > 0) {
                  const text = jsonArray.choices[0].delta?.content || "";
                  if (counter < 2 && (text.match(/\n/) || []).length) {
                    return;
                  }
                  response = response + text;
                  setIsTyping(true);
                  counter++;
                }
              } catch (parseError) {
                console.log(
                  "Error parsing JSON array item:",
                  parseError,
                  " Raw Item ",
                  da
                );
              }
            });
          } catch (readError) {
            console.log("Error reading the response stream:", readError);
            break; // Exit the loop if reading fails
          }
        }
      } catch (fetchError) {
        console.log("Error fetching data:", fetchError);
        // Handle fetch error, such as displaying a message to the user
      }

      const botMessage = {
        text: response,
        type: "reply",
        imageURL: AppImages.chatAIIcon,
        citations: [],
        chatType: isMKSChat,
        skill: "",
      };

      let assistantMessageModel =
        mksDefaultConfiguration.assistantMessage.replace(
          "$replyMsg",
          `"${response}"`
        );
      let assistantMessage = {};
      try {
        assistantMessage = JSON.parse(assistantMessageModel);
      } catch (error) {
        assistantMessage = JSON.parse(
          mksDefaultConfiguration.assistantMessage.replace("$replyMsg", `"$"`)
        );
        if (assistantMessage.content[0].type === undefined) {
          assistantMessage.content = response;
          assistantMessage.role = "assistant";
        } else {
          assistantMessage.content[0].text = response;
          assistantMessage.role = "assistant";
        }
      }

      // let assistantMessage = JSON.parse(assistantMessageModel);
      mksUserMessages.push(assistantMessage);
      setMksUserMessages([...mksUserMessages]);
      setMessageToSession(botMessage);
      setMessages((prev) => [
        ...prev.filter((p) => p.text !== "loader"),
        botMessage,
      ]);
      setShowChatLoader(false);
      setLastDisplayMessage("");
      typeText(response, []);
    }
  };

  const removeTrailingSlash = (url) => {
    return url.endsWith("\\") ? url.slice(0, -1) : url;
  };

  const typeText = (replyText, citations) => {
    setCurrentMessage(replyText);
    setResumeCitations(citations);
    let index = 0;
    if (!isRunning) {
      setIsRunning(true);

      intervalRef.current = setInterval(() => {
        setLastDisplayMessage(
          (lastDisplayMessage) => lastDisplayMessage + replyText[index - 1]
        );
        setDisableStopGenerateButton(false);
        index++;
        setRunningIndex(index);
        if (index >= replyText.length) {
          clearInterval(intervalRef.current);
          setIsTyping(false);
          setIsRunning(false);
          setRunningIndex(replyText.length - 1);
          setDisplayLastCitations(citations);
          setDisableStopGenerateButton(true);
          setDisableRegenerateButton(true);
        }
      }, process.env.REACT_DEFAULT_TIME_INTERVAL);
    }
  };
  const pauseTimer = () => {
    if (isRunning) {
      setIsRunning(false);
      clearInterval(intervalRef.current);
      setIsMKSProcessing(false);
      setIsSkillProcessing(false);
      setDisableRegenerateButton(false);
    }
  };

  const resumeResponse = () => {
    let index = runningIndex;
    if (!isMKSChat) {
      setIsSkillProcessing(true);
      setIsMKSProcessing(false);
    } else {
      setIsSkillProcessing(false);
      setIsMKSProcessing(true);
    }

    if (!isRunning) {
      setIsSkillProcessing(false);
      setIsMKSProcessing(false);
    }

    if (
      !isRunning &&
      currentMessage &&
      lastDisplayMessage.length < currentMessage.length
    ) {
      setIsRunning(true);
      setDisableStopGenerateButton(false);

      intervalRef.current = setInterval(() => {
        if (currentMessage[index - 1] !== undefined) {
          setLastDisplayMessage((prev) => {
            if (prev.length < currentMessage.length) {
              return prev + currentMessage[index - 1];
            }
            return prev;
          });
          index++;
          setRunningIndex(index);
        }

        if (index >= currentMessage.length) {
          clearInterval(intervalRef.current);
          setIsTyping(false);
          setIsRunning(false);
          setDisableStopGenerateButton(true);
          setDisableRegenerateButton(true);
          setDisplayLastCitations(resumeCitations);
        }
      }, process.env.REACT_DEFAULT_TIME_INTERVAL);
    }
  };

  const getWelcomeMessage = () => {
    if (messages?.length === 0) {
      if (isMKSChat) {
        return (
          <>
            <h1 className="h1 color-span medium-font">Welcome to MKS Chat</h1>
            <p className="subtext">
              Get more contextual answers to problems you are solving with a
              conversational assistant tailored to specialized domains and
              functional areas.
            </p>
          </>
        );
      } else {
        return (
          <>
            <h1 className="h1 color-span medium-font">
              Welcome to{" "}
              {skill?.Name === "" ||
              skill?.Name === undefined ||
              defaultSkillWelMessage
                ? "Skills Chat"
                : skill?.Name + " Chat"}
            </h1>
            <p className="subtext">
              {skill?.ShortDescription === "" ||
              skill?.ShortDescription === undefined ||
              skill?.ShortDescription === null ||
              defaultSkillWelMessage
                ? "Get more contextual answers to problems you are solving with a conversational assistant tailored to specialized domains and functional areas."
                : skill?.ShortDescription}
            </p>
          </>
        );
      }
    }
  };

  return (
    <>
      <div className="chatbox">
        <div className="userintro">
          <p>Hi {fullName} </p>
          <div className="wavy-hand"></div>
        </div>

        {getWelcomeMessage()}
        <>
          {isFileTypeChat && (
            <FileChatbot
              showChatLoader={showChatLoader}
              messages={messages}
              initials={initials}
              isFileTypeChat={isFileTypeChat}
              lastDisplayMessage={lastDisplayFileMessage}
              uploadedFileDetails={uploadedFileDetails}
              isTyping={isTyping}
              blackBoxAdditives={blackBoxAdditives}
              isBlackBoxAdded={isBlackBoxAdded}
              setIsMKSProcessing={setIsMKSProcessing}
              setIsSkillProcessing={setIsSkillProcessing}
            ></FileChatbot>
          )}
          {!isFileTypeChat && (
            <TextChatbot
              showChatLoader={showChatLoader}
              messages={messages}
              initials={initials}
              isFileTypeChat={isFileTypeChat}
              displayLastCitations={displayLastCitations}
              lastDisplayMessage={lastDisplayMessage}
              setOpenPdfViewer={setOpenPdfViewer}
              setSelectedCitation={setSelectedCitation}
              isTyping={isTyping}
              setIsMKSProcessing={setIsMKSProcessing}
              setIsSkillProcessing={setIsSkillProcessing}
            ></TextChatbot>
          )}
        </>
        {!isFileTypeChat && (
          <div
            className={`chat-event-btn-wrapper ${
              isFileTypeChat ? "dropchat" : ""
            }`}
          >
            <div
              className={`chat-event-button stop-generate w-button ${
                disableStopGenerateButton === true ? "disabled-div" : ""
              }`}
              onClick={(e) => pauseTimer()}
            >
              Stop Generate
            </div>
            <div
              className={`chat-event-button regenerate w-button ${
                disableRegenerateButton === true ? "disabled-div" : ""
              }`}
              onClick={(e) => resumeResponse()}
            >
              Regenerate
            </div>
          </div>
        )}

        <div ref={bottomPanelRef}></div>
      </div>
      {
        <ChatPrompt
          setInit={setInit}
          isMKSChat={isMKSChat}
          navigateFromHome={navigateFromHome}
          chatMessage={chatMessage}
          promptText={promptText}
          setMessages={setMessages}
          setSkill={setSkill}
          skill={skill}
          handleSendMessage={handleAIGenericResponse}
          fileName={fileName}
          setIsSkillChat={setIsSkillChat}
          setIsFileTypeChat={setIsFileTypeChat}
          isFileTypeChat={isFileTypeChat}
          setDefaultSkillWelMessage={setDefaultSkillWelMessage}
          setUploadedFilesFromChat={setUploadedFilesFromChat}
          uploadedFilesFromChat={uploadedFilesFromChat}
          clearUploadedFile={clearUploadedFile}
          setResetAdditives={setResetAdditives}
          setGenericAPIUserMessages={setGenericAPIUserMessages}
          isMKSProcessing={isMKSProcessing}
          setIsMKSProcessing={setIsMKSProcessing}
          isSkillProcessing={isSkillProcessing}
          setIsSkillProcessing={setIsSkillProcessing}
          setClearUploadedFile={setClearUploadedFile}
          selectedAdditives={selectedAdditives}
        />
      }
      <ChatHistory
        isSkillChat={!isMKSChat}
        isMKSChat={isMKSChat}
        isFileType={isFileTypeChat}
        setSelectedAdditives={setSelectedAdditives}
        setIsClearChat={setIsClearChat}
        isChatVisible={isChatVisible}
        openPdfViewer={openPdfViewer}
        setOpenPdfViewer={setOpenPdfViewer}
        selectedCitation={selectedCitation}
        resetAdditives={resetAdditives}
        setResetAdditives={setResetAdditives}
        selectedAdditives={selectedAdditives}
      />
    </>
  );
};

export default ChatBox;
