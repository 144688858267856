import { BOTAPIError } from "../../../../../utils/apiErrors";
import { handleApiError } from "../../../../../utils/globalErrorHandler";

export const splitPromptsAndTags = (text) => {
  const regex = /{"prompt":\s*"([^"]+)",\s*"Tags":\s*"([^"]+)"}/g;
  let match;
  let result = [];

  try {
    while ((match = regex.exec(text)) !== null) {
      result.push({
        prompt: match[1],
        tags: match[2].split(", ").map((tag) => tag.trim()),
      });
    }
    if (result.length === 0) {
      let convertedArray = "[" + text + "]";
      let FormatteArray = JSON.parse(convertedArray);
      result = FormatteArray;
    }
    return result;
  } catch (error) {
    try {
      // Remove the backticks and parse the JSON string
      const cleanedString = text.replace(/```json|```/g, "");
      const jsonObject = JSON.parse(cleanedString);

      // Extract prompt and tags
      const prompt = jsonObject.prompt;
      const tags = jsonObject.Tags.split(",").map((tag) => tag.trim());
      result.push({
        prompt: prompt,
        tags: tags,
      });
      return result;
    } catch (error) {
      return [];
    }
  }
};
const removeTrailingSlash = (url) => {
  return url.endsWith("\\") ? url.slice(0, -1) : url;
};

export const splitPromptsFromStream = (
  convertedJson,
  mksDefaultConfiguration
) => {
  let response = "";
  try {
    const responseA = fetch(removeTrailingSlash(mksDefaultConfiguration.url), {
      method: mksDefaultConfiguration.method,
      headers: mksDefaultConfiguration.headers,
      body: JSON.stringify(convertedJson),
    });

    if (!responseA.ok || !responseA.body) {
      handleApiError(new BOTAPIError());
      return;
    }
    const reader = responseA.body.getReader();
    const decoder = new TextDecoder();
    const loopRunner = true;

    while (loopRunner) {
      try {
        const { value, done } = reader.read();
        if (done) {
          break;
        }
        const decodedChunk = decoder.decode(value, { stream: true });
        let datas;
        try {
          // Process the decoded chunk
          datas = JSON.parse(
            JSON.stringify(
              decodedChunk.replace("data: ", "").split("\n\ndata:").slice(0, -1)
            )
          );
        } catch (decodeError) {
          console.log("Error processing decoded chunk:", decodeError);
          continue; // Skip this chunk if JSON parsing fails
        }

        // Process each item in the parsed data
        let counter = 0;
        datas.forEach((da) => {
          let jsonArray = null;
          try {
            jsonArray = JSON.parse(da);
            if (jsonArray.choices.length > 0) {
              const text = jsonArray.choices[0].delta?.content || "";
              if (counter < 2 && (text.match(/\n/) || []).length) {
                return;
              }
              response = response + text;
              counter++;
            }
          } catch (parseError) {
            handleApiError();
          }
        });
      } catch (readError) {
        handleApiError(new BOTAPIError());
        break; // Exit the loop if reading fails
      }
    }
  } catch (fetchError) {
    handleApiError(new BOTAPIError());
    return;
    // Handle fetch error, such as displaying a message to the user
  }
  return response;
};

export const filterTopMessagesV2 = (
  messageList,
  numberOfCount,
  historyList,
  assistantMessageFormat,
  userMessageFormat
) => {
  let topMessages = [];
  if (messageList.length === 0) {
    if (historyList.length > 0) {
      historyList.forEach((history) => {
        if (history.type === "reply") {
          let message = getTopMessagesFromHistory(
            assistantMessageFormat,
            history.text
          );
          topMessages.push(message);
        } else {
          let userMessageData = userMessageFormat.replace(
            "$Message",
            `"${history.text}"`
          );

          topMessages.push(JSON.parse(userMessageData));
        }
      });
      topMessages = topMessages.slice(-numberOfCount);
      return topMessages;
    } else {
      return [];
    }
  } else {
    topMessages = messageList.slice(-numberOfCount);
    topMessages = messageList.slice(-numberOfCount);
    return topMessages;
  }
};

export const getTopMessagesFromHistory = (assistantMessageFormat, response) => {
  let assistantMessageModel = assistantMessageFormat.replace(
    "$replyMsg",
    `"${response}"`
  );
  let assistantMessage = {};
  try {
    assistantMessage = JSON.parse(assistantMessageModel);
  } catch (error) {
    assistantMessage = JSON.parse(
      assistantMessageFormat.replace("$replyMsg", `"$"`)
    );
    if (assistantMessage.content[0].type === undefined) {
      assistantMessage.content = response;
      assistantMessage.role = "assistant";
    } else {
      assistantMessage.content[0].text = response;
      assistantMessage.role = "assistant";
    }
  }
  return assistantMessage;
};

export const filterTopMessages = (messageList, numberOfCount) => {
  if (messageList.length === 0) {
    return [];
  }
  const topMessages = messageList.slice(-numberOfCount);
  return topMessages;
};
