import React from "react";
import { useHeaderContext } from "../../../context/user/headerContext";

const WelcomeSection = ({ screenName }) => {
  const { headerState } = useHeaderContext();
  const shortDesc =
    "Welcome to your GenAI ecosystem, built to help you unlock exponential value in your work and projects. The more precise your prompt, the more powerful the response — great prompts lead to great outcomes. Save high-performing prompts in a centralized repository and share them with teams using prompt management.";
  return (
    <>
      <div className="welcomsection">
        <h1 className="h1">
          Welcome to{" "}
          <span className="color-span">
            {screenName === "Chat"
              ? headerState.headerValue === ""
                ? screenName
                : headerState.headerValue
              : screenName}
          </span>
        </h1>
        <p className="paragraph">
          {screenName === "Chat"
            ? headerState.ShortDescription === ""
              ? shortDesc
              : headerState.ShortDescription
            : shortDesc}
        </p>
      </div>
    </>
  );
};

export default WelcomeSection;
