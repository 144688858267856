import React, { useEffect, useState, useRef } from "react";
import AppImages from "../../../../../../constants/images";
import BreadCrumb from "../breadCrumb";
import SkillStoreDetail from "../skillStoreDetail";
import SkillTags from "../skillTags";
import SkillContact from "../skillContact";
import SkillVersionHistory from "../skillVersionHistory";

const SkillStoreDetailPage = (props) => {
  const { selectedSkill, updateOriginalData, IsThirdPartyAITool } = props;

  const [skillDetails, setSkillDetails] = useState(null);

  const scrollToRef = useRef(null);

  useEffect(() => {
    setSkillDetails(selectedSkill);
  }, [selectedSkill]);

  const handleVersionClick = (versionId) => {
    setSkillDetails([]);
    if (scrollToRef.current) {
      scrollToRef.current.scrollIntoView({ behavior: "smooth" });
    }

    const versionData = skillDetails?.versions;
    const skillStoreDataByVersion = versionData?.find(
      (item) => item.VersionId === versionId
    )?.versionDetail;

    if (skillStoreDataByVersion) {
      const updatedSkillStore = {
        ...skillStoreDataByVersion,
        versions: versionData,
      };
      setSkillDetails(updatedSkillStore);
    }
  };

  return (
    <>
      {skillDetails && (
        <div className="" ref={scrollToRef}>
          <BreadCrumb
            IsThirdPartyAITool={IsThirdPartyAITool}
            skillStoreName={skillDetails?.Name}
          />
          <SkillStoreDetail
            AppImages={AppImages}
            IsThirdPartyAITool={IsThirdPartyAITool}
            skillDetails={skillDetails}
            selectedSkill={selectedSkill}
            updateOriginalData={updateOriginalData}
          />
          <div className="skill-full-wrap">
            <SkillTags skillTags={skillDetails?.skillTags} />
            <SkillContact AppImages={AppImages} skillStoreData={skillDetails} />
            <SkillVersionHistory
              versions={skillDetails?.versions}
              handleVersionClick={handleVersionClick}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default SkillStoreDetailPage;
