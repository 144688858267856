import React, { useEffect, useRef, useState, useContext } from "react";
import showMessage from "../../../../../common/message";
import { getUseSkills } from "../../../services/promptManagementService";
import { hasGroupAccess } from "../../../../../../services/commonService";
import { UserContext } from "../../../../../../context/user/userContext";

const GeneratedPrompts = ({
  isPromptVisible,
  promptMessage,
  isLoading,
  setIsLoading,
  onBackStep,
  promptFromChild,
  setIsShowConfidentialPopup,
  setChatbotResponse,
  setPromptForm,
  hideBackButton,
  setPromptFormChild,
}) => {
  const [isLoadingTest, setIsLoadingTest] = useState(false);
  // const [promptForm, setPromptForm] = useState(promptFromChild);
  const [selectedChatbot, setSelectedChatbot] = useState(null);
  const [selectedPromptForTest, setSelectedPromptForTest] = useState("");
  const [useSkills, setUseSkills] = useState([]);
  const skillref = useRef(null);
  const { state } = useContext(UserContext);
  const securityGroups = state?.user?.userProfile?.msalSecurityGroup;
  // const handlePromptSelectedChild = (e, prompt) => {
  //   setSelectedPromptForTest(prompt.promptMessage);
  //   handlePromptSelected(e, prompt);
  // };
  const handlePromptSelected = (event, item) => {
    setSelectedPromptForTest(item.promptMessage);
    if (event.currentTarget.checked) {
      const data = {
        ModelAPIURL: "",
        PromptStyle: "",
        WordCount: 0,
        PromptGenerated: true,
        PromptText: item.promptMessage,
        Id: 0,
        Status: "",
      };
      let selectedPrompt = [];
      selectedPrompt.push(data);

      setPromptForm((prevData) => ({
        ...prevData,
        PromptSystemGeneratedInputs: selectedPrompt,
        tags: item.tags,
      }));
      // setPromptFormChild((prevData) => ({
      //   ...prevData,
      //   PromptSystemGeneratedInputs: selectedPrompt,
      //   tags: item.tags,
      // }));
    }
  };

  const onBackStepChild = () => {
    onBackStep();
  };

  const getUseSkillsData = () => {
    getUseSkills().then((resp) => {
      setUseSkills(resp);

      if (
        promptFromChild?.SkillStoreId !== null &&
        promptFromChild?.SkillStoreId !== undefined &&
        promptFromChild?.SkillStoreId !== 0
      ) {
        setPrviousSelectedChatbotConfig(resp);
      }
      if (promptFromChild?.PromptSystemGeneratedInputs?.length > 0) {
        setSelectedPromptForTest(
          promptFromChild?.PromptSystemGeneratedInputs[0].PromptText
        );
      }
    });
  };
  useEffect(() => {
    getUseSkillsData();
  }, []);

  const setPrviousSelectedChatbotConfig = (resp) => {
    const selectedSkill = resp.filter(
      (item) => item.Id === parseInt(promptFromChild?.SkillStoreId)
    )[0];

    setSelectedChatbot(selectedSkill);
  };

  const handleTestGeneratedPrompt = async () => {
    setIsLoadingTest(true);
    if (selectedChatbot === undefined || selectedChatbot === null) {
      showMessage("Please select a skill first.", -2);
      setIsLoadingTest(false);
      return;
    }
    if (selectedPromptForTest === "") {
      showMessage("Please select at least one prompt.", -2);
      setIsLoadingTest(false);
      return;
    }
    try {
      await handleLoadGenericApiConfigurations();
      setIsShowConfidentialPopup(true);
    } catch (error) {
      console.error("Error testing prompt:", error);
      setIsLoadingTest(false);
    } finally {
      setIsLoadingTest(false);
    }
  };
  const removeTrailingSlash = (url) => {
    return url.endsWith("\\") ? url.slice(0, -1) : url;
  };
  const handleLoadGenericApiConfigurations = async () => {
    let inputText = selectedPromptForTest;
    let userMessage = selectedChatbot.ChatbotConfiguration.UserMessageFormat;
    userMessage = userMessage.replace("$Message", `"${inputText}"`);
    let userMessageModel2 = selectedChatbot.messageBody.replace(
      "$UserMessage",
      userMessage
    );

    let response = "";
    const responseA = await fetch(removeTrailingSlash(selectedChatbot.aiUrl), {
      method: "post",
      headers: selectedChatbot.headers,
      body: userMessageModel2,
    });
    if (!responseA.ok || !responseA.body) {
      throw responseA.statusText;
    }

    const reader = responseA.body.getReader();
    const decoder = new TextDecoder("utf-8");
    const loopRunner = true;
    let citations = [];

    while (loopRunner) {
      try {
        const { value, done } = await reader.read();
        if (done) {
          break;
        }

        let decodedChunk = decoder.decode(value, { stream: true });

        let replacedChunk = decodedChunk.replace("data: ", "");

        let findContext =
          replacedChunk.indexOf("context") &&
          replacedChunk.indexOf("citations");
        if (findContext > -1) {
          citations =
            JSON.parse(replacedChunk)?.choices[0]?.delta?.context?.citations;
        }
        let splitChunk = replacedChunk.split("\n\ndata:").slice(0, -1);

        let datas = JSON.parse(JSON.stringify(splitChunk));
        let counter = 0;

        datas.forEach((da) => {
          let jsonArray = null;
          try {
            jsonArray = JSON.parse(da);
            if (jsonArray.choices.length > 0) {
              const text = jsonArray.choices[0].delta?.content || "";
              if (counter < 2 && (text.match(/\n/) || []).length) {
                return;
              }
              response = response + text;
              counter++;
            }
          } catch (parseError) {
            console.log(
              "Error parsing JSON array item:",
              parseError,
              " Raw Item ",
              da
            );
          }
        });
      } catch (readError) {
        console.log("Error reading the response stream:", readError);
        break; // Exit the loop if reading fails
      }
      setIsLoading(false);
    }
    let cbResponse = {
      description: response,
      citations: citations,
    };

    setChatbotResponse(cbResponse);

    // const promptTextData = {
    //   promptText1: promptText1,
    //   promptText2: promptText2,
    //   promptText3: promptText3,
    // };
    // const promptStatus = {
    //   isConfidential: "yes",
    //   isPromptPrivate: false,
    // };
    // const promptSelectedData = selectPrompts;
    // onNextStep(promptTextData, promptSelectedData, promptStatus);
  };

  const handleSelectedSkillChange = (skillId) => {
    const selectedSkill = useSkills.filter(
      (item) => item.Id === parseInt(skillId)
    )[0];

    const access = hasGroupAccess(
      securityGroups,
      selectedSkill?.SecurityGroupId
    );
    if (!access) {
      showMessage(
        "You don't have access to " + selectedSkill.Name + " bot.",
        -2
      );
      return;
    }

    setPromptForm((prevData) => ({
      ...prevData,
      SkillStoreId: skillId,
    }));
    setPromptFormChild((prevData) => ({
      ...prevData,
      ...prevData,
      SkillStoreId: skillId,
    }));
    setSelectedChatbot(selectedSkill);
    if (skillId !== "0") skillref.current.classList.remove("border-red");
  };

  return (
    <div className="dash-section p20 prompt-step-two">
      {isPromptVisible > 0 && (
        <>
          <div className="prompt-review">
            <div className="prompt-step-name">
              {promptMessage.length} Prompts Generated
            </div>
            <div className="prompt-step-desc">
              Compare the generated based on your prompt
            </div>
            <div className="prompt-gen">
              <div className="selected-tag">gpt-4o</div>
            </div>
          </div>
          <div className="prompt-review-wrap">
            {promptMessage.map((prompt) => (
              <div key={prompt.promtId} className="prompt-review-box">
                <input
                  type="radio"
                  name="prompt"
                  onChange={(e) => handlePromptSelected(e, prompt)}
                  className="p-checkbox"
                  checked={selectedPromptForTest === prompt.promptMessage}
                />
                <textarea
                  readOnly
                  className="prompt-textarea"
                  rows="5"
                  value={prompt.promptMessage}
                ></textarea>
              </div>
            ))}
          </div>
        </>
      )}
      {isPromptVisible === false && isLoading && (
        <div className="prompt-review">
          <div className="prompt-step-name">
            <div className="btn-spinner">
              <span className="loadspinner"></span> Loading...
            </div>
          </div>
        </div>
      )}

      <div className="prompt-button-wrapper">
        {hideBackButton === false && (
          <button className="backbtn" onClick={() => onBackStepChild()}>
            Back
          </button>
        )}
        {hideBackButton && <div></div>}
        <div className="dropdown-with-button">
          <div className="prompt-title-wrap">
            {/* <h3 className="prompt-title">
            Use Skills <span className="red">*</span>
          </h3> */}
            <select
              value={promptFromChild?.SkillStoreId}
              className="prompt-skill"
              onChange={(e) => {
                handleSelectedSkillChange(e.target.value);
              }}
              ref={skillref}
            >
              <option value="-1">Select Skill</option>
              {useSkills?.map((item) => (
                <option key={item.Id} value={item.Id}>
                  {item.Name}
                </option>
              ))}
            </select>
          </div>
          <button
            onClick={() => handleTestGeneratedPrompt()}
            className="genrate-btn"
          >
            {isLoadingTest ? (
              <div className="btn-spinner">
                <span className="loadspinner"></span> Loading...
              </div>
            ) : (
              "Test Prompt"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default GeneratedPrompts;
