import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {getPromptData} from "../../../../promptManagement/services/promptManagementService";

const NotificationDetail = (props) => {
  const { notification, hideDetailPage} = props;
  const navigate = useNavigate();

  const handlePrompClick = async (id) => {
    const response = await getPromptData(id);
    if (response !== undefined && response !== null) {
      navigate("/updatePrompt", { state: { prompt: response } });
    }
  };
  const convertToDesiredFormat = (datetimeString) => {
    // Parse and sanitize input
    const sanitizedString = datetimeString?.replace(" PM", "")?.replace(" AM", "");
    const date = new Date(sanitizedString);
   
      if (isNaN(date)) {
        return ""; // Handle invalid input
      }
    
      // Days of the week array
      const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    
      // Get parts of the date
      const dayOfWeek = daysOfWeek[date.getDay()];
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Convert month to 2-digit
      const year = date.getFullYear();
    
      // Format time
      let hours = date.getHours();
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const meridian = hours >= 12 ? "PM" : "AM";
      hours = hours % 12 || 12; // Convert 24-hour to 12-hour format
    
      return `${dayOfWeek} ${day}/${month}/${year} ${hours}:${minutes} ${meridian}`;
    };

    
  return (
    <>
      <div className="notif-right-section">
            <div className="expand-history-btn rotate hide-on-desk" onClick={()=>hideDetailPage()}></div>
            <div className="user-dec-info">
            <div className="user-set">
            <div className="user-text">
              <h3><span className="small-text">{notification.FromFirstName} {notification.FromLastName} ({notification.FromUserName})</span></h3>
              <div className="small-text">To : {notification.ToFirstName} {notification.ToLastName} ({notification.ToUserName})</div>
            </div>
            <div className="user-time-detail">
              <div>{convertToDesiredFormat(notification.SharedOn)}</div>
            </div>
            </div>
            
            <div className="user-prompt-desc prompt-desc">
              <div>{notification.NotificationMessage}</div>
              <div className="prompt-desc-style">
                <h3>
                <span className="small-text">Title</span>
                </h3>
                <div>
                  {notification.Title}
                </div>
                <h3>
                <span className="small-text">Description</span>
                </h3>
                <div>
                  {notification.Description}
                </div>
              </div>
              <div className="small-text more-details">To get more details <a className="small-text-anchor" onClick={() => handlePrompClick(notification.DataId)}>click here</a></div>
            </div>
          </div>
        </div>
    </>
  );
};

export default NotificationDetail;
