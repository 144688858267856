import React, { useRef, useState } from "react";
import SkillApplicability from "../skillApplicability";
import SkillTagsDetail from "../skillTagsDetail";
import SkillContactDetails from "../skillContactDetails";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import BOTConfiguration from "../botConfiguration";
import { CreateSkillStore } from "../../../services/skillStoreService";
import { toast } from "react-toastify";
import { Link, Navigate, useNavigate } from "react-router-dom";

const SkillHeaderDetails = (props) => {
  const navigate = useNavigate();
  const { skillStoreForm, setskillStoreForm} = props;
  const [isThirdPartyAITool, setIsThirdPartyAITool] = useState(false);
  const [isBackButtonClicked, setIsBackButtonClicked] = useState(false);
  const [showBotConfiguration, setShowBotConfiguration] = useState(false);
  const [validationFieldName, setValidationFieldName] = useState("");
  const nameRef = useRef(null);
  const chatbotURLRef = useRef(null);
  const accuracyRef = useRef(null);
  const executionRef = useRef(null);
  const publishedonRef = useRef(null);

  const handleSetGuideline = () => {
    let thirdPartyAITool = !isThirdPartyAITool;
    updateSkillStoreForm("IsThirdPartyAITool", thirdPartyAITool);
    
    if (thirdPartyAITool) {
      updateMultipleFields({
        IsChatbotTestable: null,
        ShowCitation: null,
        ShowFollowUpQuestions: null,
        InputType: null,
        OutputType: null,
        LastMessagesCount: null,
        ChatGPTVersion: null,
        CurlRequestString: null,
        UserAssistantMessageFormat: null,
        FileType: null,
        ChatbotUIView: null,
        DynamicProperties: [],
      });
    } else {
      updateMultipleFields({
        InputType: "",
        OutputType: "",
        LastMessagesCount: "",
        ChatGPTVersion: "",
        IsChatbotTestable: false,
        ShowCitation: false,
        ShowFollowUpQuestions: false,
        CurlRequestString: "",
        UserAssistantMessageFormat: "",
        FileType: "",
        ChatbotUIView: "",
        DynamicProperties: [],
      });
      updateSkillStoreForm("ChatBotURL","");
      setIsBackButtonClicked(false);
    }

    setIsThirdPartyAITool(!isThirdPartyAITool);
  };

  const updateMultipleFields = (updates) => {
    setskillStoreForm((prevState) => ({
      ...prevState,
      ...updates,
    }));
  };

  const handleExecutionChange = (e) => {
    const newValue = e.target.value;
    // Allow only numbers (optional: allow decimals)
    if (/^\d*\.?\d*$/.test(newValue)) {
      const numericValue = newValue === "" ? "" : parseFloat(newValue);
      if (numericValue !== "" && numericValue >= 0 && numericValue <= 1000) {
        executionRef.current.classList.remove("border-red");
        updateSkillStoreForm("AvgExecutionTime", parseInt(newValue));
      } else if (newValue?.trim() === "") {
        updateSkillStoreForm("AvgExecutionTime", "");
      }
    }
  };

  const handleAccuracyChange = (e) => {
    const newValue = e.target.value;
    // Allow only numbers (optional: allow decimals)
    if (/^\d*\.?\d*$/.test(newValue)) {
      const numericValue = newValue === "" ? "" : parseFloat(newValue);
      if (numericValue !== "" && numericValue >= 0 && numericValue <= 100) {
        accuracyRef.current.classList.remove("border-red");
        updateSkillStoreForm("Acurracy", parseInt(newValue));
      } else if (newValue?.trim() === "") {
        updateSkillStoreForm("Acurracy", "");
      }
    }
  };

  const isValidURL = (url) => {
    try {
      new URL(url);
      return true;
    } catch (error) {
      return false;
    }
  };

  const validateFields = () => {
    if (skillStoreForm.Name === "" || skillStoreForm?.Name?.trim() === "") {
      nameRef.current.classList.add("border-red");
      nameRef.current.focus();
      toast.error("Skill Name is required");
      return false;
    }

    if (
      skillStoreForm.DepartmentNameId === 0 ||
      skillStoreForm.DepartmentNameId === "-1"
    ) {
      setValidationFieldName("Department");
      toast.error("Department is required");
      return false;
    }

    if (
      skillStoreForm.functions?.length === 0 ||
      skillStoreForm.functions === null
    ) {
      setValidationFieldName("Function");
      toast.error("Function is required");
      return false;
    }
    if (
      skillStoreForm.subFunctions?.length === 0 ||
      skillStoreForm.subFunctions === null
    ) {
      setValidationFieldName("SubFunction");
      toast.error("Sub Function is required");
      return false;
    }
    if (
      skillStoreForm.LookupSkillScopeId === 0 ||
      skillStoreForm.LookupSkillScopeId === "-1" ||
      skillStoreForm.LookupSkillScopeId === ""
    ) {
      setValidationFieldName("Scope");
      toast.error("Scope is required");
      return false;
    }
    if (skillStoreForm.IsThirdPartyAITool) {
      if (
        skillStoreForm.ChatBotURL === "" ||
        skillStoreForm?.ChatBotURL?.trim() === ""
      ) {
        chatbotURLRef.current.classList.add("border-red");
        toast.error("Chatbot URL is required");
        chatbotURLRef.current.focus();
        return false;
      } else if (!isValidURL(skillStoreForm.ChatBotURL)) {
        chatbotURLRef.current.classList.add("border-red");
        toast.error("Chatbot URL entered is invalid");
        chatbotURLRef.current.focus();
        return false;
      }
    }
    if (skillStoreForm.Acurracy === "") {
      accuracyRef.current.classList.add("border-red");
      accuracyRef.current.focus();
      toast.error("Accuracy is required");
      return false;
    }
    if (skillStoreForm.AvgExecutionTime === "") {
      executionRef.current.classList.add("border-red");
      executionRef.current.focus();
      toast.error("Avg. Execution Time is required");
      return false;
    }
    if (skillStoreForm.PublishOn === "") {
      publishedonRef.current.input.classList.add("border-red");
      publishedonRef.current.input.focus();
      toast.error("Publish On Date is required");
      return false;
    }

    if (skillStoreForm?.tags?.length === 0) {
      setValidationFieldName("Tags");
      toast.error("Tags are required");
      return false;
    }

    if (skillStoreForm?.Owner?.length === 0) {
      setValidationFieldName("Owner");
      toast.error("Owner is required");
      return false;
    }
    if (skillStoreForm?.Contact?.length === 0) {
      setValidationFieldName("Contact");
      toast.error("Contact is required");
      return false;
    }

    return true;
  };

  const validateFormDataandSave = () => {
    if (validateFields()) {
      CreateSkillStore(skillStoreForm).then((res) => {
        if (res?.Status === "Skill Created Successfully.") {
          toast.success(res?.Status);
          navigate("/thirdpartyaitools");
        } else {
          toast.error("Unable to Create skill");
        }
        //
      });
    }
  };

  const handleShowBotConfiguration = () => {
    if (validateFields()) {
      if(!isBackButtonClicked){
        updateMultipleFields({
          InputType: "",
          OutputType: "",
          LastMessagesCount: "",
          ChatGPTVersion: "",
          IsChatbotTestable: false,
          ShowCitation: false,
          ShowFollowUpQuestions: false,
          CurlRequestString: "",
          UserAssistantMessageFormat: "",
          FileType: "",
          ChatbotUIView: "",
          DynamicProperties: [],
        });
      }
      setShowBotConfiguration(!showBotConfiguration);
    }
  };

  const updateSkillStoreForm = (field, value) => {
    setskillStoreForm((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const formatDate = (date) => {
    date.setHours(0, 0, 0, 0);
    return date;
  };

  return (
    <>
      {!showBotConfiguration && (
        <div className="">
          <div className="skill-detail-wrapper">
            <div className="skill-detail-left create-skill-left">
              <div className="prompt-img-title">
                <div className="prompt-mobile-wrap">
                  <div className="prompt-title-wrap">
                    <h3 className="prompt-title">
                      Name <span className="red">*</span>{" "}
                    </h3>
                    <input
                      maxLength={255}
                      type="textbox"
                      className="prompt-text"
                      value={skillStoreForm?.Name}
                      onInput={(e) => {
                        updateSkillStoreForm("Name", e.target.value);
                        nameRef.current.classList.remove("border-red");
                      }}
                      ref={nameRef}
                    />
                  </div>
                </div>
              </div>
              <div className="description-wrap">
                <div className="prompt-desc-wrap w-full">
                  <h3 className="prompt-title">Short Description</h3>
                  <textarea
                    className="prompt-textarea prompt-pii"
                    value={skillStoreForm?.ShortDescription}
                    onInput={(e) =>
                      updateSkillStoreForm("ShortDescription", e.target.value)
                    }
                    maxLength={2000}
                  ></textarea>
                </div>
                <div className="prompt-desc-wrap w-full">
                  <h3 className="prompt-title">Description</h3>
                  <textarea
                    className="prompt-textarea prompt-pii"
                    value={skillStoreForm?.Description}
                    onInput={(e) =>
                      updateSkillStoreForm("Description", e.target.value)
                    }
                    maxLength={2000}
                  ></textarea>
                </div>
              </div>
              <div className="skill-update-box">
                <div className="prompt-title-wrap">
                  <h3 className="prompt-title">
                    Accuracy <span className="red">*</span>{" "}
                  </h3>
                  <input
                    type="textbox"
                    className="prompt-text"
                    onChange={(e) => handleAccuracyChange(e)}
                    value={
                      skillStoreForm?.Acurracy === 0
                        ? ""
                        : skillStoreForm.Acurracy
                    }
                    ref={accuracyRef}
                  />
                </div>
                <div className="prompt-title-wrap">
                  <h3 className="prompt-title">
                    Avg. Execution Time <span className="red">*</span>{" "}
                  </h3>
                  <input
                    type="textbox"
                    className="prompt-text"
                    onChange={(e) => handleExecutionChange(e)}
                    value={
                      skillStoreForm?.AvgExecutionTime === 0
                        ? ""
                        : skillStoreForm.AvgExecutionTime
                    }
                    ref={executionRef}
                  />
                </div>
                <div className="prompt-title-wrap publish-date-wrap">
                  <h3 className="prompt-title">
                    Published On <span className="red">*</span>{" "}
                  </h3>
                  <DatePicker
                    className="input-date-text prompt-text"
                    onFocus={(e) => e.target.blur()} // Prevent manual input but allow date selection
                    minDate={new Date()}
                    dateFormat="MM/dd/yyyy"
                    selected={skillStoreForm?.PublishOn}
                    onChange={(date) => {
                      updateSkillStoreForm("PublishOn", formatDate(date));
                      publishedonRef.current.input.classList.remove(
                        "border-red"
                      );
                    }}
                    placeholderText="Select Date"
                    ref={publishedonRef}
                  />
                </div>
              </div>
              <div className="skill-one-row">
                <div className="check-ackn">
                  <label className="check-contain">
                    <input
                      type="checkbox"
                      checked={skillStoreForm?.IsThirdPartyAITool}
                      className="p-checkbox"
                      onChange={() => {
                        handleSetGuideline();
                      }}
                    />
                    <span className="checkmark-check"></span>
                  </label>
                  <p>Third Party AI Tool </p>
                </div>
                {isThirdPartyAITool && (
                  <div className="prompt-title-wrap">
                    {/* <h3 className="prompt-title">
                        Chatbot URL <span className="red">*</span>{" "}
                      </h3> */}
                    <input
                      maxLength={256}
                      type="textbox"
                      placeholder="Chatbot URL"
                      className="prompt-text"
                      value={skillStoreForm?.ChatBotURL}
                      onInput={(e) => {
                        updateSkillStoreForm("ChatBotURL", e.target.value);
                        chatbotURLRef.current.classList.remove("border-red");
                      }}
                      ref={chatbotURLRef}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="skill-detaill-right autowidth">
              <SkillApplicability
                skillStoreForm={skillStoreForm}
                updateSkillStoreForm={updateSkillStoreForm}
                validationFieldName={validationFieldName}
                setValidationFieldName={setValidationFieldName}
              ></SkillApplicability>
            </div>
          </div>
          <div className="skill-fullwidth">
            <div className="skill-create-tags">
              <SkillTagsDetail
                skillStoreForm={skillStoreForm}
                updateSkillStoreForm={updateSkillStoreForm}
                validationFieldName={validationFieldName}
                setValidationFieldName={setValidationFieldName}
              ></SkillTagsDetail>
            </div>

            <div className="create-skill-window skill-update-box">
              <SkillContactDetails
                skillStoreForm={skillStoreForm}
                updateSkillStoreForm={updateSkillStoreForm}
                validationFieldName={validationFieldName}
                setValidationFieldName={setValidationFieldName}
              ></SkillContactDetails>
            </div>
          </div>
          <div className="skill-fullwidth ">
            {isThirdPartyAITool && (
              <div className="prompt-button-wrapper">
                <div>
                  {" "}
                  <Link className="cancel-btn" to="/skillstore">
                    Cancel
                  </Link>
                </div>
                <button
                  className="genrate-btn"
                  onClick={() => validateFormDataandSave()}
                >
                  Save
                </button>
              </div>
            )}
            {!isThirdPartyAITool && (
              <div className="prompt-button-wrapper">
                <div>
                  {" "}
                  <Link className="cancel-btn" to="/skillstore">
                    Cancel
                  </Link>
                </div>
                <button
                  className="genrate-btn"
                  onClick={() => handleShowBotConfiguration()}
                >
                  Set Bot Configuration
                </button>
              </div>
            )}
          </div>
        </div>
      )}
      {showBotConfiguration && (
        <BOTConfiguration
          skillStoreForm={skillStoreForm}
          updateSkillStoreForm={updateSkillStoreForm}
          setShowBotConfiguration={setShowBotConfiguration}
          setIsBackButtonClicked={setIsBackButtonClicked}
        ></BOTConfiguration>
      )}
    </>
  );
};

export default SkillHeaderDetails;
