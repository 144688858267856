import { SyncRedactor } from "cognosys-redact-pii";

const redactor = new SyncRedactor();

export const getHighlightedText = (text) => {
  if (text !== undefined) {
    const words = text.split(/\s+/);
    const redactedWords = redactor.redact(text).split(/\s+/);

    return words
      .map((word, index) => {
        const isPII = !redactedWords.includes(word) && word.trim().length > 0;
        return isPII
          ? `<span key=${index} style="background-color:#8a1f23;color:white;">${word}</span>`
          : word;
      })
      .join(" ");
  }
  
};

export const textContainsPIIText = (text) => {
  if (text !== undefined) {
    const words = text.split(/\s+/);
    const redactedWords = redactor.redact(text).split(/\s+/);

    // Check if any word is identified as PII
    const containsPII = words.some((word, index) => {
      return !redactedWords.includes(word) && word.trim().length > 0;
    });

    return containsPII;
  }

  return false; // Return false if text is undefined
};

export const handlePIIChange = (e) => {
  const text = e.target.innerText;
  // Apply highlighting inside the contentEditable div
  const highlightedText = getHighlightedText(text);
  e.target.innerHTML = highlightedText;
  //placeCaretAtEnd(e.target);
};

// Helper function to keep the caret at the end after updating innerHTML
export const placeCaretAtEnd = (el) => {
  el.focus();
  const range = document.createRange();
  const sel = window.getSelection();
  range.selectNodeContents(el);
  range.collapse(false);
  sel.removeAllRanges();
  sel.addRange(range);
};
