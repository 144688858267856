import React, { useContext, useEffect, useRef, useState } from "react";
import AppImages from "../../../../../../constants/images";
import {
  handlePIIChange,
  getHighlightedText,
} from "../../../../../../services/piiService";

import { Link, useNavigate } from "react-router-dom";
import { CropImage } from "../../_partials/cropImage";
import showMessage from "../../../../../common/message/index";
import { defaultDisclaimerText } from "../../../../../../constants/enums";
import {
  getPromptManagementStoreData,
  getCreativityData,
  getUseSkills,
  updateViewStatusById,
} from "../../../services/promptManagementService";
import SelectByPreferences from "../../_partials/selectByPreferences";
import loaderContext from "../../../../../../context/loader/loaderContext";

const PromptStepOne = ({ onNextStep, promptForm }) => {
  const [promptTitles, setPromptTitles] = useState([]);
  const [promptTitlesOriginal, setPromptTitlesOriginal] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [useSkills, setUseSkills] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [showCropImagePopup, setShowCropImagePopup] = useState(false);
  const [creativityData, setCreativityData] = useState([]);
  const [loadHighlightedText, setLoadHighlightedText] = useState(false);
  const [promptStepOneDataOriginal, setPromptStepOneDataOriginal] =
    useState(promptForm);
  const { updateLoaderStatus } = useContext(loaderContext);

  const titleRef = useRef("");
  const descriptionRef = useRef("");
  const text1Ref = useRef("");
  const text2Ref = useRef("");
  const guideLineCheckedRef = useRef(false);
  const wordCountRef = useRef(null);
  const divPreferencesRef = useRef(null);
  const suggestRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const getDataPromptStoreData = async () => {
      const data = await getPromptManagementStoreData();
      setPromptTitlesOriginal(data);
    };

    const getDataOfCreativity = async () => {
      const creativityData = await getCreativityData();
      setCreativityData(creativityData);
    };

    getUseSkillsData();
    getDataPromptStoreData();
    getDataOfCreativity();
  }, []);
  const handleCardClick = async (prompt) => {
    const promptUserInputId = prompt?.Id;
    const response = await updateViewStatusById(promptUserInputId);
    if (response !== undefined && response !== null) {
      navigate("/updatePrompt", { state: { prompt: prompt } });
    }
  };
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (showSuggestions && promptTitles.length > 0) {
        if (e.key === "ArrowDown") {
          e.preventDefault();
          setSelectedIndex(
            (prevIndex) => (prevIndex + 1) % promptTitles.length
          );
        } else if (e.key === "ArrowUp") {
          e.preventDefault();
          setSelectedIndex(
            (prevIndex) =>
              (prevIndex - 1 + promptTitles.length) % promptTitles.length
          );
        } else if (e.key === "Enter" && selectedIndex >= 0) {
          e.preventDefault();

          const selectedTitle = promptTitles[selectedIndex];
          handleSuggestionClick(selectedTitle.Title);
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [selectedIndex, promptTitles, showSuggestions]);

  useEffect(() => {
    if (promptForm !== null) {
      setPromptStepOneDataOriginal(promptForm);
      setLoadHighlightedText(true);
    }
  }, [promptForm]);

  useEffect(() => {
    highlightText();
  }, [loadHighlightedText]);

  const highlightText = () => {
    if (promptStepOneDataOriginal?.ShortDescription !== undefined) {
      descriptionRef.current.innerHTML = getHighlightedText(
        promptStepOneDataOriginal?.ShortDescription
      );
    }
    if (promptStepOneDataOriginal?.PromptBackground !== undefined) {
      text1Ref.current.innerHTML = getHighlightedText(
        promptStepOneDataOriginal?.PromptBackground
      );
    }
    if (promptStepOneDataOriginal?.DesiredPromptOutput) {
      text2Ref.current.innerHTML = getHighlightedText(
        promptStepOneDataOriginal?.DesiredPromptOutput
      );
    }
  };

  const getUseSkillsData = () => {
    getUseSkills().then((resp) => {
      setUseSkills(resp);
    });
  };

  useEffect(() => {
    const handleClickOutside = (e) => {
      // Check if the click is outside both the menu and toggle button
      if (
        showSuggestions &&
        suggestRef.current &&
        !suggestRef.current.contains(e.target)
      ) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showSuggestions]);

  const handleSuggestionClick = (selectedTitle) => {
    setPromptStepOneDataOriginal((prevData) => ({
      ...prevData,
      Title: selectedTitle,
    }));
    setShowSuggestions(false);
  };

  const handleSaveImage = (croppedImageUrl, dataUrl, fileName, mimeType) => {
    setPromptStepOneDataOriginal((prevData) => ({
      ...prevData,
      ImageName: fileName,
      imageBase64String: dataUrl,
      imageURL: croppedImageUrl,
      mimeType: "",
    }));
    setShowCropImagePopup(false);
  };

  const handleCancelClick = () => {
    setPromptStepOneDataOriginal((prevData) => ({
      ...prevData,
      ImageName: "",
      CardImage_Base64: AppImages.ImageCloudUpload,
      imageURL: AppImages.ImageCloudUpload,
      MimeType: "",
    }));
    setShowCropImagePopup(false);
  };

  const handleShowPopup = () => {
    setShowCropImagePopup(true);
  };

  const handleSetGuideline = (event) => {
    setPromptStepOneDataOriginal((prevData) => ({
      ...prevData,
      IsDisclaimerAccepted: guideLineCheckedRef.current.checked, // event.currentTarget.checked,
    }));
  };

  const getValidationMessage = () => {
    if (promptStepOneDataOriginal.IsDisclaimerAccepted === false) {
      showMessage(
        "Please provide your aknowledgement for the guidelines to procced.",
        -2
      );
      return false;
    }

    if (
      promptStepOneDataOriginal.Title === "" ||
      promptStepOneDataOriginal.Title === null
    ) {
      showMessage("Please provide title for Prompt.", -2);
      titleRef.current.classList.add("border-red");
      titleRef.current.focus();
      return false;
    }

    if (
      promptStepOneDataOriginal.ShortDescription === "" ||
      promptStepOneDataOriginal.ShortDescription === null
    ) {
      showMessage("Please provide Description for Prompt.", -2);
      descriptionRef.current.classList.add("border-red");
      descriptionRef.current.focus();
      return false;
    }

    if (
      promptStepOneDataOriginal.PromptBackground === "" ||
      promptStepOneDataOriginal.PromptBackground === null ||
      promptStepOneDataOriginal.PromptBackground === undefined
    ) {
      showMessage("Please provide background for Prompt.", -2);
      text1Ref.current.classList.add("border-red");
      text1Ref.current.focus();
      return false;
    }

    if (
      promptStepOneDataOriginal.DesiredPromptOutput === "" ||
      promptStepOneDataOriginal.DesiredPromptOutput === null ||
      promptStepOneDataOriginal.DesiredPromptOutput === undefined
    ) {
      showMessage("Please provide desired output format for Prompt.", -2);
      text2Ref.current.classList.add("border-red");
      text2Ref.current.focus();
      return false;
    }

    if (
      promptStepOneDataOriginal.LookupCreativityId === null ||
      promptStepOneDataOriginal.LookupCreativityId === undefined ||
      promptStepOneDataOriginal.LookupCreativityId === "-1" ||
      promptStepOneDataOriginal.LookupCreativityId === 0
    ) {
      showMessage("Please select Preference.", -2);
      divPreferencesRef.current.classList.add("border-red");
      return false;
    }
    if (
      promptStepOneDataOriginal.inputWordCount === null ||
      promptStepOneDataOriginal.inputWordCount === undefined ||
      promptStepOneDataOriginal.inputWordCount === ""
    ) {
      showMessage("Please fill Word Count.", -2);
      wordCountRef.current.classList.add("border-red");
      return false;
    }
    return true;
  };

  const validateInput = () => {
    updateLoaderStatus(true);
    let validationMessage = getValidationMessage();
    if (!validationMessage) {
      updateLoaderStatus(false);
      return false;
    } else {
      updateLoaderStatus(true);
      onNextStep(promptStepOneDataOriginal);
    }
  };

  const onPreferencesSelection = async (preferencesId, preference) => {
    divPreferencesRef.current.classList.remove("border-red");
    setPromptStepOneDataOriginal((prevData) => ({
      ...prevData,
      LookupCreativityId: preferencesId,
      SelectedPreference: preference,
    }));
  };

  const handleWordCountChange = (e) => {
    wordCountRef.current.classList.remove("border-red");
    const newValue = e.target.value;
    const cursorPosition = e.target.selectionStart;
    if (/^\d*$/.test(newValue) && parseInt(newValue) <= 150) {
      if (parseInt(newValue) === 0) {
        setPromptStepOneDataOriginal((prevData) => ({
          ...prevData,
          inputWordCount: "",
          WordCount: "",
        }));
      } else {
        setPromptStepOneDataOriginal((prevData) => ({
          ...prevData,
          inputWordCount: newValue,
          WordCount: newValue,
        }));
      }
    } else if (newValue === "") {
      setPromptStepOneDataOriginal((prevData) => ({
        ...prevData,
        inputWordCount: "",
        WordCount: "",
      }));
    } else if (parseInt(newValue) > 150) {
      showMessage("Word count should be less than or equal to 150.", -2);
    } else {
      if (/^\d*$/.test(newValue)) {
        if (parseInt(newValue) <= 150) {
          setPromptStepOneDataOriginal((prevData) => ({
            ...prevData,
            inputWordCount: newValue,
            WordCount: newValue,
          }));
        }
      } else {
        const numericValue = newValue.replace(/\D/g, "");

        setPromptStepOneDataOriginal((prevData) => ({
          ...prevData,
          inputWordCount: numericValue,
          WordCount: numericValue,
        }));
      }
    }

    setTimeout(() => {
      if (wordCountRef.current) {
        wordCountRef.current.setSelectionRange(cursorPosition, cursorPosition);
      }
    }, 10);
  };

  return (
    <div className="dash-section p20 prompt-step-one">
      <div className="check-ackn">
        <label className="check-contain">
          <input
            type="checkbox"
            checked={promptStepOneDataOriginal?.IsDisclaimerAccepted}
            className="p-checkbox"
            onChange={() => {
              handleSetGuideline();
            }}
            ref={guideLineCheckedRef}
          />
          <span className="checkmark-check"></span>
        </label>
        <p>
          {defaultDisclaimerText}
          <span className="red">*</span>
        </p>
      </div>

      <div className="prompt-img-title">
        <div className="prompt-mobile-wrap">
          <div className="prompt-title-wrap">
            <h3 className="prompt-title">
              Prompt Title <span className="red">*</span>{" "}
            </h3>
            <input
              maxLength={256}
              type="textbox"
              className="prompt-text"
              value={promptStepOneDataOriginal?.Title}
              onInput={(e) => {
                setPromptStepOneDataOriginal((prevData) => ({
                  ...prevData,
                  Title: e.target.value === undefined ? "" : e.target.value,
                }));
                setPromptTitles([
                  ...new Set(
                    promptTitlesOriginal?.filter((prompt) =>
                      prompt.Title.toLowerCase().includes(
                        e.target.value.toLowerCase()
                      )
                    )
                  ),
                ]);
                titleRef.current.classList.remove("border-red");
                setShowSuggestions(e.target.value.length > 0);
                e.preventDefault();
              }}
              ref={titleRef}
            />
            <div ref={suggestRef}>
              {showSuggestions && promptTitles.length > 0 && (
                <div className="suggestions mks-suggestion-prompt">
                  <div className="suggestion-item-heading">Suggestions</div>
                  {promptTitles.map((prompt, index) => (
                    <div
                      className={`flex-row suggestion-item ${
                        index === selectedIndex ? "selected" : ""
                      }`}
                      key={index}
                    >
                      <div onClick={() => handleSuggestionClick(prompt.Title)}>
                        {prompt.Title}
                      </div>
                      <div
                        className="view-btn"
                        onClick={() => handleCardClick(prompt)}
                      >
                        View
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="prompt-img">
            <img
              src={promptStepOneDataOriginal?.imageBase64String}
              title="Upload an Image"
              alt=""
              onClick={() => handleShowPopup()}
              className="upload-icon"
            />
          </div>
        </div>
      </div>

      <div className="prompt-desc-wrap">
        <h3 className="prompt-title">
          Short Description <span className="red">*</span>
        </h3>
        <div
          contentEditable
          suppressContentEditableWarning
          className="prompt-textarea prompt-pii"
          value={promptStepOneDataOriginal?.ShortDescription}
          onInput={(e) => {
            const textValue = e.currentTarget.innerText;
            setPromptStepOneDataOriginal((prevData) => ({
              ...prevData,
              ShortDescription: textValue === undefined ? "" : textValue,
            }));
            descriptionRef.current.classList.remove("border-red");
            if (textValue.length > 1000) {
              // Truncate to 1000 characters
              e.currentTarget.innerText = textValue.slice(0, 1000);

              // Move cursor to the end
              const range = document.createRange();
              const sel = window.getSelection();
              range.selectNodeContents(e.currentTarget);
              range.collapse(false);
              sel.removeAllRanges();
              sel.addRange(range);
            }
            e.preventDefault();
          }}
          onBlur={(e) => handlePIIChange(e)}
          rows="4"
          ref={descriptionRef}
        ></div>
      </div>

      <div className="prompt-explanaion">
        <div className="prompt-box">
          <p className="prompt-title-small">
            Provide some background and explain the use case for which you would
            like to design the prompt <span className="red">*</span>
          </p>
          <div
            contentEditable
            suppressContentEditableWarning
            className="prompt-textarea prompt-pii"
            value={promptStepOneDataOriginal?.PromptBackground}
            rows="6"
            onInput={(e) => {
              const textValue = e.currentTarget.innerText;
              setPromptStepOneDataOriginal((prevData) => ({
                ...prevData,
                PromptBackground: textValue === undefined ? "" : textValue,
              }));
              text1Ref.current.classList.remove("border-red");
              if (textValue.length > 1000) {
                // Truncate to 1000 characters
                e.currentTarget.innerText = textValue.slice(0, 1000);

                // Move cursor to the end
                const range = document.createRange();
                const sel = window.getSelection();
                range.selectNodeContents(e.currentTarget);
                range.collapse(false);
                sel.removeAllRanges();
                sel.addRange(range);
              }
              e.preventDefault();
            }}
            onBlur={(e) => handlePIIChange(e)}
            ref={text1Ref}
          ></div>
        </div>
        <div className="prompt-box">
          <p className="prompt-title-small">
            Articulate the desired output format in which you would like to see
            the response? <span className="red">*</span>
          </p>
          <div
            contentEditable
            suppressContentEditableWarning
            className="prompt-textarea prompt-pii"
            value={promptStepOneDataOriginal?.DesiredPromptOutput}
            onInput={(e) => {
              const textValue = e.currentTarget.innerText;
              setPromptStepOneDataOriginal((prevData) => ({
                ...prevData,
                DesiredPromptOutput: textValue,
              }));
              text2Ref.current.classList.remove("border-red");
              if (textValue.length > 1000) {
                // Truncate to 1000 characters
                e.currentTarget.innerText = textValue.slice(0, 1000);

                // Move cursor to the end
                const range = document.createRange();
                const sel = window.getSelection();
                range.selectNodeContents(e.currentTarget);
                range.collapse(false);
                sel.removeAllRanges();
                sel.addRange(range);
              }
              e.preventDefault();
            }}
            onBlur={(e) => handlePIIChange(e)}
            rows="6"
            ref={text2Ref}
          ></div>
        </div>
      </div>
      <div className="preference-wrapper">
        <label>
          Preference <span className="red">*</span>
        </label>

        <div className="pref-block">
          <div ref={divPreferencesRef}>
            <SelectByPreferences
              onPreferencesSelection={onPreferencesSelection}
              creativityData={creativityData}
              selectPreferences={promptStepOneDataOriginal?.LookupCreativityId}
            />
          </div>
          <input
            className="word-count-field numeric-input-box"
            ref={wordCountRef}
            value={promptStepOneDataOriginal?.inputWordCount}
            onChange={(e) => handleWordCountChange(e)}
            onPaste={(e) => e.preventDefault()}
            placeholder="Word Count"
          />
        </div>
      </div>
      <div className="prompt-button-wrapper">
        <Link className="cancel-btn" to="/promptmanagement">
          Cancel
        </Link>

        <button className="genrate-btn" onClick={() => validateInput()}>
          Generate Prompt
        </button>
      </div>

      {showCropImagePopup && (
        <CropImage
          handleSaveImage={handleSaveImage}
          handleCancelClick={() => handleCancelClick()}
        ></CropImage>
      )}
    </div>
  );
};

export default PromptStepOne;
