import { useContext } from "react";
import { UserContext } from "../../../../context/user/userContext";
import { getFullName } from "../useUserFullName";

export const useUserName = () => {
  const { state } = useContext(UserContext);

  const getInitials = () => {
    const fullName = getFullName(state);
    if (!fullName) return "";
    return fullName
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase())
      .join("");
  };

  const getUserFullName = () => {
    const fullName = getFullName(state);
    if (!fullName) return "";
    return fullName;
  };

  return { fullName: getUserFullName(), initials: getInitials() };
};
