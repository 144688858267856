import { RequireAuth } from "../auth/requireAuth";
import "../../../styles/css/mks-application.css";
import DashboardNavBar from "../dashboardNavBar";
import { Outlet, Link } from "react-router-dom";
import DashboardSetting from "../dashboardSetting";
import PageWithTitle from "../pageWithTitle";
import { useEffect, useRef, useState } from "react";

export const Layout = () => {
  const [menuClass, setMenuClass] = useState(false);
  const [menuClassMobile, setMenuClassMobile] = useState(false);
  const menuRef = useRef(null);
  const toggleButtonRef = useRef(null);

  const menuToggle = () => {
    setMenuClass((menuClass) => !menuClass);
  };

  const menuToggleMobile = () => {
    setMenuClassMobile((menuClassMobile) => !menuClassMobile);
  }

  useEffect(() => {
    const handleClickOutside = (e) => {
      // Check if the click is outside both the menu and toggle button
      if (
        menuClassMobile && 
        menuRef.current && 
        !menuRef.current.contains(e.target) &&
        toggleButtonRef.current &&
        !toggleButtonRef.current.contains(e.target)
      ) {
        setMenuClassMobile(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuClassMobile]);
  

  return (
    <>
      <RequireAuth>
        <div className="flexbox">
          <div className="workspace-dashboard">
            <div className="mobile-header">
              <Link to="/home" className="menu-logo w-inline-block"></Link>
              <div className="close-panel-button"></div>
              <div  ref={toggleButtonRef} className="toggle-menu-mobile" onClick={menuToggleMobile}></div>
            </div>
            <div ref={menuRef} className="hide-on-desk">
            <div className={menuClassMobile ? "dash-menu" : "dash-menu open"}>
              <div className="menunav">
                <div className="left-menu-header">
                  <Link to="/home" className="menu-logo w-inline-block"></Link>
                  <div className="toggle-menu-desk"  onClick={menuToggleMobile}></div>
                 
                </div>
                
                <div className="hide-on-desk">
                <DashboardNavBar setMenuClassMobile={setMenuClassMobile} />
                </div>
                
               
              </div>

            </div>
            </div>

            <div className={menuClass ? "dash-menu hide-on-mobile" : "dash-menu open hide-on-mobile"}>
            <div className="menunav ">
                <div className="left-menu-header">
                  <Link to="/home" className="menu-logo w-inline-block"></Link>
                  {menuClass ?
                  <div className="toggle-menu-desk" title="Collapse" onClick={menuToggle}></div> : <div className="toggle-menu-desk" title="Expand" onClick={menuToggle}></div>
                }
                  
                 
                </div>
                
                <div className="hide-on-mobile">
                <DashboardNavBar />
                </div>
               
              </div>
            </div>
            
            <div className={menuClass ? "dash-body " : "dash-body expand"}>
              <DashboardSetting />
              <PageWithTitle>
                <Outlet />
              </PageWithTitle>
            </div>
          </div>
        </div>
      </RequireAuth>
    </>
  );
};
