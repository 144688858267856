import React, { useEffect, useRef, useState } from "react";
import AppImages from "../../../../../../constants/images";
import { getInitials } from "../../../../../../services/commonService";
import { capitalizeFirstLetter } from "../../../../../../services/commonService";
import UserImage from "../../../../../common/userImage/index.js";
import showMessage from "../../../../../common/message";

const CommentModal = (props) => {
  const { isOpen, onClose, onSave, onChange, commentData, onSaveChildComment } =
    props;
  const [replyText, setReplyText] = useState("");
  const [isReplyClicked, setIsReplyClicked] = useState(false);
  const [commentIdSelected, setCommentIdSelected] = useState(null);
  const [mainReplyText, setMainReplyText] = useState("");
  const refForPrentComment = useRef();
  const refForChildComment = useRef(null);

  useEffect(() => {
    if (isReplyClicked && refForChildComment.current) {
      refForChildComment.current.focus(); // Set focus on the textarea
    }
  }, [isReplyClicked]);

  useEffect(() => {
    if (isOpen && refForPrentComment.current) {
      refForPrentComment.current.focus(); // Set focus on the textarea
    }
  }, [isOpen]);

  if (!isOpen) return null;

  const hasReplyClicked = (CommentId) => {
    if (isReplyClicked && commentIdSelected === CommentId) return true;
    else return false;
  };

  const hasChildComment = (replies) => {
    if (replies?.length > 0) return true;
    else return false;
  };

  const handleonClose = () => {
    setIsReplyClicked(false);
    setCommentIdSelected(null);
    setReplyText("");
    onClose();
  };

  const checkIsLastRecord = (replies, index) => {
    if (replies.length - 1 === index) {
      return true;
    } else return false;
  };

  const formatDateTime = (dateTime) => {
    let newDateTime = null;
    if (dateTime.includes("AM")) {
      newDateTime = dateTime.replace("AM", "");
    } else newDateTime = dateTime.replace("PM", "");

    const now = new Date(); // Current date and time
    //const givenDate = new Date(newDateTime); // Provided date and time
    const givenDate = new Date(newDateTime + "UTC");

    // Check if the provided date is today
    const isToday = now.toDateString() === givenDate.toDateString();

    // Check if the provided date is yesterday
    const yesterday = new Date(now);
    yesterday.setDate(now.getDate() - 1);
    const isYesterday = yesterday.toDateString() === givenDate.toDateString();

    // Time in 24-hour format (HH:mm)
    const hours = String(givenDate.getHours()).padStart(2, "0");
    const minutes = String(givenDate.getMinutes()).padStart(2, "0");
    const timeString = `${hours}:${minutes}`;

    if (isToday) {
      return `Today at ${timeString}`;
    } else if (isYesterday) {
      return `Yesterday at ${timeString}`;
    } else {
      // Return date in YYYY-MM-DD HH:mm format
      const year = givenDate.getFullYear();
      const month = String(givenDate.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
      const day = String(givenDate.getDate()).padStart(2, "0");
      return `${year}-${month}-${day} ${timeString}`;
    }
  };

  const handleOnSaveChildComment = (Id, replyText) => {
    if (replyText?.trim() === "") {
      showMessage("Please enter comment", -2);
      refForChildComment.current.focus();
      return;
    }

    setIsReplyClicked(false);
    setCommentIdSelected(null);
    onSaveChildComment(Id, replyText);
    setReplyText("");
  };

  const handleOnSave = () => {
    if (mainReplyText?.trim() === "") {
      showMessage("Please enter comment", -2);
      refForPrentComment.current.focus();
      return;
    }
    setMainReplyText("");
    onSave();
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content comment-modal">
        <div className="coment-section">
          <div
            className="close-icon top-close"
            onClick={() => handleonClose()}
          ></div>
          <h2 className="comment-header">All Comments</h2>
          <div className="all-comment">
            {commentData &&
              commentData
                ?.sort((a, b) => b.Id - a.Id)
                ?.map((data, index) => (
                  <div className="recent-comments" key={index}>
                    <div
                      className={
                        hasReplyClicked(data.Id) ||
                        hasChildComment(data.replies)
                          ? "new-comment replied"
                          : "new-comment"
                      }
                    >
                      <div className="prompt-user-img">
                        <UserImage
                          userImageBytes={data?.userImage}
                          userImageMimeType={data?.userImageMimeType}
                          userInitials={getInitials(
                            data?.FirstName + " " + data?.LastName
                          )}
                        ></UserImage>
                      </div>
                      <div className="full-width light-coment-bg">
                        <div className="user-comment-name">
                          <div className="bold-name">
                            {capitalizeFirstLetter(data?.FirstName) +
                              " " +
                              capitalizeFirstLetter(data?.LastName)}
                          </div>
                          <div className="commentdate">
                            <span>{formatDateTime(data?.CommentDate)}</span>
                          </div>
                        </div>
                        <div className="user-coment">{data?.Comment}</div>
                        <div className="comment-actions">
                          <div
                            className="comment-action"
                            onClick={() => {
                              setIsReplyClicked(true);
                              setCommentIdSelected(data.Id);
                            }}
                          >
                            Reply
                          </div>
                        </div>
                      </div>
                    </div>
                    {isReplyClicked && commentIdSelected === data?.Id && (
                      <div className="reply-comment text-comment">
                        <div className="new-reply-comment">
                          <textarea
                            value={replyText}
                            onChange={(e) => setReplyText(e.target.value)}
                            placeholder="Write your reply..."
                            rows="2"
                            style={{ width: "100%" }}
                            onKeyDown={(e) => {
                              if (e.shiftKey && e.key === "Enter") {
                                e.preventDefault();                               
                              }

                              if (
                                e.key === "Enter" &&
                                !e.altKey &&
                                !e.shiftKey
                              ) {
                                e.preventDefault();
                                handleOnSaveChildComment(data?.Id, replyText);
                              }
                            }}
                            ref={refForChildComment}
                            maxLength={256}
                          />
                          <div className="flex-block g-10">
                            <button
                              className="cancel-comment"
                              onClick={() => {
                                setIsReplyClicked(false);
                                setCommentIdSelected(null);
                                setReplyText("");
                              }}
                              type="submit"
                            >
                              Cancel
                            </button>
                            <button
                              className="comment-reply-btn"
                              onClick={() => {
                                handleOnSaveChildComment(data?.Id, replyText);
                              }}
                              type="submit"
                            >
                              Reply
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                    {data?.replies &&
                      data?.replies.map((reply, ind) => (
                        <div
                          className={
                            checkIsLastRecord(data?.replies, ind) === true
                              ? "reply-comment"
                              : "reply-comment replied"
                          }
                          key={ind}
                        >
                          <div className="prompt-user-img">
                            <span>
                              {getInitials(
                                reply?.FirstName + " " + reply?.LastName
                              )}
                            </span>
                          </div>
                          <div className="full-width light-coment-bg">
                            <div className="user-comment-name">
                              <div className="bold-name">
                                {capitalizeFirstLetter(reply?.FirstName) +
                                  " " +
                                  capitalizeFirstLetter(reply?.LastName)}
                              </div>
                              <div className="commentdate">
                                <span>
                                  {formatDateTime(reply?.CommentDate)}
                                </span>
                              </div>
                            </div>
                            <div className="user-coment">{reply?.Comment}</div>
                          </div>
                        </div>
                      ))}
                  </div>
                ))}
          </div>
          <div className="text-box">
            <textarea
              className="textwrap"
              rows="2"
              value={mainReplyText}
              ref={refForPrentComment}
              onChange={(e) => {
                onChange(e.target.value);
                setMainReplyText(e.target.value);
              }}
              onKeyDown={(e) => {

                if (e.shiftKey && e.key === "Enter") {
                  e.preventDefault();                               
                }
                
                if (e.key === "Enter" && !e.altKey && !e.shiftKey) {
                  e.preventDefault();
                  handleOnSave();
                }
              }}
              maxLength={256}
            ></textarea>
            <button className="submit-btn" onClick={() => handleOnSave()}>
              <img src={AppImages.sendicon} alt="" className="send-icon" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(CommentModal);
