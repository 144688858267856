import React, { useEffect, useState, useContext } from "react";
import FeedbackList from "./views/_partials/feedbackList";
import CreateFeedback from "./views/_partials/createFeedback";
import { getUserFeedback } from "./services/feedbackService";
import { captureUserFeedback } from "./services/feedbackService";
import showMessage from "../../common/message";
import FeedbackDetailPopUp from "./views/_partials/feedbackDetailPopUp";
import loaderContext from "../../../context/loader/loaderContext";

const Feedback = () => {
  const [isAnonymous, setIsAnonymous] = useState(false);
  const [characterCount, setCharacterCount] = useState(0);
  const [feedbackText, setFeedbackText] = useState("");
  const [feedbackList, setFeedbackList] = useState([]);
  const [feedbackFullText, setFeedbackFullText] = useState("");
  const [isShowPopUp, setIsShowPopUp] = useState(false);
  const [loadFeedbackForm, setLoadFeedbackForm] = useState(false);

  const { updateLoaderStatus } = useContext(loaderContext);

  useEffect(() => {
    updateLoaderStatus(true);
    getData();
    updateLoaderStatus(false);
  }, []);

  const getData = async () => {
    const feedbackData = await getUserFeedback();
    const filteredFeedback = feedbackData.filter(
      (item) => item.FeedbackDescription.trim() !== ""
    );
    setFeedbackList(filteredFeedback);
    setLoadFeedbackForm(true);
  };

  const submitFeedback = async () => {
    updateLoaderStatus(true);
    const feedbackData = {
      FeedbackDescription: feedbackText,
      IsAnonymous: isAnonymous,
    };
    const res = await captureUserFeedback(feedbackData);
    if (res !== null && res !== undefined) {
      showMessage(res.Status, 1);
      setFeedbackText("");
      await getData();
    }
    setCharacterCount(0);
    updateLoaderStatus(false);
  };

  const showFullFeedbackPopUp = (id) => {
    const fullDescText = feedbackList.filter((f) => f.ID === id)[0]
      ?.FeedbackDescription;
    setFeedbackFullText(fullDescText);
    setIsShowPopUp(!isShowPopUp);
  };

  const hideFullFeedbackPopUp = () => {
    setIsShowPopUp(!isShowPopUp);
  };

  return (
    <div className="feedabck-wrapper">
      <FeedbackList
        feedbackList={feedbackList}
        showFullFeedbackPopUp={showFullFeedbackPopUp}
      />
      {loadFeedbackForm && (
        <CreateFeedback
          characterCount={characterCount}
          setCharacterCount={setCharacterCount}
          feedbackText={feedbackText}
          setFeedbackText={setFeedbackText}
          submitFeedback={submitFeedback}
        />
      )}

      {isShowPopUp && (
        <FeedbackDetailPopUp
          feedbackFullText={feedbackFullText}
          hideFullFeedbackPopUp={hideFullFeedbackPopUp}
        />
      )}
    </div>
  );
};

export default Feedback;
