export const getAccessToken = () => {
    return sessionStorage.getItem("token");
  };
  
  export const setAccessToken = (AccessToken) => {
    sessionStorage.setItem("token", AccessToken);
  };
  
  export const removeAccessToken = () => {
    sessionStorage.removeItem("token");
  };

  