import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Skills from "../../../../skillStore/views/_partials/skills";
import { getPromptManagementStoreData } from "../../../../promptManagement/services/promptManagementService";
import PromptManagementContent from "../../../../promptManagement/views/_partials/promptManagementContent";

const SkillsAndPrompt = () => {
  const CardLimit = parseInt(process.env.REACT_APP_CARD_LIMIT);
  const [promptManagementStoreData, setPromptManagementStoreData] = useState(
    []
  );

  useEffect(() => {
    const getData = async () => {
      const data = await getPromptManagementStoreData();
      setPromptManagementStoreData(data);
    };
    getData();
  }, []);

  const handleFavoriteClick = (val, cardId) => {
    const updatedData = promptManagementStoreData.map((item) => {
      if (item.Id === cardId) {
        return { ...item, IsStared: !val };
      }
      return item;
    });

    setPromptManagementStoreData([...updatedData]);
  };

  return (
    <>
      <Skills limit={CardLimit} />
      <div className="dash-section p20">
        <div className="w-layout-hflex h-flex head-wrapper">
          <h2 className="h2">Prompts</h2>
          <Link to="/promptmanagement" className="button text w-button">
            View All
          </Link>
        </div>
        <PromptManagementContent
          limit={CardLimit}
          promptManagementStores={promptManagementStoreData}
          onFavouriteClick={handleFavoriteClick}
        />
      </div>
    </>
  );
};

export default SkillsAndPrompt;
