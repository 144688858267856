import { TriStateValue, accessTypes } from "../constants/enums";
import axiosApiInstance from "../api/axiosApiClient";

const baseAPIUrl = process.env.REACT_APP_AUTHAPI_URL;

export const getAccessName = (type) => {
  switch (type) {
    case accessTypes.UPDATE: {
      return "Update";
    }
    case accessTypes.CREATE: {
      return "Create";
    }
    case accessTypes.DELETE: {
      return "Delete";
    }
    case accessTypes.READ:
    default: {
      return "Read";
    }
  }
};

export const hasAccess = (privileges, entityName, accessType) => {
  let result = false;
  if (
    privileges !== undefined &&
    privileges !== null &&
    privileges.length > 0
  ) {
    let found = privileges.find((x) => x.EntityName === entityName);
    if (found !== null && found !== undefined) {
      switch (accessType) {
        case accessTypes.CREATE: {
          result = found.CanCreate === true;
          break;
        }
        case accessTypes.UPDATE: {
          result = found.CanUpdate === true;
          break;
        }
        case accessTypes.DELETE: {
          result = found.CanDelete === true;
          break;
        }
        default:
        case accessTypes.READ: {
          result = found.CanRead === true;
          break;
        }
      }
    }
  }
  return result;
};

export const getAssignablePermissions = async () => {
  let url = `${baseAPIUrl}rolemanagement/GetAssignablePermissions`;

  const permissionsResponse = await axiosApiInstance.get(url);
  let data = await permissionsResponse.data;
  return data;
};

export const transformAssignablePermissions = (assignablePermissions) => {
  let transformedObject = {};
  transformedObject.headings = getArrUnchecked();
  let modules = [];

  for (let k = 0; k < assignablePermissions.length; k++) {
    let permission = assignablePermissions[k];
    let module = {};
    module.name = permission.ModuleName;
    module.description = permission.ModuleDescription;
    module.expanded = true;
    module.accessTypes = getArrUnchecked();
    let children = [];

    let sortedEntities = assignablePermissions.sort((a, b) => {
      return a.displayIndex < b.displayIndex ? -1 : 1;
    });
    for (let a = 0; a < sortedEntities.length; a++) {
      let entity = sortedEntities[a];
      let child = {};
      let v = shouldConfigureNumberOfDays(entity);
      child.configureNumberOfDays = v;
      child.name = entity.EntityName;
      child.id = entity.EntityId;
      child.description = entity.EntityDescription;
      child.accessTypes = getArrUnchecked();
      child.isAutoAccept = entity.IsAutoAccept;
      child.autoAcceptType = entity.AutoAcceptType;
      child.isCorporateOnly = entity.IsCorporteOnly;
      child.numberOfDays = entity.MaxDays;
      child.indentLevel = entity.DisplayOrder;
      children.push(child);
    }
    module.children = children;
    modules.push(module);
  }
  transformedObject.modules = modules;
  return transformedObject;
};

export const transformAssignedPermissions = (
  assignablePermissions,
  assignedPermissions,
  isFranchisee = false
) => {
  let transformedObject = {};
  transformedObject.headings = getArr(assignedPermissions, 2);
  let modules = [];
  const getMatchedModule = (module) => {
    let assignedPermissionsModule = assignedPermissions.modulePermissions;
    if (
      assignedPermissionsModule !== null &&
      assignedPermissionsModule !== undefined
    ) {
      let matchedModule = assignedPermissions.modulePermissions.find(
        (x) => x.moduleName === module.name
      );
      return matchedModule;
    }
    return undefined;
  };
  const getMatchedEntity = (module, entity) => {
    let matchedModule = getMatchedModule(module);
    if (matchedModule === undefined || matchedModule === null) {
      return undefined;
    }
    let moduleEntityPermissions = matchedModule.entityPermissions;
    if (
      moduleEntityPermissions !== null &&
      moduleEntityPermissions !== undefined
    ) {
      return matchedModule.entityPermissions.find(
        (x) => x.entityName === entity.name
      );
    }
    return undefined;
  };
  if (isFranchisee === false) {
    let sortedModules = assignablePermissions.modules.sort((a, b) => {
      return a.displayOrder < b.displayOrder ? -1 : 1;
    });
    for (let k = 0; k < sortedModules.length; k++) {
      let permission = sortedModules[k];
      let module = {};
      module.name = permission.name;
      module.description = permission.description;
      module.expanded = true;
      let matchedModule = getMatchedModule(module);
      if (matchedModule === undefined || matchedModule === null) {
        module.accessTypes = getArrUnchecked();
      } else {
        module.accessTypes = getArr(matchedModule, 1);
      }
      let children = [];
      let sortedEntities = permission.children.sort((a, b) => {
        return a.displayIndex < b.displayIndex ? -1 : 1;
      });

      for (let a = 0; a < sortedEntities.length; a++) {
        let entity = sortedEntities[a];
        let child = {};
        child.configureNumberOfDays = shouldConfigureNumberOfDays(entity); //  entity.configureNumberOfDays;
        child.id = entity.id;
        child.name = entity.name;
        child.description = entity.description;
        child.displayIndex = entity.displayIndex;
        child.indentLevel = entity.indentLevel;
        let matchedEntity = getMatchedEntity(module, child);
        if (matchedEntity === undefined || matchedEntity === null) {
          child.accessTypes = getArrUnchecked();
          child.numberOfDays = 0;
          child.isAutoAccept = false;
          child.autoAcceptType = null;
        } else {
          child.accessTypes = getArr(matchedEntity, 0);
          child.numberOfDays = matchedEntity.numberOfDays;
          child.isAutoAccept = matchedEntity.isAutoAccept;
          child.autoAcceptType = matchedEntity.autoAcceptType;
        }

        children.push(child);
      }
      module.children = children;
      modules.push(module);
    }
  } else {
    let sortedModules = assignedPermissions.modulePermissions;
    for (let k = 0; k < sortedModules.length; k++) {
      let permission = sortedModules[k];
      let module = {};
      module.name = permission.moduleName;
      module.description = permission.moduleDescription;
      module.expanded = true;
      let matchedModule = getMatchedModule(module);
      if (matchedModule === undefined || matchedModule === null) {
        module.accessTypes = getArrUnchecked();
      } else {
        module.accessTypes = getArr(matchedModule, 1);
      }
      let children = [];
      let sortedEntities = permission.entityPermissions.sort((a, b) => {
        return a.displayIndex < b.displayIndex ? -1 : 1;
      });
      for (let a = 0; a < sortedEntities.length; a++) {
        let entity = sortedEntities[a];

        if (entity.isCorporateOnly === false) {
          let child = {};

          child.configureNumberOfDays = shouldConfigureNumberOfDays(
            entity,
            assignablePermissions
          );
          child.id = entity.entityId;
          child.name = entity.entityName;
          child.description = entity.entityDescription;
          child.displayIndex = entity.displayIndex;
          child.indentLevel = entity.indentLevel;
          let matchedEntity = getMatchedEntity(module, child);
          if (matchedEntity === undefined || matchedEntity === null) {
            child.accessTypes = getArrUnchecked();
            child.numberOfDays = null;
            child.isAutoAccept = false;
            child.autoAcceptType = null;
          } else {
            child.accessTypes = getArr(matchedEntity, 0);
            if (child.configureNumberOfDays) {
              if (matchedEntity.numberOfDays > 0) {
                child.numberOfDays = matchedEntity.numberOfDays;
              } else {
                child.numberOfDays = 1;
              }
            } else {
              child.numberOfDays = null;
            }

            child.isAutoAccept = matchedEntity.isAutoAccept;
            child.autoAcceptType = matchedEntity.autoAcceptType;
          }

          children.push(child);
        }
      }
      module.children = children;
      modules.push(module);
    }
  }
  transformedObject.modules = modules;
  return transformedObject;
};

const shouldConfigureNumberOfDays = (entityObject, aP = undefined) => {
  /*const eName = entityObject.entityName?.toUpperCase();
  if(eName === 'OAREFUNDS' || eName === 'OASTATUS'){
    return true;
  }*/
  const configureNumberOfDays = entityObject?.configureNumberOfDays;
  if (
    configureNumberOfDays !== undefined &&
    configureNumberOfDays !== null &&
    configureNumberOfDays === true
  ) {
    return true;
  }
  if (aP !== undefined) {
    let found = null;
    for (let c = 0; c < aP?.modules.length; c++) {
      let module = aP.modules[c];
      for (let k = 0; k < module.children.length; k++) {
        let f = module.children[k];
        if (f.id === entityObject.entityId) {
          found = f;
          break;
        }
      }
    }
    if (found !== null) {
      return found.configureNumberOfDays ?? false;
    }
  }
  const numberOfDays = entityObject?.numberOfDays;
  if (numberOfDays !== undefined && numberOfDays !== null && numberOfDays > 0) {
    return true;
  }
  return false;
};

const getArrUnchecked = () => {
  let defaultValue = TriStateValue.UNCHECKED;
  let arrValue = [];
  for (let prop in accessTypes) {
    arrValue.push({ name: prop, value: defaultValue, percent: 0 });
  }
  return arrValue;
};

const getArr = (obj, type = 0) => {
  let arrValue = [];
  for (let prop in accessTypes) {
    let stateValue = TriStateValue.UNCHECKED;
    let percent = 0;
    if (type === 0) {
      stateValue =
        obj[accessTypes[prop]] === true
          ? TriStateValue.CHECKED
          : TriStateValue.UNCHECKED;
    } else if (type === 1 || type === 2) {
      let percentPropName = "percent" + getAccessName(accessTypes[prop]);
      percent = Number(parseFloat(obj[percentPropName]).toFixed(2));
      if (percent === 100) {
        stateValue = TriStateValue.CHECKED;
      } else if (percent === 0) {
        stateValue = TriStateValue.UNCHECKED;
      } else {
        stateValue = TriStateValue.PARTIAL;
      }
    }

    arrValue.push({ name: prop, value: stateValue, percent: percent });
  }
  return arrValue;
};
