import React, { useState } from "react";
import BreadCrumb from "../_partials/breadCrumb";
import SkillHeaderDetails from "../_partials/skillHeaderDetails";

const CreateSkill = () => {
  const [skillStoreForm, setskillStoreForm] = useState({
    Name: "",
    Description: "",
    ShortDescription: "",
    DepartmentNameId: 0,
    LookupSkillScopeId: 0,
    PublishOn: "",
    Acurracy: "",
    AvgExecutionTime: "",
    IsThirdPartyAITool: false,
    functions: [],
    subFunctions: [],
    tags: [],
    Owner: [],
    Contact: [],
    ChatBotURL: "",
    InputType: null,
    OutputType: null,
    LastMessagesCount: null,
    ChatGPTVersion: null,
    IsChatbotTestable: null,
    ShowCitation: null,
    ShowFollowUpQuestions: null,
    CurlRequestString: null,
    UserAssistantMessageFormat: null,
    FileType: null,
    ChatbotUIView: null,
    DynamicProperties: [],
  });

  return (
    <div className="dashboard-wrapper">
      <div className="">
        <BreadCrumb skillStoreName="Create Skill" />
        <SkillHeaderDetails
          skillStoreForm={skillStoreForm}
          setskillStoreForm={setskillStoreForm}
        ></SkillHeaderDetails>
      </div>
    </div>
  );
};

export default CreateSkill;
