import React, { useState, useEffect } from "react";
import PromptCard from "../promptCard";
import PromptManagementFilterSection from "../promptManagementFilterSection";
import {
  getSkillDepartmentData,
  getSkillFunctionData,
  getSkillSubFunctionData,
  getSkillScopeData,
} from "../../../services/promptManagementService";
import { Pager } from "../../../../shared/pager";

const PromptManagementContent = (props) => {
  const { promptManagementStores, limit, onFavouriteClick } = props;
  const [currentPageNbr, setCurentPageNbr] = useState(1);
  const [currentPageSize, setCurrentPageSize] = useState(10);
  const [promptManagementStoreData, setPromptManagementStoreData] = useState(
    []
  );
  const [
    originalPromptManagementStoreData,
    setOriginalPromptManagementStoreData,
  ] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedFunction, setSelectedFunction] = useState([]);
  const [selectedSubFunction, setSelectedSubFunction] = useState([]);
  const [selectedScope, setSelectedScope] = useState("");
  const [promptDepartmentData, setPromptDepartmentData] = useState([]);
  const [promptFunctiondData, setPromptFunctionData] = useState([]);
  const [promptSubFunctionData, setPromptSubFunctionData] = useState([]);
  const [promptScopeData, setPromptScopeData] = useState([]);
  const [promptManagmntCountList, setPromptManagmntCountList] = useState([]);

  useEffect(() => {
    // if (
    //   promptManagementStores !== null &&
    //   promptManagementStores !== undefined &&
    //   promptManagementStores?.length > 0
    // )
    // setPromptManagementStoreData(promptManagementStores);
    setOriginalPromptManagementStoreData(promptManagementStores);
  }, [promptManagementStores]);

  useEffect(() => {
    const getSkillDepData = async () => {
      const data = await getSkillDepartmentData();
      setPromptDepartmentData(data);
    };
    const getSkillScoData = async () => {
      const data = await getSkillScopeData();
      setPromptScopeData(data);
    };
    getSkillDepData();
    getSkillScoData();
  }, []);

  useEffect(() => {
    const filterPrompManagement = () => {
      let filteredPrompManagements = originalPromptManagementStoreData;
      if (searchTerm) {
        const lowercasedVal = searchTerm.toLowerCase();
        filteredPrompManagements = filteredPrompManagements.filter((prompt) =>
          prompt.Title.toLowerCase().includes(lowercasedVal)
        );
      }

      if (selectedDepartment) {
        const globalVal = "global";
        const department = promptDepartmentData.find(
          (dep) => dep.Id === parseInt(selectedDepartment)
        );
        if (department) {
          const departmentName = department.Name;
          filteredPrompManagements = filteredPrompManagements.filter(
            (skill) =>
              skill.DepartmentName?.toLowerCase() ===
                departmentName.toLowerCase() ||
              /* Additional condition here */
              skill.DepartmentName?.toLowerCase() === globalVal
          );
        }
      }

      if (selectedFunction?.length > 0 && Array.isArray(selectedFunction)) {
        filteredPrompManagements = filteredPrompManagements.filter((skill) =>
          skill.functions.some((func) => selectedFunction.includes(func.Id))
        );
      }

      if (
        selectedSubFunction?.length > 0 &&
        Array.isArray(selectedSubFunction)
      ) {
        filteredPrompManagements = filteredPrompManagements.filter((skill) =>
          skill.subFunctions.some((func) =>
            selectedSubFunction.includes(func.Id)
          )
        );
      }

      if (selectedScope) {
        filteredPrompManagements = filteredPrompManagements.filter(
          (skill) =>
            skill.SkillScope.toLowerCase() === selectedScope.toLowerCase()
        );
      }

      setPromptManagmntCountList(filteredPrompManagements);
      const totalPages = Math.ceil(
        filteredPrompManagements.length / currentPageSize
      );
      if (currentPageNbr > 1) {
        if (currentPageNbr > totalPages) {
          setCurentPageNbr(1);
          //setCurentPageNbr(currentPageNbr - 1)};
        }
      }

      if (parseInt(currentPageSize) === -1) {
        setPromptManagementStoreData([...filteredPrompManagements]);
      } else {
        const startIndex = (currentPageNbr - 1) * parseInt(currentPageSize);
        if (filteredPrompManagements.length <= startIndex) {
          setPromptManagementStoreData([...filteredPrompManagements]);
        } else {
          setPromptManagementStoreData([
            ...filteredPrompManagements.slice(
              startIndex,
              startIndex +
                parseInt(
                  parseInt(currentPageSize) === -1
                    ? filteredPrompManagements.length
                    : currentPageSize
                )
            ),
          ]);
        }
      }

      //setPromptManagementStoreData(masterPromptManagementList);
      // setPromptManagementStoreData(filteredPrompManagements);
    };

    filterPrompManagement();
  }, [
    searchTerm,
    selectedDepartment,
    selectedFunction,
    selectedSubFunction,
    selectedScope,
    promptDepartmentData,
    promptManagementStores,
    promptManagementStoreData.length,
    originalPromptManagementStoreData,
    currentPageNbr,
    currentPageSize,
  ]);

  const searchByInputVal = (val) => {
    setSearchTerm(val);
  };

  const searchByPromptDepartment = async (selectedVal) => {
    setSelectedDepartment(selectedVal);
    const functionData = await getSkillFunctionData(
      selectedVal === "" ? 0 : selectedVal
    );

    if (selectedVal === "") {
      setSelectedFunction("");
      setSelectedSubFunction("");
    }

    if (functionData !== null && functionData !== undefined) {
      const updateData = functionData.filter(
        (item) => item.Name.toLowerCase() !== "all"
      );
      setPromptFunctionData(updateData);
      setPromptSubFunctionData([]);
    }
    setCurentPageNbr(1);
    setSelectedFunction([]);
  };

  const searchByPromptFunction = async (selectedVal) => {
    if (selectedVal.length === 0) setSelectedSubFunction("");
    setSelectedFunction(selectedVal);
    //Call here Sub Function Method
    const subFunctionData = await getSkillSubFunctionData(selectedVal);
    if (subFunctionData !== null && subFunctionData !== undefined) {
      setPromptSubFunctionData(subFunctionData);
    } else setPromptSubFunctionData([]);
    setCurentPageNbr(1);
  };

  const searchByPromptSubFunction = async (selectedVal) => {
    setSelectedSubFunction(selectedVal);
    setCurentPageNbr(1);
  };

  const searchByScope = (selectedVal) => {
    setSelectedScope(selectedVal);
    setCurentPageNbr(1);
  };

  const displayedPromptManagement = limit
    ? originalPromptManagementStoreData?.slice(0, limit)
    : originalPromptManagementStoreData;

  const onPageNumberChange = (newPageNumber) => {
    setCurentPageNbr(newPageNumber);
  };

  const onPageSizeChange = (event) => {
    const pageSizeValue = event.target.value;
    setCurentPageNbr(1);
    setCurrentPageSize(parseInt(pageSizeValue));
  };

  const getPagerArea = () => {
    return (
      <Pager
        pageRecords={
          promptManagementStoreData === undefined
            ? 0
            : promptManagementStoreData.length
        }
        totalRecords={promptManagmntCountList.length}
        pageNumber={currentPageNbr}
        pageSize={currentPageSize}
        onPageNumberChange={onPageNumberChange}
        onPageChangeSize={onPageSizeChange}
        pageSizeOptions={[10, 20, 50, 100]}
        selectStyleClass={"no-border"}
        hidePageNumber={false}
        paginationRangeSeparator={"-"}
        keepAllPageOptions={true}
      ></Pager>
    );
    // return <React.Fragment></React.Fragment>;
  };

  return (
    <>
      <div className="prompts-tabs-pane w-tab-pane w--tab-active">
        <div className="prompts-tabs-content">
          {!limit && (
            <PromptManagementFilterSection
              onInputSearchPromptManagement={searchByInputVal}
              searchDepartmentData={promptDepartmentData}
              onSearchByPromptDepartment={searchByPromptDepartment}
              searchFunctionData={promptFunctiondData}
              onSearchByPromptFunction={searchByPromptFunction}
              searchSubFunctionData={promptSubFunctionData}
              onSearchByPromptSubFunction={searchByPromptSubFunction}
              searchPromptScopeData={promptScopeData}
              onSearchByPromptScope={searchByScope}
            />
          )}
          {!limit && promptManagementStoreData.length > 0 && (
            <div className="pagination-custom">{getPagerArea()}</div>
          )}
          <div
            className={
              promptManagementStoreData?.length > 0
                ? "w-layout-grid ai-collection-grid light-bg"
                : "w-layout-grid ai-collection-grid light-bg center-grid-search"
            }
          >
            {/* {displayedPromptManagement !== null &&
            displayedPromptManagement !== undefined &&
            displayedPromptManagement.length === 0 ? (
              <div>No Prompt(s) Available</div>
            ) : (
              displayedPromptManagement.length > 0 &&
              displayedPromptManagement.map((prompt, index) => ( */}
            {!limit &&
            promptManagementStoreData !== null &&
            promptManagementStoreData !== undefined &&
            promptManagementStoreData.length === 0 ? (
              <div>No Prompt(s) Available</div>
            ) : (
              !limit &&
              promptManagementStoreData.length > 0 &&
              promptManagementStoreData.map((prompt, index) => (
                <PromptCard
                  key={index}
                  onFavouriteClick={onFavouriteClick}
                  prompt={prompt}
                />
              ))
            )}
            {limit &&
            displayedPromptManagement !== null &&
            displayedPromptManagement !== undefined &&
            displayedPromptManagement.length === 0 ? (
              <div>No Prompt(s) Available</div>
            ) : (
              limit &&
              displayedPromptManagement.length > 0 &&
              displayedPromptManagement.map((prompt, index) => (
                <PromptCard
                  key={index}
                  onFavouriteClick={onFavouriteClick}
                  prompt={prompt}
                />
              ))
            )}

            {/* {promptManagementStoreData !== undefined &&
            promptManagementStoreData !== null &&
            promptManagementStoreData?.length === 0 && (
              <div>No item(s) to display </div>
            )} */}
          </div>
        </div>
      </div>
      {!limit && promptManagementStoreData.length > 0 && (
        <div className="pagination-custom">{getPagerArea()}</div>
      )}
    </>
  );
};

export default PromptManagementContent;
