import React, { useEffect, useRef, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getSkillStoreData } from "../../../../skillStore/services/skillStoreService";
import loaderContext from "../../../../../../context/loader/loaderContext";
import { getChatPromptManagementStoreData } from "../../../../chat/services/chatService";
import { getSkillDepartmentData } from "../../../../skillStore/services/skillStoreService";
import ChatPromptCollection from "../../../../chat/views/_partials/chatBox/chatPromptCollection";
import { ExtractCurlParameters } from "../../../../promptManagement/shared/curlConverter";
import { hasGroupAccess } from "../../../../../../services/commonService";
import { UserContext } from "../../../../../../context/user/userContext";
import showMessage from "../../../../../common/message";
import { useSkillContext } from "../../../../../../context/ThemeContext";
import MksChatContext from "../../../../context/mksContext";
import {
  getSkillStoresFromPrompt,
  getSkillStoresParams,
} from "../../../../shared/skillParams";

const ChatData = () => {
  const [isMKSChat, setIsMKSChat] = useState(true);
  const [mksChatMessage, setMKSChatMessage] = useState("");
  const [skillChatMessage, setSkillChatMessage] = useState("");
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [filteredSkills, setFilteredSkills] = useState([]);
  const [originalSkills, setOriginalSkills] = useState([]);
  const [showSkillChatButton, setShowSkillChatButton] = useState(false);
  const skillRef = useRef(null);
  const mksRef = useRef(null);
  const navigate = useNavigate();
  const [selectedSkill, setSelectedSkill] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [isAdditives, setIsAdditives] = useState(false);
  const [isFile, setIsFile] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showPromptManagement, setShowPromptManagement] = useState(false);
  const [tabId, setTabId] = useState(1);
  const [showTip, setShowTip] = useState(false);
  const suggestRef = useRef(null);
  const [promptManagementStoreData, setPromptManagementStoreData] = useState(
    []
  );
  const [
    promptManagementStoreDataOriginal,
    setPromptManagementStoreDataOriginal,
  ] = useState([]);
  const [promptDepartmentData, setPromptDepartmentData] = useState([]);
  const [name, setName] = useState("");
  const [selectedPrompt, setSelectedPrompt] = useState(null);
  const chatRef = useRef(null);
  const toggleButtonRef = useRef(null);
  const { updateLoaderStatus } = useContext(loaderContext);
  const { state } = useContext(UserContext);
  const securityGroups = state?.user?.userProfile?.msalSecurityGroup;
  var context = useContext(MksChatContext);
  const { isMksChatEnable, isSkillChatEnable } = context;

  useEffect(() => {
    const handleClickOutside = (e) => {
      // Check if the click is outside both the menu and toggle button
      if (
        showPromptManagement &&
        chatRef.current &&
        !chatRef.current.contains(e.target) &&
        toggleButtonRef.current &&
        !toggleButtonRef.current.contains(e.target)
      ) {
        setShowPromptManagement(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showPromptManagement]);

  useEffect(() => {
    const handleClickOut = (e) => {
      // Check if the click is outside both the menu and toggle button
      if (
        showSuggestions &&
        suggestRef.current &&
        !suggestRef.current.contains(e.target)
      ) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOut);
    return () => {
      document.removeEventListener("mousedown", handleClickOut);
    };
  }, [showSuggestions]);

  useEffect(() => {
    getAllSkills();
  }, []);

  // useEffect(() => {
  //   handleSkillChatButtonClick();
  // }, [isMksChatEnable]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (
        showSuggestions &&
        filteredSkills !== undefined &&
        filteredSkills?.length > 0
      ) {
        if (e.key === "ArrowDown") {
          e.preventDefault();
          setSelectedIndex(
            (prevIndex) => (prevIndex + 1) % filteredSkills.length
          );
        } else if (e.key === "ArrowUp") {
          e.preventDefault();
          setSelectedIndex(
            (prevIndex) =>
              (prevIndex - 1 + filteredSkills.length) % filteredSkills.length
          );
        } else if (showSuggestions && e.key === "Enter" && selectedIndex >= 0) {
          e.preventDefault();
          const selectedSkill = filteredSkills[selectedIndex];
          const access = hasGroupAccess(
            securityGroups,
            selectedSkill.SecurityGroupId
          );
          if (!access) {
            setShowTip(true);
            showMessage(
              "You don't have access to " + selectedSkill.Name + " bot.",
              -2
            );
          } else {
            setSelectedSkill(selectedSkill);
            setSkillChatMessage("");
            setShowSkillChatButton(true);
            setShowSuggestions(false);
            setShowTip(false);
            setSelectedIndex(-1);
            setIsAdditives(
              selectedSkill?.ChatbotConfiguration?.ChatbotUIView?.toLowerCase() ===
                "additives"
                ? true
                : false
            );
            setIsFile(
              selectedSkill?.ChatbotConfiguration?.InputType?.toLowerCase() ===
                "file"
                ? true
                : false
            );
          }
        } else if (e.key === "Escape") {
          setShowSuggestions(false);
          setSelectedIndex(-1);
        }
      } else if (!showSuggestions && selectedSkill !== null && !isMKSChat) {
        if (e.key === "Enter" && selectedIndex >= 0) {
          e.preventDefault();
          setShowSkillChatButton(true);
          setShowSuggestions(false);
          setSelectedIndex(-1);
          setIsAdditives(
            selectedSkill?.ChatbotConfiguration?.ChatbotUIView?.toLowerCase() ===
              "additives"
              ? true
              : false
          );
          setIsFile(
            selectedSkill?.ChatbotConfiguration?.InputType?.toLowerCase() ===
              "file"
              ? true
              : false
          );
          handleSkillChatButtonClick();
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [selectedIndex, filteredSkills, showSuggestions, skillChatMessage]);

  useEffect(() => {
    if (isMKSChat && isMksChatEnable) {
      setMKSChatMessage(name);
    } else {
      setSkillChatMessage(name);
    }
    if (isMksChatEnable === false) {
      setIsMKSChat(false);
    }
  }, [name]);

  const setAdditivies = (skill) => {
    setIsAdditives(
      skill?.ChatbotConfiguration?.ChatbotUIView?.toLowerCase() === "additives"
        ? true
        : false
    );
    setIsFile(
      skill?.ChatbotConfiguration?.InputType?.toLowerCase() === "file"
        ? true
        : false
    );
  };

  const getAllSkills = async () => {
    const res = await getSkillStoreData();
    setOriginalSkills(res);
  };

  const onSkillMessageChange = (e) => {
    const text = e.target.value;
    const query = text.startsWith("@") ? text : "";
    if (query === "@") {
      setShowSuggestions(true);
      setFilteredSkills(originalSkills);
    } else if (query.includes("@ ") || query === "") {
      setShowSuggestions(false);
      setFilteredSkills([]);
    } else {
      const searchQuery = query.split("@").pop().trim();
      if (originalSkills !== undefined)
        if (searchQuery) {
          setShowSuggestions(true);
          setFilteredSkills([
            ...new Set(
              originalSkills?.filter((skill) =>
                skill.Name.toLowerCase().includes(searchQuery.toLowerCase())
              )
            ),
          ]);
        } else {
          setShowSuggestions(false);
        }
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (files.length > 0) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        console.log("File content:", data);
      };
      reader.readAsText(file);
    }
  };

  const handleNameChange = (e) => {
    setSkillChatMessage(e.target.value);
    setShowTip(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      if (selectedSkill !== null && event?.target?.value?.trim() !== "") {
        handleSkillChatButtonClick();
      }
    }
    if (event.key === "Backspace") {
      setSkillChatMessage("");
      setShowSuggestions(false);
    }
  };

  const handleKeyDownMksChat = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      if (event?.target?.value !== "") {
        handleMKSChatButtonClick();
      }
    }
  };

  const handleMKSChatButtonClick = () => {
    navigate("/chat", {
      state: {
        isMKSChat,
        chatMessage: mksChatMessage,
        navigateFromHome: false,
        isAdditivesFound: isAdditives,
        isFileType: isFile,
      },
    });
  };

  const handleSkillChatButtonClick = () => {
    navigate("/chat", {
      state: {
        isMKSChat: false,
        chatMessage: skillChatMessage,
        skill: selectedSkill,
        navigateFromHome: true,
        isAdditivesFound: isAdditives,
        isFileType: isFile,
      },
    });
  };

  const setTabAndData = (tabId) => {
    if (tabId === 1) {
      setPromptManagementStoreData(promptManagementStoreDataOriginal);
    } else if (tabId === 2) {
      const filteredRecords = promptManagementStoreDataOriginal?.filter(
        (record) => record.IsMyPrompt
      );
      setPromptManagementStoreData(filteredRecords);
    } else if (tabId === 3) {
      const filteredRecords = promptManagementStoreDataOriginal?.filter(
        (record) => record.IsSharedPrompt
      );
      setPromptManagementStoreData(filteredRecords);
    } else if (tabId === 4) {
      const filteredRecords = promptManagementStoreDataOriginal?.filter(
        (record) => record.IsStared
      );
      setPromptManagementStoreData(filteredRecords);
    }
    setTabId(tabId);
  };

  const handleTogglePromptManagement = () => {
    if (!showPromptManagement) {
      setMKSChatMessage("");
      setSkillChatMessage("");
      const fetchData = async () => {
        setIsLoading(true);
        updateLoaderStatus(true);
        try {
          setTabId(1);
          const skillStoreId =
            selectedSkill?.Id === 0 || selectedSkill?.Id === undefined
              ? 0
              : selectedSkill?.Id;
          const promptData = await getChatPromptManagementStoreData(
            skillStoreId
          );

          let filteredPromptData = null;
          if (skillStoreId || !isMKSChat) {
            filteredPromptData = promptData.filter(
              (item) => !item?.ChatbotConfiguration?.IsDefault
            );
          } else {
            filteredPromptData = promptData;
          }
          setPromptManagementStoreData(filteredPromptData);
          setPromptManagementStoreDataOriginal(filteredPromptData);

          const departmentData = await getSkillDepartmentData();
          setPromptDepartmentData(departmentData);
        } catch (error) {
          console.error("Failed to fetch data:", error);
        } finally {
          setIsLoading(false);
          updateLoaderStatus(false);
        }
      };
      fetchData();
    } else {
      setPromptManagementStoreData([]);
      setPromptManagementStoreDataOriginal([]);
    }

    setShowPromptManagement(!showPromptManagement);
  };

  const handleMouseEnter = () => {
    if (
      (skillChatMessage === "" ||
        skillChatMessage === null ||
        skillChatMessage === undefined) &&
      !showSkillChatButton
    ) {
      setShowTip(true);
    }
  };

  const handleMouseLeave = () => {
    setShowTip(false);
  };
  const showtipToggle = (e) => {
    e.stopPropagation();
    setShowTip(false);
  };

  const handleSetSelectedPrompt = (prompt) => {
    if (!isMKSChat) {
      setSelectedPrompt(prompt);
      if (originalSkills !== undefined) {
        const index = originalSkills.findIndex(
          (item) => item.Id === prompt.SkillStoreId
        );
        setSelectedIndex(index);
        if (index !== -1) {
          const defaultSkill = originalSkills.filter(
            (item) => item.Id === prompt.SkillStoreId
          );
          setSelectedSkill(defaultSkill[0]);
          setShowSkillChatButton(true);
        } else {
          setSelectedIndex(-1);
          setShowSkillChatButton(true);
          setSelectedSkill(getSkillStoresFromPrompt(prompt));
        }
      }
    }
    if (isMKSChat) {
      setMKSChatMessage(prompt?.PromptSystemGeneratedInputs[0]?.PromptText);
      if (mksRef.current) {
        mksRef.current.focus();
      }
    } else {
      setSkillChatMessage(prompt?.PromptSystemGeneratedInputs[0]?.PromptText);
    }
  };

  // const getSkillData = (prompt) => {
  //   const params = ExtractCurlParameters(
  //     prompt?.ChatbotConfiguration?.CurlRequestString
  //   );

  //   const skill = {
  //     Id: prompt.SkillStoreId,
  //     Name: prompt.SkillStoreName,
  //     ChatbotConfiguration: prompt.ChatbotConfiguration,
  //     aiUrl: params.url,
  //     headers: params.headers,
  //     headersV2: params.headersV2,
  //     method: params.method,
  //     aiFlag: "",
  //     apiKey: "",
  //     messageBody: params.body,
  //     ShortDescription: prompt.SkillStoreShortDescription,
  //     userMessage: prompt.ChatbotConfiguration.UserMessageFormat,
  //     AssistantMessageFormat:
  //       prompt.ChatbotConfiguration.AssistantMessageFormat,
  //     ShowCitation: prompt?.ChatbotConfiguration?.ShowCitation,
  //   };
  //   return skill;
  // };

  const handleSuggestionsClick = (skill, index) => {
    const access = hasGroupAccess(securityGroups, skill.SecurityGroupId);
    if (!access) {
      showMessage("You don't have access to " + skill.Name + " bot.", -2);
    } else {
      setSkillChatMessage("");
      setSelectedSkill(skill);
      setShowSuggestions(false);
      setShowSkillChatButton(true);
      setAdditivies(skill);
      setSelectedIndex(index);
    }
  };

  const handleMKSChatClick = () => {
    if (!isMKSChat) {
      setIsMKSChat(true);
      setMKSChatMessage("");
      setSkillChatMessage("");
      setShowSkillChatButton(false);
      setShowSuggestions(false);
      setShowPromptManagement(false);
      setSelectedSkill(null);
      setSelectedIndex(-1);
      setSelectedPrompt(null);
    }
  };

  const handleSkillChatClick = () => {
    if (isMKSChat) {
      setIsMKSChat(false);
      setMKSChatMessage("");
      setSkillChatMessage("");
      setShowSkillChatButton(false);
      setShowSuggestions(false);
      setShowPromptManagement(false);
      setSelectedSkill(null);
      setSelectedIndex(-1);
      setSelectedPrompt(null);
    }
  };

  return (
    <div className="dash-section">
      {/* <div className="chat-form-wrapper w-form">
        <div className="chat-form">
          <div className="chat-tabs w-tabs">
            {isMksChatEnable && isSkillChatEnable && (
              <div className="chat-tab-menu w-tab-menu" role="tablist">
                {isMksChatEnable && (
                  <div
                    className={`chat-tablink w-inline-block w-tab-link ${
                      isMKSChat && isMksChatEnable ? "w--current" : ""
                    }`}
                    onClick={() => {
                      handleMKSChatClick();
                    }}
                    role="tab"
                    aria-selected={!isMKSChat}
                  >
                    <div>MKS Chat</div>
                  </div>
                )}
                <div
                  onClick={() => {
                    handleSkillChatClick();
                  }}
                  className={`chat-tablink w-inline-block w-tab-link ${
                    !isMKSChat || !isMksChatEnable ? "w--current" : ""
                  }`}
                  aria-selected={isMKSChat}
                >
                  <div>Skills Chat</div>
                </div>
              </div>
            )}

            {(isSkillChatEnable || isMksChatEnable) && (
              <div className="chat-tabs-content w-tab-content">
                {isMKSChat && isMksChatEnable ? (
                  <div className="chat-tab-pane w-tab-pane w--tab-active">
                    <div className="chat-field-wrapper">
                      <label
                        className="chatprompt-field-label"
                        onClick={handleTogglePromptManagement}
                        ref={toggleButtonRef}
                      >
                        {isLoading ? (
                          <div className="btn-spinner">
                            <span className="loadspinner"></span> Loading...
                          </div>
                        ) : (
                          <div className="prompt-icon">
                            <span>Prompt</span>
                          </div>
                        )}
                      </label>
                      <textarea
                        className="chat-input-field w-input home-chat-field scrollinput"
                        onInput={(e) => setMKSChatMessage(e.target.value)}
                        maxLength="6000"
                        placeholder="Start a conversation with MKS.ai Skills Chat to access expert insights on specialized domains and functional areas."
                        type="text"
                        onKeyDown={handleKeyDownMksChat}
                        value={mksChatMessage}
                        ref={mksRef}
                      />

                      <input
                        disabled={mksChatMessage === ""}
                        type="submit"
                        className={`chat-button w-button ${
                          mksChatMessage !== "" ? "" : "chat-button-visibility"
                        }`}
                        onClick={handleMKSChatButtonClick}
                        value="Chat"
                      />
                    </div>
                  </div>
                ) : (
                  <div
                    className="chat-tab-pane w-tab-pane w--tab-active"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <div className="chat-field-wrapper">
                      <label
                        className="chatprompt-field-label"
                        onClick={handleTogglePromptManagement}
                        ref={toggleButtonRef}
                      >
                        {isLoading ? (
                          <div className="btn-spinner">
                            <span className="loadspinner"></span> Loading...
                          </div>
                        ) : (
                          <div className="prompt-icon">
                            <span>Prompt</span>
                          </div>
                        )}
                      </label>
                      <textarea
                        className="chat-input-field w-input home-chat-field scrollinput"
                        maxLength="6000"
                        placeholder={
                          selectedSkill?.Name
                            ? `Welcome to ${selectedSkill?.Name}`
                            : "Start a conversation with MKS.ai Skills Chat to access expert insights on specialized domains and functional areas."
                        }
                        onChange={handleNameChange}
                        onDrop={handleDrop}
                        onDragOver={handleDragOver}
                        onInput={onSkillMessageChange}
                        ref={skillRef}
                        onKeyDown={handleKeyDown}
                        value={skillChatMessage}
                        type="text"
                      />
                      {showTip && !isMKSChat && (
                        <div className="skill-info-wrapper top-position">
                          <div className="skill-mention">
                            <div className="skill-popup-heading">
                              Skill mentions
                            </div>
                            <div
                              className="close-icon"
                              onClick={showtipToggle}
                            ></div>
                          </div>
                          <div className="skill-desc-popup">
                            Type @ to mention a skill and add it directly to the
                            conversation
                          </div>
                          <div className="chat-down-triangle"></div>
                        </div>
                      )}
                      <div ref={suggestRef}>
                        {showSuggestions &&
                          filteredSkills !== undefined &&
                          filteredSkills?.length > 0 && (
                            <div className="suggestions mks-suggestion">
                              <div className="suggestion-item-heading">
                                Suggestions
                              </div>
                              {filteredSkills?.map((skill, index) => (
                                <div
                                  key={index}
                                  className={`suggestion-item ${
                                    index === selectedIndex ? "selected" : ""
                                  }`}
                                  onClick={() =>
                                    handleSuggestionsClick(skill, index)
                                  }
                                >
                                  {skill.Name}
                                </div>
                              ))}
                            </div>
                          )}
                      </div>
                      <input
                        type="submit"
                        disabled={!showSkillChatButton}
                        className={`chat-button w-button ${
                          showSkillChatButton ? "" : "chat-button-visibility"
                        }`}
                        onClick={handleSkillChatButtonClick}
                        value="Chat"
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div> */}
      {(isMksChatEnable || isSkillChatEnable) && (
        <>
          <p className="paragraph home mb0">
            As an MKS professional, it is essential to use MKS.ai responsibly
            and in alignment with ethical principles. Always ensure compliance
            with{" "}
            <strong>
              MKS's ethical AI standards, Microsoft’s Acceptable Use Policy, the
              Microsoft Code of Conduct for OpenAI Services,
            </strong>{" "}
            and the <strong>MKS.ai Guidelines.</strong> Before using{" "}
            <strong>MKS.ai,</strong> confirm that you have the necessary rights
            and permissions for the data and carefully validate all outputs for
            accuracy and reliability. All usage must follow functional
            requirements outlined in the <strong>MKS.ai Guidelines.</strong>{" "}
            Additionally, any work product derived from MKS.ai outputs must be
            reviewed for accuracy, consistency, and validity, adhering to all
            relevant country-specific and department-specific quality processes.
          </p>
          {/* <p className="paragraph">
            The privacy notice is available <Link to="#">here</Link>
          </p> */}
        </>
      )}

      <div ref={chatRef}>
        <div className="upperchartbox-home">
          {showPromptManagement && (
            <>
              <div className="upperchartbox-wide">
                <span
                  className="down-arrow prompt-down-arrow"
                  onClick={handleTogglePromptManagement}
                ></span>

                <div className="prompt-tabs w-tabs">
                  <div
                    className="tabs-menu h-flex head-wrapper w-tab-menu"
                    role="tablist"
                  >
                    <div
                      className={`prompt-tabs-link w-inline-block w-tab-link ${
                        tabId === 1 ? "w--current" : ""
                      } `}
                      aria-selected="true"
                      onClick={() => setTabAndData(1)}
                    >
                      <div>Prompts Collection</div>
                    </div>
                    <div
                      className={`prompt-tabs-link w-inline-block w-tab-link ${
                        tabId === 2 ? "w--current" : ""
                      } `}
                      aria-selected="false"
                      onClick={() => setTabAndData(2)}
                    >
                      <div>My Prompts</div>
                    </div>
                    <div
                      className={`prompt-tabs-link w-inline-block w-tab-link ${
                        tabId === 3 ? "w--current" : ""
                      } `}
                      aria-selected="false"
                      onClick={() => setTabAndData(3)}
                    >
                      <div>Shared</div>
                    </div>
                    <div
                      className={`prompt-tabs-link w-inline-block w-tab-link ${
                        tabId === 4 ? "w--current" : ""
                      } `}
                      aria-selected="false"
                      onClick={() => setTabAndData(4)}
                    >
                      <div>Favourites</div>
                    </div>
                  </div>
                </div>
                {tabId === 1 && (
                  <ChatPromptCollection
                    promptCollectionData={promptManagementStoreData}
                    promptDepartmentData={promptDepartmentData}
                    showPromptManagement={showPromptManagement}
                    setShowPromptManagement={setShowPromptManagement}
                    handleSetSelectedPrompt={handleSetSelectedPrompt}
                    setName={setName}
                    isMKSChat={isMKSChat}
                  />
                )}
                {tabId === 2 && (
                  <ChatPromptCollection
                    promptCollectionData={promptManagementStoreData}
                    promptDepartmentData={promptDepartmentData}
                    showPromptManagement={showPromptManagement}
                    setShowPromptManagement={setShowPromptManagement}
                    handleSetSelectedPrompt={handleSetSelectedPrompt}
                    setName={setName}
                    isMKSChat={isMKSChat}
                  />
                )}
                {tabId === 3 && (
                  <ChatPromptCollection
                    promptCollectionData={promptManagementStoreData}
                    promptDepartmentData={promptDepartmentData}
                    showPromptManagement={showPromptManagement}
                    setShowPromptManagement={setShowPromptManagement}
                    handleSetSelectedPrompt={handleSetSelectedPrompt}
                    setName={setName}
                    isMKSChat={isMKSChat}
                  />
                )}
                {tabId === 4 && (
                  <ChatPromptCollection
                    promptCollectionData={promptManagementStoreData}
                    promptDepartmentData={promptDepartmentData}
                    showPromptManagement={showPromptManagement}
                    setShowPromptManagement={setShowPromptManagement}
                    handleSetSelectedPrompt={handleSetSelectedPrompt}
                    setName={setName}
                    isMKSChat={isMKSChat}
                  />
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatData;
