import { getAccessToken, setAccessToken } from "./localStorageService";
import { jwtDecode } from "jwt-decode";

const invalidToken = "Invalid Token";

export const isStorageTokenInvalidOrEmpty = () => {
    let token = getStorageToken();
    if (token === invalidToken) {
      return true;
    }
    if (isGivenTokenEmpty(token)) {
      return true;
    }
    return false;
  };
  
  export const isGivenTokenInvalid = (token) => {
    if (token === invalidToken) {
      return true;
    }
    return false;
  };
  
  export const isGivenTokenEmpty = (token) => {
    if (
      token === undefined ||
      token === null ||
      token === "" ||
      token === "null"
    ) {
      return true;
    }
    return false;
  };

   
  export const isGivenTokenExpired = (token) => {
    if (isGivenTokenEmpty(token) === false) {
      return calc(token, 0);
    }
    return undefined;
  };
  
  export const isGivenTokenExpiring = (token) => {
    if (isGivenTokenEmpty(token) === false) {
      return calc(token, 5);
    }
  
    return undefined;
  };
  
  const calc = (token, diffMins) => {
    const decode = jwtDecode(token);
    const current = Date.now() / 1000;
    const tokenExpiry = decode.exp;
  
    const secs = 60 * diffMins;
  
    if (tokenExpiry - current <= secs) {
      return true;
    }
  
    return false;
  };

  export const getStorageToken = () => {
    return getAccessToken();
  };
  
  export const setStorageToken = (token) => {
    setAccessToken(token);
  };