import React, { useEffect, useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import loaderContext from "../../../context/loader/loaderContext";
import { UserContext } from "../../../context/user/userContext";
import { setStorageToken } from "../../../services/tokenService";
import { UserCorpLogin, UserLogin } from "../../../services/authService";
import { getPageTitle } from "../../../services/commonService";
import { getAccessToken } from "../../../services/localStorageService";
import { useMsal } from "@azure/msal-react";
import loginvideo from "../../../styles/video/MKS-Instruments-Company-Video.mp4";
import loginlogo from "../../../styles/images/mks_logo-blue.png";

const Login = () => {
  const { instance, accounts } = useMsal();
  const location = useLocation();
  const { updateLoaderStatus } = useContext(loaderContext);
  const [formData, setFormData] = useState({ userName: "", password: "" });
  const [loginResp, setLoginResp] = useState(null);
  const [errors, setErrors] = useState({
    userName: false,
    password: false,
    credentials: "",
  });
  const { replaceUrl } = useContext(UserContext);
  let shouldReplaceUrl = false;

  useEffect(() => {
    updateLoaderStatus(true, "login");
    const token = getAccessToken();
    if (token !== null && token !== undefined) {
      CheckToken(token);
      if (shouldReplaceUrl) replaceUrl();
    }
    if (process.env.REACT_APP_MSAL_REDIRECT_URL !== document.referrer) {
      updateLoaderStatus(false);
    }
  }, []);

  useEffect(() => {
    updateLoaderStatus(true, "login");
    if (accounts.length > 0) {
      const accesstoken = getMSALAccessToken();
      Login(true, accesstoken);
    }
    // updateLoaderStatus(false, "login");
  }, [accounts]);
  useEffect(() => {
    updateLoaderStatus(true, "login");
    // Add this line to set the page title
    document.title = getPageTitle(location.pathname);
  }, [location.pathname]);

  const getMSALAccessToken = () => {
    const token = sessionStorage.getItem(process.env.REACT_APP_MSAL_TOKEN_KEY);
    if (token !== null && token !== undefined) {
      return JSON.parse(
        sessionStorage.getItem(
          JSON.parse(
            sessionStorage.getItem(process.env.REACT_APP_MSAL_TOKEN_KEY)
          ).accessToken[0]
        )
      ).secret;
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    setErrors((prevState) => ({
      ...prevState,
      [name]: false,
      credentials: "",
    }));
  };

  const handleEntraIdLogin = async () => {
    try {
      updateLoaderStatus(true, "login");
      instance
        .loginRedirect({
          scopes: ["User.Read"],
        })
        .then((loginResponse) => {
          setLoginResp(loginResponse);
          console.log("Login Successful!", loginResponse);
          updateLoaderStatus(false);
        })
        .catch((error) => {
          console.error("Login Failed", error);
          updateLoaderStatus(false);
        });
    } catch (error) {
      updateLoaderStatus(false);
      console.error(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    Login(false, null);
  };

  const Login = async (isCorp, token) => {
    try {
      updateLoaderStatus(true, "login");
      let response;
      if (isCorp) {
        response = await UserCorpLogin(token);
      } else {
        const { userName, password } = formData;
        const newErrors = {
          userName: !userName,
          password: !password,
          credentials: "",
        };

        if (!userName || !password) {
          setErrors(newErrors);
          return;
        }

        const requestBody = {
          userName: formData.userName,
          Password: formData.password,
        };

        response = await UserLogin(requestBody);
      }

      if (response.status === 200) {
        const token = response.data;
        CheckToken(token);
      } else {
        setErrors((prevState) => ({
          ...prevState,
          credentials: "Incorrect credentials",
        }));
        console.error("Error:", response.statusText);
      }
    } catch (err) {
      if (err.response && err.response.status === 400) {
        if (err.response.data && err.response.data["Status"]) {
          setErrors((prevState) => ({
            ...prevState,
            credentials: "Incorrect credentials",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            credentials: "Something went wrong. Please contact to site owner",
          }));
        }
      }
      console.error("Error:", err);
    } finally {
      updateLoaderStatus(false);
    }

    if (shouldReplaceUrl) replaceUrl();
  };

  const CheckToken = (token) => {
    shouldReplaceUrl = true;
    setStorageToken(token);
  };

  return (
    <>
      <div className="flexbox">
        <div className="workspace-dashboard flex-end">
          <div className="login-form-logo"> 
          <div
                data-autoplay="true"
                data-loop="true"
                data-wf-ignore="true"
                className="login-bg-video w-background-video w-background-video-atom"
              >
                <video
                  id="de590cc2-71f7-9a6b-9b04-56517b44e297-video"
                  autoPlay
                  loop
                  style={{
                    backgroundImage: "url({bgVideoImage})",
                  }}
                  muted
                  playsInline
                  data-wf-ignore="true"
                  data-object-fit="cover"
                >
                  <source src={loginvideo} data-wf-ignore="true" />
                </video>
              </div>
           
          </div>
          <div className="login-form-wrapper">
          <img src={loginlogo} className="login-logo-img" loading="lazy" width="180" alt="" />
            <h1>Sign-in to your account</h1>
            <div className="form-wrapper">
              {/* <form
                name="email-form"
                aria-label="Email Form"
                onSubmit={handleSubmit}
              >
                <div className="text-left">
                  <label htmlFor="userName" className="field-label">
                    Username
                  </label>
                  <input
                    className={`input-field w-input ${
                      errors.userName ? "input-error" : ""
                    }`}
                    maxLength={256}
                    name="userName"
                    type="text"
                    onChange={handleChange}
                  />
                </div>
                <div className="text-left">
                  <label htmlFor="password" className="field-label">
                    Password
                  </label>
                  <input
                    className={`input-field w-input ${
                      errors.password ? "input-error" : ""
                    }`}
                    maxLength={256}
                    name="password"
                    type="password"
                    onChange={handleChange}
                  />
                </div>
                <input
                  type="submit"
                  className="submit-button w-button full-width"
                  value="Submit"
                />
                {errors.credentials && (
                  <div className="error-message">{errors.credentials}</div>
                )}
              </form> */}
              {/* <div className="autl-logo-link">
                <button onClick={handleEntraIdLogin}>
                  <div className="login-small-text">Sign in with</div>
                  <div className="auth-logo-icon"></div>
                </button>
              </div> */}
              <div className="auth-logo-link">
                {/* <div className="text-label">Using</div> */}
                <button onClick={handleEntraIdLogin} className="azure-btn">
                  <div className="login-small-text">SSO</div>
                  <div className="right-arrow"></div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
