import React, { useState, useEffect, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import PromptManagementFilterSection from "../../../../../promptManagement/views/_partials/promptManagementFilterSection";
import PromptTable from "../promptTable";
import { getSkillFunctionData } from "../../../../../promptManagement/services/promptManagementService";

const ChatPromptCollection = (props) => {
  const {
    promptCollectionData,
    promptDepartmentData,
    showPromptManagement,
    setShowPromptManagement,
    handleSetSelectedPrompt,
    setName,
    isMKSChat,
  } = props;
  const toggleButtonRef = useRef(null);
  const prompRef = useRef(null);

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedFunction, setSelectedFunction] = useState([]);
  const [promptFunctionData, setPromptFunctionData] = useState([]);
  const [sortByAscend, setSortByAscend] = useState(false);
  const [showPromptPopup, setShowPromptPopup] = useState(false);

  const [promptManagementStoreData, setPromptManagementStoreData] = useState(
    []
  );
  const [
    originalPromptManagementStoreData,
    setOriginalPromptManagementStoreData,
  ] = useState([]);

  useEffect(() => {
    setPromptManagementStoreData(promptCollectionData);
    setOriginalPromptManagementStoreData(promptCollectionData);
  }, [promptCollectionData, promptDepartmentData]);

  useEffect(() => {
    const handleClickOutside = (e) => {
      // Check if the click is outside both the menu and toggle button
      if (
        showPromptPopup &&
        prompRef.current &&
        !prompRef.current.contains(e.target) &&
        toggleButtonRef.current &&
        !toggleButtonRef.current.contains(e.target)
      ) {
        setShowPromptPopup(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showPromptPopup]);

  useEffect(() => {
    const filterPromptManagement = () => {
      let filteredPromptManagement = originalPromptManagementStoreData;
      filteredPromptManagement = filteredPromptManagement?.sort(
        (a, b) => b.Id - a.Id
      );

      if (searchTerm) {
        const lowercasedVal = searchTerm.toLowerCase();
        filteredPromptManagement = filteredPromptManagement?.filter((prompt) =>
          prompt.Title.toLowerCase().includes(lowercasedVal)
        );
      }

      if (selectedDepartment) {
        const globalVal = "global";
        const department = promptDepartmentData.find(
          (dep) => dep.Id === parseInt(selectedDepartment)
        );
        if (department) {
          const departmentName = department.Name;
          filteredPromptManagement = filteredPromptManagement?.filter(
            (skill) =>
              skill?.DepartmentName?.toLowerCase() ===
                departmentName?.toLowerCase() ||
              skill.DepartmentName?.toLowerCase() === globalVal
          );
        }
      }

      if (selectedFunction.length > 0) {
        filteredPromptManagement = filteredPromptManagement?.filter((skill) =>
          skill.functions.some((func) => selectedFunction.includes(func.Id))
        );
      }
      if (sortByAscend) {
        filteredPromptManagement?.sort(
          (a, b) => new Date(a.CreatedOn) - new Date(b.CreatedOn)
        );
      }

      if (!sortByAscend) {
        filteredPromptManagement?.sort(
          (a, b) => new Date(b.CreatedOn) - new Date(a.CreatedOn)
        );
      }
      if (filteredPromptManagement !== undefined)
        setPromptManagementStoreData([...filteredPromptManagement]);
    };

    filterPromptManagement();
  }, [
    searchTerm,
    selectedDepartment,
    selectedFunction,
    sortByAscend,
    promptDepartmentData,
    originalPromptManagementStoreData,
  ]);

  const searchByInputVal = (val) => {
    setSearchTerm(val);
  };

  const handleSearchByDepartment = async (selectedVal) => {
    setSelectedDepartment(selectedVal);
    try {
      const functionData = await getSkillFunctionData(
        selectedVal === "" ? 0 : selectedVal
      );
      setSelectedFunction([]);

      const updatedData = functionData.filter(
        (item) => item?.Name?.toLowerCase() !== "all"
      );
      setPromptFunctionData(updatedData);
    } catch (error) {
      console.error("Failed to fetch function data:", error);
    }
  };

  const handleSearchByFunction = async (selectedVal) => {
    setSelectedFunction(selectedVal);
  };

  const getSortOrder = (val) => {
    if (val === "Newly Added") {
      setSortByAscend(false);
    } else if (val === "Old One") {
      setSortByAscend(true);
    }
  };
  const handlePromptToggle = () => {
    setShowPromptPopup((prevShowPromptPopup) => !prevShowPromptPopup);
  };

  return (
    <>
      <div className="w-tab-content chatpromp">
        {showPromptManagement && (
          <PromptManagementFilterSection
            onInputSearchPromptManagement={searchByInputVal}
            searchDepartmentData={promptDepartmentData}
            onSearchByPromptDepartment={handleSearchByDepartment}
            searchFunctionData={promptFunctionData}
            onSearchByPromptFunction={handleSearchByFunction}
          />
        )}
        {showPromptManagement && (
          <PromptTable
            displayedPromptManagement={promptManagementStoreData}
            setName={setName}
            setShowPromptManagement={setShowPromptManagement}
            handleSetSelectedPrompt={handleSetSelectedPrompt}
            isMKSChat={isMKSChat}
          />
        )}
      </div>
      <div className="chat-bottom-wrap">
        <div className="chat-sort">
          <span>Sort Order:</span>
          <select
            className="chat-order-select"
            onChange={(e) => getSortOrder(e.target.value)}
          >
            <option value="Newly Added">Newly Added</option>
            <option value="Old One">Old One</option>
          </select>
        </div>
        <div className="buttonpopup">
          <div
            className="button  w-button flex"
            ref={toggleButtonRef}
            onClick={handlePromptToggle}
          >
            Design Your Prompt
            <div className="down-arrow-white"></div>
          </div>
          <div ref={prompRef}>
            {showPromptPopup && (
              <>
                <div className="prompt-popup-menu">
                  <div className="popup-row">
                    <div className="add-prompt-text"></div>
                    <Link to="/addprompt">Add prompt</Link>
                  </div>
                  <div className="popup-row">
                    <div className="logout-icon"></div>
                    <Link to="/importprompt">Import Prompt</Link>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatPromptCollection;
