import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Skills from "../../../../skillStore/views/_partials/skills";
import { getPromptManagementStoreData } from "../../../../promptManagement/services/promptManagementService";
import PromptManagementContent from "../../../../promptManagement/views/_partials/promptManagementContent";

const SkillsAndPrompt = () => {
  const CardLimit = parseInt(process.env.REACT_APP_CARD_LIMIT);
  const [promptManagementStoreData, setPromptManagementStoreData] = useState(
    []
  );
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      try {
        const data = await getPromptManagementStoreData();
        setPromptManagementStoreData(data);
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };
    getData();
  }, []);

  return (
    <>
      <div className="dash-section p20">
        <div className="w-layout-hflex h-flex head-wrapper">
          <h2 className="h2">Skills</h2>

          <Link to="/skillstore" className="button text w-button">
            View All
          </Link>
        </div>
        <Skills IsThirdPartyAITool={false} limit={CardLimit} />
      </div>

      <div className="dash-section p20">
        <div className="w-layout-hflex h-flex head-wrapper">
          <h2 className="h2">Prompts</h2>
          <Link to="/promptmanagement" className="button text w-button">
            View All
          </Link>
        </div>
        {isLoading ? (
          <div className="btn-spinner">
            <span className="loadspinner"></span> Loading...
          </div>
        ) : (
          <PromptManagementContent
            limit={CardLimit}
            promptManagementStores={promptManagementStoreData}
            onFavouriteClick={null}
            onHandleLikeClick={null}
            onHandleCommentClick={null}
          />
        )}
      </div>
    </>
  );
};

export default SkillsAndPrompt;
