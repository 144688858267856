import React, { useState, useEffect, useContext, useRef } from "react";
import TabLink from "../tabLink";
import { Link } from "react-router-dom";
import PromptManagementContent from "../promptManagementContent";
import loaderContext from "../../../../../../context/loader/loaderContext";
import {
  getPromptManagementStoreData,
  getSkillDepartmentData,
} from "../../../services/promptManagementService";

const PromptManagementDashboard = () => {
  const [showTab, setShowTab] = useState(1);
  const [promptManagementStoreData, setPromptManagementStoreData] = useState(
    []
  );
  const [
    promptManagementStoreDataOriginal,
    setPromptManagementStoreDataOriginal,
  ] = useState([]);
  const [departmentData, setDepartmentData] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const { updateLoaderStatus } = useContext(loaderContext);
  const [showPromptPopup, setShowPromptPopup] = useState(false);
  const prompRef = useRef(null);
  const toggleButtonRef = useRef(null);

  const handlePromptToggle = () => {
    setShowPromptPopup((prevShowPromptPopup) => !prevShowPromptPopup);
  };

  useEffect(() => {
    const handleClickOutside = (e) => {
      // Check if the click is outside both the menu and toggle button
      if (
        showPromptPopup &&
        prompRef.current &&
        !prompRef.current.contains(e.target) &&
        toggleButtonRef.current &&
        !toggleButtonRef.current.contains(e.target)
      ) {
        setShowPromptPopup(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showPromptPopup]);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      updateLoaderStatus(true);
      const data = await getPromptManagementStoreData();
      const sortedData = data?.sort((a, b) => b.Id - a.Id);
      setPromptManagementStoreData(sortedData);
      setPromptManagementStoreDataOriginal(sortedData);
      updateLoaderStatus(false);
      setIsLoading(false);
    };
    const getSkillDepData = async () => {
      const data = await getSkillDepartmentData();
      setDepartmentData(data);
    };
    getData();
    getSkillDepData();
  }, []);

  const handleFavoriteClick = (val, cardId) => {
    const updatedOriginalData = promptManagementStoreDataOriginal.map(
      (item) => {
        if (item.Id === cardId) {
          return { ...item, IsStared: !val };
        }
        return item;
      }
    );

    setPromptManagementStoreDataOriginal([...updatedOriginalData]);
  };

  const onHandleLikeClick = (cardId, likeCount, val) => {
    const updatedOriginalData = promptManagementStoreDataOriginal.map(
      (item) => {
        if (item.Id === cardId) {
          return {
            ...item,
            LikeCount: likeCount,
            IsLiked: val,
          };
        }
        return item;
      }
    );

    setPromptManagementStoreDataOriginal([...updatedOriginalData]);
  };

  const onHandleCommentClick = (
    cardId,
    commentCount,
    allComments,
    isCommented
  ) => {
    const updatedOriginalData = promptManagementStoreDataOriginal.map(
      (item) => {
        if (item.Id === cardId) {
          return {
            ...item,
            CommentCount: commentCount,
            comments: allComments,
            IsCommented: isCommented,
          };
        }
        return item;
      }
    );

    setPromptManagementStoreDataOriginal([...updatedOriginalData]);
  };

  useEffect(() => {
    const setTabData = () => {
      if (showTab === 1) {
        setPromptManagementStoreData(promptManagementStoreDataOriginal);
      } else if (showTab === 2) {
        const filteredRecords = promptManagementStoreDataOriginal?.filter(
          (record) => record?.IsMyPrompt
        );
        setPromptManagementStoreData(filteredRecords);
      } else if (showTab === 3) {
        const filteredRecords = promptManagementStoreDataOriginal?.filter(
          (record) => record?.IsSharedPrompt
        );
        setPromptManagementStoreData(filteredRecords);
      } else if (showTab === 4) {
        const filteredRecords = promptManagementStoreDataOriginal?.filter(
          (record) => record?.IsStared
        );
        setPromptManagementStoreData(filteredRecords);
      }
    };
    setTabData();
  }, [showTab, promptManagementStoreDataOriginal]);

  return (
    <div className="dashboard-wrapper">
      <div className="section-wrapper">
        <div className="dash-section ">
          <div className="add-prompt-btn">
            <div className="buttonpopup">
              <div
                className="button  w-button flex"
                ref={toggleButtonRef}
                onClick={handlePromptToggle}
              >
                Design Your Prompt
                <div className="down-arrow-white"></div>
              </div>
              <div ref={prompRef}>
                {showPromptPopup && (
                  <>
                    <div className="prompt-popup-menu">
                      <div className="popup-row">
                        <div className="add-prompt-text"></div>
                        <Link to="/addprompt">Add prompt</Link>
                      </div>
                      <div className="popup-row">
                        <div className="logout-icon"></div>
                        <Link to="/importprompt">Import Prompt</Link>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="prompt-tabs w-tabs">
            <div
              className="tabs-menu h-flex head-wrapper w-tab-menu"
              role="tablist"
            >
              <TabLink
                tabId="1"
                href="#"
                isSelected={showTab === 1}
                onClick={() => setShowTab(1)}
              >
                Prompts Collection
              </TabLink>
              <TabLink
                tabId="2"
                href="#"
                isSelected={showTab === 2}
                onClick={() => setShowTab(2)}
              >
                My Prompts
              </TabLink>
              <TabLink
                tabId="3"
                href="#"
                isSelected={showTab === 3}
                onClick={() => setShowTab(3)}
              >
                Shared
              </TabLink>
              <TabLink
                tabId="4"
                href="#"
                isSelected={showTab === 4}
                onClick={() => setShowTab(4)}
              >
                Favourites
              </TabLink>
            </div>
            <div className="w-tab-content">
              {showTab === 1 && promptManagementStoreData?.length > 0 && (
                <PromptManagementContent
                  promptManagementStores={promptManagementStoreData}
                  departmentData={departmentData}
                  onFavouriteClick={handleFavoriteClick}
                  onHandleLikeClick={onHandleLikeClick}
                  onHandleCommentClick={onHandleCommentClick}
                />
              )}
              {!isLoading &&
                showTab === 1 &&
                promptManagementStoreData !== null &&
                promptManagementStoreData !== undefined &&
                promptManagementStoreData?.length === 0 && (
                  <div className="w-layout-grid ai-collection-grid light-bg center-grid">
                    <div>No Prompt(s) Available</div>
                  </div>
                )}

              {showTab === 2 && (
                <PromptManagementContent
                  promptManagementStores={promptManagementStoreData}
                  departmentData={departmentData}
                  onFavouriteClick={handleFavoriteClick}
                  onHandleLikeClick={onHandleLikeClick}
                  onHandleCommentClick={onHandleCommentClick}
                />
              )}

              {showTab === 3 && (
                <PromptManagementContent
                  promptManagementStores={promptManagementStoreData}
                  departmentData={departmentData}
                  onFavouriteClick={handleFavoriteClick}
                  onHandleLikeClick={onHandleLikeClick}
                  onHandleCommentClick={onHandleCommentClick}
                />
              )}

              {showTab === 4 && (
                <PromptManagementContent
                  promptManagementStores={promptManagementStoreData}
                  departmentData={departmentData}
                  onFavouriteClick={handleFavoriteClick}
                  onHandleLikeClick={onHandleLikeClick}
                  onHandleCommentClick={onHandleCommentClick}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PromptManagementDashboard;
