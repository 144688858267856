import React from "react";
import { defaultConfidentialText } from "../../../../../../constants/enums";

const ConfidentialPopup = ({ handleConfidentialChecked }) => {
  return (
    <div className="modal-wrapper  confidential show-modal-wrapper">
      <div className="modal-block border-radius-5">
        <div className="confirmation-content-wrapper saveprompt">
          <div className="modal-content">
            <div className="modal-header">
              <h2>Confidential Information Alert</h2>
            </div>
            <div className="modal-body">
              <p>{defaultConfidentialText}</p>
            </div>
            <div className="popup-btn-container">
              <button
                type="button"
                onClick={() => handleConfidentialChecked(false)}
                className="cancel-btn"
              >
                Yes
              </button>
              <button
                type="button"
                onClick={() => handleConfidentialChecked(true)}
                className="genrate-btn"
              >
                No Personal Data or Confidential Information
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfidentialPopup;
