import React, { useEffect, useState } from "react";
import { ExporttToExcelBlackBoxAdditives } from "../../../../services/fileChatExcelDownload";

import { convertStringToJSONString } from "../../../../shared/tableConverter";

const BlackBoxAdditiveTable = ({
  replacedText,
  isTyping,
  lastDisplayMessage,
  setIsMKSProcessing,
  setIsSkillProcessing,
}) => {
  const [dataList, setDataList] = useState([]);
  const handleExportToExcel = () => {
    ExporttToExcelBlackBoxAdditives(lastDisplayMessage);
  };
  useEffect(() => {
    try {
      const jsonObject = convertStringToJSONString(replacedText, "");
      setDataList(jsonObject);
      setIsMKSProcessing(false);
      setIsSkillProcessing(false);
    } catch (error) {
      setDataList([]);
      setIsMKSProcessing(false);
      setIsSkillProcessing(false);
    }
  }, [replacedText]);

  return (
    <>
      <div className="auto-chat-table">
        {dataList.length > 0 && (
          <>
            <div className="auto-chat-row">
              <div>Chemical Additives</div>
              <div>Count</div>
              <div>Type</div>
              <div>Chemical Additives in Original Language</div>
             
            </div>
            <div
                onClick={() => handleExportToExcel()}
                className="excel-icon-list absolute-icon"
              ></div>
            <div className="chat-table-list">
              {dataList.map((additives, index) => {
                return (
                  <div key={index} className="chat-table-lis-row">
                    <div className="chem-addit">
                      {additives.ChemicalAdditives}
                    </div>
                    <div className="count-name">{additives.Count}</div>
                    <div className="type-name">{additives.Type}</div>
                    <div className="original-name">
                      {additives.ChemicalAdditivesinOriginalLanguage}
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        )}
        {dataList.length === 0 && !isTyping && (
          <div className="chat-table-list">
            <div className="chat-table-lis-row">
              <div className="white-space">No Record(s) available!</div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default BlackBoxAdditiveTable;
