import axiosApiInstance from "../../../../api/axiosApiClient";

const BASE_MKSSKILLSTORE_API_URL = process.env.REACT_APP_MKS_API_URL;

export const getNotificationsData = async () => {
  try {
    const response = await axiosApiInstance.get(
      `${BASE_MKSSKILLSTORE_API_URL}notification/GetNotifications`
    );

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(`Unexpected response status: ${response.status}`);
    }
  } catch (error) {
    console.error("Error fetching notifications data:", error);
    throw error; // Optionally re-throw the error to be handled elsewhere
  }
};

export const updateNotificationReadStatusById = async (id) => {
  try {
    const response = await axiosApiInstance.put(
      `${BASE_MKSSKILLSTORE_API_URL}notification/UpdateNotificationReadStatus?notificationId=${id}`
    );
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error("Error updating read status notifcation:", error);
    throw error; // Optionally re-throw the error to be handled elsewhere
  }
};

