import React from 'react';
import { Link } from 'react-router-dom';

const BreadCrumb = ({skillStoreName}) => {
  return (
    <div className="breadcrumb">
    <Link className="visited home-link" to="/home">
      
    </Link>
    <Link className="visited normal-link" to="/skillstore">
      Skill Library
    </Link>
    <div className="normal-link">{skillStoreName}</div>
  </div>
  )
}

export default BreadCrumb