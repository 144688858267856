import React, { useContext, useEffect, useRef, useState } from "react";
import { UserContext } from "../../../../../../context/user/userContext";
import { getShareUserList } from "../../../../promptManagement/services/promptManagementService";
import SearchByInput from "../../../../../common/searchByInput";

const UserListDropdown = (props) => {
  const {
    dropDownType,
    updateSkillStoreForm,
    validationFieldName,
    selectedUser,
    setValidationFieldName,
  } = props;
  const { state } = useContext(UserContext);
  const [userList, setUserList] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState(null);
  const [selectAll, setSelectAll] = useState(false);
  const [showUserList, setShowUserList] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedNames, setSelectedNames] = useState("");
  const allCheckboxRef = useRef(null);
  const divRef = useRef(null);
  const showUserListRef = useRef(null);

  useEffect(() => {
    const getShareUserListData = async () => {
      const res = await getShareUserList();

      setUserList(res);
      setFilteredUsers(res);
    };
    getShareUserListData();
  }, []);

  useEffect(() => {
    const selectedUserInfo = userList
      .filter((user) => selectedUsers.includes(user.id))
      .map((user) => `${user.firstName} ${user.lastName} (${user.userName})`);
    setSelectedNames(selectedUserInfo);
  }, [selectedUsers, userList]);

  useEffect(() => {
    setSelectedUsers(selectedUser || []);
  }, [selectedUser]);

  useEffect(() => {
    if (validationFieldName === "Owner" && dropDownType === "Owner") {
      divRef.current.classList.add("border-red");
      divRef.current.focus();
    }
    if (validationFieldName === "Contact" && dropDownType === "Contact") {
      divRef.current.classList.add("border-red");
      divRef.current.focus();
    }
  }, [validationFieldName]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        showUserListRef.current &&
        !showUserListRef.current.contains(event.target) &&
        divRef.current &&
        !divRef.current.contains(event.target) // Ensure the dropdown button is also excluded
      ) {
        if (userList.length > 0) {
          setFilteredUsers(userList);
        }
        setShowUserList(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showUserList]);

  const updateSelectAllState = (currentSelected) => {
    const selectedRows = filteredUsers?.filter((item) =>
      currentSelected.includes(item.id)
    );
    if (selectedRows?.length === 0) {
      setSelectAll(false);
      if (allCheckboxRef.current) {
        allCheckboxRef.current.indeterminate = false;
      }
    } else if (selectedRows?.length === filteredUsers?.length) {
      setSelectAll(true);
      if (allCheckboxRef.current) {
        allCheckboxRef.current.indeterminate = false;
      }
    } else {
      setSelectAll(false);
      if (allCheckboxRef.current) {
        allCheckboxRef.current.indeterminate = true;
      }
    }
  };

  const handleUserSelection = (userId) => {
    let updatedSelected = [];
    setSelectedUsers((prevSelected) => {
      const isSelected = prevSelected.includes(userId);
      updatedSelected = isSelected
        ? prevSelected.filter((id) => id !== userId)
        : [...prevSelected, userId];
      updateSkillStoreForm(
        dropDownType === "Owner" ? "Owner" : "Contact",
        updatedSelected
      );
      updateSelectAllState(updatedSelected);
      return updatedSelected;
    });
    if (validationFieldName === "Owner" && dropDownType === "Owner") {
      setValidationFieldName("");
      divRef.current.classList.remove("border-red");
      divRef.current.focus();
    }
    if (validationFieldName === "Contact" && dropDownType === "Contact") {
      setValidationFieldName("");
      divRef.current.classList.remove("border-red");
      divRef.current.focus();
    }
  };

  const handleSelectAll = () => {
    if (selectAll) {
      // Uncheck all users in the current filtered list
      const remainingSelected = selectedUsers.filter(
        (id) => !filteredUsers.some((user) => user.id === id)
      );
      updateSkillStoreForm(
        dropDownType === "Owner" ? "Owner" : "Contact",
        remainingSelected
      );
      setSelectedUsers(remainingSelected);
      setSelectAll(false);
      if (allCheckboxRef.current) allCheckboxRef.current.indeterminate = false;
    } else {
      // Select all users in the current filtered list
      const allUserIds = filteredUsers.map((user) => user.id);
      const updatedSelected = Array.from(
        new Set([...selectedUsers, ...allUserIds])
      );
      updateSkillStoreForm(
        dropDownType === "Owner" ? "Owner" : "Contact",
        updatedSelected
      );
      setSelectedUsers(updatedSelected);
      if (validationFieldName === "Owner" && dropDownType === "Owner") {
        setValidationFieldName("");
        divRef.current.classList.remove("border-red");
        divRef.current.focus();
      }
      if (validationFieldName === "Contact" && dropDownType === "Contact") {
        setValidationFieldName("");
        divRef.current.classList.remove("border-red");
        divRef.current.focus();
      }
      setSelectAll(true);
      if (allCheckboxRef.current) allCheckboxRef.current.indeterminate = false;
    }
  };

  const onInputSearchUserList = (value) => {
    if (value?.trim()) {
      const searchUserList = userList.filter((item) => {
        return (
          item.firstName.toLowerCase().includes(value.toLowerCase()) ||
          item.lastName.toLowerCase().includes(value.toLowerCase()) ||
          item.userName.toLowerCase().includes(value.toLowerCase())
        );
      });
      setFilteredUsers(searchUserList);

      // Update state based on current selection
      const selectedInSearch = searchUserList.filter((user) =>
        selectedUsers.includes(user.id)
      );
      updateSelectAllStateClearAll(
        searchUserList,
        selectedInSearch.map((user) => user.id)
      );
    } else {
      // Reset to show all users if search is cleared
      setFilteredUsers((prev) => userList);
      updateSelectAllStateClearAll(userList, selectedUsers);
    }
  };

  const updateSelectAllStateClearAll = (userList, currentSelected) => {
    const selectedRows = userList.filter((item) =>
      currentSelected.includes(item.id)
    );
    if (selectedRows?.length === 0) {
      setSelectAll(false);
      if (allCheckboxRef.current) {
        allCheckboxRef.current.indeterminate = false;
      }
    } else if (selectedRows?.length === userList?.length) {
      setSelectAll(true);
      if (allCheckboxRef.current) {
        allCheckboxRef.current.indeterminate = false;
      }
    } else {
      setSelectAll(false);
      if (allCheckboxRef.current) {
        allCheckboxRef.current.indeterminate = true;
      }
    }
  };

  const renderWorkspaceApp = (user) => {
    return (
      <div className="applist-row" key={user.id}>
        <input
          type="checkbox"
          checked={selectedUsers.includes(user.id)}
          onChange={() => handleUserSelection(user.id)}
        />
        <div className="app-name-label">
          {user?.firstName + " " + user?.lastName + " (" + user?.userName + ")"}
        </div>
        <div className="app-category-label"></div>
      </div>
    );
  };

  const handleShowUserList = () => {
    setShowUserList((prevShowUserList) => {
      const nextShowUserList = !prevShowUserList;

      if (nextShowUserList) {
        // Wait for the component to update before modifying the checkbox
        setTimeout(() => {
          if (allCheckboxRef.current && selectedUsers.length>0) {
            updateSelectAllState(selectedUsers);
          }
        }, 0);

        setSelectAll(false);
      }

      return nextShowUserList;
    });
  };

  return (
    <>
      <div className="owner-box">
        <div className="custom-dropdown">
          <div
            ref={divRef}
            onClick={() => handleShowUserList()}
            className="dropdown-button"
          >
            {selectedNames.length === 0 && <span>Select {dropDownType}</span>}

            <span title={selectedNames} className="all-selected-item">
              {selectedNames.length > 0 &&
                selectedNames.slice(0, 1).map((name, index) => (
                  <span className="selected-val" key={index}>
                    {name}
                  </span>
                ))}
              {selectedNames?.length > 1 && (
                <span className="more-tags">+{selectedNames?.length - 1}</span>
              )}
            </span>
            <span
              onClick={() => handleShowUserList()}
              className="down-arrow"
            ></span>
          </div>
        </div>
      </div>
      <div ref={showUserListRef}>
        {showUserList && (
          <div className="custom-dropset ownerdrodown">
            <div className="modal-header prompt-share-header">
              <SearchByInput onInputSearch={onInputSearchUserList} />
            </div>
            <div className="modal-body">
              <div className="applist-view">
                <div className="applist-row border-line">
                  <input
                    disabled={filteredUsers?.length === 0}
                    type="checkbox"
                    ref={allCheckboxRef}
                    checked={selectAll}
                    onChange={handleSelectAll}
                  />
                  <div
                    className={
                      filteredUsers?.length === 0
                        ? "app-name-label disabled-element"
                        : "app-name-label"
                    }
                  >
                    All {" (" + selectedUsers?.length + ")"}
                  </div>
                </div>
                {filteredUsers?.length > 0 &&
                  filteredUsers.map((user, index) =>
                    renderWorkspaceApp(user, index)
                  )}
              </div>
            </div>
            {filteredUsers?.length === 0 && (
              <div className="center-align-text">No User(s) Found</div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default UserListDropdown;
