import { useReducer } from "react";
import LoaderContext from "./loaderContext";

const reducer = (state, { type, payload }) => {
  switch (type) {
    case "status":
   
      return {
        ...state,
        visible: payload.visible,
        loaderType: payload.loaderType || state.loaderType,
      };
      default:
        return state;
  }
};

const LoaderState = ({children}) => {
  const [loader, dispatch] = useReducer(reducer, {
    visible: false,
    loaderType: "common",
  });
  const updateLoaderStatus = (status, loaderType = "common") => {
    const fn = () => {
      dispatch({
        type: "status",
        payload: { visible: status, loaderType },
      });
    };
    if (status === false) {
      setTimeout(() => {
        fn();
      }, 1000);
    } else {
      fn();
    }
  };

  return (
    <LoaderContext.Provider value={{ loader, updateLoaderStatus }}>
      {children}
    </LoaderContext.Provider>
  );
};

export default LoaderState;
