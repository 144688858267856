import { entityNames } from "../constants/enums";

export const getEntityUrl = (entityName) => {
  let entityUrl = undefined;
  switch (entityName) {
    case entityNames.DASHBOARD: {
      entityUrl = "/home";
      break;
    }

    default: {
      break;
    }
  }

  if (entityUrl !== undefined) return entityUrl;

  return "";
};

export const getEntityDescription = (privileges, entityName) => {
  let description = "";
  if (
    privileges !== undefined &&
    privileges !== null &&
    privileges.length > 0
  ) {
    let found = privileges.find((x) => x.EntityName === entityName);
    if (found !== null && found !== undefined) {
      description = found.EntityDescription;
    }
  }
  return description;
};
