import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { updateUsedStatusById } from "../../../services/skillStoreService";
import { ExtractCurlParameters } from "../../../../promptManagement/shared/curlConverter";

import { hasGroupAccess } from "../../../../../../services/commonService";
import { UserContext } from "../../../../../../context/user/userContext";
import showMessage from "../../../../../common/message/index";

const SkillStoreDetail = (props) => {
  const { AppImages, selectedSkill } = props;
  const navigate = useNavigate();
  const { state } = useContext(UserContext);
  const securityGroups = state?.user?.userProfile?.msalSecurityGroup;
  const handleUseSkillToNavigate = async () => {
    const access = hasGroupAccess(securityGroups,selectedSkill.SecurityGroupId);
    if(!access) {
      showMessage(
        "You don't have access to " + selectedSkill.Name + " bot.",
        -2
      );
      return;
    }
    const skillStoreId = selectedSkill?.Id;
    const response = await updateUsedStatusById(skillStoreId);
    const isAdditives =
      selectedSkill?.ChatbotConfiguration?.ChatbotUIView?.toLowerCase() ===
      "additives"
        ? true
        : false;

    const isFile =
      selectedSkill?.ChatbotConfiguration?.InputType?.toLowerCase() === "file"
        ? true
        : false;
    navigate("/chat", {
      state: {
        skill: getSkillData(selectedSkill),
        isAdditivesFound: isAdditives,
        isFileType: isFile,
        isMKSChat: false,
        message: "",
        isFileTyple: false,
      },
    });
  };

  const getSkillData = (skillInfo) => {
    const params = ExtractCurlParameters(
      skillInfo.ChatbotConfiguration?.CurlRequestString
    );

    const skill = {
      Id: skillInfo.Id,
      Name: skillInfo.Name,
      ChatbotConfiguration: skillInfo.ChatbotConfiguration,
      aiUrl: params.url,
      headers: params.headers,
      aiFlag: "",
      apiKey: "",
      messageBody: params.body,
      userMessage: skillInfo.ChatbotConfiguration.UserMessageFormat,
      AssistantMessageFormat: skillInfo.ChatbotConfiguration.AssistantMessageFormat,
    };
    return skill;
  };

  return (
    <div className="skill-detail-wrapper">
      <div className="skill-detail-left ">
        <div className="skill-block-header">
          <div className="skill-icon-block">
            <img src={AppImages.ChatAIImage} alt="" className="ai-block-icon" />
          </div>

          <div className="skill-heading">
            <h2>{selectedSkill?.Name}</h2>
            <div className="skill-checkbox-wrapper">
              <img
                src={AppImages.CheckGreenImage}
                loading="lazy"
                alt=""
                className="check-icon"
              />
              <div>{selectedSkill?.SkillScope}</div>
            </div>
          </div>
        </div>

        <div className="skill-flex-col ">
          <div className="skill-card-small">
            <p className="skill-subtitle">Accuracy:</p>
            <p className="skill-subtitle-info">{selectedSkill?.Acurracy}%</p>
          </div>
          <div className="skill-card-small">
            <p className="skill-subtitle">Avg. Excecution Time:</p>
            <p className="skill-subtitle-info">
              {selectedSkill?.AvgExecutionTime} Seconds
            </p>
          </div>
          <div className="skill-card-small">
            <p className="skill-subtitle">Version:</p>
            <p className="skill-subtitle-info">{selectedSkill?.VersionName}</p>
          </div>
          <div className="skill-card-small">
            <p className="skill-subtitle">Used</p>
            <p className="skill-subtitle-info">{selectedSkill?.UsedCount}</p>
          </div>
        </div>
        <div className="skill-desc">
          <h3 className="skill-subheading">Description</h3>
          <p>{selectedSkill?.Description}</p>
        </div>
      </div>
      <div className="skill-detaill-right ">
        <div className="use-skill-row">
          <div
            onClick={() => handleUseSkillToNavigate()}
            className="button light w-button text-center"
          >
            Use Skill
          </div>
          <div className="infowrap">
            <div className="infotext">How can i access this skill?</div>
          </div>
        </div>

        <h3>Applicability</h3>
        <div className="skill-info">
          <div className="skill-subtitle">Department</div>
          <div className="skill-subtitle-info">
            {selectedSkill?.DepartmentName}
          </div>
        </div>
        <div className="skill-info">
          <div className="skill-subtitle">Function</div>
          <div className="keyword-block">
            {selectedSkill?.functions?.map((func) => (
              <div key={func?.Id} className="skill-keyword">
                {func?.Name}
              </div>
            ))}
          </div>
        </div>
        <div className="skill-info">
          <div className="skill-subtitle">Sub Function</div>
          <div className="keyword-block">
            {selectedSkill?.subFunctions?.map((func) => (
              <div key={func?.Id} className="skill-keyword">
                {func?.Name}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkillStoreDetail;
