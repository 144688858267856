import axiosApiInstance from "../../../../api/axiosApiClient";
import { ExtractCurlParameters } from "../../promptManagement/shared/curlConverter";
import { InternalAPIError } from "../../../../utils/apiErrors";
import { handleApiError } from "../../../../utils/globalErrorHandler";

const BASE_MKSSKILLSTORE_API_URL = process.env.REACT_APP_MKS_API_URL;

export const getSkillStoreData = async () => {
  try {
    const response = await axiosApiInstance.get(
      `${BASE_MKSSKILLSTORE_API_URL}skillstore/GetSkillStore`
    );

    if (response.status === 200) {
      response.data.map((skill) => {
        if (skill.ChatbotConfiguration !== null) {
          let params = ExtractCurlParameters(
            skill.ChatbotConfiguration.CurlRequestString
          );
          skill.aiUrl = params.url;
          skill.headers = params.headers;
          skill.headersV2 = params.headersV2;
          skill.method = params.method;
          skill.aiFlag = "";
          skill.apiKey = "";
          skill.messageBody = params.body;
          skill.userMessage = skill.ChatbotConfiguration.UserMessageFormat;
          skill.lastMessageCount = skill.ChatbotConfiguration.LastMessagesCount;
          skill.AssistantMessageFormat =
            skill.ChatbotConfiguration.AssistantMessageFormat;
          skill.ShowCitation = skill.ChatbotConfiguration.ShowCitation;
        }
        return skill;
      });
      return response.data;
    } else {
      handleApiError(new InternalAPIError());
    }
  } catch (error) {
    handleApiError(new InternalAPIError());
  }
};

export const getSkillDepartmentData = async () => {
  try {
    const response = await axiosApiInstance.get(
      `${BASE_MKSSKILLSTORE_API_URL}common/GetDepartments`
    );

    if (response.status === 200) {
      return response.data;
    } else {
      handleApiError(new InternalAPIError());
    }
  } catch (error) {
    handleApiError(new InternalAPIError());
  }
};

export const getSkillFunctionData = async (departmentId) => {
  try {
    const response = await axiosApiInstance.get(
      `${BASE_MKSSKILLSTORE_API_URL}common/GetFunction?LookupDepartmentId=${departmentId}`
    );

    if (response.status === 200) {
      return response.data;
    } else {
      handleApiError(new InternalAPIError());
    }
  } catch (error) {
    handleApiError(new InternalAPIError());
  }
};

export const getSkillSubFunctionData = async (functionIds) => {
  try {
    const response = await axiosApiInstance.post(
      `${BASE_MKSSKILLSTORE_API_URL}common/GetSubFunction`,
      functionIds
    );

    if (response.status === 200) {
      return response.data;
    } else {
      handleApiError(new InternalAPIError());
    }
  } catch (error) {
    handleApiError(new InternalAPIError());
  }
};

export const getSkillScopeData = async () => {
  try {
    const response = await axiosApiInstance.get(
      `${BASE_MKSSKILLSTORE_API_URL}common/GetSkillScope`
    );

    if (response.status === 200) {
      return response.data;
    } else {
      handleApiError(new InternalAPIError());
    }
  } catch (error) {
    handleApiError(new InternalAPIError());
  }
};

export const getSkillStoreDataById = async (id) => {
  try {
    const response = await axiosApiInstance.get(
      `${BASE_MKSSKILLSTORE_API_URL}skillstore/GetSkillStoreById?SkillStoreId=${id}`
    );
    if (response.status === 200) {
      return response.data;
    } else {
      handleApiError(new InternalAPIError());
    }
  } catch (error) {
    handleApiError(new InternalAPIError());
  }
};

export const setLikeByUserSelection = async (data) => {
  try {
    const response = await axiosApiInstance.put(
      `${BASE_MKSSKILLSTORE_API_URL}skillstore/UpdateLikeStatus`,
      data
    );

    if (response.status === 200) {
      return response.data;
    } else {
      handleApiError(new InternalAPIError());
    }
  } catch (error) {
    handleApiError(new InternalAPIError());
  }
};

export const updateUsedStatusById = async (id) => {
  try {
    const response = await axiosApiInstance.put(
      `${BASE_MKSSKILLSTORE_API_URL}skillstore/UpdateUsedStatus?SkillStoreId=${id}`
    );
    if (response.status === 200) {
      return response.data;
    } else {
      handleApiError(new InternalAPIError());
    }
  } catch (error) {
    handleApiError(new InternalAPIError());
  }
};

export const getSkillStoreDetailsDataById = async (id) => {
  try {
    const response = await axiosApiInstance.get(
      `${BASE_MKSSKILLSTORE_API_URL}skillstore/GetSkillDetails?SkillStoreId=${id}`
    );
    if (response.status === 200) {
      return response.data;
    } else {
      handleApiError(new InternalAPIError());
    }
  } catch (error) {
    handleApiError(new InternalAPIError());
  }
};

export const CreateSkillStore = async (data) => {
  try {
    const response = await axiosApiInstance.post(
      `${BASE_MKSSKILLSTORE_API_URL}skillstore/CreateSkillStore`,
      data
    );
    if (response.status === 200) {
      return response.data;
    } else {
      handleApiError(new InternalAPIError());
    }
  } catch (error) {
    handleApiError(new InternalAPIError());
  }
};

export const updateSkillStore = async (SkillStoreId, data) => {
  try {
    const response = await axiosApiInstance.put(
      `${BASE_MKSSKILLSTORE_API_URL}skillstore/UpdateSkillStore?SkillStoreId=${SkillStoreId}`,
      data
    );
    if (response.status === 200) {
      return response.data;
    } else {
      handleApiError(new InternalAPIError());
    }
  } catch (error) {
    console.log(error);
    handleApiError(new InternalAPIError());
  }
};

export const deleteSkill = async (Id) => {
  try {
    const response = await axiosApiInstance.delete(
      `${BASE_MKSSKILLSTORE_API_URL}skillstore/DeleteSkillById?SkillStoreId=${parseInt(
        Id
      )}`
    );
    if (response?.status === 200) {
      return response.data;
    } else {
      handleApiError(new InternalAPIError());
    }
  } catch (error) {
    handleApiError(new InternalAPIError());
  }
};