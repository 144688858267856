import React, { useState, useEffect, useRef } from "react";

const SelectBySubFunction = (props) => {
  const {
    onSubFunctionSelection,
    subFunctionData,
    selectedSubFunction,
    isReadonlyAccess,
  } = props;
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedOptionsName, setSelectedOptionsName] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [searchSubFunctionData, setSearchSubFunctionData] = useState([]);
  const allCheckboxRef = useRef(null);

  useEffect(() => {
    const allSubFunctionData = subFunctionData || [];
    setSearchSubFunctionData(allSubFunctionData);
    setSelectedOptions([]);
    setSelectedOptionsName([]);
    setIsAllSelected(false);
  }, [subFunctionData]);

  useEffect(() => {
    setData();
  }, [searchSubFunctionData]);

  const setData = () => {
    if (
      selectedSubFunction !== null &&
      selectedSubFunction !== undefined &&
      selectedSubFunction?.length > 0
    ) {
      setSelectedOptions((prev) => {
        return selectedSubFunction;
      });
      const matchedNames = subFunctionData
        .filter((item) => selectedSubFunction.includes(item.Id))
        .map((item) => item.Name);
      setSelectedOptionsName(matchedNames);
    }
    if (selectedSubFunction !== undefined) {
      if (searchSubFunctionData?.length === selectedSubFunction?.length) {
        setIsAllSelected(true);
        if (allCheckboxRef.current) {
          allCheckboxRef.current.indeterminate = false;
        }
      } else if (selectedSubFunction?.length === 0) {
        if (allCheckboxRef.current) {
          allCheckboxRef.current.indeterminate = false;
        }
      } else {
        setIsAllSelected(false);
        if (allCheckboxRef.current) {
          allCheckboxRef.current.indeterminate = true;
        }
      }
    } else {
      if (allCheckboxRef.current) {
        allCheckboxRef.current.indeterminate = false;
      }
    }
  };

  const handleSelectChange = (item) => {
    let updatedSelectedOptions;
    let updatedSelectedOptionName;

    if (selectedOptions.includes(item.Id)) {
      updatedSelectedOptions = selectedOptions.filter((id) => id !== item.Id);
      updatedSelectedOptionName = selectedOptionsName.filter(
        (name) => name !== item.Name
      );
    } else {
      updatedSelectedOptions = [...selectedOptions, item.Id];
      updatedSelectedOptionName = [...selectedOptionsName, item.Name];
    }

    setSelectedOptions(updatedSelectedOptions);
    setSelectedOptionsName(updatedSelectedOptionName);
    onSubFunctionSelection(updatedSelectedOptions);

    if (updatedSelectedOptions.length === searchSubFunctionData?.length) {
      setIsAllSelected(true);
      if (allCheckboxRef.current) {
        allCheckboxRef.current.indeterminate = false;
      }
    } else if (updatedSelectedOptions.length === 0) {
      setIsAllSelected(false);
      if (allCheckboxRef.current) {
        allCheckboxRef.current.indeterminate = false;
      }
    } else {
      if (allCheckboxRef.current) {
        allCheckboxRef.current.indeterminate = true;
      }
    }
  };

  const handleSelectAllChange = () => {
    if (isAllSelected) {
      setSelectedOptions([]);
      setIsAllSelected(false);
      allCheckboxRef.current.indeterminate = false;
      onSubFunctionSelection([]);
      setSelectedOptionsName([]);
    } else {
      const allIds = searchSubFunctionData?.map((item) => item.Id);
      const allNames = searchSubFunctionData?.map((item) => item.Name);
      setSelectedOptions(allIds);
      setSelectedOptionsName(allNames);
      setIsAllSelected(true);
      allCheckboxRef.current.indeterminate = false;
      onSubFunctionSelection(allIds);
    }
  };

  const isSelected = (itemId) => selectedOptions.includes(itemId);

  return (
    <div className={isReadonlyAccess ? "not-clickable" : ""}>
   
    <div
      className={
        isReadonlyAccess
          ? "custom-dropdown max-dropdown disabled-parent"
          : "custom-dropdown max-dropdown"
      }
    >
      <div
        className={
          isReadonlyAccess
            ? "dropdown-button disabled-element"
            : "dropdown-button"
        }
      >
        {selectedOptionsName.length === 0 && <span>Select Sub Functions</span>}

        <span title={selectedOptionsName} className="all-selected-item">
          {selectedOptionsName.length > 0 &&
            selectedOptionsName.slice(0, 1).map((name, index) => (
              <span className="selected-val" key={index}>
                {name}
              </span>
            ))}
          {selectedOptions?.length > 1 && (
            <span className="more-tags">+{selectedOptions?.length - 1}</span>
          )}
        </span>
        <span className="down-arrow"></span>
      </div>
      {searchSubFunctionData?.length > 0 && (
        <ul className="dropdown-menu-custom">
          {searchSubFunctionData?.length > 1 && (
            <li>
              <label>
                <input
                  type="checkbox"
                  ref={allCheckboxRef}
                  value="All"
                  checked={isAllSelected}
                  onChange={handleSelectAllChange}
                />
                All
              </label>
            </li>
          )}
          {searchSubFunctionData?.map((item) => (
            <li key={item.Id}>
              <label>
                <input
                  type="checkbox"
                  value={item.Id}
                  checked={isSelected(item.Id)}
                  onChange={() => handleSelectChange(item)}
                />
                {item.Name}
              </label>
            </li>
          ))}
        </ul>
      )}
    </div>
    </div>
  );
};

export default SelectBySubFunction;
