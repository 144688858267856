export const convertStringToJsonArray = (stringFileArray) => {
  let combinedAdditiveArray = [];
  let numbersofPatents = stringFileArray.length;
  stringFileArray.forEach((obj) => {
    let cbArray = generateColumnBaseArray(obj.jsonOutPut, obj.fileName);
    combinedAdditiveArray = [...combinedAdditiveArray, ...cbArray];
  });

  let finalAdditivesArray = [];
  combinedAdditiveArray.forEach((additive) => {
    let checmicalAdditive = additive.ChemicalAdditives;
    let count = combinedAdditiveArray.filter(
      (additive) => additive.ChemicalAdditives === checmicalAdditive
    ).length;

    additive.OccurrenceInPercentage = (count / numbersofPatents) * 100;
    if (
      combinedAdditiveArray.filter(
        (additive) => additive.ChemicalAdditives === checmicalAdditive
      ).length === 0
    )
      additive.PatentNumbers = [
        ...new Set(combinedAdditiveArray.map((obj) => obj.PatentNumbers)),
      ].join(",");

    if (
      finalAdditivesArray.filter(
        (additive) => additive.ChemicalAdditives === checmicalAdditive
      ).length === 0
    ) {
      finalAdditivesArray.push(additive);
    }
  });

  return finalAdditivesArray;
};
export const generateColumnBaseArray = (inputString, fileName) => {
  try {
    inputString = "[" + inputString + "]";
    const jsonObject = JSON.parse(inputString);

    // Convert the JSON object to an array of objects
    const jsonArray = Object.keys(jsonObject[0]).map((key) => {
      return {
        name: key,
        ...jsonObject[0][key],
      };
    });

    const renameKeys = (array) => {
      return array.map((obj) => {
        let newObj = {};
        for (let key in obj) {
          switch (key) {
            case "name":
              newObj[`ChemicalAdditives`] = obj[key];
              break;
            case "additive_in_original_language":
              newObj[`ChemicalAdditivesinOriginalLanguage`] = obj[key];
              break;
            case "count":
              newObj[`Count`] = obj[key];
              break;
            case "type":
              newObj[`Type`] = obj[key];
              break;
            default:
              break;
          }
        }
        return newObj;
      });
    };
    let newArray = renameKeys(jsonArray);
    newArray = newArray.map((obj) => {
      return {
        ...obj,
        OccurrenceInPercentage: 0,
        PatentNumbers: fileName,
      };
    });
    return newArray;
  } catch (error) {
    return [];
  }
};
export const convertStringToJSONString = (outPutString, fileName) => {
  let cbArray = generateColumnBaseArrayForBlackBoxAdditive(
    outPutString,
    fileName
  );
  return cbArray;
};
export const generateColumnBaseArrayForBlackBoxAdditive = (
  inputString,
  fileName
) => {
  try {
    const jsonObject = JSON.parse(inputString);
    let datatable = [];
    jsonObject.forEach((obj) => {
      const jsonArray = Object.keys(obj).map((key) => {
        return {
          name: key,
          ...obj[key],
        };
      });
      datatable = [...datatable, ...renameJSONKeys(jsonArray)];
    });

    return datatable;
  } catch (error) {
    return [];
  }
};
const renameJSONKeys = (array) => {
  return array.map((obj) => {
    let newObj = {};
    for (let key in obj) {
      switch (key) {
        case "name":
          newObj[`ChemicalAdditives`] = obj[key];
          break;
        case "additive_in_original_language":
          newObj[`ChemicalAdditivesinOriginalLanguage`] = obj[key];
          break;
        case "count":
          newObj[`Count`] = obj[key];
          break;
        case "type":
          newObj[`Type`] = obj[key];
          break;
        default:
          break;
      }
    }
    return newObj;
  });
};
