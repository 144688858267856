import React from "react";
import PromptTableRows from "../promptTableRows";

const PromptTable = (props) => {
  const {
    displayedPromptManagement,
    setName,
    setShowPromptManagement,
    handleSetSelectedPrompt,
    isMKSChat,
  } = props;
  
  
  return (
    <>
      <div className="custom-div-table ai-collection-info-block">
        <div className="div-table-body ai-collection-info-block">
          {displayedPromptManagement.length === 0 ? (
            <div className="div-table-row empty-row">
              <div className="div-table-cell">
                <span>No prompt(s) found.</span>
              </div>
            </div>
          ) : (
            displayedPromptManagement.map((item) => (
              <PromptTableRows
                key={item.Id}
                item={item}
                setName={setName}
                setShowPromptManagement={setShowPromptManagement}
                handleSetSelectedPrompt={handleSetSelectedPrompt}
                isMKSChat={isMKSChat}
              />
            ))
          )}
        </div>
      </div>
    </>
  );
};
export default PromptTable;
