export const ExtractCurlParameters = (curlString) => {
  const urlMatch = curlString.match(/(https?:\/\/[^\s]+)/);
  const url = urlMatch ? urlMatch[0].toString().replaceAll("'", "") : "";

  const methodMatch = curlString.match(/-X\s('\w+')/);
  const method = methodMatch
    ? methodMatch[1].toString().replaceAll("'", "")
    : "GET";

  const headerMatches = [...curlString.matchAll(/-H\s'([^']*)'/g)];
  let headers = headerMatches.reduce((acc, match) => {
    const [key, value] = match[1].split(":").map((str) => str.trim());
    acc[key] = value;
    return acc;
  }, {});

  const headersV2 = {
    // accept: headers["accept"],
    // "accept-language": headers["accept-language"],
    openai_api_key: headers["api-key"],
    "content-type": headers["content-type"],
    openai_endpoint: url,
  };

  const dataMatch = curlString.match(/--data-raw '([^]+)'/);
  let body = dataMatch ? dataMatch[1] : null;
  body = body ? body.replace("$presence_penalty", "null") : null;

  return { url, body, headers, method, headersV2 };
};
