import React from "react";
import { useThemeColor } from "../../../context/ThemeContext";
import { updateApplicationBackgroundMode } from "./services/settingService";
import showMessage from "../../common/message";

const Setting = () => {
  const { theme, toggleTheme } = useThemeColor();

  const handleChnageTheme = async (e) => {
    const selectedTheme = e.target.value;
    toggleTheme(selectedTheme);
    const res = await updateApplicationBackgroundMode(selectedTheme);
    if (res !== null && res !== undefined) {
      showMessage(res.Status);
    }
  };

  return (
    <div className="section-wrapper height-100">
      <h2 className="h2">Application Background Mode   </h2>
      <label className="checkbox-container"> Light
        <input
          type="radio"
          value="light"
          checked={theme === "light"}
          onChange={handleChnageTheme}
        />
        <span className="custom-check"></span>
      </label>
      <label className="checkbox-container"> Dark
        <input
          type="radio"
          value="dark"
          checked={theme === "dark"}
          onChange={handleChnageTheme}
        />
        <span className="custom-check"></span>
      </label>
    </div>
  );
};

export default Setting;
