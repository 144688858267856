import React, { useState } from "react";
import ChatTerminalHeader from "./views/_partials/chatTerminalHeader";
import { PdfURLProvider } from "../../../context/pdfURL/pdfURLProvider";
import { useLocation } from "react-router-dom";

const Chat = () => {
  const { state } = useLocation();

  const [isSkillChat, setIsSkillChat] = useState(
    state === null ? false : state?.isAdditivesFound
  );
  const [isFileType, setIsFileType] = useState(state?.isFileType);

  return (
    <PdfURLProvider>
      <div className="chat-terminal-wrapper">
        <div className="chat-box-wrapper">
          <ChatTerminalHeader
            isMKSChatSelected={state === null ? true : state?.isMKSChat}
            chatMessage={state?.chatMessage}
            setIsSkillChat={setIsSkillChat}
            setIsFileType={setIsFileType}
            isFileType={isFileType}
          />
        </div>
      </div>
    </PdfURLProvider>
  );
};

export default Chat;
