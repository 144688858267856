import axiosApiInstance from "../../../../api/axiosApiClient";
import { ExtractCurlParameters } from "../../promptManagement/shared/curlConverter";

const BASE_MKSSKILLSTORE_API_URL = process.env.REACT_APP_MKS_API_URL;

export const getSkillStoreData = async () => {
  const response = await axiosApiInstance.get(
    `${BASE_MKSSKILLSTORE_API_URL}skillstore/GetSkillStore`
  );

  if (response.status === 200) {
    response.data.map((skill) => {
      let params = ExtractCurlParameters(
        skill.ChatbotConfiguration.CurlRequestString
      );
      skill.aiUrl = params.url;
      skill.headers = params.headers;
      skill.aiFlag = "";
      skill.apiKey = "";
      skill.messageBody = params.body;
      skill.userMessage = skill.ChatbotConfiguration.UserMessageFormat;
      skill.lastMessageCount = skill.ChatbotConfiguration.LastMessagesCount;
      skill.AssistantMessageFormat =
        skill.ChatbotConfiguration.AssistantMessageFormat;
      // ('{"messages": [{"role": "system","content": "You are a Marketing Analyst. Your roles include data collection and analysis, market research, reporting and presentation, campaign evaluation, customer insights, strategic planning, technical proficiency, and collaboration. Regularly, you read and analyze documents such as market research reports, customer feedback surveys, sales data, marketing campaign reports, financial statements, internal reports, social media analytics, website analytics, industry publications, competitive intelligence, regulatory documents, and internal marketing plans. Read through all relevant documents then take a moment and think through step by step before answering accordingly. Important: When you receive a question that does not include a specific date, please use the most recent information."},$UserMessage],"max_tokens": 4000,"temperature": 0.7,"top_p": 0.95,"frequency_penalty": 0,"presence_penalty": 0,"stream": true,"data_sources": [{"type": "azure_search","parameters": {"filter": "","endpoint": "https://search-ai-mkt-poc.search.windows.net","index_name": "indexstoragepocfolder","semantic_configuration": "azureml-default","authentication": {"type": "api_key","key": "pjjJmKwlhszlL4B82II395dBAg0rPdpViNENFU4eEEAzSeCFgbt7"},"embedding_dependency": {"type": "endpoint","endpoint": "https://ai-aihubmktaipoc311289393938.cognitiveservices.azure.com/openai/deployments/text-embedding-ada-001/embeddings?api-version=2023-05-15","authentication": {"type": "api_key","key": "4eaa27a9b0b0409fa6558717644b82ba"}},"query_type": "vector_simple_hybrid","in_scope": "True","role_information": "Help user to answer their queries","strictness": 3,"top_n_documents": 20}}]}');
      // skill.AssistantMessageFormat =
      // ('{"role": "assistant","content": $replyMsg}');
      // ('{"messages": [{"role": "system","content": [{"type": "text","text": "$System_Message"}]},$UserMessage],"temperature": $temperature,"top_p": $top_p,"max_tokens": $max_tokens,"stream": true}');
      // skill.assistantModel =
      //   '{"role": "assistant","content": [{"type": "text","text": $replyMsg}]}';

      return skill;
    });
    return response.data;
  }
};

export const getSkillDepartmentData = async () => {
  try {
    const response = await axiosApiInstance.get(
      `${BASE_MKSSKILLSTORE_API_URL}common/GetDepartments`
    );

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(`Unexpected response status: ${response.status}`);
    }
  } catch (error) {
    console.error("Error fetching skill area data:", error);
    throw error; // Optionally re-throw the error to be handled elsewhere
  }
};

export const getSkillFunctionData = async (departmentId) => {
  try {
    const response = await axiosApiInstance.get(
      `${BASE_MKSSKILLSTORE_API_URL}common/GetFunction?LookupDepartmentId=${departmentId}`
    );

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(`Unexpected response status: ${response.status}`);
    }
  } catch (error) {
    console.error("Error fetching skill area data:", error);
    throw error; // Optionally re-throw the error to be handled elsewhere
  }
};

export const getSkillSubFunctionData = async (functionIds) => {
  try {
    const response = await axiosApiInstance.post(
      `${BASE_MKSSKILLSTORE_API_URL}common/GetSubFunction`,
      functionIds
    );

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(`Unexpected response status: ${response.status}`);
    }
  } catch (error) {
    console.error("Error fetching skill area data:", error);
    throw error; // Optionally re-throw the error to be handled elsewhere
  }
};

export const getSkillScopeData = async () => {
  try {
    const response = await axiosApiInstance.get(
      `${BASE_MKSSKILLSTORE_API_URL}common/GetSkillScope`
    );

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(`Unexpected response status: ${response.status}`);
    }
  } catch (error) {
    console.error("Error fetching skill status data:", error);
    throw error; // Optionally re-throw the error to be handled elsewhere
  }
};

export const getSkillStoreDataById = async (id) => {
  const response = await axiosApiInstance.get(
    `${BASE_MKSSKILLSTORE_API_URL}skillstore/GetSkillStoreById?SkillStoreId=${id}`
  );
  if (response.status === 200) {
    return response.data;
  }
};

export const setLikeByUserSelection = async (data) => {
  try {
    const response = await axiosApiInstance.put(
      `${BASE_MKSSKILLSTORE_API_URL}skillstore/UpdateLikeStatus`,
      data
    );

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(`Unexpected response status: ${response.status}`);
    }
  } catch (error) {
    console.error("Error updating like status:", error);
    throw error; // Optionally re-throw the error to be handled elsewhere
  }
};

export const updateUsedStatusById = async (id) => {
  try {
    const response = await axiosApiInstance.put(
      `${BASE_MKSSKILLSTORE_API_URL}skillstore/UpdateUsedStatus?SkillStoreId=${id}`
    );
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error("Error updating used status:", error);
    throw error; // Optionally re-throw the error to be handled elsewhere
  }
};

// JSON data with unique area Names
const jsonData = [
  {
    Name: "Competitive Intelligence Chatbot",
    Value: "",
    apiURL: "",
    Status: "Internal",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat.",
    area: [
      { Id: 1, Name: "Marketing" },
      { Id: 2, Name: "Sales" },
      { Id: 3, Name: "Testing" },
      { Id: 4, Name: "Finance" },
      { Id: 5, Name: "Art" },
    ],
  },
  {
    Name: "Customer Feedback Analyzer",
    Value: "",
    apiURL: "",
    Status: "Internal",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat.",
    area: [
      { Id: 1, Name: "Customer Support" },
      { Id: 2, Name: "Quality Assurance" },
    ],
  },
  {
    Name: "Sales Forecasting Tool",
    Value: "",
    apiURL: "",
    Status: "Internal",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat.",
    area: [
      { Id: 1, Name: "Finance" },
      { Id: 2, Name: "Sales" },
    ],
  },
  {
    Name: "Market Trend Analyzer",
    Value: "",
    apiURL: "",
    Status: "Internal",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat.",
    area: [
      { Id: 1, Name: "Market Research" },
      { Id: 2, Name: "Analytics" },
    ],
  },
  {
    Name: "Product Launch Dashboard",
    Value: "",
    apiURL: "",
    Status: "Internal",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat.",
    area: [
      { Id: 1, Name: "Product Management" },
      { Id: 2, Name: "Marketing" },
    ],
  },
];
