import React from "react";

const SkillVersionHistory = (props) => {
  const { versions, handleVersionClick } = props;
  return (
    <div className="version-history-wrapper">
      <h3 className="skill-subheading">Version History</h3>
      <div className="version-col">
        {versions?.map((version, index) => (
          <div key={index} className="version-link">
            <div
              key={index}
              className="col-text"
              style={{ cursor: "pointer" }}
              onClick={() => handleVersionClick(version?.VersionId)}
            >
              V{version?.versionName}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SkillVersionHistory;
