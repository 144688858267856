import React, { useEffect, useState } from "react";
import "../../../../styles/css/shared/pager.css";
export const Pager = ({
  pageRecords,
  totalRecords,
  pageNumber,
  pageSize,
  onPageNumberChange,
  onPageChangeSize,
  pageSizeOptions,
  selectStyleClass,
  hidePageNumber,
  paginationRangeSeparator,
  keepAllPageOptions,
}) => {
  const [startRecordNumber, setStartRecordNumber] = useState(0);
  const [endRecordNumber, setEndRecordNumber] = useState(0);
  const [totalRecordsValue, setTotalRecordsValue] = useState(0);
  const [totalPagesValue, setTotalPagesValue] = useState(0);
  const [canNavigateToFirstPage, setCanNavigateToFirstPage] = useState(false);
  const [canNavigateToLastPage, setCanNavigateToLastPage] = useState(false);
  const [canNavigateToPreviousPage, setCanNavigateToPreviousPage] =
    useState(false);
  const [canNavigateToNextPage, setCanNavigateToNextPage] = useState(false);

  useEffect(() => {
    const localPageSize =
      keepAllPageOptions && parseInt(pageSize) === -1 ? totalRecords : pageSize;
    const start = localPageSize * (pageNumber - 1) + 1;
    const end = Math.min(start + pageRecords - 1, totalRecords);

    const totalPages = Math.ceil(totalRecords / localPageSize);
    const isLastPage = pageNumber === totalPages;
    const isFirstPage = pageNumber === 1;

    setStartRecordNumber(start);
    setEndRecordNumber(end);
    setTotalRecordsValue(totalRecords);
    setTotalPagesValue(totalPages);
    setCanNavigateToFirstPage(!isFirstPage && totalPages > 1);
    setCanNavigateToLastPage(!isLastPage && pageNumber < totalPages);
    setCanNavigateToPreviousPage(!isFirstPage && totalPages > 1);
    setCanNavigateToNextPage(!isLastPage && pageNumber < totalPages);
  }, [pageNumber, pageSize, pageRecords, totalRecords]);

  const renderOptions = () => {
    const pageOptions = pageSizeOptions || [10, 20, 50, 100];
    const firstPageOption = pageOptions[0];

    if (totalRecordsValue <= firstPageOption) {
      return (
        <option key={firstPageOption} value={firstPageOption}>
          {firstPageOption}
        </option>
      );
    } else {
      return pageOptions.map((option) => {
        return (
          totalRecordsValue >= option && (
            <option key={option} value={option}>
              {option}
            </option>
          )
        );
      });
    }
  };

  const renderAllOptions = () => {
    const pageOptions = pageSizeOptions || [10, 20, 50, 100];
    return pageOptions.map((option) => {
      return (
        <option key={option} value={option}>
          {option}
        </option>
      );
    });
  };

  const getPageClassName = (canNavigate, baseClassName, invalidClassName) => {
    return `${baseClassName} ${canNavigate ? "" : invalidClassName}`;
  };

  return (
    <div className="pagination-container">
      <div className="pagination-wrapper">
        <div className="pagination-page-size">
          <span className="paginate-highlight padding-right-5">
            Rows per page :
          </span>
          <select
            value={
              !keepAllPageOptions && pageSize >= totalRecords
                ? totalRecords
                : pageSize
            }
            className={`page-size-selector ${selectStyleClass}`}
            onChange={onPageChangeSize}
          >
            {keepAllPageOptions ? renderAllOptions() : renderOptions()}
            {!keepAllPageOptions && (
              <option key={"All"} value={totalRecords}>
                All
              </option>
            )}
            {keepAllPageOptions && (
              <option key={"All"} value={-1}>
                All
              </option>
            )}
          </select>
        </div>
        <div className="pagination-range">
          <span className="paginate-highlight">{startRecordNumber}</span>{" "}
          {paginationRangeSeparator || "to"}{" "}
          <span className="paginate-highlight">{endRecordNumber}</span> of{" "}
          <span className="paginate-highlight">{totalRecordsValue}</span>
        </div>
        <div className="pagination-block">
          <button
            className={getPageClassName(
              canNavigateToFirstPage,
              "paginate-link w-inline-block first-page",
              "pagination-not-valid"
            )}
            onClick={() => canNavigateToFirstPage && onPageNumberChange(1)}
          ></button>
          <button
            className={getPageClassName(
              canNavigateToPreviousPage,
              "paginate-link w-inline-block previous-page",
              "pagination-not-valid"
            )}
            onClick={() =>
              canNavigateToPreviousPage && onPageNumberChange(pageNumber - 1)
            }
          ></button>
          {!hidePageNumber && (
            <div className="pagination-of">
              Page <span className="paginate-highlight">{pageNumber}</span> of{" "}
              <span className="paginate-highlight">{totalPagesValue}</span>
            </div>
          )}
          <button
            className={getPageClassName(
              canNavigateToNextPage,
              "paginate-link w-inline-block next-page",
              "pagination-not-valid"
            )}
            onClick={() =>
              canNavigateToNextPage && onPageNumberChange(pageNumber + 1)
            }
          ></button>
          <button
            className={getPageClassName(
              canNavigateToLastPage,
              "paginate-link w-inline-block last-page",
              "pagination-not-valid"
            )}
            onClick={() =>
              canNavigateToLastPage && onPageNumberChange(totalPagesValue)
            }
          ></button>
        </div>
      </div>
    </div>
  );
};
