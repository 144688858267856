import React, { createContext, useContext, useState, useEffect } from "react";
import { UserContext } from "./user/userContext";

const ThemeContext = createContext();

export const useThemeColor = () => useContext(ThemeContext);

export const ThemeProvider = ({ children }) => {
  const { state } = useContext(UserContext);
  const [theme, setTheme] = useState('');

  useEffect(() => {
    const defaultTheme=state?.user?.userProfile?.ApplicationBackgroundMode;
    const newTheme = defaultTheme
      ? defaultTheme?.toLowerCase()
      : "light"; 
    setTheme(newTheme);
  }, [state]);

  const toggleTheme = (newTheme) => setTheme(newTheme);

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      <div className={theme === "light" ? "light-theme" : "dark-theme"}>
        {children}
      </div>
    </ThemeContext.Provider>
  );
};
