import React from "react";
import { capitalizeFirstLetter, getInitials } from "../../../../../../services/commonService";
import UserImage from "../../../../../common/userImage/index";

const SkillContact = (props) => {
  const { AppImages, skillStoreData } = props;
  
  const formattedCreatedOn = skillStoreData?.CreatedOn
  ? new Date(skillStoreData.CreatedOn).toLocaleDateString()
  : "";

  const formattedPublishedOn = skillStoreData?.PublishedOn
  ? new Date(skillStoreData.PublishedOn).toLocaleDateString()
  : "";

  const formattedModifiedOn = skillStoreData?.ModifiedOn
  ? new Date(skillStoreData.ModifiedOn).toLocaleDateString()
  : "";

  return (
    <div className="skill-contact-detail">
      <h3 className="skill-subheading">Contact details</h3>
      <div className="contact-detail-box">
        <div className="skill-update-box">
          <div className="contact-detail">
            <div className="skill-icon-circle">
              <img
                src={AppImages.calendericon}
                alt="calendericon"
                className="contact-detail-icon"
              />
            </div>
            <div className="skillinfo">
              <div className="skill-subtitle">Created On</div>
              <div className="skill-subtitle-info">
                {formattedCreatedOn}
              </div>
            </div>
          </div>
          <div className="contact-detail">
            <div className="skill-icon-circle">
              <img
                src={AppImages.publishIcon}
                alt="publishicon"
                className="contact-detail-icon"
              />
            </div>
            <div className="skillinfo">
              <div className="skill-subtitle">Published On</div>
              <div className="skill-subtitle-info">
                {formattedPublishedOn}
              </div>
            </div>
          </div>
          <div className="contact-detail">
            <div className="skill-icon-circle">
              <img
                src={AppImages.RecentUpdate}
                alt="recent"
                className="contact-detail-icon"
              />
            </div>
            <div className="skillinfo">
              <div className="skill-subtitle">Last Updated On</div>
              <div className="skill-subtitle-info">{formattedModifiedOn}</div>
            </div>
          </div>
        </div>
        <div className="skill-contact-person">
          <div className="skill-col">
            <div className="contact-detail">
              <div className="skill-icon-circle">
              <UserImage userImageBytes={skillStoreData?.CreatorUserImage} userImageMimeType={skillStoreData?.CreatorUserImageMimeType} userInitials={getInitials(skillStoreData?.CreatedBy)}></UserImage>
              </div>
              <div className="skillinfo">
                <div className="col-text">Created by</div>
                <div className="skill-subtitle-info">
                  {capitalizeFirstLetter(skillStoreData?.CreatedBy)}
                </div>
              </div>
            </div>
          </div>
          <div className="skill-col">
            <div className="contact-detail">
              <div className="skill-icon-circle">
              <UserImage userImageBytes={skillStoreData?.OwnerUserImage} userImageMimeType={skillStoreData?.OwnerUserImageMimeType} userInitials={getInitials(skillStoreData?.Owner)}></UserImage>

              </div>
              <div className="skillinfo">
                <div className="col-text">Owner</div>
                <div className="skill-subtitle-info">
                  {skillStoreData?.Owner}
                </div>
              </div>
            </div>
          </div>
          <div className="skill-col">
            <div className="contact-detail">
              <div className="skill-icon-circle">
                <img
                  src={AppImages.person}
                  alt="calendericon"
                  className="contact-detail-icon"
                />
              </div>
              <div className="skillinfo">
                <div className="col-text">Contact</div>
                <div className="skill-subtitle-info">
                  {skillStoreData?.ContactName}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkillContact;
