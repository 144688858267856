import React, { useState } from "react";
import { Link } from "react-router-dom";
import NotificationList from "./views/_partials/notificationList";

const NotificationHub = () => {
  return (
    <>
      <NotificationList/>
    </>
  );
};

export default NotificationHub;
