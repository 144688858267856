import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BreadCrumb from "../_partials/breadCrumb";
import UpdatePromptStepOneandTwo from "../_partials/updatePromptStepOneandTwo";
import PromptCompleteSteps from "../_partials/updatePromptCompleteSteps";
import UpdatePromptStepThree from "../_partials/updatePromptStepThree";
import { updatePromptData } from "../../services/promptManagementService";
import showMessage from "../../../../common/message";
import loaderContext from "../../../../../context/loader/loaderContext";

import {
  defaultDisclaimerText,
  defaultConfidentialText,
} from "../../../../../constants/enums";
import { getDefaultChatbotConfig } from "../../../shared/defaultChatBotConfig/defaultConfigService";
import { PdfURLProvider } from "../../../../../context/pdfURL/pdfURLProvider";

const UpdatePrompt = () => {
  const { state } = useLocation();
  const [promptStepOneandTwoData, setPromptStepOneandTwoData] = useState(null);
  const [promptData, setPromptData] = useState(state.prompt);
  const [selectedPrompts, setSelectedPrompts] = useState(null);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedTagsOriginal, setSelectedTagsOriginal] = useState([]);
  const [selectedOptionsData, setSelectedOptionsData] = useState({});
  const [validationField, setValidationField] = useState("");
  const [headerValues, setHeaderValues] = useState(null);
  const [isImported, setIsImported] = useState(false);
  const { updateLoaderStatus } = useContext(loaderContext);
  const navigate = useNavigate();
  const [mksDefaultConfiguration, setMksDefaultConfiguration] = useState();
  const [chatbotResponse, setChatbotResponse] = useState({
    description: "",
    citations: [],
  });

  useEffect(() => {
    getDefaultChatbotConfig().then((data) => {
      setMksDefaultConfiguration(data);
    });
  }, []);
  const getData = async () => {
    updateLoaderStatus(true);
    const promptStepOneandTwoValues = {
      isGuideLineChecked: promptData?.IsDisclaimerAccepted,
      selectedSkill: promptData?.SkillStoreId,
      promptTitle: promptData?.Title,
      description: promptData?.ShortDescription,
      promptText1: promptData.PromptBackground,
      promptText2: promptData.DesiredPromptOutput,
      promptText3: promptData.SourceOnInputOutput,
      fileName: promptData?.ImageName,
      imageURL: undefined,
      imageBase64String: promptData?.CardImage_Base64,
      mimeType: promptData?.MimeType,
      inputWordCount: promptData?.WordCount,
      IsConfidentialAccepted: promptData?.IsConfidentialAccepted,
      IsPublic: promptData?.IsPublic,
      selectPreferences: promptData?.LookupCreativityId,
      IsReadonlyAccess: promptData?.IsReadonlyAccess,
      CreatedByFirstName: promptData?.CreatedByFirstName,
      CreatedByLastName: promptData?.CreatedByLastName,
      isImported: promptData?.IsImportedPrompt,
      Result: promptData?.Result,
      selectedPreferenceName: "",
    };
    const chatResp = {
      description: promptData?.Result,
      citations: [],
    };
    setChatbotResponse(chatResp);

    const pageHeaderValues = {
      Id: promptData?.Id,
      isPublic: promptData?.IsPublic,
      createdDate: promptData?.CreatedOn,
      lastUpdated: promptData?.LastUpdatedOn,
      isImportedPrompt: promptData?.IsImportedPrompt,
      isLiked: promptData?.IsLiked,
      likeCount: promptData?.LikeCount,
      viewCount: promptData?.ViewCount,
      isViewed: promptData?.IsViewed,
      commentCount: promptData?.CommentCount,
      isStared: promptData?.IsStared,
      isCommented: promptData?.IsCommented,
      comments: promptData?.comments,
      prompt: promptData,
      isImported: promptData?.IsImportedPrompt,
      CreatedByFirstName: promptData?.CreatedByFirstName,
      CreatedByLastName: promptData?.CreatedByLastName,
    };
    setHeaderValues(pageHeaderValues);
    setPromptStepOneandTwoData(promptStepOneandTwoValues);
    setSelectedPrompts(promptData?.PromptSystemGeneratedInputs);
    setIsImported(promptData?.IsImportedPrompt);
    const namesOnly = promptData?.tags?.map((tag) => tag.Name);
    setSelectedTags(namesOnly);
    setSelectedTagsOriginal(namesOnly);

    const options = {
      selectDeparment: promptData?.LookupDepartmentId,
      selectFunction: promptData?.functions?.map((functions) => functions.Id),
      selectSubFunction: promptData?.subFunctions?.map(
        (subfunctions) => subfunctions.Id
      ),
      selectPreferences: promptData?.LookupCreativityId,
    };
    setSelectedOptionsData(options);
    updateLoaderStatus(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const handleSubmitPrompt = () => {
    updateLoaderStatus(true);
    let updatedPromot = {
      SkillStoreId: promptStepOneandTwoData.selectedSkill,
      IsDisclaimerAccepted: promptStepOneandTwoData.isGuideLineChecked,
      Title: promptStepOneandTwoData.promptTitle,
      ShortDescription: promptStepOneandTwoData.description,
      DisclaimerText: defaultDisclaimerText,
      PromptBackground:
        promptStepOneandTwoData.promptText1 === null
          ? ""
          : promptStepOneandTwoData.promptText1,
      DesiredPromptOutput:
        promptStepOneandTwoData.promptText2 === null
          ? ""
          : promptStepOneandTwoData.promptText2,
      SourceOnInputOutput:
        promptStepOneandTwoData.promptText3 === null
          ? ""
          : promptStepOneandTwoData.promptText3,
      MimeType:
        promptStepOneandTwoData.mimeType === null
          ? ""
          : promptStepOneandTwoData.mimeType,
      ConfidentialText: defaultConfidentialText,
      IsConfidentialAccepted: promptStepOneandTwoData.IsConfidentialAccepted,
      LookupDepartmentId: selectedOptionsData.selectDeparment,
      IsPublic: promptStepOneandTwoData.IsPublic,
      CardImage_Base64:
        promptStepOneandTwoData.imageBase64String === null
          ? ""
          : promptStepOneandTwoData.imageBase64String,
      ImageName:
        promptStepOneandTwoData.fileName === null
          ? ""
          : promptStepOneandTwoData.fileName,
      functions: selectedOptionsData.selectFunction,
      subFunctions: selectedOptionsData.selectSubFunction,
      LookupCreativityId:
        promptStepOneandTwoData.selectPreferences === null ||
        promptStepOneandTwoData.selectPreferences === "-1"
          ? null
          : promptStepOneandTwoData.selectPreferences,
      tags: selectedTags,
      WordCount:
        promptStepOneandTwoData.inputWordCount === null
          ? null
          : parseInt(promptStepOneandTwoData.inputWordCount),
      PromptSystemGeneratedInputs: selectedPrompts,
    };
    if (chatbotResponse !== null && chatbotResponse !== undefined)
      updatedPromot.Result = chatbotResponse.description;
    updatePromptData(updatedPromot, promptData.Id).then((resp) => {
      if (resp?.Status === "Prompt Updated Successfully.") {
        showMessage("Prompt Updated Successfully.");
        updateLoaderStatus(false);
        navigate("/promptmanagement");
      } else {
        showMessage("Could not update prompt. Please try again", -2);
        updateLoaderStatus(false);
      }
    });
  };

  const handlePromptStepOneandTwoData = (value, field) => {
    setValidationField("");

    setPromptStepOneandTwoData((prevData) => ({ ...prevData, [field]: value }));
  };

  const handleRemoveExistingPrompt = (value) => {
    setValidationField("");
    setSelectedPrompts((prevItems) =>
      prevItems.filter((item) => item.PromptText !== value.promptMessage)
    );
  };

  const handleAddPrompt = (value) => {
    setValidationField("");
    let updatedPrompt = [];
    updatedPrompt.push(value);
    setSelectedPrompts(updatedPrompt);
  };

  const handleSaveImage = (croppedImageUrl, dataUrl, fileName, mimeType) => {
    setValidationField("");
    setPromptStepOneandTwoData((prevData) => ({
      ...prevData,
      fileName: fileName,
      imageBase64String: dataUrl,
      imageURL: croppedImageUrl,
      mimeType: "",
    }));
  };

  const handleCancelClick = (imageUpload) => {
    setValidationField("");
    setPromptStepOneandTwoData((prevData) => ({
      ...prevData,
      fileName: "",
      imageBase64String: "",
      imageURL: "",
      mimeType: "",
    }));
  };

  const updatePromptResult = (resp) => {};

  const handleSelectTags = (tag) => {
    setValidationField("");
    setSelectedTags([...selectedTags, tag]);
  };

  const handleRemoveTags = (tag) => {
    setValidationField("");
    setSelectedTags(selectedTags.filter((tagItem) => tagItem !== tag));
    setSelectedTagsOriginal(
      selectedTagsOriginal.filter((tagItem) => tagItem !== tag)
    );
  };

  const handleTagAdd = (inputVal) => {
    setValidationField("");
    setSelectedTags([...selectedTags, inputVal]);
    setSelectedTagsOriginal([...selectedTagsOriginal, inputVal]);
  };

  const handleAppendTags = (newTags) => {
    setValidationField("");
    setSelectedTags((prevTags) => [...selectedTagsOriginal, ...newTags]);
  };

  const handleOptionsChange = (value, field) => {
    setValidationField("");
    if (field === "selectDeparment")
      setSelectedOptionsData((prevData) => ({
        ...prevData,
        [field]: value,
        selectFunction: [],
        selectSubFunction: [],
      }));
    if (field === "selectFunction")
      setSelectedOptionsData((prevData) => ({
        ...prevData,
        [field]: value,
        selectSubFunction: [],
      }));
    if (field === "selectSubFunction")
      setSelectedOptionsData((prevData) => ({ ...prevData, [field]: value }));
  };

  const validatePromptData = () => {
    setValidationField((prevData) => {
      return "";
    });
    if (!isImported && promptStepOneandTwoData.isGuideLineChecked === false) {
      showMessage(
        "Please provide your aknowledgement for the guidelines to procced.",
        -2
      );

      setValidationField("isGuideLineChecked");
      return false;
    }
    if (
      !isImported &&
      (promptStepOneandTwoData.selectedSkill === "-1" ||
        promptStepOneandTwoData.selectedSkill === null)
    ) {
      showMessage("Please select skill.", -2);
      setValidationField("selectedSkill");
      return false;
    }

    if (
      promptStepOneandTwoData.promptTitle === "" ||
      promptStepOneandTwoData.promptTitle === null
    ) {
      showMessage("Please provide title for Prompt.", -2);
      setValidationField("promptTitle");
      return false;
    }
    if (
      promptStepOneandTwoData?.description.trim() === "" ||
      promptStepOneandTwoData.description === null
    ) {
      showMessage("Please provide Description for Prompt.", -2);
      setValidationField("description");
      return false;
    }
    // if (
    //   promptStepOneandTwoData?.description.trim() !== "" &&
    //   promptStepOneandTwoData.description !== null
    // ) {
    //   if (textContainsPIIText(promptStepOneandTwoData.description)) {
    //     showMessage(
    //       "Cannot proceed further because the description contains PII.",
    //       -2
    //     );
    //     setValidationField("description");
    //     return false;
    //   }
    // }

    if (
      !isImported &&
      (promptStepOneandTwoData.promptText1?.trim() === "" ||
        promptStepOneandTwoData.promptText1 === null ||
        promptStepOneandTwoData.promptText1 === undefined)
    ) {
      showMessage("Please provide background for Prompt.", -2);
      setValidationField("promptText1");
      return false;
    }
    // if (
    //   promptStepOneandTwoData.promptText1?.trim() !== "" &&
    //   promptStepOneandTwoData.promptText1 !== null &&
    //   promptStepOneandTwoData.promptText1 !== undefined
    // ) {
    //   if (textContainsPIIText(promptStepOneandTwoData.promptText1)) {
    //     showMessage(
    //       "Cannot proceed further because the background for prompt contains PII.",
    //       -2
    //     );
    //     setValidationField("promptText1");
    //     return false;
    //   }
    // }
    if (
      !isImported &&
      (promptStepOneandTwoData.promptText2?.trim() === "" ||
        promptStepOneandTwoData.promptText2 === null ||
        promptStepOneandTwoData.promptText2 === undefined)
    ) {
      showMessage("Please provide desired output format for Prompt.", -2);
      setValidationField("promptText2");
      return false;
    }

    // if (
    //   promptStepOneandTwoData.promptText2?.trim() !== "" &&
    //   promptStepOneandTwoData.promptText2 !== null &&
    //   promptStepOneandTwoData.promptText2 !== undefined
    // ) {
    //   if (textContainsPIIText(promptStepOneandTwoData.promptText2)) {
    //     showMessage(
    //       "Cannot proceed further because the desired output for prompt contains PII.",
    //       -2
    //     );
    //     setValidationField("promptText2");
    //     return false;
    //   }
    // }
    // if (
    //   !isImported &&
    //   (promptStepOneandTwoData.promptText3?.trim() === "" ||
    //     promptStepOneandTwoData.promptText3 === null ||
    //     promptStepOneandTwoData.promptText3 === undefined)
    // ) {
    //   showMessage("Please provide source/input for Prompt.", -2);
    //   setValidationField("promptText3");
    //   return false;
    // }
    // if (
    //   isImported &&
    //   promptStepOneandTwoData.promptText3?.trim() !== "" &&
    //   promptStepOneandTwoData.promptText3 !== null &&
    //   promptStepOneandTwoData.promptText3 !== undefined
    // ) {
    //   if (textContainsPIIText(promptStepOneandTwoData.promptText3)) {
    //     showMessage(
    //       "Cannot proceed further because the source/input for prompt contains PII.",
    //       -2
    //     );
    //     setValidationField("promptText3");
    //     return false;
    //   }
    // }
    if (
      !isImported &&
      (promptStepOneandTwoData.selectPreferences === null ||
        promptStepOneandTwoData.selectPreferences === undefined ||
        promptStepOneandTwoData.selectPreferences === "-1")
    ) {
      showMessage("Please select Preference.", -2);
      setValidationField("selectPreferences");
      return false;
    }
    if (
      !isImported &&
      (promptStepOneandTwoData.inputWordCount === null ||
        promptStepOneandTwoData.inputWordCount === undefined ||
        promptStepOneandTwoData.inputWordCount === "")
    ) {
      showMessage("Please fill Word Count.", -2);
      setValidationField("inputWordCount");
      return false;
    }
    if (!isImported && selectedTags?.length === 0) {
      showMessage("Please Add Tags", -2);
      setValidationField("tags");
      return false;
    }
    if (
      !isImported &&
      (selectedOptionsData.selectDeparment === null ||
        selectedOptionsData.selectDeparment === undefined ||
        selectedOptionsData.selectDeparment === "-1")
    ) {
      showMessage("Please select Department", -2);
      setValidationField("selectDeparment");
      return false;
    }
    if (
      !isImported &&
      (selectedOptionsData.selectFunction === null ||
        selectedOptionsData.selectFunction === undefined ||
        selectedOptionsData.selectFunction?.length === 0)
    ) {
      showMessage("Please select Function", -2);
      setValidationField("selectFunction");
      return false;
    }

    return true;
  };

  const updatePrompt = () => {
    if (validatePromptData()) {
      handleSubmitPrompt();
    }
  };

  return (
    <PdfURLProvider>
      <div className="dashboard-wrapper">
        <BreadCrumb editPromptTitle={promptStepOneandTwoData?.promptTitle} />
        <div className="section-wrapper pt-0">
          <PromptCompleteSteps
            isReadonlyAccess={promptStepOneandTwoData?.IsReadonlyAccess}
            pageHeaderValues={headerValues}
          />
          <UpdatePromptStepOneandTwo
            promptData={promptStepOneandTwoData}
            handlePromptStepOneandTwoData={handlePromptStepOneandTwoData}
            prevSelectedPrompts={selectedPrompts}
            handleRemoveExistingPrompt={handleRemoveExistingPrompt}
            handleAddPrompt={handleAddPrompt}
            handleSaveImage={handleSaveImage}
            handleCancelClick={handleCancelClick}
            updatePromptResult={updatePromptResult}
            validationField={validationField}
            isReadonlyAccess={promptStepOneandTwoData?.IsReadonlyAccess}
            setSelectedPrompts={setSelectedPrompts}
            handleAppendTags={handleAppendTags}
            chatbotResponse={chatbotResponse}
            setChatbotResponse={setChatbotResponse}
            mksDefaultConfiguration={mksDefaultConfiguration}
          ></UpdatePromptStepOneandTwo>
          <UpdatePromptStepThree
            isImportedPrompt={isImported}
            selectedTags={selectedTags}
            selectedOptions={selectedOptionsData}
            handleSelectTags={handleSelectTags}
            handleRemoveTags={handleRemoveTags}
            handleTagAdd={handleTagAdd}
            handleOptionsChange={handleOptionsChange}
            updatePromptData={updatePrompt}
            validationField={validationField}
            isReadonlyAccess={promptStepOneandTwoData?.IsReadonlyAccess}
          ></UpdatePromptStepThree>
        </div>
      </div>
    </PdfURLProvider>
  );
};

export default UpdatePrompt;
