import { Routes, Route } from "react-router-dom";
import Login from "./login";
import Logout from "./logout";
import { Layout } from "./layout";
import NotFound from "./notFound";
import ScrollToTop from "./scrollToTop";

import { ExternalLogin } from "./externalLogin";
import Home from "../mks-applications/home";
import Chat from "../mks-applications/chat";
import MyworkSpace from "../mks-applications/myWorkSpace";
import SkillStore from "../mks-applications/skillStore";

import NotificationHub from "../mks-applications/notificationHub";
import AboutMKS from "../mks-applications/aboutMKS";
import GuidanceAndGuidelines from "../mks-applications/guidanceAndGuidelines";
import Feeback from "../mks-applications/feedback";
import Help from "../mks-applications/help";
import Settings from "../mks-applications/settings";
import SkillStoreDetail from "../mks-applications/skillStore/views/skillStoreDetail";
import AddPrompt from "../mks-applications/promptManagement/views/addPrompt";
import UpdatePrompt from "../mks-applications/promptManagement/views/updatePrompt";
import PromptManagementDashboard from "../mks-applications/promptManagement/views/_partials/promptManagementDashboard";
import ImportPrompt from "../mks-applications/promptManagement/views/importPrompt";
import CorpLogin from "./corpLogin";
import { HeaderProvider } from "../../context/user/headerContext";
import CreateSkill from "../mks-applications/skillStore/views/createSkill";
import UpdateSkill from "../mks-applications/skillStore/views/updateSkill";
import ThirdPartyAITools from "../mks-applications/thirdPartyAiTools";
import Users from "../mks-applications/users";
export const CommonRoute = () => {
  return (
    <>
      <ScrollToTop />
      <HeaderProvider>
        <Routes>
          <Route path="/externalLogin" element={<ExternalLogin />} />
          <Route path="/" exact element={<Login />} />
          <Route path="/login" exact element={<Login />} />
          <Route path="/corplogin" exact element={<CorpLogin />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/" element={<Layout />}>
            <Route path="/home" element={<Home />} />
            <Route path="/chat" element={<Chat />} />
            <Route path="/myworkspace" element={<MyworkSpace />} />
            <Route path="/skillstore" element={<SkillStore />} />
            <Route
              path="/promptmanagement"
              element={<PromptManagementDashboard />}
            />
            <Route path="/thirdpartyaitools" element={<ThirdPartyAITools />} />
            <Route path="/notificationhub" element={<NotificationHub />} />
            <Route path="/aboutmks" element={<AboutMKS />} />
            <Route path="/guidance" element={<GuidanceAndGuidelines />} />
            <Route path="/feedback" element={<Feeback />} />
            <Route path="/help" element={<Help />} />
            <Route path="/settings" element={<Settings />} />
            <Route
              path="/skillstoredetail"
              element={<SkillStoreDetail IsThirdPartyAITool={false} />}
            />
            <Route
              path="/thirdpartyaitoolsdetail"
              element={<SkillStoreDetail IsThirdPartyAITool={true} />}
            />
            <Route path="/addPrompt" element={<AddPrompt />} />
            <Route path="/users" element={<Users/>}/>
            <Route path="/createskill" element={<CreateSkill />} />
            <Route
              path="/updateSkill"
              element={<UpdateSkill IsThirdPartyAITool={false} />}
            />
            <Route
              path="/updatethirdpartyaitools"
              element={<UpdateSkill IsThirdPartyAITool={true} />}
            />
            <Route path="/updatePrompt" element={<UpdatePrompt />} />
            <Route path="/importPrompt" element={<ImportPrompt />} />
            <Route path="/notFound" element={<NotFound />} />
          </Route>
        </Routes>
      </HeaderProvider>
    </>
  );
};
