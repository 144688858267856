import React from "react";
import SearchByInput from "../../../../../common/searchByInput";
import SearchByDepartment from "../../../../../common/searchByDepartment";
import SearchByFunction from "../../../../../common/searchByFunction";
import SearchBySubFunction from "../../../../../common/searchBySubFunction";
import SearchByScope from "../../../../../common/searchByScope";

const SkillStoreFilterSection = (props) => {
  const {
    onInputSearchSkillStore,
    searchDepartmentData,
    onSearchBySkillStoreDepartment,
    onSearchBySkillStoreFunction,
    searchFunctionData,
    searchSubFunctionData,
    onSearchBySkillStoreSubFunction,
    searchSkillStoreScopeData,
    onSearchBySkillStoreScope
  } = props;
  return (
    <div className="skill-filter-wrapper w-form">
      <div className="skill-filter-form">
        <SearchByInput onInputSearch={onInputSearchSkillStore} />
        <SearchByDepartment
          searchDepartmentData={searchDepartmentData}
          onSearchDepartmentSelection={onSearchBySkillStoreDepartment}
        />
        <SearchByFunction
          searchFunctionData={searchFunctionData}
          onSearchFunctionSelection={onSearchBySkillStoreFunction}
        />
        {/* <SearchBySubFunction
          searchSubFunctionData={searchSubFunctionData}
          onSearchSubFunctionSelection={onSearchBySkillStoreSubFunction}
        /> */}
        <SearchByScope
          searchSkillStoreScopeData={searchSkillStoreScopeData}
          onSearchByScope={onSearchBySkillStoreScope}
        />
      </div>
    </div>
  );
};

export default SkillStoreFilterSection;
