import React, { useContext, useEffect, useState } from "react";
import BreadCrumb from "../_partials/breadCrumb";
import SkillUpdateHeaderDetails from "../_partials/skillUpdateHeaderDetails";
import { useLocation } from "react-router-dom";
import { getSkillStoreDetailsDataById } from "../../services/skillStoreService";
import { updateSkillStore } from "../../services/skillStoreService";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import loaderContext from "../../../../../context/loader/loaderContext";

const UpdateSkill = ({ IsThirdPartyAITool }) => {
  const skillState = useLocation();
  const skillStoreid = skillState?.state?.id;
  const navigate = useNavigate();
  const { updateLoaderStatus } = useContext(loaderContext);
  const [skillStoreForm, setskillStoreForm] = useState({
    Name: "",
    Description: "",
    ShortDescription: "",
    DepartmentNameId: 0,
    LookupSkillScopeId: 0,
    PublishOn: "",
    Acurracy: "",
    AvgExecutionTime: "",
    IsThirdPartyAITool: false,
    functions: [],
    subFunctions: [],
    tags: [],
    Owner: [],
    Contact: [],
    ChatBotURL: "",
    InputType: "",
    OutputType: "",
    LastMessagesCount: "",
    ChatGPTVersion: "",
    IsChatbotTestable: false,
    ShowCitation: false,
    ShowFollowUpQuestions: false,
    CurlRequestString: "",
    UserAssistantMessageFormat: "",
    FileType: "",
    ChatbotUIView: "",
    DynamicProperties: [],
  });

  useEffect(() => {
    const fetchSkillDetails = async () => {
      if (skillStoreid) {
        const response = await getSkillStoreDetailsDataById(skillStoreid);
        if (response) {
          setskillStoreForm(response);
        }
      }
    };
    updateLoaderStatus(true);
    fetchSkillDetails();
    updateLoaderStatus(false);
  }, [skillStoreid]);

  const handleUpdateSubmit = async () => {
    try {
      const response = await updateSkillStore(skillStoreid, skillStoreForm);
      if (response) {
        toast.success("Skill Updated Successfully.");
        let navigateRoute = IsThirdPartyAITool
          ? "/thirdpartyaitools"
          : "/skillstore";
        navigate(navigateRoute);
      }
    } catch (err) {
      toast.error("Error updating skill store");
    }
  };

  return (
    <div className="dashboard-wrapper">
      <div className="">
        <BreadCrumb
          IsThirdPartyAITool={IsThirdPartyAITool}
          skillStoreName={skillStoreForm?.Name}
        />
        <SkillUpdateHeaderDetails
          skillStoreForm={skillStoreForm}
          setskillStoreForm={setskillStoreForm}
          handleUpdateSubmit={handleUpdateSubmit}
        ></SkillUpdateHeaderDetails>
      </div>
    </div>
  );
};

export default UpdateSkill;
