import React, { useContext } from "react";

import { useUserName } from "../customHooks/useUserName";
import UserImage from "../userImage";
import { UserContext } from "../../../context/user/userContext.js";

const DashboardMobileHeader = () => {
  const { state } = useContext(UserContext);
  const imagebytes = state?.user?.userProfile?.userImage;
  const imagetype = state?.user?.userProfile?.userImagemimeType;
  const { fullName, initials } = useUserName();

  return (
    <>
      <div className="dash-header">
        <div className="left-user-settings">
          <div className="user-profile-menu w-inline-block">
            <div className="user-profile-initial">
            <UserImage userImageBytes={imagebytes} userImageMimeType={imagetype} userInitials={initials}></UserImage>
            </div> 
            <div>{fullName}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardMobileHeader;
