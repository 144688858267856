import React, { useEffect, useState } from "react";

const SelectByDepartment = (props) => {
  const {
    onDepartmentSelection,
    departmentData,
    selectDepartment,
    isReadonlyAccess,
  } = props;
  const [selectedDepartmentByUser, setSelectedDepartmentByUser] = useState();
  useEffect(() => {
    setData();
  }, [selectDepartment]);

  const setData = () => {
    if (
      selectDepartment !== null &&
      selectDepartment !== undefined &&
      selectDepartment !== ""
    ) {
      setSelectedDepartmentByUser(selectDepartment);
    } else {
      setSelectedDepartmentByUser("-1");
    }
  };
  const selectDepartmentHandler = (e) => {
    const selectedVal = e.target.value;
    setSelectedDepartmentByUser(selectedVal);
    onDepartmentSelection(selectedVal);
  };

  return (
    <div  className={isReadonlyAccess ? "not-clickable" : ""}>
    <select
      value={selectedDepartmentByUser}
      className={
        isReadonlyAccess
          ? "skill-filter-field w-select disabled-element"
          : "skill-filter-field w-select"
      }
      onChange={(e) => selectDepartmentHandler(e)}
    >
      <option value="-1">Select Department</option>
      {departmentData?.map((item) => (
        <option key={item.Id} value={item.Id}>
          {item.Name}
        </option>
      ))}
    </select>
    </div>
  );
};

export default SelectByDepartment;
