import axiosApiInstance from "../../../../api/axiosApiClient";
import { InternalAPIError } from "../../../../utils/apiErrors";
import { handleApiError } from "../../../../utils/globalErrorHandler";

const BASE_AUTH_API_URL = process.env.REACT_APP_AUTHAPI_URL;

export const getUserFeedback = async () => {
  try {
    const response = await axiosApiInstance.get(
      `${BASE_AUTH_API_URL}usermanagement/GetUserFeedback`
    );
    if (response.status === 200) {
      return response.data;
    } else {
      handleApiError(new InternalAPIError());
    }
  } catch (error) {
    handleApiError(new InternalAPIError());
  }
};

export const captureUserFeedback = async (body) => {
  try {
    let url = `${BASE_AUTH_API_URL}usermanagement/CaptureUserFeedback`;
    const response = await axiosApiInstance.post(url, body);
    return await response.data;
  } catch (error) {
    handleApiError(new InternalAPIError());
  }
};
