import React from "react";

const CreateFeedback = (props) => {
  const {
    characterCount,
    setCharacterCount,
    feedbackText,
    setFeedbackText,
    submitFeedback,
  } = props;

  const handleFeedbackText = (e) => {
    const text = e.target.value;
    const charactersCount = text.trim().length;
    setFeedbackText(text);
    setCharacterCount(charactersCount);
  };

  return (
      <div className="feedback-right-section">
        <textarea
          className="feedback-textarea"
          placeholder="Write feedback here"
          maxLength={1000}
          onChange={handleFeedbackText}
          value={feedbackText}
        ></textarea>
        <div className="feedback-flex-row">
          {/* <div className="check-wrap">
            <input
              type="checkbox"
              checked={isAnonymous}
              onChange={() => setIsAnonymous(!isAnonymous)}
              className="check-class"
            />
            <label>Anonymous</label>
          </div> */}
          <div className="count-word">{characterCount}/1000</div>
          <button
            className="feedback-btn-submit"
            disabled={characterCount === 0}
            onClick={submitFeedback}
          >
            Submit
          </button>
        </div>
      </div>
  );
};

export default CreateFeedback;
