import React, { useState, useContext } from "react";
import AppImages from "../../../../../../constants/images";
import {
  setLikeByUserSelection,
  updateUsedStatusById,
} from "../../../services/skillStoreService";
import { useNavigate } from "react-router-dom";
import { hasGroupAccess } from "../../../../../../services/commonService";
import { UserContext } from "../../../../../../context/user/userContext";
import showMessage from "../../../../../common/message/index";


const SkillCard = (props) => {
  const { skill } = props;
  const { state } = useContext(UserContext);
  const securityGroups = state?.user?.userProfile?.msalSecurityGroup;
  const [like, setLike] = useState(skill?.IsLiked);
  let [likeCount, setLikeCount] = useState(skill?.LikeCount);

  const navigate = useNavigate();

  const maxItemsToShow = 3; // Define how many items you want to display
  const areasToShow = skill?.skillTags?.slice(0, maxItemsToShow);
  const remainingCount = skill?.skillTags?.length - maxItemsToShow;

  const handleLikeClick = (val) => {
    const data = {
      SkillStoreId: skill?.Id,
      Status: !val,
    };
    setLike(!val);
    setLikeByUserSelection(data);
    likeCount = val ? likeCount - 1 : likeCount + 1;
    setLikeCount(likeCount);
  };

  const handleUseSkillClick = async () => {
    const access = hasGroupAccess(securityGroups,skill.SecurityGroupId);
    if(!access) {
      showMessage(
        "You don't have access to " + skill.Name + " bot.",
        -2
      );
      return;
    }
    const skillStoreId = skill?.Id;
    const response = await updateUsedStatusById(skillStoreId);
    const isAdditives =
      skill?.ChatbotConfiguration?.ChatbotUIView?.toLowerCase() === "additives"
        ? true
        : false;

    const isFile =
      skill?.ChatbotConfiguration?.InputType?.toLowerCase() === "file"
        ? true
        : false;
    if (response !== undefined && response !== null) {
      if (skill.aiUrl !== "")
        navigate("/chat", {
          state: {
            skill: skill,
            isAdditivesFound: isAdditives,
            isFileType: isFile,
          },
        });
    }
  };

  const handleCardClick = () => {
    navigate("/skillstoredetail", { state: { skill: skill } });
  };

  return (
    <div className="ai-collection-link-block">
      <div className="ai-collection-info-block">
        <div className="ai-block-header" onClick={handleCardClick}>
          <img
            src={AppImages.ChatAIImage}
            loading="lazy"
            alt=""
            className="ai-block-icon"
          />
          <h3 className="h3 small">{skill.Name}</h3>
        </div>
        <div className="keyword-block" onClick={handleCardClick}>
          {areasToShow.map((a) => (
            <div key={a.Id} className="skill-keyword">
              {a.Name}
            </div>
          ))}
          {remainingCount > 0 && (
            <div className="skill-keyword">+{remainingCount}</div>
          )}
        </div>
        <p onClick={handleCardClick} className="paragraph small">
          {skill.Description}
        </p>
        <div onClick={handleCardClick} className="use-skill ">
          <div className="use-skill-icon active"></div>
          <div>02</div>
        </div>
        <div className="ai-button-wrapper">
          <div className="skill-checkbox-wrapper">
            <img
              src={AppImages.CheckGreenImage}
              loading="lazy"
              alt=""
              className="check-icon"
              onClick={handleCardClick}
            />
            <div>{skill.SkillStatus}</div>
            <div
              onClick={() => handleLikeClick(like)}
              className="prompt-action-link w-inline-block"
            >
              <img
                src={
                  like ? AppImages.ThumbLikeIconDark : AppImages.ThumbLikeIcon
                }
                alt=""
                className="prompt-action-icon"
              />
              <div className="prompt-action-count">{likeCount}</div>
            </div>
          </div>
          <div onClick={handleUseSkillClick} className="button  w-button">
            Use Skill
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkillCard;
