import React, { useContext } from "react";
import { deletePrompt } from "../../../services/promptManagementService";
import showMessage from "../../../../../common/message/index";
import loaderContext from "../../../../../../context/loader/loaderContext";
import { useNavigate } from "react-router-dom";

const DeletePromptConfirmation = (props) => {
  const { promptId, promptName, handleClosePopup } = props;
  const { updateLoaderStatus } = useContext(loaderContext);
  const navigate = useNavigate();

  const closePopup = () => {
    handleClosePopup();
  };

  const deleteCurrentPrompt = () => {
    updateLoaderStatus(true);
    deletePrompt(promptId).then((resp) => {
      if (resp?.Status === "Prompt Deleted Successfully.") {
        showMessage("Prompt Deleted Successfully.");
        updateLoaderStatus(false);
        navigate("/promptmanagement");
      } else {
        showMessage("Could not delete prompt. Please try again", -2);
        updateLoaderStatus(false);
      }
    });
  };

  return (
    <div className="modal-wrapper show-modal-wrapper delete-prompt-modal">
      <div className="modal-block border-radius-5">
        <div className="confirmation-content-wrapper">
          <div className="modal-content ">
            <div className="modal-header">
              <h2>Delete Prompt</h2>
            </div>
            <div className="modal-body">
              <p>Are you sure you want to delete the prompt?</p>
            </div>
            <div className="popup-btn-container">
              <button
                type="button"
                onClick={() => deleteCurrentPrompt()}
                className="genrate-btn"
              >
                Yes
              </button>
              <button
                type="button"
                onClick={() => closePopup()}
                className="cancel-btn"
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeletePromptConfirmation;
