import React, { useContext } from "react";
import { deleteSkill } from "../../../../skillStore/services/skillStoreService";
import showMessage from "../../../../../common/message/index";
import loaderContext from "../../../../../../context/loader/loaderContext";
import { useNavigate } from "react-router-dom";

const DeleteSkillConfirmation = (props) => {
  const { skillStoreId, handleClosePopup, IsThirdPartyAITool } = props;
  const { updateLoaderStatus } = useContext(loaderContext);
  const navigate = useNavigate();

  const closePopup = () => {
    handleClosePopup();
  };

  const deleteCurrentSkill = () => {
    updateLoaderStatus(true);
    deleteSkill(skillStoreId).then((resp) => {
      if (resp?.Status === "Skill Deleted Successfully.") {
        showMessage("Skill Deleted Successfully.");
        updateLoaderStatus(false);
        navigate(IsThirdPartyAITool ? "/thirdpartyaitools" : "/skillstore");
      } else {
        showMessage("Could not delete skill. Please try again", -2);
        updateLoaderStatus(false);
      }
    });
  };

  return (
    <div className="modal-wrapper show-modal-wrapper delete-prompt-modal">
      <div className="modal-block border-radius-5">
        <div className="confirmation-content-wrapper">
          <div className="modal-content ">
            <div className="modal-header">
              <h2>Delete Skill</h2>
            </div>
            <div className="modal-body">
              <p>Are you sure you want to delete the skill?</p>
            </div>
            <div className="popup-btn-container">
              <button
                type="button"
                onClick={() => deleteCurrentSkill()}
                className="genrate-btn"
              >
                Yes
              </button>
              <button
                type="button"
                onClick={() => closePopup()}
                className="cancel-btn"
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteSkillConfirmation;
