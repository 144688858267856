import React, { useEffect, useState, useContext } from "react";
import SkillStoreDetailPage from "../_partials/skillStoreDetailPage";
import { useLocation } from "react-router-dom";
import { getSkillStoreDataById } from "../../../skillStore/services/skillStoreService";
import loaderContext from "../../../../../context/loader/loaderContext";

const SkillStoreDetail = ({ IsThirdPartyAITool }) => {
  const { state } = useLocation();
  const [selectedSkill, setSelectedSkill] = useState(null);
  const { updateLoaderStatus } = useContext(loaderContext);

  useEffect(() => {
    const getSkillStoreDetailData = async () => {
      const res = await getSkillStoreDataById(state?.skill?.Id);
      setSelectedSkill(res);
    };
    getSkillStoreDetailData();
    updateLoaderStatus(false);
  }, []);

  const updateOriginalData = (usedCount) => {
    const updatedSkillData = {
      ...selectedSkill,
      UsedCount: usedCount,
    };
    setSelectedSkill(updatedSkillData);
  };

  return (
    <div className="dashboard-wrapper">
      <SkillStoreDetailPage
        IsThirdPartyAITool={IsThirdPartyAITool}
        selectedSkill={selectedSkill}
        updateOriginalData={updateOriginalData}
      />
    </div>
  );
};

export default SkillStoreDetail;
