import React, { useEffect, useRef, useState, useContext } from "react";
import showMessage from "../../../../../common/message";
import { getUseSkills } from "../../../services/promptManagementService";
import { hasGroupAccess } from "../../../../../../services/commonService";
import { UserContext } from "../../../../../../context/user/userContext";
import { BOTAPIError } from "../../../../../../utils/apiErrors";
import { handleApiError } from "../../../../../../utils/globalErrorHandler";
import loaderContext from "../../../../../../context/loader/loaderContext";

const GeneratedPrompts = ({
  isPromptVisible,
  promptMessage,
  onBackStep,
  promptFromChild,
  setIsShowConfidentialPopup,
  setChatbotResponse,
  setPromptForm,
  hideBackButton,
  setPromptFormChild,
  mksDefaultConfiguration,
  handlePromptSelected,
  selectedPromptForTest,
}) => {
  const [selectedChatbot, setSelectedChatbot] = useState(null);
  const { updateLoaderStatus } = useContext(loaderContext);
  const [useSkills, setUseSkills] = useState([]);
  const skillref = useRef(null);
  const { state } = useContext(UserContext);
  const securityGroups = state?.user?.userProfile?.msalSecurityGroup;

  const handleSelectPrompt = (e, item) => {
    handlePromptSelected(e, item);
  };

  const onBackStepChild = () => {
    onBackStep();
  };

  const getUseSkillsData = () => {
    getUseSkills().then((resp) => {
      setUseSkills(resp);

      if (
        promptFromChild?.SkillStoreId !== null &&
        promptFromChild?.SkillStoreId !== undefined &&
        promptFromChild?.SkillStoreId !== 0
      ) {
        setPrviousSelectedChatbotConfig(resp);
      }
    });
  };
  useEffect(() => {
    getUseSkillsData();
  }, []);

  const setPrviousSelectedChatbotConfig = (resp) => {
    const selectedSkill = resp?.filter(
      (item) => item.Id === parseInt(promptFromChild?.SkillStoreId)
    )[0];

    setSelectedChatbot(selectedSkill);
  };

  const handleTestGeneratedPrompt = async () => {
    updateLoaderStatus(true);
    if (selectedPromptForTest === "") {
      showMessage("Please select at least one prompt.", -2);
      updateLoaderStatus(false);
      return;
    }
    if (selectedChatbot === undefined || selectedChatbot === null) {
      showMessage("Please select a skill first.", -2);
      updateLoaderStatus(false);
      return;
    }
    try {
      const isNewFlag = process.env.REACT_APP_PROMPT_FLAG_MKS_NEW;
      if (isNewFlag === "true") {
        await handleLoadGenericApiConfigurationsV2();
      } else {
        await handleLoadGenericApiConfigurations();
      }

      setIsShowConfidentialPopup(true);
    } catch (error) {
      //handleApiError(new BOTAPIError());
      updateLoaderStatus(false);
    } finally {
      updateLoaderStatus(false);
    }
  };
  const removeTrailingSlash = (url) => {
    return url.endsWith("\\") ? url.slice(0, -1) : url;
  };
  const handleLoadGenericApiConfigurations = async () => {
    updateLoaderStatus(true);
    let inputText = selectedPromptForTest;
    let userMessage = selectedChatbot.ChatbotConfiguration.UserMessageFormat;
    userMessage = userMessage.replace("$Message", `"${inputText}"`);
    let userMessageModel2 = selectedChatbot.messageBody.replace(
      "$UserMessage",
      userMessage
    );

    let response = "";
    const responseA = await fetch(removeTrailingSlash(selectedChatbot.aiUrl), {
      method: "post",
      headers: selectedChatbot.headers,
      body: userMessageModel2,
    });
    if (!responseA.ok || !responseA.body) {
      handleApiError(new BOTAPIError());
      return;
    }
    const reader = responseA.body.getReader();
    const decoder = new TextDecoder("utf-8");
    const loopRunner = true;
    let citations = [];

    while (loopRunner) {
      try {
        const { value, done } = await reader.read();
        if (done) {
          break;
        }
        let decodedChunk = decoder.decode(value, { stream: true });
        let replacedChunk = decodedChunk.replace("data: ", "");
        let findContext =
          replacedChunk.indexOf("context") &&
          replacedChunk.indexOf("citations");
        if (findContext > -1) {
          citations =
            JSON.parse(replacedChunk)?.choices[0]?.delta?.context?.citations;
        }
        let splitChunk = replacedChunk.split("\n\ndata:").slice(0, -1);

        let datas = JSON.parse(JSON.stringify(splitChunk));
        let counter = 0;

        datas.forEach((da) => {
          let jsonArray = null;
          try {
            updateLoaderStatus(true);
            jsonArray = JSON.parse(da);
            if (jsonArray.choices.length > 0) {
              const text = jsonArray.choices[0].delta?.content || "";
              if (counter < 2 && (text.match(/\n/) || []).length) {
                return;
              }
              response = response + text;
              counter++;
            }
          } catch (parseError) {
            console.log(
              "Error parsing JSON array item:",
              parseError,
              " Raw Item ",
              da
            );
          }
        });
      } catch (readError) {
        updateLoaderStatus(false);
      }
    }
    let cbResponse = {
      description: response,
      citations: citations,
    };
    setChatbotResponse(cbResponse);
  };

  const handleLoadGenericApiConfigurationsV2 = async () => {
    updateLoaderStatus(true);
    let inputText = selectedPromptForTest;
    inputText +=
      promptFromChild?.DesiredPromptOutput === undefined
        ? ""
        : promptFromChild?.DesiredPromptOutput;

    let userMessage = selectedChatbot.ChatbotConfiguration.UserMessageFormat;
    userMessage = userMessage.replace("$Message", `"${inputText}"`);
    let userMessageModel2 = selectedChatbot.messageBody.replace(
      "$UserMessage",
      userMessage
    );
    let userModelJson = JSON.parse(userMessageModel2);
    userModelJson.stream = false;
    const finalPayload = JSON.stringify(userModelJson);
    let response = "";
    let citations = [];

    const requestOptions = {
      method: selectedChatbot.method,
      headers: selectedChatbot.headersV2,
      body: finalPayload,
      redirect: "follow",
    };

    try {
      const apiResponse = await fetch(
        process.env.REACT_APP_CHAT_WRAPPER_URL,
        requestOptions
      );
      const data = await apiResponse.text();
      let replacedChunk = JSON.parse(data);
      if (replacedChunk.choices.length > 0) {
        replacedChunk.choices[0].messages.forEach((msg) => {
          if (msg?.role === "tool") {
            citations = msg?.content;
          } else if (msg?.role === "assistant") {
            response = response + msg?.content;
          }
        });
      }
    } catch (error) {
      handleApiError(new BOTAPIError());
      updateLoaderStatus(false);
    }

    let cbResponse = {
      description: response,
      citations: citations,
    };

    setChatbotResponse(cbResponse);
  };

  const handleSelectedSkillChange = (skillId) => {
    const selectedSkill = useSkills.filter(
      (item) => item.Id === parseInt(skillId)
    )[0];

    const access = hasGroupAccess(
      securityGroups,
      selectedSkill?.SecurityGroupId
    );
    if (!access) {
      showMessage(
        "You don't have access to " + selectedSkill.Name + " bot.",
        -2
      );
      return;
    }

    setPromptForm((prevData) => ({
      ...prevData,
      SkillStoreId: skillId,
    }));
    setPromptFormChild((prevData) => ({
      ...prevData,
      ...prevData,
      SkillStoreId: skillId,
    }));
    setSelectedChatbot(selectedSkill);
    if (skillId !== "0") skillref.current.classList.remove("border-red");
  };

  return (
    <div className="dash-section p20 prompt-step-two">
      {isPromptVisible > 0 && (
        <>
          <div className="prompt-review">
            <div className="prompt-step-name">
              {promptMessage.length} Prompt(s) Generated
            </div>
            <div className="prompt-step-desc">
              Compare the generated based on your prompt
            </div>
            <div className="prompt-gen">
              <div className="selected-tag">
                {mksDefaultConfiguration.ChatGPTVersion}
              </div>
            </div>
          </div>
          <div className="prompt-review-wrap">
            {promptMessage.map((prompt) => (
              <div key={prompt.promtId} className="prompt-review-box">
                <input
                  type="radio"
                  name="prompt"
                  onChange={(e) => handleSelectPrompt(e, prompt)}
                  className="p-checkbox"
                  checked={prompt.isSelected}
                />
                <textarea
                  readOnly
                  className="prompt-textarea"
                  rows="5"
                  value={prompt.promptMessage}
                ></textarea>
              </div>
            ))}
          </div>
        </>
      )}

      <div className="prompt-button-wrapper">
        {hideBackButton === false && (
          <button className="backbtn" onClick={() => onBackStepChild()}>
            Back
          </button>
        )}
        {hideBackButton && <div></div>}
        <div className="dropdown-with-button">
          <div className="prompt-title-wrap">
            <select
              value={promptFromChild?.SkillStoreId}
              className="prompt-skill"
              onChange={(e) => {
                handleSelectedSkillChange(e.target.value);
              }}
              ref={skillref}
            >
              <option value="-1">Select Skill</option>
              {useSkills?.map((item) => (
                <option key={item.Id} value={item.Id}>
                  {item.Name}
                </option>
              ))}
            </select>
          </div>
          {promptMessage.length > 0 && (
            <button
              className="genrate-btn"
              onClick={() => handleTestGeneratedPrompt()}
            >
              Test Prompt
            </button>
          )}
          {promptMessage.length === 0 && (
            <button className="genrate-btn disabled-btn" disabled>
              Test Prompt
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default GeneratedPrompts;
