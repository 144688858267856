import { useState } from "react";
import PdfURLContext from "./pdfURLContext";

export const PdfURLProvider = ({ children }) => {
  const [url, setUrl] = useState('');

  return (
    <PdfURLContext.Provider value={{ url, setUrl }}>
      {children}
    </PdfURLContext.Provider>
  );
};
