export const defaultDisclaimerText =
  "I have read and understood the Guidelines for the responsible use of MKS and acknowledge my responsibility to ensure that the prompt is being used appropriately for the intended purpose. ";

export const defaultConfidentialText =
  "Have you included any personal data or Confidential Information in the prompt description, or any other fields? If you select Yes, you will not be able to proceed to nextpage unless PII Data removed. ";

export const roleTypes = {
  CORPORATE: "Corporate",
  FRANCHISEE: "Franchisee",
};
export const accessTypes = {
  READ: "canRead",
  UPDATE: "canUpdate",
  CREATE: "canCreate",
  DELETE: "canDelete",
};

export const entityNames = {
  DASHBOARD: "Dashboard",
  ROLEMANAGEMENT: "RoleManagement",
  USERMANAGEMENT: "UserManagement",
};

export const TriStateValue = {
  CHECKED: "CHECKED",
  UNCHECKED: "UNCHECKED",
  PARTIAL: "PARTIAL",
};

export const rowTypes = {
  Main: "Main",
  Module: "Module",
  Entity: "Entity",
};
export const stateClasses = {
  Checked: "checked-state",
  UnChecked: "unchecked-state",
  Partial: "partial-state",
  Any: "checkbox-any-state",
};
export const attributes = {
  priviligeType: "data-privilegetype",
  moduleName: "data-moduleName",
  module: "data-module",
  rowType: "data-rowType",
};

export const endPoints = {
  USERS_LIST: "usermanagement/GetUserList",
  USER_ASSIGNABLE_ROLE: "usermanagement/GetAssignableRole",
  ALL_ROLES_API: "rolemanagement/GetRoleList",
  ALL_ASSIGNABLE_LOCATION_API: "usermanagement/GetAssignableLocations",
  CREATE_USER_API: "usermanagement/createuser",
  GET_USER_DETAILS_API: "usermanagement/GetUserDetails?userId=",
  UPDATE_USER_DETAILS_API: "usermanagement/updateuser",
};

export const blackBoxAndTranslate = () => {
  let bb = [];
  bb.push({
    Id: 0,
    Name: "Blackbox",
    Value: "Blackbox",
    checked: false,
  });
  bb.push({
    Id: -1,
    Name: "Translate",
    Value: "Translate",
    checked: false,
  });
  return bb;
};
