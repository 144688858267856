import React, { useEffect, useRef, useState } from "react";
import {
  getSkillDepartmentData,
  getSkillFunctionData,
  getSkillScopeData,
  getSkillSubFunctionData,
} from "../../../services/skillStoreService";
import SelectByFunction from "../../../../promptManagement/views/_partials/selectByFunction";
import SelectByDepartment from "../../../../promptManagement/views/_partials/selectByDepartment";
import SelectBySubFunction from "../../../../promptManagement/views/_partials/selectBySubFunction";
import SelectByScope from "../../../../../common/selectByScope";

const SkillApplicability = (props) => {
  const {
    skillStoreForm,
    updateSkillStoreForm,
    validationFieldName,
    setValidationFieldName,
  } = props;

  const [selectDeparment, setSelectDepartment] = useState();
  const [functionData, setFunctionData] = useState([]);
  const [selectFunction, setSelectFunction] = useState([]);
  const [subFunctionData, setSubFunctionData] = useState([]);
  const [selectSubFunction, setSubSelectFunction] = useState([]);
  const [departmentData, setDepartmentData] = useState([]);
  const [skillStoreScopeData, setSkillStoreScopeData] = useState([]);
  const [selectedScope, setSelectedScope] = useState();
  const [isLoaded, setIsLoaded] = useState(false);
  const divDepartmentRef = useRef(null);
  const divFunctionRef = useRef(null);
  const divSubFunctionRef = useRef(null);
  const divScopeRef = useRef(null);

  useEffect(() => {
    const getDataOfSkillDep = async () => {
      const departmentData = await getSkillDepartmentData();
      setDepartmentData(departmentData);
    };

    const getDataOfSkillScope = async () => {
      const data = await getSkillScopeData();
      setSkillStoreScopeData(data);
    };
    getDataOfSkillScope();
    getDataOfSkillDep();
  }, []);

  useEffect(() => {
    if (validationFieldName === "Department") {
      divDepartmentRef.current.classList.add("border-red");
      divDepartmentRef.current.focus();
    } else if (validationFieldName === "Function") {
      divFunctionRef.current.classList.add("border-red");
      divFunctionRef.current.focus();
    } else if (validationFieldName === "SubFunction") {
      divSubFunctionRef.current.classList.add("border-red");
      divSubFunctionRef.current.focus();
    } else if (validationFieldName === "Scope") {
      divScopeRef.current.classList.add("border-red");
      divScopeRef.current.focus();
    }
  }, [validationFieldName]);

  useEffect(() => {
    if (!isLoaded && skillStoreForm?.DepartmentNameId > 0) {
      setSelectDepartment(skillStoreForm?.DepartmentNameId);
      bindFunctionData(skillStoreForm?.DepartmentNameId);
      setSelectFunction(skillStoreForm?.functions);
      if (skillStoreForm?.functions !== null) {
        bindSubFunctionData(skillStoreForm?.functions);
        setSubSelectFunction(skillStoreForm?.subFunctions);
      }
      setIsLoaded(true);
    }
  }, [skillStoreForm?.DepartmentNameId]);

  useEffect(()=>{
    setSelectedScope(skillStoreForm?.LookupSkillScopeId);
  },[skillStoreForm?.LookupSkillScopeId])

  const bindFunctionData = async (departmentId) => {
    const functionData = await getSkillFunctionData(departmentId);
    const filteredData = functionData?.filter((func) => func.Name !== "All");
    setFunctionData(filteredData);
  };

  const bindSubFunctionData = async (functionIds) => {
    const subFunctionData = await getSkillSubFunctionData(functionIds);
    setSubFunctionData(subFunctionData);
  };

  const onDepartmentSelection = async (departmentId) => {
    divDepartmentRef.current.classList.remove("border-red");
    setValidationFieldName("");
    updateSkillStoreForm("DepartmentNameId", departmentId);
    updateSkillStoreForm("functions", []);
    updateSkillStoreForm("subFunctions", []);
    bindFunctionData(departmentId);
    setSelectFunction([]);
    setSubFunctionData([]);
    setSubSelectFunction([]);
  };

  const onFunctionSelection = async (functionIds) => {
    divFunctionRef.current.classList.remove("border-red");
    setValidationFieldName("");
    updateSkillStoreForm("functions", functionIds);
    updateSkillStoreForm("subFunctions", []);
    setSubSelectFunction([]);
    bindSubFunctionData(functionIds);
  };

  const onSubFunctionSelection = (subFunctionIds) => {
    divSubFunctionRef.current.classList.remove("border-red");
    setValidationFieldName("");
    updateSkillStoreForm("subFunctions", subFunctionIds);
    setSubSelectFunction(subFunctionIds);
  };

  const searchByScope = (selectedVal) => {
    divScopeRef.current.classList.remove("border-red");
    setValidationFieldName("");
    // let selectScopeID = 0;
    // if (selectedVal) selectScopeID = parseInt(selectedVal);
    setSelectedScope(selectedVal);
    updateSkillStoreForm("LookupSkillScopeId", selectedVal);
  };

  return (
    <div className="prompt-selects">
      <div className="prompt-function">
        <div className="skill-subtitle">
          Department <span className="red">*</span>
        </div>

        <div ref={divDepartmentRef}>
          <SelectByDepartment
            onDepartmentSelection={onDepartmentSelection}
            departmentData={departmentData}
            selectDepartment={selectDeparment}
          />
        </div>
      </div>
      <div className="prompt-function">
        <div className="skill-subtitle">
          Function<span className="red">*</span>
        </div>

        <div ref={divFunctionRef}>
          <SelectByFunction
            onFunctionSelection={onFunctionSelection}
            functionData={functionData}
            selectedFunction={selectFunction}
          />
        </div>
      </div>
      <div className="prompt-function">
        <div className="skill-subtitle">
          Sub Function <span className="red">*</span>
        </div>
        <div ref={divSubFunctionRef}>
          <SelectBySubFunction
            onSubFunctionSelection={onSubFunctionSelection}
            subFunctionData={subFunctionData}
            selectedSubFunction={selectSubFunction}
          />
        </div>
      </div>
      <div className="prompt-function">
        <div className="skill-subtitle">
          Scope <span className="red">*</span>
        </div>
        <div ref={divScopeRef}>
          <SelectByScope
            searchSkillStoreScopeData={skillStoreScopeData}
            onSearchByScope={searchByScope}
            selectedScope={selectedScope}
          />
        </div>
      </div>
    </div>
  );
};

export default SkillApplicability;
