import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { UserProvider } from "./context/user/userProvider";
import LoaderState from "./context/loader/loaderState";
import { ThemeProvider } from "./context/ThemeContext";
import {registerLicense} from '@syncfusion/ej2-base';

const MKS_APP_SYNC_FUSION_LICENSE_KEY=process.env.REACT_APP_SYNC_FUSION_LICENSE_KEY;
registerLicense(MKS_APP_SYNC_FUSION_LICENSE_KEY);



const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
 <ThemeProvider>
    <LoaderState>
      <UserProvider>
          <App />
      
      </UserProvider>
    </LoaderState>
    </ThemeProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
