import axiosApiInstance from "../../../../api/axiosApiClient";
import { ExtractCurlParameters } from "../../promptManagement/shared/curlConverter";

const BASE_MKSPROMPTMANAGEMENT_API_URL = process.env.REACT_APP_MKS_API_URL;

export const getDefaultChatbotConfig = async () => {
  try {
    const response = await axiosApiInstance.get(
      `${BASE_MKSPROMPTMANAGEMENT_API_URL}common/GetDefaultChatbotConfig`
    );

    if (response.status === 200) {
      let params = {
        url: response.data.url,
        method: response.data.method,
        headers: response.data.headers,
        body: response.data.body,
        userMessage: response.data.UserMessageFormat,
        lastMessageCount: response.data.LastMessagesCount,
        assistantMessage: response.data.AssistantMessageFormat,
      };
      let output = ExtractCurlParameters(response.data.CurlRequestString);
      params.url = output.url;
      params.method = output.method;
      params.body = output.body;
      params.headers = output.headers;

      return params;
    }
  } catch (error) {
    console.log(error);
  }
};

export const getMKSChatURLInfo = () => {
  getDefaultChatbotConfig();
};
