import "./App.css";
import { CommonRoute } from "./components/common";
import { ToastContainer } from "react-toastify";
import AutoLogout from "./services/autoLogout";
import Spinner from "./components/common/spinner";
import { useContext } from "react";
import loaderContext from "./context/loader/loaderContext";
import TippyTooltip from "@tippyjs/react";

import Loginloader from "./components/common/loginloader";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";

const MSAL_CLIENT_ID = process.env.REACT_APP_MSAL_CLIENT_ID;
const MSAL_TENANT_ID = process.env.REACT_APP_MSAL_TENANT_ID;
const MSAL_REDIRECT_URL = process.env.REACT_APP_MSAL_REDIRECT_URL;

const msalConfig = {
  auth: {
    clientId: MSAL_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${MSAL_TENANT_ID}`,
    redirectUri: MSAL_REDIRECT_URL,
  },
};

const msalInstance = new PublicClientApplication(msalConfig);

function App() {
  let loading = useContext(loaderContext);

  return (
    <MsalProvider instance={msalInstance}>
      {loading.loader.loaderType === "login" ? (
        <Loginloader />
      ) : (
        loading.loader.visible && <Spinner />
      )}
      <AutoLogout>
        <div>
          <CommonRoute />
          <TippyTooltip />
        </div>
        <ToastContainer />
      </AutoLogout>
    </MsalProvider>
  );
}

export default App;
