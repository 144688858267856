import React from "react";
import UserListDropdown from "../userList";

const SkillContactDetails = (props) => {
  const {
    skillStoreForm,
    updateSkillStoreForm,
    validationFieldName,
    setValidationFieldName,
  } = props;
  return (
    <>
      <div className="max-width-row">
        <div className="prompt-title-wrap">
          <h3 className="prompt-title">
            Owner <span className="red">*</span>{" "}
          </h3>
          <UserListDropdown
            dropDownType="Owner"
            updateSkillStoreForm={updateSkillStoreForm}
            validationFieldName={validationFieldName}
            selectedUser={skillStoreForm?.Owner}
            setValidationFieldName={setValidationFieldName}
          ></UserListDropdown>
        </div>
        <div className="prompt-title-wrap">
          <h3 className="prompt-title">
            Contact <span className="red">*</span>{" "}
          </h3>
          <UserListDropdown
            dropDownType="Contact"
            updateSkillStoreForm={updateSkillStoreForm}
            validationFieldName={validationFieldName}
            selectedUser={skillStoreForm?.Contact}
            setValidationFieldName={setValidationFieldName}
          ></UserListDropdown>
        </div>
      </div>
    </>
  );
};

export default SkillContactDetails;
