import React, { useEffect, useState, useContext } from "react";
import {
  getSkillStoreData,
  getSkillDepartmentData,
  getSkillScopeData,
  getSkillStoreDataById,
} from "../../../services/skillStoreService";
import loaderContext from "../../../../../../context/loader/loaderContext";
import TabLink from "../../../../promptManagement/views/_partials/tabLink";
import SkillStore from "../skillstore";
import ThirdPartyAIToolsSkills from "../thirdPartyAIToolsSkills";

const Skills = ({ IsThirdPartyAITool, limit }) => {
  const [originalSkills, setOriginalSkills] = useState([]);

  const { updateLoaderStatus } = useContext(loaderContext);
  const [skillDepartmentData, setSkillDepartmentData] = useState([]);
  const [skillStoreScopeData, setSkillStoreScopeData] = useState([]);

  useEffect(() => {
    const getDataOfSkillDep = async () => {
      const data = await getSkillDepartmentData();
      setSkillDepartmentData(data);
    };

    const getDataOfSkillScope = async () => {
      const data = await getSkillScopeData();
      setSkillStoreScopeData(data);
    };

    const getDataOfSkillStore = async () => {
      updateLoaderStatus(true);
      const data = await getSkillStoreData();

      setOriginalSkills(data);
      updateLoaderStatus(false);
    };

    getDataOfSkillDep();
    getDataOfSkillScope();
    getDataOfSkillStore();
  }, []);

  const updateOriginalData = (skillId) => {
    getSkillStoreDataById(skillId).then((res) => {
      setOriginalSkills((prevData) =>
        prevData.map((item) =>
          item.Id === skillId ? { ...item, UsedCount: res.UsedCount } : item
        )
      );
    });
  };

  return (
    <div className="dash-section ">
      <div className="skills-tabs w-tabs">
        <div className="w-tab-content">
          {!IsThirdPartyAITool && (
            <SkillStore
              limit={limit}
              originalSkills={originalSkills}
              skillDepartmentData={skillDepartmentData}
              skillStoreScopeData={skillStoreScopeData}
              IsThirdPartyAITool={IsThirdPartyAITool}
              setOriginalSkills={setOriginalSkills}
            ></SkillStore>
          )}
          {IsThirdPartyAITool && (
            <ThirdPartyAIToolsSkills
              limit={limit}
              originalSkills={originalSkills}
              skillDepartmentData={skillDepartmentData}
              skillStoreScopeData={skillStoreScopeData}
              IsThirdPartyAITool={IsThirdPartyAITool}
              setOriginalSkills={setOriginalSkills}
              updateOriginalData={updateOriginalData}
            ></ThirdPartyAIToolsSkills>
          )}
        </div>
      </div>
    </div>
  );
};

export default Skills;
