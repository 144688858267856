import React from "react";
import Skills from "../skillStore/views/_partials/skills";

const ThirdPartyAITools = () => {
  return (
    <div className="dashboard-wrapper">
      <div className="section-wrapper">
        <Skills IsThirdPartyAITool={true} />
      </div>
    </div>
  );
};

export default ThirdPartyAITools;
