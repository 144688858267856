import React from 'react'
import Loginloader from '../loginloader'

const CorpLogin = () => {
  return (
    <div>
     <Loginloader />
    </div>
  )
}

export default CorpLogin
