import React, { useState, useEffect, useRef, useContext } from "react";
import { getShareUserList } from "../../../services/promptManagementService";
import { UserContext } from "../../../../../../context/user/userContext";
import SearchByInput from "../../../../../common/searchByInput";

const ShareUserList = (props) => {
  const { state } = useContext(UserContext);

  const { handelAppPopUp, onSaveSharedUser, prompt } = props;
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [userList, setUserList] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const allCheckboxRef = useRef(null);

  useEffect(() => {
    const getShareUserListData = async () => {
      const res = await getShareUserList();
      const filteredUserList = res?.filter(
        (r) =>
          r.id !== prompt?.CreatedById &&
          r.id !== state?.user?.userProfile?.user_id
      );
      setUserList(filteredUserList);
      setFilteredUsers(filteredUserList);
    };
    getShareUserListData();
  }, []);

  useEffect(() => {
    if (prompt?.Users?.length > 0) {
      const preSelectedUsers = prompt?.Users?.filter(
        (id) => id !== prompt?.CreatedById
      )?.map((id) => id);
      setSelectedUsers(preSelectedUsers);
      updateSelectAllState(preSelectedUsers);
    }
  }, [prompt, userList]);

  const updateSelectAllState = (currentSelected) => {
    const selectedRows = filteredUsers.filter((item) =>
      currentSelected.includes(item.id)
    );
    if (selectedRows.length === 0) {
      setSelectAll(false);
      if (allCheckboxRef.current) {
        allCheckboxRef.current.indeterminate = false;
      }
    } else if (selectedRows.length === filteredUsers.length) {
      setSelectAll(true);
      if (allCheckboxRef.current) {
        allCheckboxRef.current.indeterminate = false;
      }
    } else {
      setSelectAll(false);
      if (allCheckboxRef.current) {
        allCheckboxRef.current.indeterminate = true;
      }
    }
  };

  const handleUserSelection = (userId) => {
    setSelectedUsers((prevSelected) => {
      const isSelected = prevSelected.includes(userId);
      const updatedSelected = isSelected
        ? prevSelected.filter((id) => id !== userId)
        : [...prevSelected, userId];

      updateSelectAllState(updatedSelected);
      return updatedSelected;
    });
  };

  const handleSelectAll = () => {
    if (selectAll) {
      // Uncheck all users in the current filtered list
      const remainingSelected = selectedUsers.filter(
        (id) => !filteredUsers.some((user) => user.id === id)
      );
      setSelectedUsers(remainingSelected);
      setSelectAll(false);
      if (allCheckboxRef.current) allCheckboxRef.current.indeterminate = false;
    } else {
      // Select all users in the current filtered list
      const allUserIds = filteredUsers.map((user) => user.id);
      const updatedSelected = Array.from(
        new Set([...selectedUsers, ...allUserIds])
      );
      setSelectedUsers(updatedSelected);
      setSelectAll(true);
      if (allCheckboxRef.current) allCheckboxRef.current.indeterminate = false;
    }
  };

  const setSelectedUsersList = (allUserIds) => {
    if (selectedUsers.length > 0) {
      setSelectedUsers((preSelectedUsers) => {
        let newSelected;
        if (allUserIds.includes(preSelectedUsers.id)) {
          newSelected = preSelectedUsers.filter((id) =>
            allUserIds.includes(id)
          );
        } else {
          newSelected = [...preSelectedUsers, allUserIds];
        }
        return newSelected;
      });
    } else {
      setSelectedUsers(allUserIds);
    }
  };

  const removeSelectedFilterUsers = () => {
    setSelectedUsers(
      selectedUsers.filter(
        (item) => !filteredUsers.some((user) => user.id === item)
      )
    );
  };

  const handleSave = () => {
    if (prompt) {
      prompt.Users = selectedUsers;
    }

    const updatedUsers = [...selectedUsers, prompt?.CreatedById];
    onSaveSharedUser(updatedUsers);
  };

  const onInputSearchUserList = (value) => {
    if (value?.trim()) {
      const searchUserList = userList.filter((item) => {
        return (
          item.firstName.toLowerCase().includes(value.toLowerCase()) ||
          item.lastName.toLowerCase().includes(value.toLowerCase()) ||
          item.userName.toLowerCase().includes(value.toLowerCase())
        );
      });
      setFilteredUsers(searchUserList);

      // Update state based on current selection
      const selectedInSearch = searchUserList.filter((user) =>
        selectedUsers.includes(user.id)
      );
      updateSelectAllStateClearAll(
        searchUserList,
        selectedInSearch.map((user) => user.id)
      );
    } else {
      // Reset to show all users if search is cleared
      setFilteredUsers((prev) => userList);
      updateSelectAllStateClearAll(userList, selectedUsers);
    }
  };

  const updateSelectAllStateClearAll = (userList, currentSelected) => {
    const selectedRows = userList.filter((item) =>
      currentSelected.includes(item.id)
    );
    if (selectedRows.length === 0) {
      setSelectAll(false);
      if (allCheckboxRef.current) {
        allCheckboxRef.current.indeterminate = false;
      }
    } else if (selectedRows.length === userList.length) {
      setSelectAll(true);
      if (allCheckboxRef.current) {
        allCheckboxRef.current.indeterminate = false;
      }
    } else {
      setSelectAll(false);
      if (allCheckboxRef.current) {
        allCheckboxRef.current.indeterminate = true;
      }
    }
  };

  const renderWorkspaceApp = (user) => {
    return (
      <div className="applist-row" key={user.id}>
        <input
          type="checkbox"
          checked={selectedUsers.includes(user.id)}
          onChange={() => handleUserSelection(user.id)}
        />
        <div className="app-name-label">
          {user?.firstName + " " + user?.lastName + " (" + user?.userName + ")"}
        </div>
        <div className="app-category-label"></div>
      </div>
    );
  };

  return (
    <div className="modal-wrapper app-list show-modal-wrapper prompt-share-modal">
      <div className="modal-block border-radius-5">
        <div className="confirmation-content-wrapper ">
          <div className="modal-content">
            <div className="modal-header prompt-share-header">
              <h3>Share With</h3>
              <SearchByInput onInputSearch={onInputSearchUserList} />
            </div>
            <div className="modal-body ">
              <div className="applist-view">
                <div className="applist-row border-line">
                  <input
                    disabled={filteredUsers.length === 0}
                    type="checkbox"
                    ref={allCheckboxRef}
                    checked={selectAll}
                    onChange={handleSelectAll}
                  />
                  <div
                    className={
                      filteredUsers.length === 0
                        ? "app-name-label disabled-element"
                        : "app-name-label"
                    }
                  >
                    All {" (" + selectedUsers.length + ")"}
                  </div>
                </div>
                {filteredUsers?.length > 0 &&
                  filteredUsers.map((user, index) =>
                    renderWorkspaceApp(user, index)
                  )}
              </div>
            </div>
            <div className="popup-btn-container">
              <button
                type="button"
                className="cancel-btn"
                onClick={handelAppPopUp}
              >
                Cancel
              </button>
              <button
                type="button"
                className="genrate-btn"
                onClick={handleSave}
              >
                Share
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareUserList;
