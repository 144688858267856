import React, { useContext, useEffect, useRef, useState } from "react";
import PdfViewer from "../pdfViewer";
import PdfURLContext from "../../../../../../context/pdfURL/pdfURLContext";
import Additives from "../additives";
import TabLink from "../../../../promptManagement/views/_partials/tabLink";
import plusicon from "../../../../../../styles/images/mksapp/plus-icon-white.svg";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";

const ChatHistory = (props) => {
  const {
    isSkillChat,
    isFileType,
    isMKSChat,
    setSelectedAdditives,
    openPdfViewer,
    setOpenPdfViewer,
    selectedCitation,
    setIsClearChat,
    isChatVisible,
    resetAdditives,
    setResetAdditives,
    isMKSProcessing,
    isSkillProcessing,
    skill,
    pdfClass,
    setPdfClass,
  } = props;

  const { url, setUrl } = useContext(PdfURLContext);
  const [showTab, setShowTab] = useState(1);
  const [expandClass, setExpandClass] = useState(false);
  const [expandChecks, setExpandChecks] = useState(false);
  const [historyBox, setHistoryBox] = useState("");
  const chatRef = useRef(null);
  const toggleButtonRef = useRef(null);
  const newChatRef = useRef(null);
  const additiveRef = useRef(null);

  useEffect(() => {
    setShowTab(1);
  }, [skill]);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (!newChatRef.current.contains(e.target)) {
        setExpandClass(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleAdditiveClickOutside = (e) => {
      if (!additiveRef.current.contains(e.target)) {
        setExpandChecks(false);
      }
    };
    document.addEventListener("mousedown", handleAdditiveClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleAdditiveClickOutside);
    };
  }, []);

  const collapseHistory = () => {
    setHistoryBox(!historyBox);
  };

  const collapseCheck = () => {
    setExpandChecks(!expandChecks);
  };

  const collapsePdf = () => {
    setPdfClass(!pdfClass);
    setUrl(selectedCitation.url);
  };

  const collapseChat = () => {
    setExpandClass(!expandClass);
  };

  useEffect(() => {
    if (url === undefined || url === null) {
      setExpandClass(true);
      setPdfClass(true);
    } else {
      if (url !== "") {
        setPdfClass(false);
        setOpenPdfViewer(true);
      } else {
        setExpandClass(false);
        setPdfClass(false);
      }
    }
    if (!isSkillChat) setExpandChecks(false);
  }, [url, isSkillChat]);

  // Split additives array into two columns

  return (
    <>
      <div className="chat-right">
        <div
          className={
            isMKSProcessing || isSkillProcessing
              ? "toggle-areas disabled-div"
              : "toggle-areas"
          }
        >
          <div
            className={
              expandClass
                ? "expand-history-btn rotate"
                : "expand-history-btn" && expandChecks
                ? "expand-history-btn hide"
                : "expand-history-btn"
            }
            ref={toggleButtonRef}
            onClick={collapseChat}
            title="Chat History"
          />
          {isFileType && isSkillChat && !isMKSChat && (
            <div
              className={
                expandClass
                  ? "expand-checklist-btn hide"
                  : "expand-checklist-btn"
              }
              ref={toggleButtonRef}
              onClick={collapseCheck}
              title="Additives"
            />
          )}

          <div
            className={
              expandClass
                ? "chat-history hide"
                : "chat-history " && expandChecks
                ? "chat-history pdfhide"
                : "chat-history "
            }
            ref={toggleButtonRef}
          >
            {url && openPdfViewer && (
              <div
                className={
                  pdfClass ? "pdf-full-expand hide" : "pdf-full-expand "
                }
              >
                <div
                  className=" pdf-expand"
                  onClick={() => collapsePdf()}
                ></div>
                <div
                  className={
                    pdfClass ? "prompt-tabs w-tabs hide" : "prompt-tabs w-tabs "
                  }
                >
                  <div
                    className="tabs-menu h-flex head-wrapper w-tab-menu chattab-wrap"
                    role="tablist"
                  >
                    <TabLink
                      tabId="1"
                      href="#"
                      isSelected={showTab === 1}
                      onClick={() => setShowTab(1)}
                    >
                      Supporting Content
                    </TabLink>
                    {skill.ShowCitation && (
                      <TabLink
                        tabId="2"
                        href="#"
                        isSelected={showTab === 2}
                        onClick={() => setShowTab(2)}
                      >
                        Citation
                      </TabLink>
                    )}
                  </div>
                  <div className="w-tab-content">
                    {showTab === 1 && (
                      <>
                        <div className="inner-tab-content">
                          <div className="text-wrap">
                            <h3>{selectedCitation.filepath}</h3>
                            <p>
                              <ReactMarkdown
                                remarkPlugins={[remarkGfm]}
                                components={{
                                  ol: ({ children }) => (
                                    <ol
                                      style={{
                                        paddingLeft: "1.5rem",
                                        margin: "0",
                                      }}
                                    >
                                      {children}
                                    </ol>
                                  ),
                                  li: ({ children }) => (
                                    <li
                                      style={{
                                        whiteSpace: "normal",
                                        wordBreak: "break-word",
                                      }}
                                    >
                                      {children}
                                    </li>
                                  ),
                                }}
                                rehypePlugins={[rehypeRaw]}
                              >
                                {selectedCitation.content}
                              </ReactMarkdown>
                            </p>
                          </div>
                        </div>
                      </>
                    )}
                    {showTab === 2 && (
                      <>
                        <div className="pdfview overlap">
                          <PdfViewer pdfUrl={url} />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div
          ref={chatRef}
          className={expandClass ? "full-height" : "full-height-auto"}
        >
          <div
            ref={newChatRef}
            className={
              expandClass ? "chat-history-sec show" : "chat-history-sec"
            }
          >
            <div className="flex-row">
              <h4 className="h4">Chat History</h4>{" "}
              <div className="bookmark-icon"></div>
            </div>
            <div
              className={
                isChatVisible
                  ? "button new-chatbutton "
                  : "button-newchat-disabled"
              }
              disabled={!isChatVisible}
              onClick={() => {
                setIsClearChat(true);
                setOpenPdfViewer(false);
              }}
            >
              <img src={plusicon} alt="plusicon" className="plusimg" /> New Chat
            </div>
            <div className="recent-history">
              <div className="collapse-div">
                <div className="flex-start">
                  Today{" "}
                  <div
                    className={historyBox ? "down-arrow rotate" : "down-arrow"}
                    onClick={collapseHistory}
                  ></div>
                </div>
                <div
                  className={historyBox ? "history-box show" : "history-box"}
                >
                  <p>No history available</p>
                </div>
              </div>
              <div className="collapse-div">
                <div className="flex-start">
                  Previous 90 days <div className="down-arrow"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          ref={additiveRef}
          className={expandClass ? "full-height-auto" : "full-height"}
        >
          {isFileType && isSkillChat && !isMKSChat && (
            <Additives
              setSelectedAdditives={setSelectedAdditives}
              expandChecks={expandChecks}
              resetAdditives={resetAdditives}
              setResetAdditives={setResetAdditives}
            ></Additives>
          )}
        </div>
      </div>
    </>
  );
};

export default ChatHistory;
