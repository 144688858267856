import React, { useEffect, useState } from "react";

const SelectByScope = (props) => {
  const { selectedScope, onSearchByScope, searchSkillStoreScopeData } = props;
  const [selectScopeByUser, setSelectScopeByUser] = useState();

  useEffect(() => {
    setData();
  }, [selectedScope]);

  const setData = () => {
    if (
      selectedScope !== null &&
      selectedScope !== undefined &&
      selectedScope !== ""
    ) {
      setSelectScopeByUser(selectedScope);
    } else {
      setSelectScopeByUser("-1");
    }
  };

  const selectByScopeHandler = (e) => {
    const selectedVal = e.target.value;
    onSearchByScope(selectedVal);
  };

  return (
    <select
      className="skill-filter-field w-select"
      onChange={(e) => selectByScopeHandler(e)}
      value={selectScopeByUser}
    >
      <option value="-1">Select Scope</option>
      {searchSkillStoreScopeData?.map((item) => (
        <option key={item.Id} value={item.Id}>
          {item.Name}
        </option>
      ))}
    </select>
  );
};

export default SelectByScope;
