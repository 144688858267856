import React from "react";

const UserModal = ({ onClose }) => {
  return (
    <div className="modal">
      <div className="modal-content">
        <span className="close" onClick={onClose}>&times;</span>
        <h2 className="comment-header">Add New User</h2>
        
        <form>
          <input type="text" placeholder="Name" className="text-style" required />
          {/* <input type="text" placeholder="Username" required />
          <input type="text" placeholder="Role Type" required /> */}
          <button type="submit" className="genrate-btn">Add</button>
        </form>
      </div>
    </div>
  );
};

export default UserModal;
