import React from "react";

const SearchByScope = (props) => {

  const {onSearchByScope,searchSkillStoreScopeData}=props;

  const selectSkillSearchByScopeHandler=(e)=>{
    const selectedVal=e.target.value;
    onSearchByScope(selectedVal);
   }

  return (
    <select
      className="skill-filter-field w-select"
      onChange={(e)=>selectSkillSearchByScopeHandler(e)}

    >
      <option value="">Select Scope</option>
      {searchSkillStoreScopeData?.map((item) => (
        <option key={item.Id} value={item.Name}>
          {item.Name}
        </option>
      ))}
    </select>
  );
};

export default SearchByScope;
