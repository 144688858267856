import React from "react";
import { useLocation } from "react-router-dom/dist";
import { UserContext } from "../../../context/user/userContext";
import { useContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom/dist";
import { refreshToken } from "../../../services/authService";
export const RequireAuth = ({ children }) => {
  const location = useLocation();
  const [waiting, setWaiting] = useState(true);
  const [processing, setProcessing] = useState(-1);
  const { state, logout, getTokenDetails, getProfileAndPrivileges } =
    useContext(UserContext);
  const [renderChildren, shouldRenderChildren] = useState(false);
  const [isGettingNewToken, setIsGettingNewToken] = useState(false);

  useEffect(() => {
    const isAuthenticated = () => {
      const { token, isInValid, isExpired, isExpiring } = getTokenDetails();
      if (isExpired || isInValid) {
        logout();
      } else if (isExpiring) {
        if (isGettingNewToken === false) {
          setIsGettingNewToken(true);
          refreshToken(token).then((res) => {
            setIsGettingNewToken(false);
          });
        }
      } else {
        shouldRenderChildren(true);
        const isAuthenticated = state?.user?.isAuthenticated ?? false;
        if (isAuthenticated === false) {
          if (processing === -1) {
            setProcessing(0);
            getProfileAndPrivileges(token, () => {
              setWaiting(false);
              setProcessing(1);
            });
            setWaiting(true);
          }
        } else {
          setWaiting(false);
        }
      }
    };

    isAuthenticated();
  }, [
    state,
    getTokenDetails,
    getProfileAndPrivileges,
    renderChildren,
    processing,
    logout,
    isGettingNewToken,
  ]);
  if (waiting) return <React.Fragment></React.Fragment>;
  if (renderChildren) {
    return children;
  }
  return <Navigate to="/login" state={{ from: location }} />;
};
