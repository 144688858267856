import React from "react";
import { useLocation, Link } from "react-router-dom";
import  AppImages  from "../../../constants/images";
import DashboardMobileHeader from "../dashboardMobileHeader";

const DashboardNavBar = () => {
  const location = useLocation();
  let currentPath = location.pathname;
  if (currentPath.includes("skillstoredetail")) currentPath = "/skillstore";
  if (currentPath.includes("addprompt")) currentPath = "/promptmanagement";
  if (currentPath.includes("updatePrompt")) currentPath = "/promptmanagement";
  return (
    <div className="left-menu-nav-wrapper">
      <div className="mobile-header">
        <DashboardMobileHeader />
      </div>

      <Link
        to="/home"
        aria-current="page"
        data-title="Home"
        className={`left-menu-link w-inline-block ${
          currentPath === "/home" ? "w--current" : ""
        }`}
      >
        <img src={AppImages.HomeIcon} loading="lazy" alt="" className="menu-icon" />
        <div className="menu-link-text">Home</div>
      </Link>

      <Link
        to="/chat"
        data-title="Chat"
        className={`left-menu-link w-inline-block ${
          currentPath === "/chat" ? "w--current" : ""
        }`}
      >
        <img src={AppImages.ChatIcon} loading="lazy" alt="" className="menu-icon" />
        <div className="menu-link-text">Chat</div>
      </Link>

      <Link
        to="/myworkspace"
        data-title="My Workspace"
        className={`left-menu-link w-inline-block ${
          currentPath === "/myworkspace" ? "w--current" : ""
        }`}
      >
        <img
          src={AppImages.MyworkSpaceIcon}
          loading="lazy"
          alt=""
          className="menu-icon"
        />
        <div className="menu-link-text">My Workspace</div>
      </Link>

      <Link
        to="/skillstore"
        data-title="Skill Library"
        className={`left-menu-link w-inline-block ${
          currentPath === "/skillstore" ? "w--current" : ""
        }`}
      >
        <img src={AppImages.SkillStoreIcon} loading="lazy" alt="" className="menu-icon" />
        <div className="menu-link-text">Skill Library</div>
      </Link>

      <Link
        to="/promptmanagement"
        data-title="Prompt Management"
        className={`left-menu-link w-inline-block ${
          currentPath === "/promptmanagement" ? "w--current" : ""
        }`}
      >
        <img
          src={AppImages.PromptManagementICon}
          loading="lazy"
          alt=""
          className="menu-icon"
        />
        <div className="menu-link-text">Prompt Management</div>
      </Link>

      <Link
        to="/notificationhub"
        data-title="Notification Center"
        className={`left-menu-link w-inline-block ${
          currentPath === "/notificationhub" ? "w--current" : ""
        }`}
      >
        <img
          src={AppImages.NotificationHubIcon}
          loading="lazy"
          alt=""
          className="menu-icon"
        />
        <div className="menu-link-text">Notification Center</div>
      </Link>

      <Link
        to="/aboutmks"
        data-title="About MKS.ai"
        className={`left-menu-link w-inline-block ${
          currentPath === "/aboutmks" ? "w--current" : ""
        }`}
      >
        <img src={AppImages.AboutMKSIcon} loading="lazy" alt="" className="menu-icon" />
        <div className="menu-link-text">About MKS.ai</div>
      </Link>

      <Link
        to="/guidance"
        data-title="Guidance & Guidelines"
        className={`left-menu-link w-inline-block ${
          currentPath === "/guidance" ? "w--current" : ""
        }`}
      >
        <img
          src={AppImages.GuidanceAndGuidelineIcon}
          loading="lazy"
          alt=""
          className="menu-icon"
        />
        <div className="menu-link-text">Guidance &amp; Guidelines</div>
      </Link>

      <Link
        to="/feedback"
        data-title="Feedback"
        className={`left-menu-link w-inline-block ${
          currentPath === "/feedback" ? "w--current" : ""
        }`}
      >
        <img src={AppImages.FeebackIcon} loading="lazy" alt="" className="menu-icon" />
        <div className="menu-link-text">Feedback</div>
      </Link>

      <Link
        to="/help"
        data-title="Help"
        className={`left-menu-link w-inline-block ${
          currentPath === "/help" ? "w--current" : ""
        }`}
      >
        <img src={AppImages.HelpIcon} loading="lazy" alt="" className="menu-icon" />
        <div className="menu-link-text">Help</div>
      </Link>

      <Link
        to="/settings"
        data-title="Settings"
        className={`left-menu-link w-inline-block ${
          currentPath === "/settings" ? "w--current" : ""
        }`}
      >
        <img src={AppImages.SettingsIcon} loading="lazy" alt="" className="menu-icon" />
        <div className="menu-link-text">Settings</div>
      </Link>
      <div className="hide-on-desk">
        <Link className="left-menu-link w-inline-block" to="/logout">
          {" "}
          <img src={AppImages.Logouticon} loading="lazy" alt="" className="menu-icon" />
          <div className="menu-link-text">Logout</div>
        </Link>
      </div>
    </div>
  );
};

export default DashboardNavBar;
