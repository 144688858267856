import React from "react";
import { Link } from "react-router-dom";

const Tile = ({
  redirectionLink,
  imgWidth,
  imgAltValue = "",
  imgPath,
  heading,
  subheading,
  IsNewTab,
  cardId,
  token,
}) => {
  return IsNewTab === false ? (
    <Link
      id={cardId}
      to={heading === "Survey" ? redirectionLink : redirectionLink + token}
      target="_parent"
      className="app-link-tile w-inline-block"
      rel="noopener noreferrer"
    >
      <div className="app-logo-icon">
        <img width={imgWidth} loading="lazy" alt={imgAltValue} src={imgPath} />
      </div>
      <div className="app-name-label">{heading}</div>
      <div className="app-category-label">{subheading}</div>
    </Link>
  ) : (
    <Link
      id={cardId}
      to={redirectionLink}
      target="_blank"
      className="app-link-tile w-inline-block"
      rel="noopener noreferrer"
    >
      <div className="app-logo-icon">
        <img width={imgWidth} loading="lazy" alt={imgAltValue} src={imgPath} />
      </div>
      <div className="app-name-label">{heading}</div>
      <div className="app-category-label">{subheading}</div>
    </Link>
  );
};

export default Tile;
