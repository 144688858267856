import React, { useEffect, useContext, useState, useLayoutEffect } from "react";
import AppImages from "../../../../../../../constants/images";
import PdfURLContext from "../../../../../../../context/pdfURL/pdfURLContext";
import { toast } from "react-toastify";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import $ from "jquery";

import UserImage from "../../../../../../common/userImage/index.js";
import { UserContext } from "../../../../../../../context/user/userContext.js";

const TextChatbot = ({
  showChatLoader,
  messages,
  initials,
  displayLastCitations,
  lastDisplayMessage,
  setOpenPdfViewer,
  setSelectedCitation,
  isTyping,
  setIsMKSProcessing,
  setIsSkillProcessing,
  pdfClass,
  setPdfClass,
  showFollowupQuestion,
  disableFolloupButton,
  handleSendMessage,
}) => {
  const { url, setUrl } = useContext(PdfURLContext);
  const { state } = useContext(UserContext);
  const imagebytes = state?.user?.userProfile?.userImage;
  const imagetype = state?.user?.userProfile?.userImagemimeType;
  const [isTextLoading, setIsTextLoading] = useState(false);

  useLayoutEffect(() => {
    if (lastDisplayMessage.length > 0) {
      setIsTextLoading(false);
    } else {
      setIsTextLoading(true);
    }
  }, [lastDisplayMessage.length]);

  const displayAllMessage = (msg) => {
    let arrayList = [];

    return (
      <>
        {ReplaceDocsComponent(msg.text, msg.citations, arrayList)}
        {arrayList
          .sort((a, b) => a - b)
          .map((index) => {
            const item = msg.citations[parseInt(index) - 1];
            if (item !== undefined) {
              return (
                <li>
                  <span
                    className="linkclass"
                    onClick={() => handleOpenPdfViewer(item)}
                    key={index}
                  >
                    {index} - {item.filepath}
                  </span>
                </li>
              );
            }
          })}
      </>
    );
  };
  const handleOpenPdfViewer = (item) => {
    setUrl("");
    setPdfClass(!pdfClass);
    const MKS_APP_VIEW_DOCUMENT_API_PARAMS =
      process.env.REACT_APP_VIEW_DOCUMENT_API_PARAMS;
    let baseUrl = process.env.REACT_APP_VIEW_DOCUMENT_API_URL;
    if (item.url.indexOf(baseUrl) === -1) {
      item.url = baseUrl + item.url;
    }
    if (item.url.indexOf(MKS_APP_VIEW_DOCUMENT_API_PARAMS) === -1) {
      item.url = item.url + MKS_APP_VIEW_DOCUMENT_API_PARAMS;
    }
    setOpenPdfViewer(true);
    setSelectedCitation(item);
    setUrl(item.url);
  };
  const displayLastMessage = () => {
    let arrayList = [];
    return (
      <>
        {displayLastCitations.length === 0 &&
          ReplaceDocsComponent(lastDisplayMessage, [], arrayList)}
        {displayLastCitations.length > 0 &&
          ReplaceDocsComponent(
            lastDisplayMessage,
            displayLastCitations,
            arrayList
          )}

        {arrayList
          .sort((a, b) => a - b)
          .map((index) => {
            const item = displayLastCitations[parseInt(index) - 1];
            if (item !== undefined) {
              return (
                <li>
                  <span
                    className="linkclass"
                    onClick={() => handleOpenPdfViewer(item)}
                    key={index}
                  >
                    {index} - {item.filepath}
                  </span>
                </li>
              );
            }
          })}
      </>
    );
  };

  const ReplaceDocsComponent = (text, citations, arrayList) => {
    const replacedText = replaceDocs(text, citations, arrayList);
    return (
      <ReactMarkdown
        remarkPlugins={[remarkGfm]}
        components={{
          ol: ({ children }) => (
            <ol style={{ paddingLeft: "1.5rem", margin: "0" }}>{children}</ol>
          ),
          li: ({ children }) => (
            <li style={{ whiteSpace: "normal", wordBreak: "break-word" }}>
              {children}
            </li>
          ),
        }}
        rehypePlugins={[rehypeRaw]}
      >
        {replacedText}
      </ReactMarkdown>
    );
  };

  useEffect(() => {
    $(document).on("click", ".linkclassText", function () {
      const index = $(this).text() - 1;
      const citation = messages.flatMap((msg) => msg.citations || [])[index];
      if (citation) {
        handleOpenPdfViewer(citation);
      }
    });

    return () => {
      $(document).off("click", ".linkclassText");
    };
  }, [messages]);

  useEffect(() => {
    $(document).on("click", ".linkclassText", function () {
      const index = $(this).text() - 1;
      const citation = displayLastCitations?.flatMap((msg) => msg || [])[index];
      if (citation) {
        handleOpenPdfViewer(citation);
      }
    });
    return () => {
      $(document).off("click", ".linkclassText");
    };
  }, [displayLastCitations?.length]);

  const replaceDocs = (text, citations, arrayList) => {
    const docPattern = /\[doc(\d+)\]/g;
    return text.replace(docPattern, (match, p1) => {
      let findCitation = citations[parseInt(p1) - 1];
      if (!arrayList.includes(p1)) {
        arrayList.push(p1);
      }

      if (findCitation) {
        return `<span className="linkclassText linkbox">${p1}</span>`;
      }
    });
  };

  const getCopyButton = (msgText) => {
    return (
      <button
        className="copy-clipboard"
        aria-label="copy to clipboard"
        data-title="Copy"
        onClick={() => {
          const textToCopy = msgText;
          // Check if Clipboard API is supported
          if (navigator.clipboard && navigator.clipboard.writeText) {
            navigator.clipboard
              .writeText(textToCopy)
              .then(() => {
                toast.success("Copied to clipboard successfully");
              })
              .catch((err) => {
                console.error("Clipboard API failed:", err);
                fallbackCopyText(textToCopy);
              });
          } else {
            // Use fallback for non-HTTPS or unsupported environments
            fallbackCopyText(textToCopy);
          }
        }}
      ></button>
    );
  };

  const enableTab = () => {
    setIsMKSProcessing(false);
    setIsSkillProcessing(false);
  };

  const fallbackCopyText = (text) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;

    // Style the textarea so it won't interfere visually
    textArea.style.position = "fixed"; // Prevent scrolling to bottom of the page
    textArea.style.opacity = "0";
    document.body.appendChild(textArea);

    textArea.focus();
    textArea.select();

    try {
      const successful = document.execCommand("copy");
      if (successful) {
        toast.success("Copied to clipboard successfully");
      } else {
        toast.error("Failed to copy");
      }
    } catch (err) {
      console.error(" copy failed:", err);
      toast.error(" failed to copy!");
    }

    document.body.removeChild(textArea);
  };
  
  const setSelectedQuestion = (question) => {
    handleSendMessage(question);
  };

  const getFollowupQuestions = (msg) => {
    return (
      <>
        {msg?.followupQuestion?.length > 0 && showFollowupQuestion ? (
          <div className="follow-up-section">
            <div className="chat-line">Follow up Questions :</div>
            <div className="suggest-line-wrapper">
              {msg?.followupQuestion?.map((quest, index) => (
                <div
                  key={index}
                  className={
                    isTextLoading
                      ? "disabled-element suggest-line-disabled"
                      : "suggest-line"
                  }
                >
                  <div
                    className={isTextLoading ? "suggest-line-disabled-div" : ""}
                    onClick={() => setSelectedQuestion(quest)}
                  >
                    {quest}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          ""
        )}
      </>
    );
  };

  const getMarkDownText = (text) => {
    return (
      <ReactMarkdown
        remarkPlugins={[remarkGfm]}
        components={{
          ol: ({ children }) => (
            <ol style={{ paddingLeft: "1.5rem", margin: "0" }}>{children}</ol>
          ),
          li: ({ children }) => (
            <li
              style={{
                whiteSpace: "normal",
                wordBreak: "break-word",
              }}
            >
              {children}
            </li>
          ),
        }}
        rehypePlugins={[rehypeRaw]}
      >
        {text}
      </ReactMarkdown>
    );
  };

  return (
    <>
      {messages.map((msg, index) =>
        msg.type === "user" ? (
          <div className="chat-msg user" key={index + " _" + 2}>
            <div className="user-profile-menu w-inline-block">
              <div className="user-profile-initial  auto-cursor">
                <UserImage
                  userImageBytes={imagebytes}
                  userImageMimeType={imagetype}
                  userInitials={initials}
                ></UserImage>
              </div>
            </div>

            <div className="chat-icon-text">
              <div className="msg-text">
                <div style={{ whiteSpace: "pre-line" }}>{msg.text}</div>
              </div>
            </div>

            {msg.isErrorOccured === true && (
              <div className="not-sent-icon">!</div>
            )}
          </div>
        ) : (
          <div className="chat-msg reply" key={index + " _" + 1}>
            <div className="chat-user-icon">
              <img
                src={msg.imageURL}
                loading="lazy"
                alt=""
                className="chat-user-img"
              />
            </div>
            <div className="chat-icon-text">
              <div className="msg-text">
                <div className="chat-text">
                  {messages.length - 1 === index && isTextLoading && (
                    <img src={AppImages.chatLoader} alt="" />
                  )}
                  <div style={{ whiteSpace: "pre-line" }}>
                    {messages.length - 1 === index
                      ? displayLastMessage()
                      : msg.text === "loader"
                      ? ""
                      : displayAllMessage(msg)}
                    {!isTextLoading &&
                      isTyping === true &&
                      messages.length - 1 === index && (
                        <span
                          style={{
                            opacity: isTyping ? 1 : 0,
                            transition: "opacity 0.6s",
                          }}
                        >
                          |
                        </span>
                      )}

                    {messages.length - 1 === index
                      ? ""
                      : getCopyButton(msg.text)}

                    {messages.length - 1 === index
                      ? ""
                      : getFollowupQuestions(msg)}

                    {!isTextLoading && getCopyButton(msg.text)}
                    {!isTextLoading && enableTab()}
                  </div>
                </div>

                {messages.length - 1 === index &&
                  !isTextLoading &&
                  getFollowupQuestions(msg)}
              </div>
            </div>
          </div>
        )
      )}
    </>
  );
};

export default TextChatbot;
