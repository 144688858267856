import React, { useContext } from "react";
import AppImages from "../../../../../../../constants/images";
import { getMultiFileClassByExtension } from "../../../../shared/fileExtension";
import UserImage from "../../../../../../common/userImage/index.js";
import { UserContext } from "../../../../../../../context/user/userContext.js";
import OtherAdditivesTable from "../otherAdditivesTable/index.js";
import BlackBoxAdditiveTable from "../blackBoxAdditiveTable/index.js";

const FileChatbot = ({
  showChatLoader,
  messages,
  initials,
  lastDisplayMessage,
  isTyping,
  blackBoxAdditives,
  isBlackBoxAdded,
  setIsMKSProcessing,
  setIsSkillProcessing,
}) => {
  const { state } = useContext(UserContext);
  const imagebytes = state?.user?.userProfile?.userImage;
  const imagetype = state?.user?.userProfile?.userImagemimeType;

  const handleDownloadFile = (file) => {
    const url = URL.createObjectURL(file);
    const link = document.createElement("a");
    link.href = url;
    link.download = file.name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const displayLastMessage = () => {
    return <>{ShowLastDisplayMessage(lastDisplayMessage)}</>;
  };

  const ShowAllMessages = (msg) => {
    return (
      <>
        {msg.isBlackBoxAdded && (
          <BlackBoxAdditiveTable
            replacedText={msg.text}
            lastDisplayMessage={msg.uploadedFileDetails}
            setIsMKSProcessing={setIsMKSProcessing}
            setIsSkillProcessing={setIsSkillProcessing}
          ></BlackBoxAdditiveTable>
        )}
        {!msg.isBlackBoxAdded && (
          <OtherAdditivesTable uploadedFileDetails={msg.uploadedFileDetails} />
        )}
      </>
    );
  };

  const ShowLastDisplayMessage = () => {
    return (
      <>
        {isBlackBoxAdded && !isTyping && (
          <BlackBoxAdditiveTable
            replacedText={blackBoxAdditives}
            lastDisplayMessage={lastDisplayMessage}
            setIsMKSProcessing={setIsMKSProcessing}
            setIsSkillProcessing={setIsSkillProcessing}
          ></BlackBoxAdditiveTable>
        )}
        {!isBlackBoxAdded && lastDisplayMessage !== "" && !isTyping && (
          <OtherAdditivesTable
            isTyping={isTyping}
            uploadedFileDetails={lastDisplayMessage}
            setIsMKSProcessing={setIsMKSProcessing}
            setIsSkillProcessing={setIsSkillProcessing}
          />
        )}
      </>
    );
  };

  return (
    <>
      {messages.map((msg, index) =>
        msg.type === "user" ? (
          <div className="chat-msg user" key={index + " _" + 2}>
            <div className="user-profile-menu w-inline-block">
              <div className="user-profile-initial">
                <UserImage
                  userImageBytes={imagebytes}
                  userImageMimeType={imagetype}
                  userInitials={initials}
                ></UserImage>
              </div>
            </div>

            <div className="chat-icon-text">
              <div className="msg-text">
                <p className="chat-text">{msg.text}</p>
                {msg.files.map((file) => {
                  const extensionClassName = getMultiFileClassByExtension(
                    file.name
                  );
                  return (
                    <div className="multifile">
                      <div className="upload-drag">
                        <div
                          title="Click to download"
                          onClick={() => handleDownloadFile(file)}
                          className={`${extensionClassName}-icon-list chat-file-dowbload`}
                        >
                          {file.name}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        ) : (
          <div className="chat-msg reply" key={index + " _" + 1}>
            <div className="chat-user-icon">
              <img
                src={msg.imageURL}
                loading="lazy"
                alt=""
                className="chat-user-img"
              />
            </div>
            <div className="chat-icon-text">
              <div className="msg-text">
                <div className="chat-text">
                  {messages.length - 1 === index && showChatLoader && (
                    <img src={AppImages.chatLoader} alt="" />
                  )}
                  <div style={{ whiteSpace: "pre-line" }}>
                    {messages.length - 1 === index
                      ? displayLastMessage()
                      : msg.text === "loader"
                      ? ""
                      : ShowAllMessages(msg)}
                    {!showChatLoader &&
                      isTyping === true &&
                      messages.length - 1 === index && (
                        <span
                          style={{
                            opacity: isTyping ? 1 : 0,
                            transition: "opacity 0.6s",
                          }}
                        >
                          |
                        </span>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      )}
    </>
  );
};

export default FileChatbot;
