import React from "react";
import SearchByInput from "../../../../../common/searchByInput";
import SearchByDepartment from "../../../../../common/searchByDepartment";
import SearchByFunction from "../../../../../common/searchByFunction";

const PromptManagementFilterSection = (props) => {
  const {
    onInputSearchPromptManagement,
    onSearchByPromptDepartment,
    searchDepartmentData,
    onSearchByPromptFunction,
    searchFunctionData,
  } = props;
  
  return (
    <div className="skill-filter-wrapper w-form">
      <div className="skill-filter-form">
        <SearchByInput onInputSearch={onInputSearchPromptManagement} />
        <SearchByDepartment
          onSearchDepartmentSelection={onSearchByPromptDepartment}
          searchDepartmentData={searchDepartmentData}
        />
        <SearchByFunction
          onSearchFunctionSelection={onSearchByPromptFunction}
          searchFunctionData={searchFunctionData}
        />
      </div>
    </div>
  );
};

export default PromptManagementFilterSection;
