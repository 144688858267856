import axiosApiInstanceMain from "../../../../api/axiosApiClient";
import { ExtractCurlParameters } from "../../promptManagement/shared/curlConverter";

const MKS_REACT_APP_API_URL = process.env.REACT_APP_MKS_API_URL;
const BASE_MKSPROMPTMANAGEMENT_API_URL = process.env.REACT_APP_MKS_API_URL;

export const getAdditives = async () => {
  try {
    const response = await axiosApiInstanceMain.get(
      `${MKS_REACT_APP_API_URL}common/GetAdditives`
    );
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(`Unexpected response status: ${response.status}`);
    }
  } catch (error) {
    console.error("Error fetching additives:", error);
    throw error; // Optionally re-throw the error to be handled elsewhere
  }
};

export const getChatPromptManagementStoreData = async (skillStoreId) => {
  try {
    const response = await axiosApiInstanceMain.get(
      `${BASE_MKSPROMPTMANAGEMENT_API_URL}prompt/GetChatPromptUserInput?SkillStoreId=${skillStoreId}`
    );

    if (response.status === 200) {
      response.data.map((skill) => {
        let params = ExtractCurlParameters(
          skill.ChatbotConfiguration.CurlRequestString
        );
        skill.aiUrl = params.url;
        skill.headers = params.headers;
        skill.apiKey = "";
        skill.messageBody = params.body;
        skill.userMessage = skill.ChatbotConfiguration.UserMessageFormat;
        skill.AssistantMessageFormat =
          skill.ChatbotConfiguration.AssistantMessageFormat;
        return skill;
      });
      return response.data;
    } else {
      console.error("Unexpected response status:", response.status);
      return null;
    }
  } catch (error) {
    console.error("Error fetching prompt management data:", error);
    return null;
  }
};
