import { BOTAPIError, InternalAPIError } from "./apiErrors";
import showMessage from "../components/common/message";

export const handleApiError = (error) => {
  if (error instanceof BOTAPIError) {
    showMessage(
      (error !== undefined && error !== null ? error : "") +
        " BOT API not responding",
      -2
    );
  } else if (error instanceof InternalAPIError) {
    showMessage("Internal API not responding", -2);
  } else {
    showMessage("API not responding", -2);
  }
};
