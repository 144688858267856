import React, { useContext, useEffect, useState } from "react";
import { checkSkillsAvailability } from "../shared/defaultChatBotConfig/defaultConfigService";
import { UserContext } from "../../../context/user/userContext";
import MksChatContext from "./mksContext";

export const MksChatProvider = ({ children }) => {
  const [isMksChatEnable, setIsMksChatEnable] = useState(false);
  const [isSkillChatEnable, setIsSkillChatEnable] = useState(false);
  const { state, getTokenDetails } = useContext(UserContext);

  useEffect(() => {
    const tokendetails = getTokenDetails();
    if (tokendetails.token !== null) checkSkills();
  }, [state]);

  const checkSkills = () => {
    checkSkillsAvailability().then((data) => {
      const mksSkill = data.filter((x) => x.ISDefault === true);
      if (mksSkill?.length > 0) {
        setIsMksChatEnable(mksSkill[0]?.IsActive);
      }
      const restSkill = data.filter(
        (x) => x.ISDefault === false && x.IsActive === true
      );
      if (restSkill?.length > 0) setIsSkillChatEnable(true);
    });
  };

  return (
    <MksChatContext.Provider value={{ isMksChatEnable, isSkillChatEnable }}>
      {children}
    </MksChatContext.Provider>
  );
};
