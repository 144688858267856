import axiosApiInstance from "../../../../api/axiosApiClient";
import { InternalAPIError } from "../../../../utils/apiErrors";
import { handleApiError } from "../../../../utils/globalErrorHandler";
const BASE_AUTH_API_URL = process.env.REACT_APP_AUTHAPI_URL;

export const getWorkspaceCardsData = async () => {
  try {
    const response = await axiosApiInstance.get(
      `${BASE_AUTH_API_URL}account/GetUserDashboardCards`
    );
    if (response.status === 200) {
      return response.data;
    } else {
      handleApiError(new InternalAPIError());
    }
  } catch (error) {
    handleApiError(new InternalAPIError());
  }
};

export const changeWorkspaceCardOrderData = async (body) => {
  try {
    let url = `${BASE_AUTH_API_URL}usermanagement/ChangeDashboardCardOrder`;
    const response = await axiosApiInstance.patch(url, body);
    return await response.data;
  } catch (error) {
    handleApiError(new InternalAPIError());
  }
};

export const enableDashboardCard = async (body) => {
  try {
    let url = `${BASE_AUTH_API_URL}usermanagement/EnableDashboardCard`;
    const response = await axiosApiInstance.patch(url, body);
    return await response.data;
  } catch (error) {
    handleApiError(new InternalAPIError());
  }
};