import axios from "axios";
import { refreshToken } from "../services/authService";
import { getStorageToken } from "../services/tokenService";

const axiosApiInstance = axios.create();

axiosApiInstance.interceptors.request.use(
  async (config) => {   
    const token = getStorageToken();
    config.headers = {
      Authorization:
        token === null
          ? "ZDNMbmN6TG05eVp5OHlNREF4THpBMEwzaHRiR1J6YVdj"
          : `Bearer ${token}`,
      // Authorization: "d3LnczLm9yZy8yMDAxLzA0L3htbGRzaWc",
      Accept: "*/*",
      "Content-Type": "application/json",
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Response interceptor for API calls
axiosApiInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.response?.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;
      const access_token = await refreshToken();
      axios.defaults.headers.common["Authorization"] = `Bearer ${access_token}`;
      return axiosApiInstance(originalRequest);
    }
    return Promise.reject(error);
  }
);

export default axiosApiInstance;
