const FeedbackList = (props) => {
  const { feedbackList, showFullFeedbackPopUp } = props;
  
  return (
    <div className="feedback-left-section">
      {feedbackList && feedbackList.map((feedback, index) => (
        <div className="feedbacktext" key={index}>
          {feedback?.FeedbackDescription?.length > 64 
            ? <>
                {feedback?.FeedbackDescription?.slice(0, 64)}...
                <span className="read-more-text" onClick={() => showFullFeedbackPopUp(feedback?.ID)}>
                  Read more
                </span>
              </>
            : feedback?.FeedbackDescription
          }
        </div>
      ))}
    </div>
  );
};

export default FeedbackList;
