import React, { useState, useEffect,useContext } from "react";
import { Link } from "react-router-dom";
import {
  getNotificationsData,
  updateNotificationReadStatusById,
} from "../../../services/notificationHubService";
import { getInitials } from "../../../../../../services/commonService";
import NotificationDetail from "../notificationDetail";
import loaderContext from "../../../../../../context/loader/loaderContext";
import UserImage from "../../../../../common/userImage/index";


const NotificationList = () => {
  const [selectedNotification, setSelectedNotification] = useState(false);
  const [notificationData, setNotificationData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { updateLoaderStatus } = useContext(loaderContext);
  const [firstNotification,setFirstNotification]=useState(null);

  useEffect(() => {
    const getDataOfNotification = async () => {
      setIsLoading(true);
      updateLoaderStatus(true);
      const data = await getNotificationsData();
      if (window.matchMedia("(min-width: 768px)").matches) {
        if (data && data.length > 0) {
          setFirstNotification(data[0]);
        }
      }
      setNotificationData(data);     
      setIsLoading(false); 
      updateLoaderStatus(false);
    };

    getDataOfNotification();
  }, []);

  useEffect(()=>{
    setSelectedNotification(firstNotification);
  },[firstNotification])

  const handleNotification = async (rNotification) => {
    if (rNotification.IsRead === false) {
      const response = await updateNotificationReadStatusById(rNotification.Id);
    }
    rNotification.IsRead = true;
    setSelectedNotification(rNotification);
  };

  const convertToCustomFormat = (datetimeString) => {
    // Remove "PM" or "AM" for 24-hour format compatibility
    const sanitizedString = datetimeString?.replace(" PM", "")?.replace(" AM", "");
    const date = new Date(sanitizedString);
   
    if (isNaN(date)) {
      return ""; // Handle invalid date
    }
   
    // Format the date
      const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const monthShort = months[date.getMonth()];
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Month as 2-digit number
    const day = String(date.getDate()).padStart(2, "0"); // Day as 2-digit number
    const year = date.getFullYear();
   
    return `${monthShort} ${day}/${month}/${year}`;
  };

  const hideDetailPage=()=>{
    setSelectedNotification(!selectedNotification);  
  }

  return (
    <>
      <div className="section-wrapper ">
        <div className="flex-content">
          <div className={selectedNotification ? "left-info-section mobile-hide" : "left-info-section"}>
            {notificationData &&
              notificationData.map((notification, index) => (
                <div
                  className={`${
                    selectedNotification !== undefined &&
                    selectedNotification !== null && (selectedNotification.Id === notification.Id) ? "user-breif selected" : "user-breif"
                  } ${notification.IsRead === false ? "unread-notif" : ""}`}
                  onClick={() => handleNotification(notification)}
                  key={index}
                >
                  <div className="user-name-box user-profile-initial">
                    <UserImage userImageBytes={notification.FromUserImage} userImageMimeType={notification.FromUserImageImageType} userInitials={getInitials(
                        notification.FromFirstName +
                          " " +
                          notification.FromLastName
                      )}></UserImage>
                  </div>
                  <div className="user-text">
                    <h3>
                      {notification.FromFirstName} {notification.FromLastName}{" "}
                      Shared Prompt with you
                    </h3>
                    <div className="small-text">
                      {convertToCustomFormat(notification.SharedOn)}
                    </div>
                  </div>
                </div>
              ))}              
          </div>
          {!isLoading && (notificationData === undefined || notificationData?.length === 0) && (
                <div>No Notification(s) Available</div>
              )}
          {selectedNotification &&
             (
              <NotificationDetail
                notification={selectedNotification}
                hideDetailPage={hideDetailPage}
              ></NotificationDetail>
            )}

            
        </div>
      </div>
    </>
  );
};

export default NotificationList;
