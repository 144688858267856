import React, { useState } from "react";
import showMessage from "../../../../../common/message/index";

const PrivateSharedPopup = (props) => {
  const { handleSavePromptStatus, handleClosePopup } = props;
  const [isprivate, setIsPrivate] = useState(false);
  const [isShared, setIsShared] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const savePromtStatus = () => {
    if (isprivate === false && isShared === false) {
      showMessage(
        "Please select option for prompt status private or shared",
        -2
      );
    } else {
      setIsLoading(true);
      handleSavePromptStatus(isprivate ? false : true);
    }
  };

  const closePopup = () => {
    handleClosePopup();
  };

  return (
    <div className="modal-wrapper show-modal-wrapper ">
      <div className="modal-block border-radius-5">
        <div className="confirmation-content-wrapper">
          <div className="modal-content">
            <div className="modal-header">
              <h2>Save Your Prompt</h2>
            </div>
            <div className="modal-body">
              <p>
                You're almost there ! Choose how you'd like to publish your
                prompt:
              </p>
              <div className="flex-block-modal">
                <label>
                  <input
                    name="radioGroup"
                    type="radio"
                    onChange={() => {
                      setIsPrivate(true);
                      setIsShared(false);
                    }}
                    className="prompt-radio"
                  />
                  Private
                </label>
                <label>
                  <input
                    name="radioGroup"
                    type="radio"
                    onChange={() => {
                      setIsPrivate(false);
                      setIsShared(true);
                    }}
                    className="prompt-radio"
                  />
                  Public
                </label>
              </div>
              {isprivate && (
                <p>
                  Your saved prompt will be visible only to you. you can share
                  it to the shared prompt library
                </p>
              )}
              {isShared && (
                <p>Your saved prompt will be visible to everyone.</p>
              )}
            </div>
            <div className="popup-btn-container">
              <button
                type="button"
                onClick={() => closePopup()}
                className="cancel-btn"
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={() => savePromtStatus()}
                className="genrate-btn"
              >
                <div>
                  {isLoading ? (
                    <div className="btn-spinner">
                      <span className="loadspinner"></span> Loading...
                    </div>
                  ) : isprivate ? (
                    "Save Prompt to Private"
                  ) : isShared ? (
                    "Save Prompt to Shared"
                  ) : (
                    "Save Prompt"
                  )}
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivateSharedPopup;
