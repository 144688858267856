import React from 'react';

const TabLink = ({ tabId, href, isSelected, children, onClick }) => {

  return (
    <div
      className={`prompt-tabs-link w-inline-block w-tab-link ${isSelected ? "w--current" : ""}`}
      aria-selected={isSelected ? "true" : "false"}
      onClick={onClick}
    >
      <div>{children}</div>
    </div>
  )
}

export default React.memo(TabLink);
