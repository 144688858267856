import React from "react";
import Skills from "./views/_partials/skills";

const SkillStore = () => {
  return (
    <div className="dashboard-wrapper">
      <div className="section-wrapper">
        <Skills/>
      </div>
    </div>
  );
};

export default SkillStore;
