import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { CreateSkillStore } from "../../../services/skillStoreService";
import { useNavigate } from "react-router-dom";
import GenericDropdown from "../../../../../common/genericDropdown";
const BOTConfiguration = (props) => {
  const {
    skillStoreForm,
    updateSkillStoreForm,
    handleUpdateSkill,
    validateFields,
    setShowBotConfiguration,
    setIsBackButtonClicked
  } = props;
  const [initialFormState, setInitialFormState] = useState();
  const isInitialized = useRef(false);

  const [validationMessage, setValidationMessage] = useState("");
  const navigate = useNavigate();
  const [errors, setErrors] = useState([]);
  const lastMessageCountRef = useRef(null);
  const inputRef = useRef(null);
  const chatGPTVersionRef = useRef(null);
  const chatbotUIViewRef = useRef(null);
  const curlRequestStringRef = useRef(null);
  const userAssistantMessageFormatRef = useRef(null);

  // useEffect(() => {
  //   if (handleUpdateSkill === undefined) {
  //     updateSkillStoreForm(
  //       "OutputType",
  //       skillStoreForm.InputType === "text"
  //         ? "Text"
  //         : skillStoreForm.InputType === ""
  //         ? ""
  //         : "Excel"
  //     );
  //     updateSkillStoreForm(
  //       "FileType",
  //       skillStoreForm.InputType === "file" ? "PDF" : ""
  //     );
  //   }
  // }, [skillStoreForm.InputType]);

  useEffect(() => {
    if (!isInitialized.current && skillStoreForm && skillStoreForm.Name) {
      setInitialFormState(JSON.parse(JSON.stringify(skillStoreForm)));
      isInitialized.current = true;
    }
  }, [skillStoreForm]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    updateSkillStoreForm(name, type === "checkbox" ? checked : value);
    if (name === "InputType") {
      inputRef.current.classList.remove("border-red");
    }
    if (name === "ChatGPTVersion") {
      chatGPTVersionRef.current.classList.remove("border-red");
    }
    // if (name === "ChatbotUIView") {
    //   chatbotUIViewRef.current.classList.remove("border-red");
    // }
    if (name === "UserAssistantMessageFormat") {
      userAssistantMessageFormatRef.current.classList.remove("border-red");
    }
  };

  const handleLastMessageCountChange = (e) => {
    const value = e.target.value;

    // Check if value exceeds 15 and set message
    if (value !== "" && Number(value) > 15) {
      // setValidationMessage("Maximum 15 value is allowed");
      lastMessageCountRef.current.focus();
      lastMessageCountRef.current.classList.add("border-red");
      toast.warn("Max value 15 is allowed");
    } else {
      setValidationMessage(""); // Clear message if valid
      updateSkillStoreForm("LastMessagesCount", value);
      lastMessageCountRef.current.classList.remove("border-red");
    }
  };

  const handleCurlChange = (e) => {
    const curlText = e.target.value;
    updateSkillStoreForm("CurlRequestString", curlText);
    updateSkillStoreForm("DynamicProperties", extractVariables(curlText));
    curlRequestStringRef.current.classList.remove("border-red");
    setErrors([]);
  };

  const extractVariables = (text) => {
    const regex = /\$([a-zA-Z_][a-zA-Z0-9_]*)/g;
    const matches = new Set();
    let match;
    while ((match = regex.exec(text)) !== null) {
      matches.add(match[0]); // Extract full match including '$'
    }
    return Array.from(matches).map((key) => ({ key, Value: "" }));
  };

  const handleVariableChange = (index, field, newValue) => {
    const updatedVariables = [...skillStoreForm.DynamicProperties];

    if (index >= 0 && index < updatedVariables.length) {
      updatedVariables[index] = { ...updatedVariables[index] };
      updatedVariables[index][field] = newValue;

      updateSkillStoreForm("DynamicProperties", updatedVariables);
    }

    const updatedErrors = [...errors];

    if (index >= 0 && index < updatedErrors.length) {
      updatedErrors[index] = { ...updatedErrors[index] };
      updatedErrors[index][field] = "";
      setErrors(updatedErrors);
    }
  };

  const addVariable = () => {
    updateSkillStoreForm("DynamicProperties", [
      ...skillStoreForm.DynamicProperties,
      { key: "", Value: "" },
    ]);
  };

  const removeVariable = (index) => {
    updateSkillStoreForm(
      "DynamicProperties",
      skillStoreForm.DynamicProperties.filter((_, i) => i !== index)
    );
    setErrors((prevState) => prevState.filter((_, i) => i !== index));
  };

  const validateChatBotConfig = () => {
 
    let isValid = true;
    let newErrors = [];

    if (skillStoreForm.InputType === "") {
      inputRef.current.classList.add("border-red");
      inputRef.current.focus();
      toast.error("Input Type is required");
      return false;
    }
    if (skillStoreForm.LastMessagesCount === "") {
      lastMessageCountRef.current.classList.add("border-red");
      lastMessageCountRef.current.focus();
      toast.error("Last Message Count is required");
      return false;
    }
    if (skillStoreForm.ChatGPTVersion === "") {
      chatGPTVersionRef.current.classList.add("border-red");
      chatGPTVersionRef.current.focus();
      toast.error("Chat GPT Version is required");
      return false;
    }
    // if (skillStoreForm.ChatbotUIView === "") {
    //   chatbotUIViewRef.current.classList.add("border-red");
    //   chatbotUIViewRef.current.focus();
    //   toast.error("Chatbot UI View is required");
    //   return false;
    // }

    if (skillStoreForm.CurlRequestString === "") {
      curlRequestStringRef.current.classList.add("border-red");
      curlRequestStringRef.current.focus();
      toast.error("Curl Request String is required");
      return false;
    }

    if (skillStoreForm.UserAssistantMessageFormat === "") {
      userAssistantMessageFormatRef.current.classList.add("border-red");
      userAssistantMessageFormatRef.current.focus();
      toast.error("User Assistant Message Format is required");
      return false;
    }

    skillStoreForm.DynamicProperties.forEach((item, index) => {
      if (!item.key || !item.key.includes("$")) {
        isValid = false;
        newErrors[index] = {
          ...newErrors[index],
          key: "Key must contain '$' and not be empty",
        };
      }

      if (!item.key.trim()) {
        isValid = false;
        newErrors[index] = {
          ...newErrors[index],
          key: "Key is required.",
        };
      } else if (!item.key.includes("$") || item.key.trim() === "$") {
        isValid = false;
        newErrors[index] = {
          ...newErrors[index],
          key: "Key must contain '$' followed by text.",
        };
      }

      if (!item.Value) {
        isValid = false;
        newErrors[index] = {
          ...newErrors[index],
          Value: "Value cannot be empty",
        };
      }
    });

    setErrors(newErrors);
    return isValid;
  };

  const handleCreateSkill = () => {
    if (validateChatBotConfig()) {
      CreateSkillStore(skillStoreForm).then((res) => {
        if (res?.Status === "Skill Created Successfully.") {
          toast.success(res?.Status);
          navigate("/skillstore");
        } else {
          toast.error("Unable to Create skill");
        }
        //
      });
    }
  };

  const isFormChanged = () => {
    return JSON.stringify(skillStoreForm) !== JSON.stringify(initialFormState);
  };

  const handleUpdateSkillAndBot=()=>{
    if(validateFields() && validateChatBotConfig())
    {
      handleUpdateSkill();
    }
  };
  return (
    <div className="bot-configuration skill-fullwidth">
      <div className="dash-section p20">
        <h2 className="h2">BOT Configuration</h2>
        <div className="form-group">
          <div className="form-config-box">
            <label>
              Input Type <span className="red">*</span>{" "}
            </label>
            <GenericDropdown
              dropdownData={[
                { Id: "text", Name: "Text" },
                { Id: "file", Name: "File" },
              ]}
              selectedValue={skillStoreForm.InputType}
              onDropdownSelection={(value) =>
                handleChange({ target: { name: "InputType", value } })
              }
              title="Select Input Type"
              inputRef={inputRef}
            ></GenericDropdown>
          </div>
          <div className="form-config-box">
            <label> File Type </label>
            {/* <input
              className="prompt-text"
              type="text"
              value={skillStoreForm.InputType === "file" ? "PDF" : ""}
              readOnly
              disabled
            /> */}
            <GenericDropdown
              dropdownData={[
                { Id: "File", Name: "File" },
                { Id: "PDF", Name: "PDF" },
              ]}
              selectedValue={skillStoreForm.FileType}
              onDropdownSelection={(value) =>
                handleChange({ target: { name: "FileType", value } })
              }
              title="Select File Type"
            ></GenericDropdown>
          </div>
          <div className="form-config-box">
            <label> Output Type </label>
            {/* <input
              className="prompt-text"
              type="text"
              name="OutputType"
              value={
                skillStoreForm.InputType === "text"
                  ? "Text"
                  : skillStoreForm.InputType === ""
                  ? ""
                  : "Excel"
              }
              readOnly
              disabled
            /> */}
            <GenericDropdown
              dropdownData={[{ Id: "Excel", Name: "Excel" }]}
              selectedValue={skillStoreForm.OutputType}
              onDropdownSelection={(value) =>
                handleChange({ target: { name: "OutputType", value } })
              }
              title="Select Output Type"
            ></GenericDropdown>
          </div>

          <div className="form-config-box">
            <label>
              Last Message Count <span className="red">*</span>{" "}
            </label>
            <input
              type="text"
              name="LastMessagesCount"
              min="0"
              max="15"
              value={skillStoreForm.LastMessagesCount}
              onChange={handleLastMessageCountChange}
              ref={lastMessageCountRef}
              className="prompt-text"
            />
          </div>
          {validationMessage && (
            <span style={{ color: "red", fontSize: "12px", marginLeft: "5px" }}>
              {validationMessage}
            </span>
          )}
          <div className="form-config-box">
            <label>
              ChatGPT Version <span className="red">*</span>{" "}
            </label>
            <input
              type="text"
              className="prompt-text"
              name="ChatGPTVersion"
              value={skillStoreForm.ChatGPTVersion}
              onChange={handleChange}
              ref={chatGPTVersionRef}
            />
          </div>
          <div className="form-config-box">
            <label>Chatbot UI View</label>
            <input
              type="text"
              name="ChatbotUIView"
              className="prompt-text"
              value={skillStoreForm.ChatbotUIView}
              ref={chatbotUIViewRef}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
      {/* <div className="form-group">
          
        </div> */}
      <div className="dash-section p20">
        <div className="form-group checkbox-row">
          <div className="form-config-box-check">
            <input
              type="checkbox"
              name="IsChatbotTestable"
              checked={skillStoreForm.IsChatbotTestable}
              onChange={handleChange}
            />
            <div> Is Chatbot Testable </div>
          </div>
          <div className="form-config-box-check">
            <input
              type="checkbox"
              name="ShowCitation"
              checked={skillStoreForm.ShowCitation}
              onChange={handleChange}
            />
            <div>Show Citation </div>
          </div>
          <div className="form-config-box-check">
            <input
              type="checkbox"
              name="ShowFollowUpQuestions"
              checked={skillStoreForm.ShowFollowUpQuestions}
              onChange={handleChange}
            />
            <div>Show Follow Up Question </div>
          </div>
        </div>
      </div>
      <div className="dash-section p20">
        <div className="form-group">
          <div className="form-config-box">
            <label>
              Curl Request <span className="red">*</span>{" "}
            </label>
            <textarea
              name="CurlRequestString"
              rows="4"
              value={skillStoreForm.CurlRequestString}
              onChange={handleCurlChange}
              className="prompt-text"
              ref={curlRequestStringRef}
            />
          </div>
          <div className="form-config-box">
            <label>
              User Assistant Message Format <span className="red">*</span>{" "}
            </label>
            <textarea
              name="UserAssistantMessageFormat"
              rows="4"
              value={skillStoreForm.UserAssistantMessageFormat}
              onChange={handleChange}
              className="prompt-text"
              ref={userAssistantMessageFormatRef}
            />
          </div>
        </div>

        {skillStoreForm?.DynamicProperties?.length > 0 && (
          <div className="all-variavle-inputs">
            {skillStoreForm?.DynamicProperties?.map((item, index) => (
              <div key={index} className="variable-input">
                <div className="input-wrapper">
                  <input
                    type="text"
                    placeholder="Key"
                    value={item.key}
                    onChange={(e) =>
                      handleVariableChange(index, "key", e.target.value)
                    }
                    className={errors[index]?.key ? "error-border" : ""}
                  />
                  {errors[index]?.key && (
                    <span className="error-text">{errors[index].key}</span>
                  )}
                </div>
                <div className="input-wrapper">
                  <input
                    type="text"
                    placeholder="Value"
                    value={item.Value}
                    onChange={(e) =>
                      handleVariableChange(index, "Value", e.target.value)
                    }
                    className={errors[index]?.value ? "error-border" : ""}
                  />
                  {errors[index]?.Value && (
                    <span className="error-text">{errors[index].Value}</span>
                  )}
                </div>
                <div className="button-wrap-row">
                  <button className="" onClick={() => removeVariable(index)}>
                    <div className="minus-icon-white"></div>
                  </button>
                  {index === skillStoreForm.DynamicProperties?.length - 1 && (
                    <button className="" onClick={addVariable}>
                      <div className="plus-icon-white"></div>
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}

        <div className="prompt-button-wrapper">
          {handleUpdateSkill === undefined && (
            <button
              className="backbtn"
              onClick={() => {
                setShowBotConfiguration(false);
                setIsBackButtonClicked(true);
              }}
            >
              Back
            </button>
          )}
          {handleUpdateSkill !== undefined && (
            <button
              className="cancel-btn"
              onClick={() => {
                navigate("/skillstore");
              }}
            >
              Cancel
            </button>
          )}
          {handleUpdateSkill !== undefined && <div></div>}
          <div
            className={
              isFormChanged()
                ? "prompt-button-wrapper"
                : "prompt-button-wrapper disabled-element"
            }
          >
            <div></div>
            {handleUpdateSkill === undefined && (
              <button
                className="btn-save-bot genrate-btn"
                onClick={handleCreateSkill}
              >
                Save Bot
              </button>
            )}
            {handleUpdateSkill !== undefined && (
              <button
                className="btn-save-bot genrate-btn"
                onClick={handleUpdateSkillAndBot}
                disabled={!isFormChanged()}
              >
                Update Bot
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BOTConfiguration;
