import React, { useEffect, useState, useContext } from "react";
import SkillStoreDetailPage from "../_partials/skillStoreDetailPage";
import { useLocation, useParams } from "react-router-dom";
import { getSkillStoreDataById } from "../../../skillStore/services/skillStoreService";
import loaderContext from "../../../../../context/loader/loaderContext";

const SkillStoreDetail = () => {
  const { state } = useLocation();
  const [selectedSkill, setSelectedSkill] = useState(null);
  const { updateLoaderStatus } = useContext(loaderContext);

  useEffect(() => {
    const getSkillStoreDetailData=async()=>{
     const res= await getSkillStoreDataById(state?.skill?.Id); 
     setSelectedSkill(res);
    }
    getSkillStoreDetailData();
    updateLoaderStatus(false);
  }, []);

  return (
    <div className="dashboard-wrapper">
      <SkillStoreDetailPage selectedSkill={selectedSkill} />
    </div>
  );
};

export default SkillStoreDetail;
