import React, { useContext, useReducer, useState } from "react";
import { UserContext } from "./userContext";
import {
  getStorageToken,
  isGivenTokenEmpty,
  isGivenTokenExpired,
  isGivenTokenExpiring,
  isGivenTokenInvalid,
} from "../../services/tokenService";

import { jwtDecode } from "jwt-decode";
import { UserPrivileges, UserProfile } from "../../services/authService";
import { removeAccessToken } from "../../services/localStorageService";
import { useNavigate } from "react-router-dom";
import loaderContext from "../loader/loaderContext";
import {
  getAssignablePermissions,
  transformAssignablePermissions,
} from "../../services/permissionService";
import { getEntityUrl } from "../../services/entityService";
import { checkSkillsAvailability } from "../../components/mks-applications/shared/defaultChatBotConfig/defaultConfigService";

const baseUserObject = {
  email: "",
  exp: 0,
  role: [],
  sub: "",
  siteName: "",
  isImpersonate: false,
  userProfile: null,
  privileges: null,
  distinctModules: [],
};

const reducer = (state, { type, payload }) => {
  switch (type?.toString().toLowerCase()) {
    case "login": {
      let userObject = { ...state.user, ...payload.decode };
      let returned = {
        user: {
          ...userObject,
          isAuthenticated: true,
          userProfile: payload.userProfile,
          role: payload.role,
          privileges: payload.privileges,
          distinctModules: payload.distinctModules,
        },
        token: payload.token,
      };
      return returned;
    }
    case "clear":
    case "logout": {
      let userObject = { ...state.user, ...baseUserObject };
      return {
        user: {
          ...userObject,
          isAuthenticated: false,
        },
        token: "",
      };
    }
    default:
      return state;
  }
};

export const UserProvider = ({ children }) => {
  const [waiting, setWaiting] = useState(-1);
  const { updateLoaderStatus } = useContext(loaderContext);

  const [state, dispatch] = useReducer(reducer, {
    user: baseUserObject,
    token: null,
  });

  const getTokenDetails = () => {
    let token = getStorageToken();
    let isInValid = false;
    let isExpired = false;
    let isExpiring = false;
    if (
      isGivenTokenInvalid(token) === true ||
      isGivenTokenEmpty(token) === true
    ) {
      isInValid = true;
    } else {
      isExpired = isGivenTokenExpired(token);
      isExpiring = isGivenTokenExpiring(token);
    }

    return {
      token,
      isInValid,
      isExpired,
      isExpiring,
    };
  };

  const getProfileAndPrivileges = (token, callbackMethod = null) => {
    updateLoaderStatus(true);

    const decode = jwtDecode(token);
    UserProfile(decode.email).then(
      (userProfile) => {
        if (userProfile === undefined || userProfile === null) {
          logout();
        } else {
          userProfile.msalSecurityGroup = decode.msalSecurityGroup;
          UserPrivileges(userProfile.user_id).then(
            (userPrivileges) => {
              getAssignablePermissions().then(
                (data) => {
                  let assignablePermissions =
                    transformAssignablePermissions(data);
                  const set = () => {
                    let role =
                      decode[
                        "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
                      ];

                    let distinctModules = [];
                    for (let c = 0; c < userPrivileges.length; c++) {
                      let privilege = userPrivileges[c];
                      let moduleName = privilege.ModuleName;
                      let moduleDescription = privilege.ModuleDescription;
                      const existing = distinctModules.find(
                        (x) => x.moduleName === moduleName
                      );
                      if (existing === null || existing === undefined) {
                        distinctModules.push({
                          moduleName: moduleName,
                          moduleDescription: moduleDescription,
                        });
                      }
                    }

                    let payload = {
                      distinctModules: distinctModules,
                      role: role,
                      userProfile: userProfile,
                      privileges: userPrivileges,
                      assignablePermissions: assignablePermissions,
                      token: token,
                    };
                    dispatch({ type: "login", payload: payload });
                    // loadScriptsAndStyles(userProfile.brandID);
                    if (callbackMethod !== null) {
                      callbackMethod();
                    }
                    updateLoaderStatus(false);
                    setWaiting(1);
                  };
                  set();
                },
                (error) => {
                  updateLoaderStatus(false);
                  setWaiting(1);
                }
              );
            },
            (error) => {
              updateLoaderStatus(false);
              setWaiting(1);
            }
          );
        }
      },
      (error) => {
        updateLoaderStatus(false);
        setWaiting(1);
      }
    );
  };
  const replaceUrl = (softReplace = false) => {
    let url = getEntityUrl("Dashboard");
    window.location.replace(url);
  };

  const removeAll = () => {
    dispatch({ type: "clear", payload: {} });
    removeAccessToken();
  };
  const navigate = useNavigate();

  const logout = (redirect = true) => {
    removeAccessToken();
    dispatch({ type: "logout", payload: {} });
    if (redirect === true) {
      navigate("/logout");
    }
  };

  return (
    <UserContext.Provider
      value={{
        state,
        getProfileAndPrivileges,
        removeAll,
        logout,
        getTokenDetails,
        replaceUrl,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
