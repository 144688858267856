import React, { useEffect, useState, useRef } from "react";

const SearchBySubFunction = (props) => {
  const {onSearchSubFunctionSelection,searchSubFunctionData,}=props;

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedOptionsName, setSelectedOptionsName] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [subFunctionData, setSubFunctionData] = useState([]);
  const allCheckboxRef = useRef(null);

  useEffect(() => {
    const allSubFunctionData = searchSubFunctionData;
    setSubFunctionData(allSubFunctionData);
    setSelectedOptions([]);
    setSelectedOptionsName([]);
    setIsAllSelected(false);
  }, [searchSubFunctionData]);

  const handleSelectChange = (item) => {
    let updatedSelectedOptions;
    let updatedSelectedOptionName;

    if (selectedOptions.includes(item.Id)) {
      updatedSelectedOptions = selectedOptions.filter((id) => id !== item.Id);
      updatedSelectedOptionName = selectedOptionsName.filter(
        (name) => name !== item.Name
      );
    } else {
      updatedSelectedOptions = [...selectedOptions, item.Id];
      updatedSelectedOptionName = [...selectedOptionsName, item.Name];
    }

    setSelectedOptions(updatedSelectedOptions);
    setSelectedOptionsName(updatedSelectedOptionName);
    onSearchSubFunctionSelection(updatedSelectedOptions);

    if (updatedSelectedOptions.length === subFunctionData?.length) {
      setIsAllSelected(true);
      if (allCheckboxRef.current) {
        allCheckboxRef.current.indeterminate = false;
      }
    } else if (updatedSelectedOptions.length === 0) {
      setIsAllSelected(false);
      if (allCheckboxRef.current) {
        allCheckboxRef.current.indeterminate = false;
      }
    } else {
      if (allCheckboxRef.current) {
        allCheckboxRef.current.indeterminate = true;
      }
    }
  };

  const handleSelectAllChange = () => {
    if (isAllSelected) {
      setSelectedOptions([]);
      setIsAllSelected(false);
      allCheckboxRef.current.indeterminate = false;
      onSearchSubFunctionSelection([]);
      setSelectedOptionsName([]);
    } else {
      const allIds = subFunctionData?.map((item) => item.Id);
      const allNames = subFunctionData?.map((item) => item.Name);
      setSelectedOptions(allIds);
      setSelectedOptionsName(allNames);
      setIsAllSelected(true);
      allCheckboxRef.current.indeterminate = false;
      onSearchSubFunctionSelection(allIds);
    }
  };

  const isSelected = (itemId) => selectedOptions.includes(itemId);

  return (
    <div className="custom-dropdown max-dropdown">
      <div className="dropdown-button">
      {selectedOptionsName.length === 0 && <span>Select Sub Functions</span>}
      <span title={selectedOptionsName} className="all-selected-item">
          {selectedOptionsName.length > 0 &&
            selectedOptionsName.slice(0, 1).map((name, index) => (
              <span className="selected-val" key={index}>
                {name}
              </span>
            ))}
          {selectedOptions?.length > 1 && (
            <span className="more-tags">+{selectedOptions?.length - 1}</span>
          )}
        </span>
        <span className="down-arrow"></span>
      </div>
      {
        subFunctionData?.length>0 && (
          <ul className="dropdown-menu-custom">
          {subFunctionData?.length > 1 && (
              <li>
                <label>
                  <input
                    type="checkbox"
                    ref={allCheckboxRef}
                    value="All"
                    checked={isAllSelected}
                    onChange={handleSelectAllChange}
                  />
                  All
                </label>
              </li>
            )}
            {subFunctionData?.map((item) => (
              <li key={item.Id}>
                <label>
                  <input
                    type="checkbox"
                    value={item.Id}
                    checked={isSelected(item.Id)}
                    onChange={() => handleSelectChange(item)}
                  />
                  {item.Name}
                </label>
              </li>
            ))}
          </ul>
        )
      }
  
    </div>
  );
};

export default SearchBySubFunction;
