import React, { useState, useEffect, useRef } from "react";
import { encodeSVG } from "../../../../../../services/commonService";
import showMessage from "../../../../../common/message";

const WorkspaceAppList = (props) => {
  const { handelAppPopUp, tiles = [], onSaveWorkSpace } = props;
  const [selectedTiles, setSelectedTiles] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const allCheckboxRef = useRef(null);

  useEffect(() => {
    if (tiles.length > 0) {
      // Ensure tiles array is not empty before filtering
      const preSelectedTiles = tiles
        .filter((tile) => tile.IsActive)
        .map((tile) => tile.Id);
      setSelectedTiles(preSelectedTiles);
      updateSelectAllState(preSelectedTiles);
    }
  }, [tiles]);

  const updateSelectAllState = (currentSelected) => {
    if (currentSelected.length === 0) {
      setSelectAll(false);
      if (allCheckboxRef.current) {
        allCheckboxRef.current.indeterminate = false;
      }
    } else if (currentSelected.length === tiles.length) {
      setSelectAll(true);
      if (allCheckboxRef.current) {
        allCheckboxRef.current.indeterminate = false;
      }
    } else {
      setSelectAll(false);
      if (allCheckboxRef.current) {
        allCheckboxRef.current.indeterminate = true;
      }
    }
  };

  const handleTileSelection = (tileId) => {
    setSelectedTiles((prevSelectedTiles) => {
      let newSelected;
      if (prevSelectedTiles.includes(tileId)) {
        newSelected = prevSelectedTiles.filter((id) => id !== tileId);
      } else {
        newSelected = [...prevSelectedTiles, tileId];
      }
      updateSelectAllState(newSelected);
      return newSelected;
    });
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedTiles([]);
      setSelectAll(false);
      if (allCheckboxRef.current) {
        allCheckboxRef.current.indeterminate = false;
      }
    } else {
      const allTileIds = tiles.map((tile) => tile.Id);
      setSelectedTiles(allTileIds);
      setSelectAll(true);
      if (allCheckboxRef.current) {
        allCheckboxRef.current.indeterminate = false;
      }
    }
  };

  const handleSave = () => {
    if (selectedTiles.length === 0) {
      showMessage("Please select at least one app.", -2);
      return;
    }
    const formattedTiles = tiles.map((tile) => {
      const isSelected = selectedTiles.includes(tile.Id);
      return {
        DashboardCardId: tile.Id,
        IsActive: isSelected,
      };
    });
    onSaveWorkSpace(formattedTiles);
  };

  const renderWorkspaceApp = (tile) => {
    const cleanedSVG = tile.CardImage.slice(2, -1);
    const encodedSVG = encodeSVG(cleanedSVG);
    const imgPath = `data:${tile.MimeType};utf8,${encodedSVG}`;

    return (
      <div className="applist-row" key={tile.Id}>
        <label className="check-contain">
          <input
            type="checkbox"
            checked={selectedTiles.includes(tile.Id)}
            className="allcheck"
            onChange={() => handleTileSelection(tile.Id)}
          />
          <span className="checkmark-check"></span>
        </label>
        <div className="app-logo-icon">
          <img alt="" src={imgPath} className="app-list-img" />
        </div>
        <div className="app-name-label">{tile.Heading}</div>
        <div className="app-category-label"></div>
      </div>
    );
  };

  return (
    <div className="modal-wrapper app-list show-modal-wrapper">
      <div className="modal-block border-radius-5">
        <div className="confirmation-content-wrapper ">
          <div className="modal-content">
            <div className="modal-header">
              <h2>Workspace App List</h2>
            </div>
            <div className="modal-body ">
              <div className="applist-view">
                <div className="applist-row">
                  <input
                    type="checkbox"
                    className="allcheck"
                    ref={allCheckboxRef} // Reference to control indeterminate state
                    checked={selectAll}
                    onChange={handleSelectAll}
                  />

                  <div className="app-name-label">All</div>
                </div>
                {tiles?.length > 0 &&
                  tiles.map((tile, index) => renderWorkspaceApp(tile, index))}
              </div>
            </div>
            <div className="popup-btn-container">
              <button
                type="button"
                className="cancel-btn"
                onClick={handelAppPopUp}
              >
                Cancel
              </button>
              <button
                type="button"
                className="genrate-btn"
                onClick={handleSave}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkspaceAppList;
