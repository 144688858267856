import React, { useState, useEffect, useRef, useContext } from "react";
import { getSkillDepartmentData } from "../../../../../promptManagement/services/promptManagementService";
import { getChatPromptManagementStoreData } from "../../../../services/chatService";
import { getSkillStoreData } from "../../../../../skillStore/services/skillStoreService";
import { toast } from "react-toastify";
import showMessage from "../../../../../../common/message";
import { getMultiFileClassByExtension } from "../../../../shared/fileExtension";
import ChatPromptCollection from "../chatPromptCollection";
import loaderContext from "../../../../../../../context/loader/loaderContext";
import { hasGroupAccess } from "../../../../../../../services/commonService";
import { UserContext } from "../../../../../../../context/user/userContext";

const ChatPrompt = (props) => {
  const {
    isMKSChat,
    skill,
    setSkill,
    setMessages,
    handleSendMessage,
    chatMessage,
    navigateFromHome,
    setIsSkillChat,
    setIsFileTypeChat,
    isFileTypeChat,
    setDefaultSkillWelMessage,
    setUploadedFilesFromChat,
    clearUploadedFile,
    promptText,
    setGenericAPIUserMessages,
    uploadedFilesFromChat,
    isMKSProcessing,
    isSkillProcessing,
    setInit,
    setClearUploadedFile,
    selectedAdditives,
  } = props;

  const [name, setName] = useState("");
  const [promptManagementStoreData, setPromptManagementStoreData] = useState(
    []
  );
  const [
    promptManagementStoreDataOriginal,
    setPromptManagementStoreDataOriginal,
  ] = useState([]);
  const [promptDepartmentData, setPromptDepartmentData] = useState([]);
  const [showPromptManagement, setShowPromptManagement] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [filteredSkills, setFilteredSkills] = useState([]);
  const [orignalSkills, setOriginalSkills] = useState([]);
  const [selectedSkill, setSelectedSkill] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const { updateLoaderStatus } = useContext(loaderContext);
  const [tabId, setTabId] = useState(1);
  const skillRef = useRef(null);
  const [selectedPrompt, setSelectedPrompt] = useState(null);
  const chatRef = useRef(null);
  const toggleButtonRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);
  const [showTip, setShowTip] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const { state } = useContext(UserContext);
  const securityGroups = state?.user?.userProfile?.msalSecurityGroup;
  const validateFilesAndAdditive = () => {
    if (uploadedFiles.length === 0) {
      showMessage("Please upload a file", -2);
      return false;
    }
    let additivess = selectedAdditives.filter(
      (item) => item.Name !== "Translate"
    );
    if (additivess.some((item) => item.checked === true)) {
      return true;
    } else {
      showMessage("Please select at least one additive", -2);
      return false;
    }
  };

  useEffect(() => {
    const handleClickOutside = (e) => {
      // Check if the click is outside both the menu and toggle button
      if (
        showPromptManagement &&
        chatRef.current &&
        !chatRef.current.contains(e.target) &&
        toggleButtonRef.current &&
        !toggleButtonRef.current.contains(e.target)
      ) {
        setShowPromptManagement(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showPromptManagement]);

  useEffect(() => {
    if (!isFileTypeChat) {
      setUploadedFiles([]);
    }
  }, [isFileTypeChat]);

  const handleMouseEnter = () => {
    if (skill?.Name === "" || skill?.Name === undefined) {
      setShowTip(true);
    }
  };

  const handleMouseLeave = () => {
    setShowTip(false);
  };
  const showtipToggle = (e) => {
    e.stopPropagation();
    setShowTip(false);
  };

  // useEffect(() => {
  //   setUploadedFiles([]);
  // }, [setUploadedFilesFromChat]);

  const handleSendMessageToChat = () => {
    const textarea = skillRef.current;
    setInit(false);
    if (isFileTypeChat && !validateFilesAndAdditive()) {
      return false;
    }
    handleSendMessage(name, selectedSkill);
    setUploadedFiles([]);
    textarea.style.height = "40px";
  };

  const MAX_FILES = process.env.REACT_APP_MAX_UPLOAD_FILES;

  const addFiles = (newFiles) => {
    const totalFiles = uploadedFiles.length + newFiles.length;
    if (totalFiles > MAX_FILES) {
      toast.error(`You can upload up to ${MAX_FILES} files`);
      return;
    }
    setUploadedFiles((prevFiles) => [...prevFiles, ...newFiles]);
    setUploadedFilesFromChat((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleMultiFiles = (event) => {
    setClearUploadedFile(false);
    const selectedFiles = Array.from(event.target.files);
    const invalidFiles = selectedFiles.filter(
      (file) => file.type !== "application/pdf"
    );

    if (invalidFiles.length > 0) {
      showMessage("Only PDF files are allowed", -2);
      return;
    }
    addFiles(selectedFiles);
    event.currentTarget.value = null;
  };

  const fileClick = () => {
    setClearUploadedFile(false);
    document.getElementById("multifiles").click();
  };

  const handleRemoveFile = (fileNameToRemove) => {
    let uploadedOldFiles = [...uploadedFilesFromChat];
    uploadedOldFiles = uploadedOldFiles.filter(
      (file) => file.name !== fileNameToRemove
    );
    setUploadedFilesFromChat([...uploadedOldFiles]);
    setUploadedFiles([...uploadedOldFiles]);
  };

  const dropFiles = (event) => {
    event.preventDefault();
    if (isFileTypeChat) {
      const droppedFiles = Array.from(event.dataTransfer.files);
      const invalidFiles = droppedFiles.filter(
        (file) => file.type !== "application/pdf"
      );

      if (invalidFiles.length > 0) {
        showMessage("Only PDF files are allowed", -2);
        return;
      }

      addFiles(droppedFiles);
    }
  };

  const dragOverFiles = (event) => {
    event.preventDefault();
  };

  const getAllSkills = async () => {
    await getSkillStoreData().then((res) => {
      setOriginalSkills(res);
    });
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (showSuggestions && filteredSkills.length > 0) {
        if (e.key === "ArrowDown") {
          e.preventDefault();
          setSelectedIndex(
            (prevIndex) => (prevIndex + 1) % filteredSkills.length
          );
        } else if (e.key === "ArrowUp") {
          e.preventDefault();
          setSelectedIndex(
            (prevIndex) =>
              (prevIndex - 1 + filteredSkills.length) % filteredSkills.length
          );
        } else if (e.key === "Enter" && selectedIndex >= 0) {
          e.preventDefault();
          const selectedSkill = filteredSkills[selectedIndex];
          const access = hasGroupAccess(
            securityGroups,
            selectedSkill.SecurityGroupId
          );
          if (!access) {
            showMessage(
              "You don't have access to " + selectedSkill.Name + " bot.",
              -2
            );
          } else {
            setSelectedName(selectedSkill);
          }
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [selectedIndex, filteredSkills, showSuggestions]);

  useEffect(() => {
    getAllSkills();
    setUploadedFiles([]);
    setUploadedFilesFromChat([]);
  }, [clearUploadedFile, isMKSChat]);

  useEffect(() => {
    if (
      chatMessage !== undefined &&
      chatMessage !== "" &&
      chatMessage !== null &&
      navigateFromHome === false
    ) {
      if (
        promptText !== null &&
        promptText !== undefined &&
        promptText !== "" &&
        !isMKSChat
      )
        setName(promptText);
      else setName(chatMessage);
    } else if (
      chatMessage !== undefined &&
      chatMessage !== "" &&
      chatMessage !== null &&
      navigateFromHome === true
    ) {
      setName(chatMessage);
    } else setName("");
    setShowSuggestions(false);
    setShowPromptManagement(false);
  }, [isMKSChat]);

  const handleNameChange = (e) => {
    if (!showPromptManagement) {
      setName(e.target.value);
    }
  };

  const setSelectSkillFromPrompt = (skill) => {
    skill?.ChatbotConfiguration?.ChatbotUIView?.toLowerCase() === "additives"
      ? setIsSkillChat(true)
      : setIsSkillChat(false);

    skill?.ChatbotConfiguration?.InputType?.toLowerCase() === "file"
      ? setIsFileTypeChat(true)
      : setIsFileTypeChat(false);

    setDefaultSkillWelMessage(false);

    setName("");
    setShowSuggestions(false);
    setSelectedSkill(skill);
    setSkill(skill);
    setMessages([]);
    skillRef.current.focus();
    setGenericAPIUserMessages([]);
  };

  const setSelectedName = (skill) => {
    skill?.ChatbotConfiguration?.ChatbotUIView?.toLowerCase() === "additives"
      ? setIsSkillChat(true)
      : setIsSkillChat(false);

    skill?.ChatbotConfiguration?.InputType?.toLowerCase() === "file"
      ? setIsFileTypeChat(true)
      : setIsFileTypeChat(false);

    setDefaultSkillWelMessage(false);

    if (!showPromptManagement) {
      setName("");
      setShowSuggestions(false);
      setSelectedSkill(skill);
      setSkill(skill);
      setMessages([]);
      skillRef.current.focus();
    }
    setGenericAPIUserMessages([]);
  };

  const handlekeyDown = (e) => {
    const textarea = skillRef.current;
    if (e.key === "Enter" && e.altKey) {
      e.preventDefault();
      setName((prevText) => prevText + "\n");
      return;
    }

    if (e.key === "Enter" && showSuggestions === false) {
      e.preventDefault();
      if (isFileTypeChat && !validateFilesAndAdditive()) {
        return false;
      }
      if (name?.trim() !== "") {
        handleSendMessage(name, selectedSkill);
        setName("");
        setUploadedFiles([]);
        textarea.style.height = "40px";
        skillRef.current?.blur();
      }
    }
  };

  const handleTogglePromptManagement = () => {
    if (!showPromptManagement) {
      const fetchData = async () => {
        setIsLoading(true);
        updateLoaderStatus(true);
        try {
          setTabId(1);
          const skillStoreId =
            skill?.Id === 0 || skill?.Id === undefined ? 0 : skill?.Id;
          const promptData = await getChatPromptManagementStoreData(
            skillStoreId
          );

          let filteredPromptData = null;
          if (skillStoreId || !isMKSChat) {
            filteredPromptData = promptData.filter(
              (item) => !item?.ChatbotConfiguration?.IsDefault
            );
          } else {
            filteredPromptData = promptData;
          }

          setPromptManagementStoreData(filteredPromptData);
          setPromptManagementStoreDataOriginal(filteredPromptData);

          const departmentData = await getSkillDepartmentData();
          setPromptDepartmentData(departmentData);
        } catch (error) {
          console.error("Failed to fetch data:", error);
        } finally {
          setIsLoading(false);
          updateLoaderStatus(false);
        }
      };
      fetchData();
    }
    // setSearchTerm(name.length > 0 ? name : "");
    setShowPromptManagement(!showPromptManagement);
  };

  const setTabAndData = (tabId) => {
    if (tabId === 1) {
      setPromptManagementStoreData(promptManagementStoreDataOriginal);
    } else if (tabId === 2) {
      const filteredRecords = promptManagementStoreDataOriginal.filter(
        (record) => record.IsMyPrompt
      );
      setPromptManagementStoreData(filteredRecords);
    } else if (tabId === 3) {
      const filteredRecords = promptManagementStoreDataOriginal.filter(
        (record) => record.IsSharedPrompt
      );
      setPromptManagementStoreData(filteredRecords);
    } else if (tabId === 4) {
      const filteredRecords = promptManagementStoreDataOriginal.filter(
        (record) => record.IsStared
      );
      setPromptManagementStoreData(filteredRecords);
    }
    setTabId(tabId);
  };

  const handleInputSearchPromptManagement = (e) => {
    const textarea = skillRef.current;
    if (textarea.value.trim() === "") {
      textarea.style.height = "40px"; // Reset to initial height when cleared
    } else {
      textarea.style.height = "40px"; // Reset height
      textarea.style.height = `${textarea.scrollHeight}px`; // Adjust based on content
    }
    const text = e.target.value;
    const query = text.startsWith("@") ? text : "";
    if (query === "@") {
      setShowSuggestions(true);
      setFilteredSkills(orignalSkills);
    } else if (query.includes("@ ") || query === "") {
      setShowSuggestions(false);
      setFilteredSkills([]);
    } else {
      const searchQuery = query.split("@").pop().trim();
      if (searchQuery) {
        setShowSuggestions(true);
        setFilteredSkills([
          ...new Set(
            orignalSkills.filter((skill) =>
              skill.Name.toLowerCase().includes(searchQuery.toLowerCase())
            )
          ),
        ]);
      } else {
        setShowSuggestions(false);
      }
    }
  };
  const handleSetSelectedPrompt = (prompt) => {
    if (!isMKSChat) {
      setShowPromptManagement(false);
      setSelectedPrompt(prompt);
      const defaultSkill = orignalSkills.filter(
        (item) => item.Id === prompt.SkillStoreId
      );
      if (defaultSkill?.length > 0) {
        setSelectedSkill(defaultSkill[0]);
        setSelectSkillFromPrompt(defaultSkill[0]);
      }
    }
    if (skillRef.current) {
      skillRef.current.focus();
    }
  };

  const getMultiFileData = (file, index) => {
    const extensionClassName = getMultiFileClassByExtension(file.name);
    return (
      <>
        <div className="multifile" key={index}>
          <div className="upload-drag">
            <div className={`${extensionClassName}-icon-list`}></div>
            <p>{file.name}</p>
            <div
              className="close-icon"
              onClick={() => handleRemoveFile(file.name)}
            ></div>
          </div>
        </div>
      </>
    );
  };

  const handleSuggestionsClick = (skill) => {
    const access = hasGroupAccess(securityGroups, skill.SecurityGroupId);
    if (!access) {
      showMessage("You don't have access to " + skill.Name + " bot.", -2);
    } else {
      setSelectedName(skill);
    }
  };

  return (
    <div className="chatprompt-block w-form">
      <div className="dropchat-wrap">
        {uploadedFiles && isFileTypeChat && !isMKSChat && (
          <div className="multifile-wrap">
            {uploadedFiles.map((file, index) => getMultiFileData(file, index))}
          </div>
        )}

        <div className="chatprompt-form">
          <label
            className="chatprompt-field-label"
            onClick={handleTogglePromptManagement}
            ref={toggleButtonRef}
          >
            {isLoading ? (
              <div className="btn-spinner">
                <span className="loadspinner"></span> Loading...
              </div>
            ) : (
              <div className="prompt-icon">
                <span>Prompt</span>
              </div>
            )}
          </label>
          {/* )} */}
          <div
            className="hoverteaxt"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <textarea
              className={`search-input-field chatprompt-field w-input scrollinput ${
                isMKSProcessing || isSkillProcessing ? "disabled-div" : ""
              }`}
              maxLength={6000}
              placeholder="Start a conversation with MKS.ai Skills Chat to access expert insights on specialized domains and functional areas."
              type="text"
              value={name}
              onChange={handleNameChange}
              onKeyDown={handlekeyDown}
              onDrop={dropFiles}
              onDragOver={dragOverFiles}
              onInput={(e) => handleInputSearchPromptManagement(e)}
              ref={skillRef}
            />
            {showTip && !isMKSChat && (
              <div className="skill-info-wrapper">
                <div className="skill-mention">
                  <div className="skill-popup-heading">Skill mentions</div>
                  <div className="close-icon" onClick={showtipToggle}></div>
                </div>
                <div className="skill-desc-popup">
                  Type @ to mention a skill and add it directly to the
                  conversation
                </div>
                <div className="chat-down-triangle"></div>
              </div>
            )}
          </div>
          {isFileTypeChat && (
            <div>
              <div className="attach-file" onClick={fileClick}></div>
              <input
                type="file"
                id="multifiles"
                accept=".pdf"
                multiple
                style={{ display: "none" }}
                onChange={handleMultiFiles}
              />
            </div>
          )}

          {!isMKSChat && showSuggestions && filteredSkills.length > 0 && (
            <div className="suggestions prompt-suggestions">
              <div className="suggestion-item-heading">Suggestions</div>
              {filteredSkills.map((skill, index) => (
                <div
                  key={index}
                  className={`suggestion-item ${
                    index === selectedIndex ? "selected" : ""
                  }`}
                  onClick={() => handleSuggestionsClick(skill)}
                >
                  {skill.Name}
                </div>
              ))}
            </div>
          )}

          <input
            type="button"
            className={`chatprompt-button w-button ${
              name?.trim() !== "" || uploadedFiles?.length > 0
                ? ""
                : "disabled-div"
            } `}
            onClick={(e) => {
              handleSendMessageToChat(name, selectedSkill);
              setName("");
            }}
          />
        </div>
      </div>
      <div ref={chatRef}>
        <div className="upperchartbox">
          {showPromptManagement && (
            <>
              <div className="upperchartbox-wide">
                <span
                  className="down-arrow prompt-down-arrow"
                  onClick={handleTogglePromptManagement}
                ></span>

                <div className="prompt-tabs w-tabs">
                  <div
                    className="tabs-menu h-flex head-wrapper w-tab-menu"
                    role="tablist"
                  >
                    <div
                      className={`prompt-tabs-link w-inline-block w-tab-link ${
                        tabId === 1 ? "w--current" : ""
                      } `}
                      aria-selected="true"
                      onClick={() => setTabAndData(1)}
                    >
                      <div>Prompts Collection</div>
                    </div>
                    <div
                      className={`prompt-tabs-link w-inline-block w-tab-link ${
                        tabId === 2 ? "w--current" : ""
                      } `}
                      aria-selected="false"
                      onClick={() => setTabAndData(2)}
                    >
                      <div>My Prompts</div>
                    </div>
                    <div
                      className={`prompt-tabs-link w-inline-block w-tab-link ${
                        tabId === 3 ? "w--current" : ""
                      } `}
                      aria-selected="false"
                      onClick={() => setTabAndData(3)}
                    >
                      <div>Shared</div>
                    </div>
                    <div
                      className={`prompt-tabs-link w-inline-block w-tab-link ${
                        tabId === 4 ? "w--current" : ""
                      } `}
                      aria-selected="false"
                      onClick={() => setTabAndData(4)}
                    >
                      <div>Favourites</div>
                    </div>
                  </div>
                </div>
                {tabId === 1 && (
                  <ChatPromptCollection
                    promptCollectionData={promptManagementStoreData}
                    promptDepartmentData={promptDepartmentData}
                    showPromptManagement={showPromptManagement}
                    setShowPromptManagement={setShowPromptManagement}
                    handleSetSelectedPrompt={handleSetSelectedPrompt}
                    setName={setName}
                    isMKSChat={isMKSChat}
                  />
                )}
                {tabId === 2 && (
                  <ChatPromptCollection
                    promptCollectionData={promptManagementStoreData}
                    promptDepartmentData={promptDepartmentData}
                    showPromptManagement={showPromptManagement}
                    setShowPromptManagement={setShowPromptManagement}
                    setName={setName}
                    handleSetSelectedPrompt={handleSetSelectedPrompt}
                    isMKSChat={isMKSChat}
                  />
                )}
                {tabId === 3 && (
                  <ChatPromptCollection
                    promptCollectionData={promptManagementStoreData}
                    promptDepartmentData={promptDepartmentData}
                    showPromptManagement={showPromptManagement}
                    setShowPromptManagement={setShowPromptManagement}
                    setName={setName}
                    handleSetSelectedPrompt={handleSetSelectedPrompt}
                    isMKSChat={isMKSChat}
                  />
                )}
                {tabId === 4 && (
                  <ChatPromptCollection
                    promptCollectionData={promptManagementStoreData}
                    promptDepartmentData={promptDepartmentData}
                    showPromptManagement={showPromptManagement}
                    setShowPromptManagement={setShowPromptManagement}
                    handleSetSelectedPrompt={handleSetSelectedPrompt}
                    setName={setName}
                    isMKSChat={isMKSChat}
                  />
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default ChatPrompt;
