import React, { PureComponent } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import showMessage from "../../../../../common/message";
import { handleApiError } from "../../../../../../utils/globalErrorHandler";

export class CropImage extends PureComponent {
  state = {
    src: null,
    name: "",
    mimeType: "",
    crop: {
      unit: "%",
      x: 0,
      y: 0,
      width: 50,
      height: 50,
    },
    isValidImage: true,
  };

  onSelectFile = (e) => {
    this.setState({ isValidImage: true });
    if (e.target.files && e.target.files.length > 0) {
      let imageArray = Array.from(e.target.files);
      let isValid = true;
      imageArray.forEach((file) => {
        const validImageTypes = ["image/jpeg", "image/png", "image/gif"];
        if (!validImageTypes.includes(file.type)) {
          showMessage("Only image files (jpeg, png, gif) are allowed", -2);
          isValid = false;
          this.setState({ isValidImage: false });
          return;
        }
      });
      if (!isValid) return;
      this.setState({ isValidImage: true });

      const reader = new FileReader();
      reader.addEventListener("load", () =>
        this.setState({ src: reader.result })
      );
      this.setState({ name: e.target.files[0].name });
      this.setState({ mimeType: e.target.files[0].type });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  // If you setState the crop in here you should return false.
  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );
      this.setState({ croppedImageUrl });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    const toDataURL = (url) =>
      fetch(url)
        .then((response) => response.blob())
        .then(
          (blob) =>
            new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.onloadend = () => resolve(reader.result);
              reader.onerror = reject;
              reader.readAsDataURL(blob);
            })
        );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          handleApiError();
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        toDataURL(this.fileUrl).then((dataUrl) => {
          this.setState({ dataUrl: dataUrl });
          console.log("RESULT:", dataUrl);
        });
        resolve(this.fileUrl);
      }, "image/jpeg");
    });
  }

  render() {
    const { crop, croppedImageUrl, src, dataUrl, name, mimeType } = this.state;
    const handleSaveImage = () => {
      if (!this.state.isValidImage) {
        showMessage("Only image files (jpeg, png, gif) are allowed", -2);
        return;
      }

      if (
        croppedImageUrl !== undefined &&
        croppedImageUrl !== null &&
        dataUrl !== undefined &&
        dataUrl !== null
      ) {
        this.props.handleSaveImage(croppedImageUrl, dataUrl, name, mimeType);
      } else {
        showMessage("Please select image to crop to continue", -2);
      }
    };

    const handleCancelClick = () => {
      this.props.handleCancelClick();
    };

    return (
      <div className="modal-wrapper show-modal-wrapper image-control">
        <div className="modal-block border-radius-5">
          <div className="confirmation-content-wrapper ">
            <div className="modal-content">
              <div className="modal-header">
                <div>
                  <input
                    type="file"
                    accept="image/png, image/gif, image/jpeg"
                    onChange={this.onSelectFile}
                  />
                </div>
              </div>
              <div className="modal-body">
                {src && (
                  <ReactCrop
                    src={src}
                    crop={crop}
                    ruleOfThirds
                    onImageLoaded={this.onImageLoaded}
                    onComplete={this.onCropComplete}
                    onChange={this.onCropChange}
                  />
                )}
                {croppedImageUrl && (
                  <div className="crop-img-wrap">
                    <img
                      alt="Crop"
                      style={{ maxWidth: "100%" }}
                      src={croppedImageUrl}
                    />
                  </div>
                )}
              </div>
              <div className="popup-btn-container">
                <button
                  type="button"
                  className="cancel-btn"
                  onClick={() => handleCancelClick()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="genrate-btn"
                  onClick={() => handleSaveImage()}
                >
                  Save Cropped Image
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
