import React, { useEffect, useState } from "react";
import { convertStringToJsonArray } from "../../../../shared/tableConverter";
import { ExporttToExcelRestAdditives } from "../../../../services/fileChatExcelDownload";

const OtherAdditivesTable = ({
  uploadedFileDetails,
  isTyping,
  setIsMKSProcessing,
  setIsSkillProcessing,
}) => {
  const [dataList, setDataList] = useState(
    convertStringToJsonArray(uploadedFileDetails)
  );

  useEffect(() => {
    setIsMKSProcessing(false);
    setIsSkillProcessing(false);
  }, []);

  const handleExportToExcel = () => {
    ExporttToExcelRestAdditives(dataList);
  };

  return (
    <>
      <div className="auto-chat-table">
        {dataList.length > 0 && (
          <>
            <div className="auto-chat-row">
              <div>Chemical Additives</div>
              <div>Occurrence in Percentage</div>
              <div>Patent Numbers</div>
              <div>Chemical Additives in Original Language</div>
             
            </div>
            <div
                onClick={() => handleExportToExcel()}
                className="excel-icon-list absolute-icon"
              ></div>

            <div className="chat-table-list">
              {dataList.map((additives, index) => {
                return (
                  <div key={index} className="chat-table-lis-row">
                    <div className="chem-addit">{additives.ChemicalAdditives}</div>
                    <div className="percent-name">{additives.OccurrenceInPercentage}</div>
                    <div className="patent-name">{additives.PatentNumbers}</div>
                    <div className="original-name">{additives.ChemicalAdditivesinOriginalLanguage}</div>
                  </div>
                );
              })}
            </div>
          </>
        )}
        {dataList.length === 0 && !isTyping && (
          <div className="chat-table-list">
            <div className="chat-table-lis-row">
              <div className="white-space">No Record(s) available!</div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default OtherAdditivesTable;
