export const getPageTitle = (pathname) => {
  if (pathname.includes("skillstoredetail")) pathname = "/skillstoredetail";
  if (pathname.includes("updatePrompt")) pathname = "/updateprompt";
  let title = "";
  switch (pathname) {
    case "/home":
      title = "mksai";
      break;
    case "/chat":
      title = "Chat Terminal";
      break;
    case "/myworkspace":
      title = "My Workspace";
      break;
    case "/skillstore":
      title = " Skill Library";
      break;
    case "/promptmanagement":
      title = "Prompt Management";
      break;
    case "/notificationhub":
      title = "Notification Center";
      break;
    case "/skillstoredetail":
      title = "Skill Detail";
      break;
    case "/addprompt":
      title = "Add Prompt";
      break;
    case "/updateprompt":
      title = "Update Prompt";
      break;
    case "/feedback":
      title = "Feedback";
      break;
    case "/aboutmks":
      title = "MKS.ai";
      break;
    case "/guidance":
      title = "Guidance & Guidelines";
      break;
    case "/help":
      title = "Help";
      break;
    case "/settings":
      title = "Settings";
      break;
    case "/login":
      title = "Login";
      break;
    case "/importprompt":
      title = "Import Bulk Prompts";
      break;
    default:
      title = "MKS App";
  }

  return title;
};

export const getScreenName = (pathname) => {
  let title = "";
  if (pathname.includes("skillstoredetail")) pathname = "/skillstoredetail";
  if (pathname.includes("updatePrompt")) pathname = "/updateprompt";
  switch (pathname) {
    case "/home":
      title = "MKS.ai";
      break;
    case "/chat":
      title = "Chat";
      break;
    case "/myworkspace":
      title = "My Workspace";
      break;
    case "/skillstore":
      title = "Skill Library";
      break;
    case "/promptmanagement":
      title = "Prompt Management";
      break;
    case "/notificationhub":
      title = "Notification Center";
      break;
    case "/skillstoredetail":
      title = "Skill Detail";
      break;
    case "/addprompt":
      title = "Add Prompt";
      break;
    case "/updateprompt":
      title = "Update Prompt";
      break;
    case "/settings":
      title = "Settings";
      break;
    case "/feedback":
      title = "Feedback";
      break;
    case "/aboutmks":
      title = "MKS.ai";
      break;
    case "/importprompt":
      title = "Import Bulk Prompts";
      break;
    case "/guidance":
      title = "Guidance & Guidelines";
      break;
    case "/help":
      title = "Help";
      break;
    default:
      title = "MKS App";
  }

  return title;
};

export const encodeSVG = (svgString) => {
  return encodeURIComponent(
    svgString
      .trim()
      .replace(/\\r\\n\\t/g, "")
      .replace(/\\r\\n/g, "")
      .replace(/\\n/g, "")
  )
    .replace(/'/g, "%27")
    .replace(/"/g, "%22");
};

export const getInitials = (name) => {
  if (!name) return "";

  const nameParts = name.trim().split(" ");

  const firstNameInitial = nameParts[0]?.charAt(0).toUpperCase() || "";
  const lastNameInitial =
    nameParts.length > 1
      ? nameParts[nameParts.length - 1].charAt(0).toUpperCase()
      : "";

  return firstNameInitial + lastNameInitial;
};

export const capitalizeFirstLetter = (str) => {
  if (!str) return str;

  const words = str.split(" ");

  const capitalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  );

  return capitalizedWords.join(" ");
};

export const clearPromptMessageSeesion = () => {
  sessionStorage.removeItem("messages");
};

export const hasGroupAccess = (securityGroups, groupId) => {
  let result = true;
  if(groupId !== null && groupId !== undefined) {
    if(securityGroups === undefined ||
      securityGroups === null || 
      securityGroups.length === 0){
       return false;
     }
     else
     {
        let found = securityGroups.find((x) => x.id === groupId);
        if(found === null || found === undefined) {
          result= false;
        } 
     }
  }  
  return result;
};