import React, { useEffect, useState } from "react";

const SelectByPreferences = (props) => {
  const {
    onPreferencesSelection,
    creativityData,
    selectPreferences,
    isReadonlyAccess,
  } = props;
  const [selectedPreferencesByUser, setSelectedPreferencesByUser] = useState();
  useEffect(() => {
    setData();
  }, [selectPreferences]);

  const setData = () => {
    if (
      selectPreferences !== null &&
      selectPreferences !== undefined &&
      selectPreferences !== ""
    ) {
      setSelectedPreferencesByUser(selectPreferences);
    } else {
      setSelectedPreferencesByUser("-1");
    }
  };
  const selectPreferencesHandler = (e) => {
    const selectedPreference = e.target.options[e.target.selectedIndex].text;
    const selectedVal = e.target.value;
    setSelectedPreferencesByUser(selectedVal);
    onPreferencesSelection(selectedVal, selectedPreference);
  };
  return (
    <select
      disabled={isReadonlyAccess}
      value={selectedPreferencesByUser}
      className={
        isReadonlyAccess ? "prompt-skill disabled-element" : "prompt-skill"
      }
      onChange={(e) => selectPreferencesHandler(e)}
    >
      <option value="-1">Select Creativity</option>
      {creativityData?.map((item) => (
        <option key={item.Id} value={item.Id}>
          {item.Name}
        </option>
      ))}
    </select>
  );
};

export default SelectByPreferences;
