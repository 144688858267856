import React, { useEffect, useState } from "react";
import { convertStringToJsonArray } from "../../../../shared/tableConverter";
import { ExporttToExcelRestAdditives } from "../../../../services/fileChatExcelDownload";

const OtherAdditivesTable = ({
  uploadedFileDetails,
  isTyping,
  setIsMKSProcessing,
  setIsSkillProcessing,
  isTranslateAdded,
}) => {
  const [dataList, setDataList] = useState(
    convertStringToJsonArray(uploadedFileDetails)
  );

  useEffect(() => {
    if (setIsMKSProcessing !== undefined) setIsMKSProcessing(false);
    if (setIsSkillProcessing !== undefined) setIsSkillProcessing(false);
  }, []);

  const handleExportToExcel = () => {
    ExporttToExcelRestAdditives(dataList, isTranslateAdded);
  };

  return (
    <>
      <div className="auto-chat-table">
        {dataList.length > 0 && (
          <>
            {isTranslateAdded && (
              <>
                <div className="auto-chat-row auto-col-5">
                  <div>Chemical Additives</div>
                  <div>Occurrence in Percentage</div>
                  <div>Patent Numbers</div>
                  <div>Type</div>
                  <div>Chemical Additives in Original Language</div>
                </div>
                <div
                  onClick={() => handleExportToExcel()}
                  className="excel-icon-list absolute-icon"
                  title="Download Excel"
                ></div>

                <div className="chat-table-list">
                  {dataList.map((additives, index) => {
                    return (
                      <div
                        key={index}
                        className="chat-table-lis-row auto-col-5"
                      >
                        <div className="chem-addit">
                          {additives.ChemicalAdditives}
                        </div>
                        <div className="percent-name">
                          {parseFloat(additives.OccurrenceInPercentage).toFixed(
                            2
                          )}
                        </div>
                        <div className="patent-name">
                          {additives.PatentNumbers}
                        </div>
                        <div className="patent-name">{additives.Type}</div>
                        <div className="original-name">
                          {additives.ChemicalAdditivesinOriginalLanguage}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </>
            )}

            {!isTranslateAdded && (
              <>
                <div className="auto-chat-row ">
                  <div>Chemical Additives</div>
                  <div>Occurrence in Percentage</div>
                  <div>Patent Numbers</div>
                  <div>Type</div>
                </div>
                <div
                  onClick={() => handleExportToExcel()}
                  className="excel-icon-list absolute-icon"
                  title="Download Excel"
                ></div>

                <div className="chat-table-list">
                  {dataList.map((additives, index) => {
                    return (
                      <div key={index} className="chat-table-lis-row">
                        <div className="chem-addit">
                          {additives.ChemicalAdditives}
                        </div>
                        <div className="percent-name">
                          {parseFloat(additives.OccurrenceInPercentage).toFixed(
                            2
                          )}
                        </div>
                        <div className="patent-name">
                          {additives.PatentNumbers}
                        </div>
                        <div className="patent-name">{additives.Type}</div>
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          </>
        )}
        {dataList.length === 0 && !isTyping && (
          <div className="chat-table-list">
            <div className="chat-table-lis-row">
              <div className="white-space">No Record(s) available!</div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default OtherAdditivesTable;
