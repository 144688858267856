import { useContext } from "react";
import { UserContext } from "../../../../context/user/userContext";
import { capitalizeFirstLetter } from "../../../../services/commonService";

export const useUserFullName = () => {
  const { state } = useContext(UserContext);
  return getFullName(state);
};

export  const getFullName = (state) => {
  const firstName = state?.user?.userProfile?.firstName;
  const lastName = state?.user?.userProfile?.lastName;
  const fullName =capitalizeFirstLetter(firstName+" "+lastName);
  return fullName.trim();
};
