import React,{useEffect,useContext} from 'react'
import loaderContext from "../../../context/loader/loaderContext";

const NotFound = () => {
    const { updateLoaderStatus } = useContext(loaderContext);

  useEffect(() => {
      updateLoaderStatus(false);
  },[]);
   
  return (
    <h2>Not Found</h2>
  )
}

export default NotFound