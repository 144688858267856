export class BOTAPIError extends Error {
    constructor(message) {
      super(message);
      this.name = 'BOT API Error';
    }
  }
  
  export class InternalAPIError extends Error {
    constructor(message) {
      super(message);
      this.name = 'Internal API Error:';
    }
  }
  