import React from "react";

const Loginloader = () => {
  return (
    <div>
      <div className="overlay-loading">

        <div className="loader-wrap">
        <div className="loader-dot loader-dot1"></div>
        <div className="loader-dot loader-dot2"></div>
        <div className="loader-dot loader-dot3"></div>
        <div className="loader-dot loader-dot4"></div>
        <div className="loader-text login-text-loader">MKS.ai Loading...</div>
        </div>
        
       
       
       
      </div>
    </div>
  );
};
export default Loginloader;
