import React from "react";
import WorkspaceSection from "./views/_partials/workspaceSection";
import { DndControllerProvider } from "../../../context/dndController/dndControllerProvider";

const MyworkSpace = () => {
  return (
    <div className="w-layout-hflex my-workspace-wrapper">
      <div className="app-left-col">
        <DndControllerProvider>
          <WorkspaceSection />
        </DndControllerProvider>
      </div>
    </div>
  );
};

export default MyworkSpace;
