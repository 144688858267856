import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { getPageTitle } from "../../../services/commonService.js";

const PageWithTitle = ({ children }) => {
  const location = useLocation();

  return (
    <>
      <Helmet>
        <title>{getPageTitle(location.pathname)}</title>
      </Helmet>
      {children}
    </>
  );
};

export default PageWithTitle;
