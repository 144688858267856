import React, { useEffect, useState } from "react";

const GenericDropdown = ({
  dropdownData = [],
  onDropdownSelection,
  selectedValue = "",
  title,
  inputRef,
}) => {
  const [selectedOptions, setSelectedOptions] = useState(new Set());
  const [selectedOptionsName, setSelectedOptionsName] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const selectedIds = selectedValue ? selectedValue.split(",") : [];
    setSelectedOptions(new Set(selectedIds));
    setSelectedOptionsName(
      dropdownData
        .filter((item) => selectedIds.includes(item.Id))
        .map((item) => item.Name)
    );
  }, [selectedValue, dropdownData]);

  const handleSelectChange = (item) => {
    const updatedSelectedOptions = new Set(selectedOptions);

    if (updatedSelectedOptions.has(item.Id)) {
      updatedSelectedOptions.delete(item.Id);
    } else {
      updatedSelectedOptions.add(item.Id);
    }

    setSelectedOptions(updatedSelectedOptions);

    // Convert selected IDs to a comma-separated string
    const updatedSelectedString = [...updatedSelectedOptions].join(",");
    const updatedNames = dropdownData
      .filter((item) => updatedSelectedOptions.has(item.Id))
      .map((item) => item.Name);

    setSelectedOptionsName(updatedNames);

    // Pass the comma-separated string to parent
    onDropdownSelection(updatedSelectedString);
  };

  const toggleDropdown = () => setIsOpen(!isOpen);

  return (
    <div className="custom-dropdown" ref={inputRef}>
      <div className="dropdown-button" onClick={toggleDropdown}>
        {selectedOptionsName.length === 0 ? (
          <span className="dropdown-button-span">{title}</span>
        ) : null}
        <span
          title={selectedOptionsName.join(", ")}
          className="all-selected-item "
        >
          {selectedOptionsName.slice(0, 1).map((name, index) => (
            <span className="selected-val" key={index}>
              {name}
            </span>
          ))}
          {selectedOptionsName.length > 1 && (
            <span className="more-tags">+{selectedOptionsName.length - 1}</span>
          )}
        </span>
        <span className="down-arrow"></span>
      </div>

      {isOpen && dropdownData.length > 0 && (
        <ul className="dropdown-menu-custom">
          {dropdownData.map((item) => (
            <li key={item.Id}>
              <label>
                <input
                  type="checkbox"
                  value={item.Id}
                  checked={selectedOptions.has(item.Id)}
                  onChange={() => handleSelectChange(item)}
                  className=""
                />
                {item.Name}
              </label>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default GenericDropdown;
