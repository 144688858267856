import { useContext, useEffect,useState } from "react";
import { UserContext } from "../../../../context/user/userContext";
import { getSkillStoreDetailsDataById } from "../../../mks-applications/skillStore/services/skillStoreService";

export const usePermissions = (skillStoreId) => {
  const[owners,setOwners]=useState([]);
  const {state} = useContext(UserContext);
  const permissions=state?.user?.privileges || [];
  const userId=state?.user?.userProfile?.user_id;

  useEffect(()=>{
      const fetchSkillDetails = async () => {
        if (skillStoreId) {
         const response= await getSkillStoreDetailsDataById(skillStoreId); 
         if (response?.Owner) {
            setOwners(response.Owner);
        }
        }
      };
      fetchSkillDetails(); 
    },[skillStoreId])

  // Find SuperAdmin permissions
  const superAdminPermissions = permissions.find(
    (perm) => perm.EntityName?.toLowerCase() === "superadmin"
  );

  return {
    isSuperAdmin: !!superAdminPermissions,
    canRead: superAdminPermissions?.CanRead || false,
    canCreate: superAdminPermissions?.CanCreate || false,
    canUpdate: superAdminPermissions?.CanUpdate || false,
    canDelete: superAdminPermissions?.CanDelete || false,
    isUserIdExist: owners?.includes(userId),
  };
};
