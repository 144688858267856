import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ChatBox from "../chatBox";

const ChatTerminalHeader = (props) => {
  const { isMKSChatSelected, chatMessage, setIsSkillChat, isFileType } = props;

  const [isMKSChat, setIsMKSChat] = useState(isMKSChatSelected);
  const [message, setMessage] = useState(chatMessage);
  const [defaultSkillWelMessage, setDefaultSkillWelMessage] = useState(false);
  const [isClearChat, setIsClearChat] = useState(false);
  const [isChatVisible, setIsChatVisible] = useState(false);
  const [openPdfViewer, setOpenPdfViewer] = useState(false);
  const [isFileTypeChat, setIsFileTypeChat] = useState(isFileType);
  const [isMKSProcessing, setIsMKSProcessing] = useState(false);
  const [isSkillProcessing, setIsSkillProcessing] = useState(false);

  const navigate = useNavigate();

  const handleMKSChat = () => {
    if (!isMKSChat) {
      setIsMKSChat(true);
      setMessage("");
      setDefaultSkillWelMessage(true);
      setOpenPdfViewer(false);
      setIsFileTypeChat(false);
      navigate("/chat", {
        state: {
          isMKSChat: true,
          message: "",
          isFileTyple: false,
        },
      });
    }
  };
  const handleSkillChat = () => {
    if (isMKSChat) {
      setIsMKSChat(false);
      setMessage("");
      setDefaultSkillWelMessage(true);
      setIsFileTypeChat(false);
      navigate("/chat", {
        state: {
          isMKSChat: false,
          message: "",
          isFileTyple: false,
        },
      });
    }
  };

  const showHideClearChatBtn = (isChatVisible) => {
    setIsChatVisible(isChatVisible);
  };

  const clearChat = () => {
    setIsClearChat(true);
    setIsSkillProcessing(false);
    setIsMKSProcessing(false);
    setOpenPdfViewer(false);
  };

  return (
    <>
      <div className="chat-terminal-header">
        <div
          className={`${
            !isMKSProcessing && isSkillProcessing ? "disabled-element" : ""
          }`}
        >
          <div
            onClick={() => handleMKSChat()}
            aria-current="page"
            className={`terminal-button w-button ${
              !isMKSProcessing && isSkillProcessing ? "disabled-div" : ""
            } ${isMKSChat ? "w--current" : ""} `}
          >
            MKS Chat
          </div>
        </div>
        <div
          className={`${
            isMKSProcessing && !isSkillProcessing ? "disabled-element" : ""
          }`}
        >
          <div
            onClick={() => handleSkillChat()}
            className={`terminal-button w-button ${
              isMKSProcessing && !isSkillProcessing ? "disabled-div" : ""
            } ${!isMKSChat ? "w--current" : ""} `}
          >
            Skills Chat
          </div>
        </div>
      </div>
      {isChatVisible && (
        <div
          className={`${
            isMKSProcessing || isSkillProcessing ? "disabled-element" : ""
          }`}
        >
          <div
            className={`clear-chatbtn ${
              isMKSProcessing || isSkillProcessing ? "disabled-div" : ""
            }`}
          >
            <div
              onClick={() => clearChat()}
              className="trash-icon"
              data-title="Clear Chat"
            ></div>
          </div>
        </div>
      )}

      <ChatBox
        chatMessage={message}
        isMKSChat={isMKSChat}
        setIsSkillChat={setIsSkillChat}
        defaultSkillWelMessage={defaultSkillWelMessage}
        setDefaultSkillWelMessage={setDefaultSkillWelMessage}
        isFileType={isFileType}
        setIsClearChat={setIsClearChat}
        isClearChat={isClearChat}
        isChatVisible={isChatVisible}
        showHideClearChatBtn={showHideClearChatBtn}
        openPdfViewer={openPdfViewer}
        setOpenPdfViewer={setOpenPdfViewer}
        setIsFileTypeChat={setIsFileTypeChat}
        isFileTypeChat={isFileTypeChat}
        isMKSProcessing={isMKSProcessing}
        setIsMKSProcessing={setIsMKSProcessing}
        isSkillProcessing={isSkillProcessing}
        setIsSkillProcessing={setIsSkillProcessing}
      />
    </>
  );
};

export default ChatTerminalHeader;
