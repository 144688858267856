import React from "react";

const SkillTags = ({ skillTags }) => {
  return (
    <div className="skill-tags">
      <h3 className="skill-subheading">Tags</h3>
      <div className="keyword-block">
        {skillTags?.map((a) => (
          <div key={a.Id} className="skill-keyword">
            {a.Name}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SkillTags;
