import { useState } from "react";
import DndControllerContext from "./dndControllerContext";

export const DndControllerProvider = ({ children }) => {
  const [isSelected, setIsSelected] = useState(false);

  return (
    <DndControllerContext.Provider value={{ isSelected, setIsSelected }}>
      {children}
    </DndControllerContext.Provider>
  );
};
