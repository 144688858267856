import React, { useEffect, useState, useContext } from "react";
import SkillCard from "../skillCard";
import {
  getSkillFunctionData,
  getSkillSubFunctionData,
} from "../../../services/skillStoreService";
import SkillStoreFilterSection from "../skillStoreFilterSection";
import { Pager } from "../../../../shared/pager";

const ThirdPartyAIToolsSkills = ({
  limit,
  originalSkills,
  skillDepartmentData,
  skillStoreScopeData,
  IsThirdPartyAITool,
  setOriginalSkills,
  updateOriginalData,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [skills, setSkills] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedFunction, setSelectedFunction] = useState([]);
  const [selectedSubFunction, setSelectedSubFunction] = useState([]);
  const [selectedScope, setSelectedScope] = useState("");
  const [skillFunctiondData, setSkillFunctionData] = useState([]);
  const [skillSubFunctionData, setSkillSubFunctionData] = useState([]);
  const [currentPageNbr, setCurentPageNbr] = useState(1);
  const [currentPageSize, setCurrentPageSize] = useState(10);
  const [skillCountList, setSkillCountList] = useState([]);

  useEffect(() => {
    const filterSkills = () => {
      let filteredSkills = originalSkills;
      if (filteredSkills !== undefined) {
        filteredSkills = filteredSkills.filter(
          (item) => item.IsThirdPartyAITool
        );

        if (searchTerm) {
          const lowercasedVal = searchTerm.toLowerCase();
          filteredSkills = filteredSkills?.filter((skill) =>
            skill.Name.toLowerCase().includes(lowercasedVal)
          );
        }

        if (selectedDepartment) {
          const globalVal = process.env.REACT_APP_DEFAULT_DEPARTMENT;
          const department = skillDepartmentData.find(
            (dep) => dep.Id === parseInt(selectedDepartment)
          );
          if (department) {
            const departmentName = department.Name;
            filteredSkills = filteredSkills?.filter(
              (skill) =>
                skill.DepartmentName.toLowerCase() ===
                  departmentName.toLowerCase() ||
                /* Additional condition here */
                skill.DepartmentName.toLowerCase() === globalVal
            );
          }
        }

        if (selectedFunction?.length > 0 && Array.isArray(selectedFunction)) {
          filteredSkills = filteredSkills?.filter((skill) =>
            skill.functions.some((func) => selectedFunction.includes(func.Id))
          );
        }

        if (
          selectedSubFunction?.length > 0 &&
          Array.isArray(selectedSubFunction)
        ) {
          filteredSkills = filteredSkills?.filter((skill) =>
            skill.subFunctions.some((func) =>
              selectedSubFunction.includes(func.Id)
            )
          );
        }

        if (selectedScope) {
          filteredSkills = filteredSkills?.filter(
            (skill) =>
              skill.SkillScope.toLowerCase() === selectedScope.toLowerCase()
          );
        }

        setSkillCountList(filteredSkills);
        const totalPages = Math.ceil(filteredSkills?.length / currentPageSize);
        if (currentPageNbr > 1) {
          if (currentPageNbr > totalPages) {
            setCurentPageNbr(1);
          }
        }

        if (parseInt(currentPageSize) === -1) {
          setSkills([...filteredSkills]);
        } else {
          const startIndex = (currentPageNbr - 1) * parseInt(currentPageSize);
          if (filteredSkills?.length <= startIndex) {
            setSkills([...filteredSkills]);
          } else {
            setSkills([
              ...filteredSkills?.slice(
                startIndex,
                startIndex +
                  parseInt(
                    parseInt(currentPageSize) === -1
                      ? filteredSkills.length
                      : currentPageSize
                  )
              ),
            ]);
          }
        }
      }
    };

    filterSkills();
  }, [
    searchTerm,
    selectedDepartment,
    selectedFunction,
    selectedSubFunction,
    selectedScope,
    originalSkills,
    currentPageNbr,
    currentPageSize,
    IsThirdPartyAITool,
  ]);

  const searchByInputVal = (val) => {
    setSearchTerm(val);
  };

  const searchBySkillDepartment = async (selectedVal) => {
    setSelectedDepartment(selectedVal);
    const functionData = await getSkillFunctionData(
      selectedVal === "" ? 0 : selectedVal
    );

    if (selectedVal === "") {
      setSelectedFunction("");
      setSelectedSubFunction("");
    }

    if (functionData !== null && functionData !== undefined) {
      const updateData = functionData.filter(
        (item) => item.Name.toLowerCase() !== "all"
      );
      setSkillFunctionData(updateData);
      setSkillSubFunctionData([]);
    }
  };

  const searchBySkillFunction = async (selectedVal) => {
    if (selectedVal.length === 0) setSelectedSubFunction("");

    setSelectedFunction(selectedVal);
    //Call here Sub Function Method
    const subFunctionData = await getSkillSubFunctionData(selectedVal);
    if (subFunctionData !== null && subFunctionData !== undefined) {
      setSkillSubFunctionData(subFunctionData);
    } else setSkillSubFunctionData([]);
  };

  const searchBySkillSubFunction = async (selectedVal) => {
    setSelectedSubFunction(selectedVal);
  };

  const searchByScope = (selectedVal) => {
    setSelectedScope(selectedVal);
  };

  const onPageNumberChange = (newPageNumber) => {
    setCurentPageNbr(newPageNumber);
  };

  const onPageSizeChange = (event) => {
    const pageSizeValue = event.target.value;
    setCurentPageNbr(1);
    setCurrentPageSize(parseInt(pageSizeValue));
  };

  const updateLikeCountAndStatus = (likeCount, likeStatus, skillId) => {
    setOriginalSkills((prevData) =>
      prevData.map((item) =>
        item.Id === skillId
          ? { ...item, IsLiked: likeStatus, LikeCount: likeCount }
          : item
      )
    );
  };

  const getPagerArea = () => {
    return (
      <Pager
        pageRecords={skills === undefined ? 0 : skills.length}
        totalRecords={skillCountList.length}
        pageNumber={currentPageNbr}
        pageSize={currentPageSize}
        onPageNumberChange={onPageNumberChange}
        onPageChangeSize={onPageSizeChange}
        pageSizeOptions={[10, 20, 50, 100]}
        selectStyleClass={"no-border"}
        hidePageNumber={false}
        paginationRangeSeparator={"-"}
        keepAllPageOptions={true}
      ></Pager>
    );
  };

  return (
    <>
      {!limit && (
        <SkillStoreFilterSection
          key={IsThirdPartyAITool}
          onInputSearchSkillStore={searchByInputVal}
          searchDepartmentData={skillDepartmentData}
          onSearchBySkillStoreDepartment={searchBySkillDepartment}
          searchFunctionData={skillFunctiondData}
          onSearchBySkillStoreFunction={searchBySkillFunction}
          searchSubFunctionData={skillSubFunctionData}
          onSearchBySkillStoreSubFunction={searchBySkillSubFunction}
          searchSkillStoreScopeData={skillStoreScopeData}
          onSearchBySkillStoreScope={searchByScope}
        />
      )}
      {!limit && skills.length > 0 && (
        <div className="pagination-custom">{getPagerArea()}</div>
      )}

      {!limit && (
        <div className="prompts-tabs-pane w-tab-pane w--tab-active">
          {!limit && skills?.length > 0 && (
            <div className="w-layout-grid ai-collection-grid light">
              {skills.map((skill, index) => (
                <SkillCard
                  IsThirdPartyAITool={IsThirdPartyAITool}
                  key={index}
                  skill={skill}
                  updateLikeCountAndStatus={updateLikeCountAndStatus}
                  updateData={updateOriginalData}
                />
              ))}
            </div>
          )}

          {!limit && skills?.length === 0 && (
            <div className="w-layout-grid ai-collection-grid light skill-center-grid">
              <div>No Skill(s) Available</div>
            </div>
          )}
        </div>
      )}
      {!limit && skills.length > 0 && (
        <div className="pagination-custom">{getPagerArea()}</div>
      )}
    </>
  );
};

export default ThirdPartyAIToolsSkills;
