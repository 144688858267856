import axiosApiInstance from "../api/axiosApiClient";
import axios from "axios";
import {
  setStorageToken,
  isGivenTokenEmpty,
  getStorageToken,
} from "./tokenService";

const BASE_AUTH_API_URL = process.env.REACT_APP_AUTHAPI_URL;
const LOGIN_API_URL = BASE_AUTH_API_URL + process.env.REACT_APP_LOGIN_API_URL;
const CORP_LOGIN_API_URL = BASE_AUTH_API_URL + process.env.REACT_APP_CORP_LOGIN_API_URL;
const USER_DETAIL_API_URL =
  BASE_AUTH_API_URL + process.env.REACT_APP_USER_DETAILS_API_URL;
const REFRESH_TOKEN_API_URL =
  BASE_AUTH_API_URL + process.env.REACT_APP_REFRESH_TOKEN_API_URL;
export const refreshToken = async (token) => {
  try {
    if (isGivenTokenEmpty(token)) {
      token = getStorageToken();
    }

    const response = await axiosApiInstance.post(`${REFRESH_TOKEN_API_URL}`);
    if (response.status === 200) {
      const json = await response.data;
      setStorageToken(json);
      return json;
    } else {
      setStorageToken("");
      return "";
    }
  } catch (err) {
    throw new Error("Invalid User");
  }
};

export const UserProfile = async (token) => {
  try {
    if (isGivenTokenEmpty(token)) {
      token = getStorageToken();
    }
    const response = await axiosApiInstance.get(`${USER_DETAIL_API_URL}`);

    if (response.status === 200) {
      const userinfo = await response.data;
      return userinfo;
    }
  } catch (err) {
    throw new Error("Invalid User");
  }
};

export const UserLogin = async (requestBody) => {
  const response = await axiosApiInstance.post(LOGIN_API_URL, requestBody);
  return response;
};

export const UserCorpLogin = async (token) => {
  const headers = { 'Accept': 'application/json', 'Content-Type': 'application/json' }
  headers['Authorization'] = 'Bearer ' + token
  const response = await axios({url: CORP_LOGIN_API_URL, headers: headers, data: null, method: 'POST'})
  .then(response => { return response })
  return response;
};

export const UserPrivileges = async (userID) => {
  try {
    const privilegesRes = await axiosApiInstance.get(
      `${BASE_AUTH_API_URL}account/GetUserAccess`,
      { securityAppName: "RAP", userID: userID }
    );
    if (privilegesRes.status === 200) {
      return await privilegesRes.data;
    }
  } catch (err) {
    throw new Error("Invalid User");
  }
};
