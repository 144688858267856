import React, { useState, useEffect, useContext } from "react";
import AppImages from "../../../../../../../constants/images";
import { getMultiFileClassByExtension } from "../../../../shared/fileExtension";
import PdfURLContext from "../../../../../../../context/pdfURL/pdfURLContext";
import { toast } from "react-toastify";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import $ from "jquery";

import UserImage from "../../../../../../common/userImage/index.js";
import { UserContext } from "../../../../../../../context/user/userContext.js";

const TextChatbot = ({
  showChatLoader,
  messages,
  initials,
  displayLastCitations,
  lastDisplayMessage,
  setOpenPdfViewer,
  setSelectedCitation,
  isTyping,
  setIsMKSProcessing,
  setIsSkillProcessing,
}) => {
  const { url, setUrl } = useContext(PdfURLContext);
  const { state } = useContext(UserContext);
  const imagebytes = state?.user?.userProfile?.userImage;
  const imagetype = state?.user?.userProfile?.userImagemimeType;
  const getMultiFileData = (files) => {
    if (files.length > 0) {
      files.map((file) => {
        const extensionClassName = getMultiFileClassByExtension(file.name);
        return (
          <div className="multifile">
            <div className="upload-drag">
              <div
                title="Click to download"
                onClick={() => handleDownloadFile(file)}
                className={`${extensionClassName}-icon-list chat-file-dowbload`}
              >
                {file.name}
              </div>
            </div>
          </div>
        );
      });
    }
    const handleDownloadFile = (file) => {
      const url = URL.createObjectURL(file);
      const link = document.createElement("a");
      link.href = url;
      link.download = file.name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    };
  };

  const displayAllMessage = (msg) => {
    return (
      <>
        {ReplaceDocsComponent(msg.text, msg.citations)}
        {msg.citations.map((item, index) => {
          return (
            <li className="linkclass" onClick={() => handleOpenPdfViewer(item)}>
              {item.filepath}
            </li>
          );
        })}
      </>
    );
  };
  const handleOpenPdfViewer = (item) => {
    const MKS_APP_VIEW_DOCUMENT_API_PARAMS =
      process.env.REACT_APP_VIEW_DOCUMENT_API_PARAMS;
    let baseUrl = process.env.REACT_APP_VIEW_DOCUMENT_API_URL;
    if (item.url.indexOf(baseUrl) === -1) {
      item.url = baseUrl + item.url;
    }
    if (item.url.indexOf(MKS_APP_VIEW_DOCUMENT_API_PARAMS) === -1) {
      item.url = item.url + MKS_APP_VIEW_DOCUMENT_API_PARAMS;
    }
    setOpenPdfViewer(true);
    setSelectedCitation(item);
    setUrl(item.url);
  };
  const displayLastMessage = () => {
    return (
      <>
        {displayLastCitations.length === 0 &&
          ReplaceDocsComponent(lastDisplayMessage, [])}
        {displayLastCitations.length > 0 &&
          ReplaceDocsComponent(lastDisplayMessage, displayLastCitations)}
        {displayLastCitations.map((item, index) => {
          return (
            <li className="linkclass" onClick={() => handleOpenPdfViewer(item)}>
              {item.filepath}
            </li>
          );
        })}
      </>
    );
  };

  const ReplaceDocsComponent = (text, citations) => {
    const replacedText = replaceDocs(text, citations);
    return (
      <ReactMarkdown
        remarkPlugins={[remarkGfm]}
        components={{
          ol: ({ children }) => (
            <ol style={{ paddingLeft: "1.5rem", margin: "0" }}>{children}</ol>
          ),
          li: ({ children }) => (
            <li style={{ whiteSpace: "normal", wordBreak: "break-word" }}>
              {children}
            </li>
          ),
        }}
        rehypePlugins={[rehypeRaw]}
      >
        {replacedText}
      </ReactMarkdown>
    );
    //return <div dangerouslySetInnerHTML={{ __html: replacedText }} />;
  };
  // const generateLink = (item, name) => {
  //   return (
  //     <li className="linkclass" onClick={() => handleOpenPdfViewer(item)}>
  //       {name}
  //     </li>
  //   );
  // };
  useEffect(() => {
    $(document).on("click", ".linkclassText", function () {
      const index = $(this).text() - 1;
      const citation = messages.flatMap((msg) => msg.citations || [])[index];
      if (citation) {
        handleOpenPdfViewer(citation);
      }
    });

    return () => {
      $(document).off("click", ".linkclassText");
    };
  }, [messages]);

  useEffect(() => {
    $(document).on("click", ".linkclassText", function () {
      const index = $(this).text() - 1;
      const citation = displayLastCitations?.flatMap((msg) => msg || [])[index];
      if (citation) {
        handleOpenPdfViewer(citation);
      }
    });
    return () => {
      $(document).off("click", ".linkclassText");
    };
  }, [displayLastCitations?.length]);

  const replaceDocs = (text, citations) => {
    const docPattern = /\[doc(\d+)\]/g;
    return text.replace(docPattern, (match, p1) => {
      let findCitation = citations[parseInt(p1) - 1];
      if (findCitation) {
        // $(document).on("click", ".linkclassText", function () {
        //   const index = $(this).index();
        //   const citation = citations[index];
        //   handleOpenPdfViewer(citation);
        // });
        return `<span className="linkclassText"><b>${p1}</b></span>`;
      }
    });
  };

  const getCopyButton = (msgText) => {
    return (
      <button
        className="copy-clipboard"
        aria-label="copy to clipboard"
        onClick={() => {
          const textToCopy = msgText;
          // Check if Clipboard API is supported
          if (navigator.clipboard && navigator.clipboard.writeText) {
            navigator.clipboard
              .writeText(textToCopy)
              .then(() => {
                toast.success("Copied to clipboard successfully");
              })
              .catch((err) => {
                console.error("Clipboard API failed:", err);
                fallbackCopyText(textToCopy);
              });
          } else {
            // Use fallback for non-HTTPS or unsupported environments
            fallbackCopyText(textToCopy);
          }
        }}
      ></button>
    );
  };

  const enableTab = () => {
    setIsMKSProcessing(false);
    setIsSkillProcessing(false);
  };

  const fallbackCopyText = (text) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;

    // Style the textarea so it won't interfere visually
    textArea.style.position = "fixed"; // Prevent scrolling to bottom of the page
    textArea.style.opacity = "0";
    document.body.appendChild(textArea);

    textArea.focus();
    textArea.select();

    try {
      const successful = document.execCommand("copy");
      if (successful) {
        toast.success("Copied to clipboard successfully");
      } else {
        toast.error("Failed to copy");
      }
    } catch (err) {
      console.error(" copy failed:", err);
      toast.error(" failed to copy!");
    }

    document.body.removeChild(textArea);
  };
  const getMarkDownText = (text) => {
    return (
      <ReactMarkdown
        remarkPlugins={[remarkGfm]}
        components={{
          ol: ({ children }) => (
            <ol style={{ paddingLeft: "1.5rem", margin: "0" }}>{children}</ol>
          ),
          li: ({ children }) => (
            <li
              style={{
                whiteSpace: "normal",
                wordBreak: "break-word",
              }}
            >
              {children}
            </li>
          ),
        }}
        rehypePlugins={[rehypeRaw]}
      >
        {text}
      </ReactMarkdown>
    );
  };

  return (
    <>
      {messages.map((msg, index) =>
        msg.type === "user" ? (
          <div className="chat-msg user" key={index + " _" + 2}>
            <div className="user-profile-menu w-inline-block">
              <div className="user-profile-initial">
                <UserImage
                  userImageBytes={imagebytes}
                  userImageMimeType={imagetype}
                  userInitials={initials}
                ></UserImage>
              </div>
            </div>

            <div className="chat-icon-text">
              <div className="msg-text">
                {/* <div style={{ whiteSpace: "pre-line" }}>
                  {msg.text}
                </div> */}
                <div style={{ whiteSpace: "pre-line" }}>
                  {getMarkDownText(msg.text)}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="chat-msg reply" key={index + " _" + 1}>
            <div className="chat-user-icon">
              <img
                src={msg.imageURL}
                loading="lazy"
                alt=""
                className="chat-user-img"
              />
            </div>
            <div className="chat-icon-text">
              <div className="msg-text">
                <div className="chat-text">
                  {messages.length - 1 === index && showChatLoader && (
                    <img src={AppImages.chatLoader} alt="" />
                  )}
                  <div style={{ whiteSpace: "pre-line" }}>
                    {messages.length - 1 === index
                      ? displayLastMessage()
                      : msg.text === "loader"
                      ? ""
                      : displayAllMessage(msg)}
                    {!showChatLoader &&
                      isTyping === true &&
                      messages.length - 1 === index && (
                        <span
                          style={{
                            opacity: isTyping ? 1 : 0,
                            transition: "opacity 0.6s",
                          }}
                        >
                          |
                        </span>
                      )}

                    {messages.length - 1 === index
                      ? ""
                      : getCopyButton(msg.text)}

                    {!isTyping && !showChatLoader && getCopyButton(msg.text)}
                    {!isTyping && !showChatLoader && enableTab()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      )}
    </>
  );
};

export default TextChatbot;
