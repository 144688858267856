import React, { useContext, useEffect, useState, useRef } from "react";
import AppTiles from "../appTiles";
import { getWorkspaceCardsData } from "../../../services/myWorkspaceService";
import DndControllerContext from "../../../../../../context/dndController/dndControllerContext";
import loaderContext from "../../../../../../context/loader/loaderContext";
import { enableDashboardCard } from "../../../services/myWorkspaceService";
import WorkspaceAppList from "../workspaceAppList";
import showMessage from "../../../../../common/message";
import AppImages from "../../../../../../constants/images";

const WorkspaceSection = () => {
  const [appsTiles, setAppsTiles] = useState([]);
  const [show, setShow] = useState(false);
  const { isSelected, setIsSelected } = useContext(DndControllerContext);
  const [originalTiles, setOriginalTiles] = useState([]);
  const [isCross, setIsCross] = useState(false);
  const [isShowPopUp, setIsShowPopUp] = useState(false);
  const [workspaceData, setWorkspaceData] = useState([]);
  const refInputVal = useRef(null);

  const { updateLoaderStatus } = useContext(loaderContext);

  useEffect(() => {
    const getData = () => {
      bindWorkspaceTiles();
    };
    getData();
  }, []);

  const bindWorkspaceTiles = async () => {
    updateLoaderStatus(true);
    setAppsTiles([]);
    setOriginalTiles([]);
    setWorkspaceData([]);
    const data = await getWorkspaceCardsData();
    const groupedData = getGroupedData(data);
    setAppsTiles(groupedData);
    setOriginalTiles(data);
    setWorkspaceData(data);
    updateLoaderStatus(false);
  };

  const handelAppPopUp = () => {
    setIsShowPopUp(!isShowPopUp);
  };

  const onSaveWorkSpace = (formattedTiles) => {
    setIsShowPopUp(!isShowPopUp);
    enableDashboardCard(formattedTiles).then((resp) => {
      if (resp !== "") {
        showMessage(resp?.Status);
        bindWorkspaceTiles();
      }
    });
  };

  const getGroupedData = (data) => {
    const groupedData = [];
    data.forEach((item) => {
      let categoryObject = groupedData.find(
        (group) => group.AppCategory === item.AppCategory
      );
      if (categoryObject) {
        categoryObject.items.push(item);
      } else {
        groupedData.push({
          AppCategory: item.AppCategory,
          items: [item],
        });
      }
    });
    return groupedData;
  };

  const handleDragableButton = () => {
    setShow(!show);
    setIsSelected(!isSelected);
  };

  const filterTiles = (searchText = "") => {
    // Make a deep copy of the original tiles
    let filteredTiles = JSON.parse(JSON.stringify(originalTiles));

    // Filter tiles based on the search text
    const filteredData =
      searchText && searchText.trim() !== ""
        ? filteredTiles.filter(
            (tile) =>
              tile.Heading?.toLowerCase().includes(searchText.toLowerCase()) ||
              tile.SubHeading?.toLowerCase().includes(searchText.toLowerCase())
          )
        : filteredTiles;

    // Group the filtered data
    const groupedData = getGroupedData(filteredData);

    // Get a list of all unique categories from the original tiles
    const allCategories = new Set(
      originalTiles.map((tile) => tile.AppCategory)
    );

    // Create the final data structure
    const finalData = Array.from(allCategories).map((category) => {
      const categoryGroup = groupedData.find(
        (group) => group.AppCategory === category
      );
      return {
        AppCategory: category,
        items: categoryGroup ? categoryGroup.items : [], // Ensure category is included with empty items if not in groupedData
      };
    });

    // Update the state with the final data
    setAppsTiles(finalData);
  };

  const handleOnChange = () => {
    const searchText = refInputVal.current.value;
    if (searchText.length === 0) setIsCross(false);
    else setIsCross(true);
    filterTiles(searchText);
  };

  const resetInputBoxValue = () => {
    refInputVal.current.value = null;
    setIsCross(false);
    filterTiles();
  };

  return (
    <div className="wrkspace-section-wrap">
      <div className="dash-section p20">
        {appsTiles?.length > 0 && (
          <div className="w-layout-hflex h-flex head-wrapper input-workspace">
            <div className="custom-application" onClick={handelAppPopUp}>
              <img
                src={AppImages.listicon}
                alt=""
                title="Customize Workspace Apps"
              />
            </div>
            <div className="flex-workspace">
              <div className="input-search-box">
                <input
                  ref={refInputVal}
                  className="search-tile-box"
                  placeholder="Search Apps"
                  maxLength="256"
                  onChange={() => {
                    handleOnChange();
                  }}
                ></input>
                {isCross === false ? (
                  <div className="search-tile-icon"></div>
                ) : (
                  <div
                    className="cross-tile-box-workspace"
                    onClick={resetInputBoxValue}
                  ></div>
                )}
              </div>
              <div className="draggable-button">
                <img
                  style={{
                    display: !show ? "block" : "none",
                    cursor: "pointer",
                  }}
                  loading="lazy"
                  alt=""
                  onClick={handleDragableButton}
                  src={AppImages.draggableInactiveIcon}
                  className="dragbutton-active"
                  title="Enable Rearrange Apps"
                />
                <img
                  style={{
                    display: show ? "block" : "none",
                    cursor: "pointer",
                  }}
                  loading="lazy"
                  alt=""
                  onClick={handleDragableButton}
                  src={AppImages.draggableActiveIcon}
                  className="dragbutton-inactive"
                  title="Disable Rearrange Apps"
                />
              </div>
            </div>
          </div>
        )}

        {appsTiles.map((tiles, index) => (
          <AppTiles key={index} appsTiles={tiles}></AppTiles>
        ))}

        {isShowPopUp && (
          <WorkspaceAppList
            handelAppPopUp={handelAppPopUp}
            tiles={workspaceData}
            onSaveWorkSpace={onSaveWorkSpace}
          />
        )}
      </div>
    </div>
  );
};

export default WorkspaceSection;
