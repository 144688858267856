import React, { useEffect, useRef, useState } from "react";
import { getTagsData } from "../../../../promptManagement/services/promptManagementService";
import { toast } from "react-toastify";

const SkillTagsDetail = (props) => {
  const {
    skillStoreForm,
    updateSkillStoreForm,
    validationFieldName,
    setValidationFieldName,
  } = props;
  const [inputVal, setInputVal] = useState("");
  const [tagsData, setTagsData] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [filteredTags, setFilteredTags] = useState([]);
  const textInputref = useRef("");
  const tagSuggestionRef = useRef(null);

  useEffect(() => {
    const getDataOfTags = async () => {
      const tagsData = await getTagsData();
      const namesOnly = tagsData?.map((tag) => tag.Name);
      setTagsData(namesOnly);
    };

    getDataOfTags();
  }, []);

  useEffect(() => {
    if (validationFieldName === "Tags") {
      textInputref.current.classList.add("border-red");
      textInputref.current.focus();
    }
  }, [validationFieldName]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (filteredTags?.length > 0) {
        if (e.key === "ArrowDown") {
          e.preventDefault();
          setSelectedIndex(
            (prevIndex) => (prevIndex + 1) % filteredTags.length
          );
        } else if (e.key === "ArrowUp") {
          e.preventDefault();
          setSelectedIndex(
            (prevIndex) =>
              (prevIndex - 1 + filteredTags.length) % filteredTags.length
          );
        } else if (e.key === "Enter" && selectedIndex >= 0) {
          e.preventDefault();
          const selectedTag = filteredTags[selectedIndex];
          handleTagSelect(selectedTag);
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [selectedIndex, filteredTags]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        tagSuggestionRef.current &&
        !tagSuggestionRef.current.contains(event.target) // Ensure the dropdown button is also excluded
      ) {
        setFilteredTags([]);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [inputVal]);

  const handleRemoveTag = (tag) => {
    let tagsData = skillStoreForm.tags.filter((tagItem) => tagItem !== tag);
    updateSkillStoreForm("tags", tagsData);
  };

  const handleTagSelect = (tag) => {
    updateSkillStoreForm("tags", [...skillStoreForm.tags, tag]);
    setTagsData(tagsData.filter((tagItem) => tagItem !== tag));
    textInputref.current.classList.remove("border-red");
    setValidationFieldName("");
    setInputVal("");
    setFilteredTags([]);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputVal(value);
    textInputref.current.classList.remove("border-red");
    setValidationFieldName("");
    // Filter tags based on input value
    const filtered = tagsData?.filter((tag) =>
      tag.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredTags(filtered);
  };

  const handleAddTag = () => {
    if (inputVal && !skillStoreForm.tags.includes(inputVal)) {
      updateSkillStoreForm("tags", [...skillStoreForm.tags, inputVal]);
      setInputVal("");
      setFilteredTags([]);
      textInputref.current.classList.remove("border-red");
      setValidationFieldName("");
    } else if (inputVal?.trim() === "" || inputVal === undefined) {
      textInputref.current.classList.add("border-red");
      toast.error("Please provide input for Tag");
      textInputref.current.focus();
    }
  };

  return (
    <div className="dash-section p20 prompt-step-two">
      <div className="prompt-tags">
        <div className="tag-flex">
          <h3 className="prompt-title">
            Tags <span className="red">*</span>
          </h3>
        </div>

        <div className="prompt-tags-wrap">
          {skillStoreForm.tags?.length > 0 &&
            skillStoreForm.tags?.map((item, index) => (
              <div className="p-tag" key={index}>
                {item}
                <div
                  className="close-icon"
                  onClick={() => handleRemoveTag(item)}
                ></div>
              </div>
            ))}
        </div>
      </div>
      <div className="addtag-input">
        <input
          type="text"
          className="input-tag"
          value={inputVal}
          ref={textInputref}
          onChange={handleInputChange}
        />
        <button onClick={() => handleAddTag()}>Add</button>

        {filteredTags?.length > 0 && (
          <div
            ref={tagSuggestionRef}
            className="suggestions mks-suggestion-prompt"
          >
            <div className="suggestion-item-heading">Suggestions</div>
            {filteredTags.map((tag, index) => (
              <div
                key={index}
                className={`suggestion-item ${
                  index === selectedIndex ? "selected" : ""
                }`}
                onClick={() => handleTagSelect(tag)}
              >
                {tag}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default SkillTagsDetail;
