import React from "react";

const FeedbackDetailPopUp = (props) => {
  const { feedbackFullText, hideFullFeedbackPopUp } = props;
  return (
    <>
      <div
        className="modal-wrapper app-list show-modal-wrapper"
        
      >
       
        <div className="modal-block border-radius-5 feedbackmodal">
        <div className="cross-icon" onClick={hideFullFeedbackPopUp}></div>
          <div className="confirmation-content-wrapper ">
            <div className="modal-content">
              <div className="modal-body ">
                <div className="applist-view">{feedbackFullText}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FeedbackDetailPopUp;
