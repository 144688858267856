import axiosApiInstance from "../../../../api/axiosApiClient";
import { InternalAPIError } from "../../../../utils/apiErrors";
import { handleApiError } from "../../../../utils/globalErrorHandler";
const BASE_AUTH_API_URL = process.env.REACT_APP_AUTHAPI_URL;

export const updateApplicationBackgroundMode = async (mode) => {
  try {
    const response = await axiosApiInstance.patch(
      `${BASE_AUTH_API_URL}account/UpdateApplicationBackgroundMode?Mode=${mode}`
    );
    if (response?.status === 201) {
      return response.data;
    } else {
      handleApiError(new InternalAPIError());
    }
  } catch (error) {
   handleApiError(new InternalAPIError());
  }
};
