import axiosApiInstanceMain from "../../../../api/axiosApiClient";
import { ExtractCurlParameters } from "../../promptManagement/shared/curlConverter";
import { InternalAPIError } from "../../../../utils/apiErrors";
import { handleApiError } from "../../../../utils/globalErrorHandler";

const MKS_REACT_APP_API_URL = process.env.REACT_APP_MKS_API_URL;
const BASE_MKSPROMPTMANAGEMENT_API_URL = process.env.REACT_APP_MKS_API_URL;

export const getAdditives = async () => {
  try {
    const response = await axiosApiInstanceMain.get(
      `${MKS_REACT_APP_API_URL}common/GetAdditives`
    );
    if (response.status === 200) {
      return response.data;
    } else {
      handleApiError(new InternalAPIError());
    }
  } catch (error) {
    handleApiError(new InternalAPIError());
  }
};

export const getChatPromptManagementStoreData = async (skillStoreId) => {
  try {
    const response = await axiosApiInstanceMain.get(
      `${BASE_MKSPROMPTMANAGEMENT_API_URL}prompt/GetChatPromptUserInput?SkillStoreId=${skillStoreId}`
    );

    if (response.status === 200) {
      response.data.map((skill) => {
        let params = ExtractCurlParameters(
          skill.ChatbotConfiguration.CurlRequestString
        );
        skill.aiUrl = params.url;
        skill.headers = params.headers;
        skill.apiKey = "";
        skill.messageBody = params.body;
        skill.userMessage = skill.ChatbotConfiguration.UserMessageFormat;
        skill.AssistantMessageFormat =
          skill.ChatbotConfiguration.AssistantMessageFormat;
        skill.ShowCitation = skill.ChatbotConfiguration.ShowCitation;
        return skill;
      });
      return response.data;
    } else {
      handleApiError(new InternalAPIError());
    }
  } catch (error) {
    handleApiError(new InternalAPIError());
  }
};

export const DisableFollowupQuestions = async (data) => {
  try {
    const response = await axiosApiInstanceMain.put(
      `${BASE_MKSPROMPTMANAGEMENT_API_URL}skillstore/DisableFollowupQuestions`,
      data
    );
    if (response.status === 200) {
      return response.data;
    } else {
      handleApiError(new InternalAPIError());
    }
  } catch (error) {
    handleApiError(new InternalAPIError());
  }
};
