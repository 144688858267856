import React, { useContext, useEffect } from "react";
import { UserContext } from "../../../context/user/userContext";
import { Navigate,useNavigate } from "react-router-dom";
import { clearPromptMessageSession, removeCorpToken } from "../../../services/commonService";
const Logout = () => {
  const { state, logout,removeAll } = useContext(UserContext);
  const navigate = useNavigate();
  useEffect(() => {
    logout(false);
    removeAll();
    clearPromptMessageSession();
    removeCorpToken();
    navigate("/login")
  }, [logout, state]);
return <>
 <Navigate to="/login" />;
</>
};

export default Logout;
