import React, { useEffect, useState } from "react";
import { getAdditives } from "../../../services/chatService";
import { blackBoxAndTranslate } from "../../../../../../constants/enums";

const Additives = ({ expandChecks, resetAdditives, setSelectedAdditives }) => {
  const [additives, setAdditives] = useState([]);
  const [blackBoxSelected, setBlackBoxSelected] = useState(false);
  const [selectedAdditivesLocal, setSelectedAdditivesLocal] = useState([]);
  const [translateSelected, setTranslateSelected] = useState(false);

  useEffect(() => {
    fetchAdditives();
    setTranslateSelected(false);
    setBlackBoxSelected(false);
  }, [!resetAdditives]);

  const disbaleChecks = (e) => {
    let checked = e.target.checked;

    setBlackBoxSelected(checked);
    if (checked) {
      let copyAdditives = [...additives];
      setSelectedAdditivesLocal([]);

      copyAdditives.map((addi) => {
        addi.checked = false;
        return addi;
      });

      let blackbox = blackBoxAndTranslate();
      blackbox.forEach((addi) => {
        if (addi.Name === "Blackbox") {
          addi.checked = checked;
        }
        if (addi.Name === "Translate") {
          addi.checked = translateSelected;
        }
        copyAdditives.push(addi);
      });

      setSelectedAdditivesLocal([...copyAdditives]);
      setSelectedAdditives([...copyAdditives]);
    } else {
      setSelectedAdditivesLocal([]);
      let copyAdditives = [...additives];
      setSelectedAdditivesLocal([]);

      copyAdditives.map((addi) => {
        addi.checked = false;
        return addi;
      });

      let blackbox = blackBoxAndTranslate();
      blackbox.forEach((addi) => {
        if (addi.Name === "Blackbox") {
          addi.checked = false;
        }
        if (addi.Name === "Translate") {
          addi.checked = translateSelected;
        }
        copyAdditives.push(addi);
      });

      setSelectedAdditivesLocal([...copyAdditives]);
      setSelectedAdditives([...copyAdditives]);
    }
    let copyAdditives = [...additives];

    copyAdditives.map((addi) => {
      addi.checked = false;
      return addi;
    });
    setAdditives([...copyAdditives]);
  };

  const includeTranslate = (e) => {
    let checked = e.target.checked;
    setTranslateSelected(checked);
    if (checked) {
      let copyAdditives = [...additives];
      setSelectedAdditivesLocal([]);

      let bb = blackBoxAndTranslate();
      bb.map((addi) => {
        if (addi.Name === "Translate") {
          addi.checked = checked;
        }
        if (addi.Name === "Blackbox") {
          addi.checked = blackBoxSelected;
        }
        copyAdditives.push(addi);
        return addi;
      });
      setSelectedAdditivesLocal([...copyAdditives]);
      setSelectedAdditives([...copyAdditives]);
    } else {
      setSelectedAdditivesLocal([]);
    }
  };

  const fetchAdditives = async () => {
    try {
      let response = await getAdditives();
      if (response !== undefined) {
        response?.map((additive) => {
          additive.checked = false;
          return additive;
        });
        setAdditives([...response]);
        let finalAdditives = [...response];
        let bb = blackBoxAndTranslate();
        bb.forEach((addi) => {
          finalAdditives.push(addi);
        });

        setSelectedAdditivesLocal([...finalAdditives]);
        setSelectedAdditives([...finalAdditives]);
      }
    } catch (error) {
      console.error("Error fetching additives:", error);
    }
  };
  useEffect(() => {
    fetchAdditives();
  }, []);

  const checkAdditive = (additive) => {
    let copyAdditives = [...additives];
    copyAdditives.map((addi) => {
      if (addi.Id === additive.Id) {
        additive.checked = !additive.checked;
      }
      return addi;
    });

    let params = [...copyAdditives];
    let bb = blackBoxAndTranslate();
    bb.map((addi) => {
      if (translateSelected === true && addi.Name === "Translate") {
        addi.checked = true;
      }
      params.push(addi);
    });

    setSelectedAdditivesLocal([...params]);
    setSelectedAdditives([...params]);
    setAdditives([...copyAdditives]);
  };

  const midPoint = Math.ceil(additives.length / 2);
  const leftColumnAdditives = additives.slice(0, midPoint);
  const rightColumnAdditives = additives.slice(midPoint);

  return (
    <div className={expandChecks ? "checkbox-list show" : "checkbox-list"}>
      <div className="flex-row">
        <h4 className="h4">Additives</h4>{" "}
      </div>
      <div className="switchrow">
        <label className="switch">
          <input
            type="checkbox"
            checked={blackBoxSelected}
            onChange={(e) => disbaleChecks(e)}
          />
          <span className="slider round"></span>
        </label>
        <span>Blackbox</span>
      </div>
      <div className="checkrow">
        <div className="check-col">
          {leftColumnAdditives.map((additive) => (
            <div
              key={additive.Id}
              className={
                blackBoxSelected ? "checklist-row disable" : "checklist-row"
              }
            >
              <label
                className={
                  blackBoxSelected ? "check-contain disable" : "check-contain"
                }
              >
                {additive.Name}
                <input
                  type="checkbox"
                  className="allcheck"
                  disabled={blackBoxSelected}
                  checked={additive.checked}
                  onChange={() => checkAdditive(additive)}
                />
                <span className="checkmark-check"></span>
              </label>
            </div>
          ))}
        </div>
        <div className="check-col">
          {rightColumnAdditives.map((additive) => (
            <div
              key={additive.Id}
              className={
                blackBoxSelected ? "checklist-row disable" : "checklist-row"
              }
            >
              <label
                className={
                  blackBoxSelected ? "check-contain disable" : "check-contain"
                }
              >
                {additive.Name}
                <input
                  type="checkbox"
                  className="allcheck"
                  disabled={blackBoxSelected}
                  checked={additive.checked}
                  onChange={() => checkAdditive(additive)}
                />
                <span className="checkmark-check"></span>
              </label>
            </div>
          ))}
        </div>
      </div>
      <div className="new-check-row">
        <div className="switchrow">
          <label className="switch">
            <input
              type="checkbox"
              checked={translateSelected}
              onChange={(e) => includeTranslate(e)}
            />
            <span className="slider round"></span>
          </label>
          <span>Translate in English</span>
        </div>
      </div>
    </div>
  );
};

export default Additives;
