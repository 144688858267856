import { InternalAPIError } from "../../../../utils/apiErrors";
import { handleApiError } from "../../../../utils/globalErrorHandler";
import { ExtractCurlParameters } from "../../promptManagement/shared/curlConverter";

export const getSkillStoresParams = (skillInfo) => {
  try {
    const params = ExtractCurlParameters(
      skillInfo.ChatbotConfiguration?.CurlRequestString
    );
    const skill = {
      Id: skillInfo.Id,
      Name: skillInfo.Name,
      ChatbotConfiguration: skillInfo.ChatbotConfiguration,
      aiUrl: params.url,
      headers: params.headers,
      headersV2: params.headersV2,
      method: params.method,
      aiFlag: "",
      apiKey: "",
      messageBody: params.body,
      userMessage: skillInfo.ChatbotConfiguration.UserMessageFormat,
      AssistantMessageFormat:
        skillInfo.ChatbotConfiguration.AssistantMessageFormat,
      ShowCitation: skillInfo.ChatbotConfiguration.ShowCitation,
    };
    return skill;
  } catch (error) {
    handleApiError(new InternalAPIError());
  }
};

export const getSkillStoresFromPrompt = (prompt) => {
  try {
    const params = ExtractCurlParameters(
      prompt?.ChatbotConfiguration?.CurlRequestString
    );
    const skill = {
      Id: prompt.SkillStoreId,
      Name: prompt.SkillStoreName,
      ChatbotConfiguration: prompt.ChatbotConfiguration,
      aiUrl: params.url,
      headers: params.headers,
      headersV2: params.headersV2,
      method: params.method,
      aiFlag: "",
      apiKey: "",
      messageBody: params.body,
      ShortDescription: prompt?.SkillStoreShortDescription,
      userMessage: prompt?.ChatbotConfiguration?.UserMessageFormat,
      AssistantMessageFormat:
        prompt?.ChatbotConfiguration?.AssistantMessageFormat,
      ShowCitation: prompt?.ChatbotConfiguration?.ShowCitation,
    };
    return skill;
  } catch (error) {
    handleApiError(new InternalAPIError());
  }
};
