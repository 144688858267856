import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import UserModal from "./shared/UserModal";
import { getUsersData } from "./services/userService";
import { Link } from "react-router-dom";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [search, setSearch] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const selectAllRef = useRef(null); // Ref for "Select All" checkbox

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    // Handle indeterminate state of "Select All" checkbox
    if (selectAllRef.current) {
      if (selectedUsers.length > 0 && selectedUsers.length < users.length) {
        selectAllRef.current.indeterminate = true;
      } else {
        selectAllRef.current.indeterminate = false;
      }
    }
  }, [selectedUsers, users]);

  const fetchUsers = async () => {
    try {
      const response = await getUsersData();
      setUsers(response);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedUsers(users.map((user) => user.id));
    } else {
      setSelectedUsers([]);
    }
  };

  const handleSelectUser = (id) => {
    setSelectedUsers((prev) =>
      prev.includes(id) ? prev.filter((uid) => uid !== id) : [...prev, id]
    );
  };

  const handleDelete = async () => {
    if (selectedUsers.length === 0) return;
    try {
      await axios.delete("/api/delete-users", { data: { ids: selectedUsers } });
      setUsers(users?.filter((user) => !selectedUsers.includes(user.id)));
      setSelectedUsers([]);
    } catch (error) {
      console.error("Error deleting users:", error);
    }
  };

  const filteredUsers = users?.filter(
    (user) =>
      user.name.toLowerCase().includes(search.toLowerCase()) ||
      user.username.toLowerCase().includes(search.toLowerCase()) ||
      user.roleType.toLowerCase().includes(search.toLowerCase())
  );

  const sortedUsers = [...filteredUsers].sort((a, b) =>
    a.name.localeCompare(b.name)
  );
  const currentUsers = sortedUsers;

  return (
    <div className="section-wrapper">
      <div className="top-bar">
        <div className="search-cross-icon-common">
        <input
          type="text"
          placeholder="Search..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          className="skill-filter-field w-input"
        />
        <div class="search-tile-icon-common"></div>
        </div>
        
        <div className="right-buttons d-flex align-items-center gap-3">
        <button className="button  w-button" onClick={() => setModalOpen(true)}>Add User</button>
          <div className="trash-button-icon light-gray-button">
            <div className="trash-icon" onClick={handleDelete}></div>
            <Link onClick={handleDelete} to="#">
              Delete
            </Link>
          </div>

          
        </div>
      </div>

      <div className="dash-section p20">
        <div className="user-table-list">
          <div className="user-table-row user-table-header">
            <div className="user-col auto-width">
              <input
                type="checkbox"
                ref={selectAllRef}
                onChange={handleSelectAll}
                checked={
                  selectedUsers.length === users.length && users.length > 0
                }
              />
            </div>

            <div className="user-col">Name</div>
            <div className="user-col">Username</div>
            <div className="user-col">Role Type</div>
          </div>
          <div className="user-table-body">
            {currentUsers.map((user) => (
              <div key={user.id} className="user-table-row">
                <div className="user-col auto-width">
                  <input
                    type="checkbox"
                    checked={selectedUsers.includes(user.id)}
                    onChange={() => handleSelectUser(user.id)}
                  />
                </div>
                <div className="user-col">{user.name}</div>
                <div className="user-col">{user.username}</div>
                <div className="user-col">{user.roleType}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* <table>
        <thead>
          <tr>
            <th>
              <input
                type="checkbox"
                ref={selectAllRef}
                onChange={handleSelectAll}
                checked={selectedUsers.length === users.length && users.length > 0}
              />
            </th>
            <th>Name</th>
            <th>Username</th>
            <th>Role Type</th>
          </tr>
        </thead>
        <tbody>
          {currentUsers.map((user) => (
            <tr key={user.id}>
              <td>
                <input
                  type="checkbox"
                  checked={selectedUsers.includes(user.id)}
                  onChange={() => handleSelectUser(user.id)}
                />
              </td>
              <td>{user.name}</td>
              <td>{user.username}</td>
              <td>{user.roleType}</td>
            </tr>
          ))}
        </tbody>
      </table> */}

      {modalOpen && <UserModal onClose={() => setModalOpen(false)} />}
    </div>
  );
};

export default Users;
