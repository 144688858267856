import React, { useState } from "react";
import { toast } from "react-toastify";
import samplecsv from "../../../../../styles/images/sample-csv.csv";
import { postBulkImportPrompt } from "../../services/promptManagementService";

const ImportPrompt = () => {
  const [fileName, setFileName] = useState("");
  const [uploadStatus, setUploadStatus] = useState("default");
  const [errorFreeEntries, setErrorFreeEntries] = useState([]);
  const [errorEntries, setErrorEntries] = useState([]);
  const [isImportDisabled, setIsImportDisabled] = useState(true);

  // Parse CSV content into an array of objects
  const parseCsv = (csvContent) => {
    const [headerLine, ...rows] = csvContent.split("\n").filter(Boolean);
    const headers = headerLine.split(",").map((header) => header?.trim());

    return rows
      .map((row) => {
        // Parse the row using a CSV parser logic that respects quotes
        const values = [];
        let current = "";
        let inQuotes = false;

        for (let char of row) {
          if (char === '"' && !inQuotes) {
            inQuotes = true; // Start of a quoted value
          } else if (char === '"' && inQuotes) {
            inQuotes = false; // End of a quoted value
          } else if (char === "," && !inQuotes) {
            // Split value on comma if not inside quotes
            values.push(current.trim());
            current = "";
          } else {
            current += char; // Add character to the current value
          }
        }
        // Push the last value
        values.push(current.trim());

        if (values.length !== headers.length) return null; // Skip malformed rows

        return headers.reduce((acc, header, index) => {
          acc[header] = values[index] || ""; // Assign empty string if missing
          return acc;
        }, {});
      })
      .filter(Boolean); // Remove null rows
  };

  const validateRow = (row) => {
    const errors = [];
    if (!row.Title?.trim()) errors.push("Title is required.");
    if (!row.Description?.trim()) errors.push("Description is required.");
    return errors;
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file && file.type === "text/csv") {
      setFileName(file.name);
      setUploadStatus("default");
      setErrorFreeEntries([]);
      setErrorEntries([]);
      setIsImportDisabled(true);
      processFile(file);

      // Reset file input
      event.target.value = null;
    } else {
      setUploadStatus("error");
      setIsImportDisabled(true);
      toast.error("Please upload a valid CSV file.");
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file && file.type === "text/csv") {
      setFileName(file.name);
      setUploadStatus("default");
      setErrorFreeEntries([]);
      setErrorEntries([]);
      setIsImportDisabled(true);
      processFile(file);
    } else {
      toast.error("Please drop a valid CSV file.");
    }
  };

  const getIconClass = () => {
    switch (uploadStatus) {
      case "success":
        return "success-icon";
      case "error":
        return "error-icon";
      default:
        return "uplod-icon";
    }
  };

  const processFile = (file) => {
    const reader = new FileReader();

    // Clear states before starting file processing
    setErrorFreeEntries([]);
    setErrorEntries([]);
    setUploadStatus("default");

    reader.onload = () => {
      const content = reader.result;
      const rows = parseCsv(content);

      const validEntries = [];
      const invalidEntries = [];

      rows.forEach((row, index) => {
        const errors = validateRow(row);
        if (errors.length > 0) {
          invalidEntries.push({ rowNumber: index + 1, row, errors });
        } else {
          validEntries.push(row);
        }
      });

      setErrorFreeEntries(validEntries);
      setErrorEntries(invalidEntries);

      if (invalidEntries.length > 0) {
        setUploadStatus("error");
        setIsImportDisabled(true);
        toast.error("Some rows have errors. Please review them.");
      } else {
        setUploadStatus("success");
        setIsImportDisabled(false);
        toast.success("All rows are valid and ready for import.");
      }
    };

    reader.onerror = () => {
      setUploadStatus("error");
      setIsImportDisabled(true);
      toast.error("Failed to read the file. Please try again.");
    };

    reader.readAsText(file);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleImport = async () => {
    if (errorFreeEntries.length > 0 && errorEntries?.length === 0) {
      const formattedEntries = errorFreeEntries.map((entry) => ({
        Title: entry.Title,
        ShortDescription: entry.Description,
      }));

      try {
        const response = await postBulkImportPrompt(formattedEntries);
        if (response) {
          toast.success(response?.Status);
          setUploadStatus("default");
          setFileName("");
          setIsImportDisabled(true);
        }
      } catch (error) {
        console.error("Error during data import:", error);
        toast.error("Failed to import data. Please try again.");
      }
    } else {
      toast.error("No valid entries to import.");
    }
  };

  const templateDownlod = () => {
    const filepath = samplecsv;
    const a = document.createElement("a");
    a.href = filepath;
    a.download = "Prompt_Template.csv";
    a.click();
  };

  return (
    <>
      <div className="import-prompt-box">
        <div className="flex-row">
          <div className="">Import Bulk Prompts</div>
          <div className="download-text" onClick={templateDownlod}>
            <div className="download-arrow"></div>
            <span>Download Template</span>
          </div>
        </div>
      </div>

      <div className="dropbox-wrapper-main">
        <div className="dropbox-wrapper">
          <div className="flex-line">
            <label className="linkcss" aria-label="Upload CSV file">
              <div
                className="prompt-img"
                
              >
                <div className={getIconClass()} onDrop={handleDrop}
                onDragOver={handleDragOver}>
                  {uploadStatus === "default" && ""} {/* Default icon */}
                  {uploadStatus === "error" && ""} {/* Error icon */}
                  {uploadStatus === "success" && ""} {/* Success icon */}
                </div>
              </div>
              <span
                className="bluelink"
                onDrop={handleDrop}
                onDragOver={handleDragOver}
              >
                Click to upload
              </span>
              <input
                type="file"
                accept=".csv"
                style={{ display: "none" }}
                onChange={handleFileSelect}
              />
              Or Drag and drop
            </label>
          </div>
          <div className="subtext">only .csv file accepted</div>
          {fileName && <p className="light-text">{fileName}</p>}
        </div>
        <div className="import-section">
          {errorEntries.length > 0 && (
            <div>
              <h4>Invalid Rows:</h4>
              <ul className="error-rows">
                {errorEntries.map((entry, index) => (
                  <li key={index}>
                    Row {entry.rowNumber}: {entry.errors.join(", ")}
                  </li>
                ))}
              </ul>
            </div>
          )}

          <button
            className={`${isImportDisabled ? "w-button button w-button-disabled" : "w-button button"}`}
            onClick={handleImport}
            disabled={isImportDisabled}
          >
            Import
          </button>
        </div>
      </div>
    </>
  );
};

export default ImportPrompt;
