import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const showMessage = (message, type = 1, title = "") => {
  switch (type) {
    case -2: {
      toast.error(message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      break;
    }

    case -1: {
      toast.warn(message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      break;
    }

    case 0: {
      toast.info(message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      break;
    }
    case 1:
    default: {
      toast.success(message, {
        position: toast.POSITION.TOP_RIGHT,
        className: "toast-success",
      });
    }
  }
};

export default showMessage;
