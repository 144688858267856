import React, { useEffect, useMemo, useRef, useState } from "react";
import { BOTAPIError } from "../../../../../../utils/apiErrors";
import { handleApiError } from "../../../../../../utils/globalErrorHandler";

import AppImages from "../../../../../../constants/images";
import ChatPrompt from "../chatBox/chatPrompt";
import { clearPromptMessageSession } from "../../../../../../services/commonService";
import { useUserName } from "../../../../../common/customHooks/useUserName";
import { json, useLocation } from "react-router-dom";
import ChatHistory from "../chatHistory";
import axios from "axios";
import FileChatbot from "./fileChatbot";
import TextChatbot from "./textChatbot";
import { useHeaderContext } from "../../../../../../context/user/headerContext";
import { getDefaultChatbotConfig } from "../../../../shared/defaultChatBotConfig/defaultConfigService";
import {
  filterTopMessages,
  filterTopMessagesV2,
} from "../../../../promptManagement/shared/promptConverter";

const ChatBox = ({
  isMKSChat,
  chatMessage,
  setIsSkillChat,
  defaultSkillWelMessage,
  setDefaultSkillWelMessage,
  setIsClearChat,
  isClearChat,
  isChatVisible,
  showHideClearChatBtn,
  openPdfViewer,
  setOpenPdfViewer,
  setIsFileTypeChat,
  isFileTypeChat,
  isMKSProcessing,
  setIsMKSProcessing,
  isSkillProcessing,
  setIsSkillProcessing,
  setDisableRegenerateButton,
  setDisableStopGenerateButton,
  disableRegenerateButton,
  disableStopGenerateButton,
  showFollowupQuestion,
  setDisableFolloupButton,
  setShowFollowupQuestion,
  disableFolloupButton,
  setSelectedSkill,
}) => {
  const [messages, setMessages] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const [lastDisplayMessage, setLastDisplayMessage] = useState("");
  const { initials, fullName } = useUserName();
  const bottomPanelRef = useRef(null);
  const [isRunning, setIsRunning] = useState(false);
  const intervalRef = useRef(null);
  const [currentMessage, setCurrentMessage] = useState("");
  const [showChatLoader, setShowChatLoader] = useState(false);
  bottomPanelRef.current?.scrollIntoView({ behavior: "smooth" });
  const [runningIndex, setRunningIndex] = useState(0);
  const [fileName, setFileName] = useState("");
  const { state } = useLocation();
  const [skill, setSkill] = useState(state?.skill);
  const [promptText, setPromptText] = useState(state?.promptText);
  const [navigateFromHome, setNavigateFromHome] = useState(
    state?.navigateFromHome
  );
  const [displayLastCitations, setDisplayLastCitations] = useState([]);
  const [selectedAdditives, setSelectedAdditives] = useState([]);
  const [uploadedFilesFromChat, setUploadedFilesFromChat] = useState([]);
  const [clearUploadedFile, setClearUploadedFile] = useState(false);
  const [selectedCitation, setSelectedCitation] = useState([]);

  const { setHeaderState } = useHeaderContext();
  const [resetAdditives, setResetAdditives] = useState(false);
  const [mksDefaultConfiguration, setMksDefaultConfiguration] = useState();
  const [mksUserMessages, setMksUserMessages] = useState([]);
  const [genericAPIUserMessages, setGenericAPIUserMessages] = useState([]);
  const [resumeCitations, setResumeCitations] = useState([]);
  const [uploadedFileDetails, setUploadedFileDetails] = useState([]);
  const [isBlackBoxAdded, setIsBlackBoxAdded] = useState(false);
  const [isTranslateAdded, setIsTranslateAdded] = useState(false);
  const [blackBoxAdditives, setBlackBoxAdditives] = useState("");
  const [init, setInit] = useState(false);
  const [ASSISTANT, TOOL, ERROR] = ["assistant", "tool", "error"];
  const [lastDisplayFileMessage, setLastDisplayFileMessage] = useState([]);
  const [pdfClass, setPdfClass] = useState("");

  useEffect(() => {
    setMessages([]);
    if (isMKSChat) {
      setSkill(null);
      getSessionStorageMessage(true);
      setHeaderState({
        headerValue: "",
        ShortDescription: "",
      });
      getDefaultChatbotConfig().then((data) => {
        setMksDefaultConfiguration(data);
      });
      setDisableRegenerateButton(true);
      setDisableStopGenerateButton(true);
    }
  }, [isMKSChat]);

  useEffect(() => {
    if (!isMKSChat && skill !== null && skill !== undefined) {
      getSessionStorageMessage(false);
      setHeaderState({
        headerValue: "",
        ShortDescription: "",
      });
    }
    setShowFollowupQuestion(skill?.ChatbotConfiguration?.ShowFollowUpQuestions);

    setDisableFolloupButton(
      skill?.ChatbotConfiguration?.DisableFollowUpQuestions
    );
    setSelectedSkill(skill);

    setDisableRegenerateButton(true);
    setDisableStopGenerateButton(true);
  }, [skill]);

  useEffect(() => {
    if (bottomPanelRef.current) {
      bottomPanelRef.current.scrollIntoView({ behavior: "smooth" });
    }
    showHideClearChatBtn(messages?.length === 0 ? false : true);
    if (
      messages?.length > 0 &&
      !isMKSChat &&
      skill !== null &&
      skill !== undefined
    ) {
      setHeaderState({
        headerValue: skill.Name,
        ShortDescription: skill.ShortDescription,
      });
    } else if (messages?.length > 0 && isMKSChat) {
      setHeaderState({
        headerValue: "MKS Chat",
        ShortDescription: "",
      });
    } else if (messages?.length === 0) {
      setHeaderState({
        headerValue: "",
        ShortDescription: "",
      });
    }
  }, [messages]);

  useEffect(() => {
    if (isClearChat) {
      if (isMKSChat) {
        clearMKSData();
      } else {
        clearSKILLData();
      }
      setMessages([]);
    }
    setIsClearChat(false);
  }, [isClearChat]);

  const handleExceptionResponse = () => {
    setIsSkillProcessing(false);
    setIsMKSProcessing(false);
    setShowChatLoader(false);
  };

  const handleExceptionAndMessages = () => {
    setShowChatLoader(false);
    let localMessage = sessionStorage.getItem("messages");
    localMessage = localMessage ? JSON.parse(localMessage) : [];
    if (localMessage.length > 0) {
      localMessage[localMessage.length - 1].isErrorOccured = true;
      setMessages([...messages, localMessage[localMessage.length - 1]]);
      sessionStorage.removeItem("messages");
      sessionStorage.setItem("messages", JSON.stringify(localMessage));
    }
    setIsSkillProcessing(false);
    setIsMKSProcessing(false);
    handleApiError(new BOTAPIError(skill?.Name)); // Wrap in BOTAPIError
  };

  const getSessionStorageMessage = (typeMKSChat) => {
    let localMessage = sessionStorage.getItem("messages") || "";
    if (localMessage === "") localMessage = [];
    else localMessage = JSON.parse(localMessage);
    if (localMessage.length === 0) {
    } else {
      setDisplayLastCitations([]);
      if (typeMKSChat) {
        const data = localMessage.filter((item) => item.chatType === true);

        if (data.length > 0) {
          setLastDisplayMessage(data[data.length - 1].text);
          setMessages(data);
        } else {
          setMessages([]);
        }
      } else {
        const data = localMessage.filter(
          (item) => !item.chatType && item.skill === skill?.Name
        );

        if (skill?.ChatbotConfiguration?.FileType !== null) {
        } else {
          if (data.length > 0) {
            setLastDisplayMessage(data[data.length - 1].text);
            setDisplayLastCitations(data[data.length - 1].citations);
            setMessages(data);
          }
        }
      }
    }
  };

  const setMessageToSession = (value) => {
    let localMessage = sessionStorage.getItem("messages") || "";
    if (localMessage === "") {
      localMessage = [];
    } else {
      localMessage = JSON.parse(localMessage);
    }

    localMessage.push(value);
    sessionStorage.setItem("messages", JSON.stringify(localMessage));
  };

  const clearMKSData = () => {
    try {
      // Retrieve and parse the "messages" from sessionStorage
      let localMessage = sessionStorage.getItem("messages");
      if (!localMessage) {
        localMessage = []; // Default to an empty array if no messages exist
      } else {
        localMessage = JSON.parse(localMessage); // Parse the JSON string
      }

      // Filter out messages without `chatType`
      if (Array.isArray(localMessage) && localMessage.length > 0) {
        const data = localMessage.filter((item) => !item.chatType);

        // Clear the old messages from sessionStorage
        clearPromptMessageSession();
        sessionStorage.setItem("messages", JSON.stringify(data));
      }
    } catch (error) {
      console.error("Error in clearMKSData:", error);
    }
  };

  const clearSKILLData = () => {
    try {
      // Retrieve and parse the "messages" from sessionStorage
      let localMessage = sessionStorage.getItem("messages");
      if (!localMessage) {
        localMessage = []; // Default to an empty array if no messages exist
      } else {
        localMessage = JSON.parse(localMessage); // Parse the JSON string
      }

      // Filter out messages without `chatType`
      if (Array.isArray(localMessage) && localMessage.length > 0) {
        const data = localMessage.filter((item) => item.skill !== skill?.Name);

        // Clear the old messages from sessionStorage
        clearPromptMessageSession();

        sessionStorage.setItem("messages", JSON.stringify(data));
      }
    } catch (error) {
      console.error("Error in clearMKSData:", error);
    }
  };

  const handleAIGenericResponse = async (inputText) => {
    if (!isMKSChat) {
      const showFollowUpQuestions =
        skill?.ChatbotConfiguration?.ShowFollowUpQuestions;
      if (
        skill?.ChatbotConfiguration?.FileType === null ||
        skill?.ChatbotConfiguration?.FileType === ""
      ) {
        const isNewFlag = process.env.REACT_APP_CHAT_FLAG_SKILLAPI;
        if (isNewFlag === "true") {
          handleLoadGenericApiConfigurationsV2(
            inputText,
            showFollowUpQuestions
          );
        } else {
          handleLoadGenericApiConfigurations(inputText, showFollowUpQuestions);
        }
        setIsSkillProcessing(true);
        setIsMKSProcessing(false);
      } else if (isFileTypeChat) {
        handleFileUploadApiConfigurations(inputText);
      }
    } else {
      const isNewFlag = process.env.REACT_APP_CHAT_FLAG_MKS_NEW;
      if (isNewFlag === "true") {
        handleMksAIWrapperResponse(inputText);
      } else {
        handleMksAIResponse(inputText);
      }
      setIsSkillProcessing(false);
      setIsMKSProcessing(true);
    }
  };

  const convertCollectionData = (collection) => {
    if (collection[0].length !== undefined) {
      return collection[0]
        .map((item) => `${item.Value}=${item.checked}`)
        .join("&");
    } else {
      return collection
        .map((item) => `${item.Value}=${item.checked}`)
        .join("&");
    }
  };

  const removeSpecialCharacters = (text) => {
    const containsSpecialCharacters = /[^a-zA-Z0-9 ]/g;
    const containsEnglish = /[a-zA-Z]/g;

    if (containsEnglish.test(text) && containsSpecialCharacters.test(text)) {
      return text.replace(/[^a-zA-Z0-9 ]/g, "");
    }
    return text;

    // const isEnglish = /[^a-zA-Z0-9 ]/g;
    // if (isEnglish.test(text)) {
    //   return text.replace(/[^a-zA-Z0-9 ]/g, "");
    // } else {
    //   return text;
    // }
    // const japaneseRegex =
    //   /[\u3000-\u303F\u3040-\u309F\u30A0-\u30FF\uFF00-\uFFEF]/;
    // return text
    //   .split("")
    //   .filter((char) => japaneseRegex.test(char) || /[a-zA-Z0-9 ]/.test(char))
    //   .join("");
    // return text.replace(/[^a-zA-Z0-9 ]/g, "");
  };
  const handleFileUploadApiConfigurations = async () => {
    try {
      let uploadedContent = [];
      let citations = [];
      let response = "";
      setUploadedFileDetails([]);
      let errorCounter = 1;

      let additivess = selectedAdditives.filter(
        (item) => item.Name !== "Translate"
      );

      const isTranslateAdded = selectedAdditives.filter(
        (item) => item.Name === "Translate"
      )[0].checked;
      setIsTranslateAdded(isTranslateAdded);

      const selectedAdditivesArray = additivess.map((item) => item.Value);

      setIsSkillProcessing(true);
      setIsMKSProcessing(false);

      let blackBox = selectedAdditives.filter(
        (item) => item.Name === "Blackbox"
      )[0];
      let blackBoxAdded = false;
      if (blackBox.checked) {
        blackBoxAdded = true;
        setIsBlackBoxAdded(true);
      } else {
        blackBoxAdded = false;
        setIsBlackBoxAdded(false);
      }

      setShowChatLoader(true);
      const userMessage = {
        text: "",
        type: "user",
        imageURL: AppImages.profilePic,
        citations: [],
        files: uploadedFilesFromChat,
        skill: skill?.Name,
        isErrorOccured: false,
      };

      setMessages((prev) => [...prev, userMessage]);

      const aiResponse = {
        text: "loader",
        type: "reply",
        imageURL: AppImages.chatAIIcon,
        citations: [],
        file: [],
      };
      setMessages((prev) => [...prev, aiResponse]);

      setBlackBoxAdditives("");
      let counter = 0;
      setLastDisplayFileMessage([]);
      let uploadedAllFiles = [];
      setClearUploadedFile(true);
      uploadedFilesFromChat.forEach(async (file) => {
        const queryString = convertCollectionData(selectedAdditives);
        let url = skill.aiUrl.replace("$additives", queryString);
        setShowChatLoader(true);
        setDisplayLastCitations([]);
        setIsTyping(true);
        const FormData = require("form-data");

        let data = new FormData();
        data.append("file", file);

        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: removeTrailingSlash(url),
          headers: skill.headers,
          data: data,
        };

        const axiosApiInstance = axios.create();

        const uploadFile = async () => {
          try {
            const resp = await axiosApiInstance.post(config.url, data, config);
            uploadedContent.push(resp.data);
            setIsSkillProcessing(true);
            uploadedAllFiles.push({
              fileName: file.name,
              jsonOutPut: JSON.stringify(resp.data),
              selectedAdditivesArray: selectedAdditivesArray,
              isBlackBoxAdded: blackBoxAdded,
              isTranslateAdded: isTranslateAdded,
            });

            counter = counter + 1;

            if (uploadedContent.length === uploadedFilesFromChat.length) {
              response = JSON.stringify(uploadedContent).toString();
              createBotMessage(
                response,
                citations,
                uploadedFilesFromChat,
                skill,
                uploadedAllFiles,
                selectedAdditivesArray,
                blackBoxAdded,
                isTranslateAdded
              );
              setLastDisplayFileMessage(uploadedAllFiles);
              setBlackBoxAdditives(response);
              setShowChatLoader(false);
              setUploadedFilesFromChat([]);
              setUploadedFileDetails([...uploadedAllFiles]);
              setResetAdditives((prev) => !prev);
              setSelectedAdditives([]);
              setIsTyping(false);
            }
            setClearUploadedFile(true);
          } catch (error) {
            if (errorCounter === 1) {
              setMessages((prev) => {
                // Clone the previous messages array
                let updatedMessages = [...prev];

                // Ensure there are at least two messages to update and remove
                if (updatedMessages.length >= 2) {
                  // Get the indices for the last two messages
                  const secondLastIndex = updatedMessages.length - 2;
                  const lastIndex = updatedMessages.length - 1;

                  // Set isErrorOccured:true for the first of the last two messages
                  updatedMessages[secondLastIndex] = {
                    ...updatedMessages[secondLastIndex],
                    isErrorOccured: true,
                  };

                  // Remove the second of the last two messages (at lastIndex)
                  updatedMessages.splice(lastIndex, 1);
                }

                return updatedMessages;
              });
              handleExceptionResponse();
              handleApiError(new BOTAPIError(skill?.name));
              errorCounter = 0;
            }
          } finally {
            setClearUploadedFile((clearUploadedFile) => !clearUploadedFile);
          }
        };
        uploadFile();
      });
    } catch {
      setIsSkillProcessing(false);
      setIsMKSProcessing(false);
      handleApiError(new BOTAPIError(skill?.name));
    }
  };

  const createBotMessage = (
    response,
    citations,
    uploadedFilesFromChat,
    skill,
    uploadedAllFiles,
    selectedAdditivesArray,
    blackBoxAdded,
    isTranslateAdded
  ) => {
    const botMessage = {
      text: response,
      type: "reply",
      imageURL: AppImages.chatAIIcon,
      citations: citations,
      files: uploadedFilesFromChat,
      skill: skill?.Name,
      uploadedFileDetails: uploadedAllFiles,
      selectedAdditivesArray: selectedAdditivesArray,
      isBlackBoxAdded: blackBoxAdded,
      isTranslateAdded: isTranslateAdded,
    };
    setMessageToSession(botMessage);
    setMessages((prev) => [
      ...prev.filter((p) => p.text !== "loader"),
      botMessage,
    ]);
  };

  const handleLoadGenericApiConfigurations = async (
    inputText,
    showFollowUpQuestions
  ) => {
    try {
      if (!inputText?.trim()) {
        return;
      }
      setDisableStopGenerateButton(true);
      setDisableRegenerateButton(true);
      setShowChatLoader(true);
      setLastDisplayMessage("");
      setDisplayLastCitations([]);
      const userMessage = {
        text: inputText,
        type: "user",
        imageURL: AppImages.profilePic,
        citations: [],
        chatType: isMKSChat,
        skill: skill?.Name,
        isErrorOccured: false,
      };
      setMessageToSession(userMessage);
      setMessages((prev) => [...prev, userMessage]);

      let updatedText = removeSpecialCharacters(inputText);
      if (showFollowUpQuestions && disableFolloupButton === false) {
        updatedText = `${updatedText} ${process.env.REACT_APP_FOLLOWUP_QUESTION}`;
      }
      let formatedInputText = updatedText
        .replace(/\n/g, "\\n") // Escape newlines
        .replace(/\r/g, "\\r") // Escape carriage returns
        .replace(/\\/g, "\\\\")
        .replace(/^"|"$/g, "");
      formatedInputText = formatedInputText.replace(
        /"([^"]*?)"(?=\s|[:,}])/g,
        '\\"$1\\"'
      );

      let userMessageData = skill.userMessage.replace(
        "$Message",
        `"${formatedInputText}"`
      );
      let lastMessageCount =
        skill.lastMessageCount == null ? 0 : skill.lastMessageCount;

      let parsedUserMessageData = JSON.parse(userMessageData);

      const finalRequestBody = [];
      let isStreamAPI = true;
      let oldgenericAPIUserMessages = [...genericAPIUserMessages];
      if (lastMessageCount > 0) {
        let previousSentMessages = filterTopMessagesV2(
          genericAPIUserMessages,
          parseInt(lastMessageCount),
          messages,
          skill.AssistantMessageFormat,
          skill.userMessage
        );

        if (genericAPIUserMessages.length > 0) {
          previousSentMessages.forEach((msg) => {
            finalRequestBody.push(msg);

            if (genericAPIUserMessages.length === 0) {
              oldgenericAPIUserMessages.push(msg);
            }
          });
        }
      }

      finalRequestBody.push(parsedUserMessageData);
      let msgs = JSON.stringify(finalRequestBody);
      msgs = msgs.substring(1, msgs.length - 1);

      let userMessageModel2 = skill.messageBody.replace("$UserMessage", msgs);
      oldgenericAPIUserMessages.push(parsedUserMessageData);
      userMessageModel2 = JSON.parse(userMessageModel2);
      isStreamAPI = userMessageModel2.stream;
      userMessageModel2 = JSON.stringify(userMessageModel2);

      const aiResponse = {
        text: "loader",
        type: "reply",
        imageURL: AppImages.chatAIIcon,
        citations: [],
        chatType: isMKSChat,
        skill: skill?.Name,
      };
      setMessages((prev) => [...prev, aiResponse]);
      setIsSkillProcessing(true);
      let response = "";
      try {
        const responseA = await fetch(removeTrailingSlash(skill.aiUrl), {
          method: "post",
          headers: skill.headers,
          body: userMessageModel2,
        });
        if (!responseA.ok || !responseA.body) {
          handleExceptionAndMessages();
          return;
        }
        const reader = responseA.body.getReader();
        const decoder = new TextDecoder("utf-8");
        const loopRunner = true;
        let citations = [];
        while (loopRunner) {
          try {
            setIsSkillProcessing(true);
            const { value, done } = await reader.read();
            if (done) {
              break;
            }
            let decodedChunk = decoder.decode(value, { stream: true });

            let replacedChunk = decodedChunk.replace("data: ", "");
            let findContext =
              replacedChunk.indexOf("context") &&
              replacedChunk.indexOf("citations");
            if (findContext > -1) {
              try {
                citations =
                  JSON.parse(replacedChunk)?.choices[0]?.delta?.context
                    ?.citations;
              } catch (error) {}
            }
            if (isStreamAPI === true) {
              let splitChunk = replacedChunk.split("\n\ndata:").slice(0, -1);
              let datas = JSON.parse(JSON.stringify(splitChunk));
              let counter = 0;
              datas.forEach((da) => {
                let jsonArray = null;
                try {
                  jsonArray = JSON.parse(da);
                  if (jsonArray.choices.length > 0) {
                    const text = jsonArray.choices[0].delta?.content || "";
                    if (counter < 2 && (text.match(/\n/) || []).length) {
                      return;
                    }
                    response = response + text;
                    setIsTyping(true);
                    counter++;
                  }
                } catch (parseError) {}
              });
            } else {
              setIsTyping(false);
              let datas = JSON.parse(replacedChunk);
              citations = datas?.choices[0]?.message?.context?.citations;
              response = datas?.choices[0]?.message?.content;
            }
          } catch (readError) {}
          setShowChatLoader(false);
        }

        let followupText = [];
        if (showFollowUpQuestions && disableFolloupButton === false) {
          const result = extractAndRemoveFollowUps(response);
          response = result.modifiedText;
          followupText = result.followUpQuestions;
        }

        const botMessage = {
          text: response,
          type: "reply",
          imageURL: AppImages.chatAIIcon,
          citations: citations,
          chatType: isMKSChat,
          skill: skill?.Name,
          followupQuestion: followupText,
        };

        let assistantMessageModel = skill.AssistantMessageFormat.replace(
          "$replyMsg",
          `"${response}"`
        );
        let assistantMessage = {};
        try {
          assistantMessage = JSON.parse(assistantMessageModel);
        } catch (error) {
          assistantMessage = JSON.parse(
            skill.AssistantMessageFormat.replace("$replyMsg", `"$"`)
          );
          if (assistantMessage.content[0].type === undefined) {
            assistantMessage.content = response;
            assistantMessage.role = "assistant";
          } else {
            assistantMessage.content[0].text = response;
            assistantMessage.role = "assistant";
          }
        }

        oldgenericAPIUserMessages.push(assistantMessage);
        setGenericAPIUserMessages([...oldgenericAPIUserMessages]);
        setMessageToSession(botMessage);
        setMessages((prev) => [
          ...prev.filter((p) => p.text !== "loader"),
          botMessage,
        ]);
        setLastDisplayMessage("");
        setDisplayLastCitations([]);
        typeText(response, citations);
      } catch (error) {
        handleExceptionAndMessages();
      }
    } catch (ex) {
      console.log(ex);
      handleExceptionAndMessages();
    }
  };

  function extractAndRemoveFollowUps(text) {
    // Regular expression to match content inside << >>
    const regex = /<<([^>>]+)>>/g;

    // Extract matched strings into an array
    const followUpQuestions = [];
    const modifiedText = text.replace(regex, (match, p1) => {
      followUpQuestions.push(p1.trim()); // Store matched question without brackets
      return ""; // Remove it from the original text
    });

    return { modifiedText: modifiedText.trim(), followUpQuestions };
  }

  const handleLoadGenericApiConfigurationsV2 = async (
    inputText,
    showFollowUpQuestions
  ) => {
    try {
      if (!inputText?.trim()) {
        return;
      }
      setDisableStopGenerateButton(true);
      setDisableRegenerateButton(true);
      setShowChatLoader(true);
      setLastDisplayMessage("");
      setDisplayLastCitations([]);
      const userMessage = {
        text: inputText,
        type: "user",
        imageURL: AppImages.profilePic,
        citations: [],
        chatType: isMKSChat,
        skill: skill?.Name,
        isErrorOccured: false,
      };
      setMessageToSession(userMessage);
      setMessages((prev) => [...prev, userMessage]);

      let updatedText = inputText; // removeSpecialCharacters(inputText);
      if (showFollowUpQuestions && disableFolloupButton === false) {
        updatedText = `${updatedText} ${process.env.REACT_APP_FOLLOWUP_QUESTION}`;
      }
      let formatedInputText = updatedText
        .replace(/\n/g, "\\n") // Escape newlines
        .replace(/\r/g, "\\r") // Escape carriage returns
        .replace(/\\/g, "\\\\")
        .replace(/^"|"$/g, "");
      formatedInputText = formatedInputText.replace(
        /"([^"]*?)"(?=\s|[:,}])/g,
        '\\"$1\\"'
      );

      let inputTextWithoutQuestionString = inputText
        .replace(/\n/g, "\\n") // Escape newlines
        .replace(/\r/g, "\\r") // Escape carriage returns
        .replace(/\\/g, "\\\\")
        .replace(/^"|"$/g, "");
      inputTextWithoutQuestionString = inputTextWithoutQuestionString.replace(
        /"([^"]*?)"(?=\s|[:,}])/g,
        '\\"$1\\"'
      );

      let userMessageData = skill.userMessage.replace(
        "$Message",
        `"${formatedInputText}"`
      );

      let userMessageDataWithQuestionString = skill.userMessage.replace(
        "$Message",
        `"${inputTextWithoutQuestionString}"`
      );

      let lastMessageCount =
        skill.lastMessageCount == null ? 0 : skill.lastMessageCount;

      let parsedUserMessageData = JSON.parse(userMessageData);
      let parsedUserMessageDataWithoutQuestionString = JSON.parse(
        userMessageDataWithQuestionString
      );

      const finalRequestBody = [];
      let isStreamAPI = true;

      let oldgenericAPIUserMessages = [...genericAPIUserMessages];

      if (lastMessageCount > 0) {
        let previousSentMessages = filterTopMessagesV2(
          genericAPIUserMessages,
          parseInt(lastMessageCount),
          messages,
          skill.AssistantMessageFormat,
          skill.userMessage
        );

        if (previousSentMessages.length > 0) {
          previousSentMessages.forEach((msg) => {
            finalRequestBody.push(msg);

            if (genericAPIUserMessages.length === 0) {
              oldgenericAPIUserMessages.push(msg);
            }
          });
        }
      }

      finalRequestBody.push(parsedUserMessageData);
      let msgs = JSON.stringify(finalRequestBody);
      msgs = msgs.substring(1, msgs.length - 1);

      let userMessageModel2 = skill.messageBody.replace("$UserMessage", msgs);
      oldgenericAPIUserMessages.push(
        parsedUserMessageDataWithoutQuestionString
      );
      userMessageModel2 = JSON.parse(userMessageModel2);
      isStreamAPI = userMessageModel2.stream;
      userMessageModel2 = JSON.stringify(userMessageModel2);

      const aiResponse = {
        text: "loader",
        type: "reply",
        imageURL: AppImages.chatAIIcon,
        citations: [],
        chatType: isMKSChat,
        skill: skill?.Name,
      };
      setMessages((prev) => [...prev, aiResponse]);
      setIsSkillProcessing(true);

      let response = "";
      let result = {};
      try {
        const responseA = await fetch(process.env.REACT_APP_CHAT_WRAPPER_URL, {
          method: skill.method,
          headers: skill.headersV2,
          body: userMessageModel2,
        });
        if (!responseA.ok || !responseA.body) {
          handleExceptionAndMessages();
          return;
        }
        const reader = responseA.body.getReader();
        const decoder = new TextDecoder("utf-8");
        const loopRunner = true;
        let citations = [];
        let runningText = "";
        while (loopRunner) {
          try {
            setIsSkillProcessing(true);
            const { value, done } = await reader.read();
            if (done) {
              break;
            }
            let decodedChunk = decoder.decode(value, { stream: true });
            const objects = decodedChunk.split("\n");
            objects.forEach((obj) => {
              try {
                if (obj !== "" && obj !== "{}") {
                  runningText += obj;
                  result = JSON.parse(runningText);
                  if (result.choices?.length > 0) {
                    result.choices[0].messages.forEach((msg) => {
                      msg.id = result.id;
                      msg.date = new Date().toISOString();
                    });
                    if (
                      result.choices[0].messages?.some(
                        (m) => m.role === ASSISTANT
                      )
                    ) {
                    }
                    result.choices[0].messages.forEach((resultObj) => {
                      if (resultObj.role === ASSISTANT) {
                        response = response + resultObj.content;
                      }
                      if (resultObj.role === TOOL) {
                        if (
                          resultObj?.role &&
                          resultObj?.role === "tool" &&
                          resultObj?.content !== ""
                        ) {
                          try {
                            let contents = JSON.parse(resultObj.content);
                            if (contents?.citations) {
                              citations = contents.citations;
                            }
                          } catch {
                            return [];
                          }
                        }

                        // if (resultObj.content?.includes("citations")) {
                        //   citations = resultObj.content;
                        //   citations = JSON.parse(citations);
                        // }
                      }
                    });
                  } else if (result.error) {
                    handleApiError(new BOTAPIError());
                  }
                  runningText = "";
                }
              } catch (e) {
                if (!(e instanceof SyntaxError)) {
                  handleApiError(new BOTAPIError());
                }
              }
            });
          } catch (readError) {}
          setShowChatLoader(false);
        }

        let followupText = [];
        if (showFollowUpQuestions && disableFolloupButton === false) {
          const result = extractAndRemoveFollowUps(response);
          response = result.modifiedText;
          followupText = result.followUpQuestions;
        }
        if (response === "") {
          response = " ";
        }

        const botMessage = {
          text: response,
          type: "reply",
          imageURL: AppImages.chatAIIcon,
          citations: citations,
          chatType: isMKSChat,
          skill: skill?.Name,
          followupQuestion: followupText,
        };

        let assistantMessageModel = skill.AssistantMessageFormat.replace(
          "$replyMsg",
          `"${response}"`
        );

        let assistantMessage = {};
        try {
          assistantMessage = JSON.parse(assistantMessageModel);
        } catch (error) {
          assistantMessage = JSON.parse(
            skill.AssistantMessageFormat.replace("$replyMsg", `"$"`)
          );
          if (assistantMessage.content[0].type === undefined) {
            assistantMessage.content = response;
            assistantMessage.role = "assistant";
          } else {
            assistantMessage.content[0].text = response;
            assistantMessage.role = "assistant";
          }
        }

        oldgenericAPIUserMessages.push(assistantMessage);
        setGenericAPIUserMessages([...oldgenericAPIUserMessages]);
        setMessageToSession(botMessage);
        setMessages((prev) => [
          ...prev.filter((p) => p.text !== "loader"),
          botMessage,
        ]);
        setLastDisplayMessage(response);
        // setDisplayLastCitations([]);
        //typeTextV2(response, citations);
        //typeTextV23(response, citations);
        setDisplayLastCitations(citations);
      } catch (error) {
        handleExceptionAndMessages();
      }
    } catch (ex) {
      console.log(ex);
      handleExceptionAndMessages();
    }
  };

  useEffect(() => {
    if (!isRunning && init) {
      messages.map((msg, index) => {
        if (messages.length - 1 === index) {
          msg.text = lastDisplayMessage;
          msg.citations = displayLastCitations;
        }
        return msg;
      });
      setMessages(messages);
      let localMessage = sessionStorage.getItem("messages") || "";
      if (localMessage === "") localMessage = [];
      else localMessage = JSON.parse(localMessage);

      localMessage.map((msg, index) => {
        if (msg.chatType === true && msg.type !== "user") {
          if (localMessage.length - 1 === index) {
            msg.text = lastDisplayMessage;
          }
        }
        if (msg.chatType === false && msg.type !== "user") {
          if (localMessage.length - 1 === index) {
            msg.text = lastDisplayMessage;
          }
        }
        return msg;
      });
      sessionStorage.setItem("messages", JSON.stringify(localMessage));
    }
  }, [isRunning]);

  const handleMksAIWrapperResponse = async (inputText) => {
    try {
      if (!inputText?.trim()) {
        return;
      }

      if (mksDefaultConfiguration !== undefined) {
        setIsSkillProcessing(false);
        setIsMKSProcessing(true);
        setShowChatLoader(true);
        setLastDisplayMessage("");
        setDisplayLastCitations([]);
        setDisableStopGenerateButton(true);
        setDisableRegenerateButton(true);
        let requestBody = mksDefaultConfiguration.body;
        const userMessage = {
          text: inputText,
          type: "user",
          imageURL: AppImages.profilePic,
          chatType: isMKSChat,
          skill: "",
        };
        setMessageToSession(userMessage);
        setMessages((prev) => [...prev, userMessage]);

        const aiResponse = {
          text: "loader",
          type: "reply",
          imageURL: AppImages.chatAIIcon,
          chatType: isMKSChat,
          skill: "",
        };
        setMessages((prev) => [...prev, aiResponse]);
        let updatedText = removeSpecialCharacters(inputText);
        const formatedInputText = updatedText
          .replace(/\n/g, "\\n") // Escape newlines
          .replace(/\r/g, "\\r") // Escape carriage returns
          .replace(/\\/g, "\\\\")
          .replace(/^"|"$/g, "");

        let userMessageModel = mksDefaultConfiguration.userMessage.replace(
          "$Message",
          `"${formatedInputText}"`
        );

        let lastMessageCount = mksDefaultConfiguration.lastMessageCount;
        // let parsedUserMessageModel = JSON.parse(userMessageModel);

        let oldmksUserMessages = [...mksUserMessages];
        const finalRequestBody = [];
        if (lastMessageCount > 0) {
          let previousSentMessages = filterTopMessagesV2(
            genericAPIUserMessages,
            parseInt(lastMessageCount),
            messages,
            mksDefaultConfiguration.assistantMessage,
            mksDefaultConfiguration.userMessage
          );

          if (previousSentMessages.length > 0) {
            previousSentMessages.forEach((msg) => {
              finalRequestBody.push(msg);
              if (mksUserMessages.length === 0) {
                oldmksUserMessages.push(msg);
              }
            });
          }
        }
        finalRequestBody.push(JSON.parse(userMessageModel));
        let finalString = JSON.stringify(finalRequestBody);
        finalString = finalString.substring(1, finalString.length - 1);
        requestBody = requestBody.replace("$UserMessage", finalString);

        oldmksUserMessages.push(JSON.parse(userMessageModel));

        mksDefaultConfiguration.headers.openai_api_key =
          mksDefaultConfiguration.headers["api-key"];
        mksDefaultConfiguration.headers.openai_endpoint =
          mksDefaultConfiguration.url;

        let response = "";
        setIsMKSProcessing(true);
        try {
          const responseA = await fetch(
            process.env.REACT_APP_CHAT_WRAPPER_URL,
            {
              method: mksDefaultConfiguration.method,
              headers: mksDefaultConfiguration.headers,
              body: json.stringify(requestBody),
            }
          );

          if (!responseA.ok || !responseA.body) {
            handleApiError(new BOTAPIError("MKS Chat"));
            handleExceptionResponse();
            return;
          }
          const reader = responseA.body.getReader();
          const decoder = new TextDecoder();
          const loopRunner = true;
          let runningText = "";
          while (loopRunner) {
            try {
              setIsMKSProcessing(true);
              const { done, value } = await reader.read();
              if (done) break;

              var text = new TextDecoder("utf-8").decode(value, {
                stream: true,
              });
              const objects = text.split("\n");
              objects.forEach((obj) => {
                try {
                  if (obj !== "" && obj !== "{}") {
                    runningText += obj;
                    let result = JSON.parse(runningText);
                    if (result.choices?.length > 0) {
                      result.choices[0].messages.forEach((msg) => {
                        if (msg.role === "ASSISTANT") {
                          response += msg.content;
                        }
                      });
                    }
                    runningText = "";
                  }
                } catch (e) {}
              });
              setIsMKSProcessing(false);
            } catch (readError) {
              break; // Exit the loop if reading fails
            }
          }
        } catch (fetchError) {
          handleExceptionAndMessages();
          return;
        }

        const botMessage = {
          text: response,
          type: "reply",
          imageURL: AppImages.chatAIIcon,
          citations: [],
          chatType: isMKSChat,
          skill: "",
        };

        let assistantMessageModel =
          mksDefaultConfiguration.assistantMessage.replace(
            "$replyMsg",
            `"${response}"`
          );
        let assistantMessage = {};
        try {
          assistantMessage = JSON.parse(assistantMessageModel);
        } catch (error) {
          assistantMessage = JSON.parse(
            mksDefaultConfiguration.assistantMessage.replace("$replyMsg", `"$"`)
          );
          if (assistantMessage.content[0].type === undefined) {
            assistantMessage.content = response;
            assistantMessage.role = "assistant";
          } else {
            assistantMessage.content[0].text = response;
            assistantMessage.role = "assistant";
          }
        }

        mksUserMessages.push(assistantMessage);
        setMksUserMessages([...mksUserMessages]);
        setMessageToSession(botMessage);
        setMessages((prev) => [
          ...prev.filter((p) => p.text !== "loader"),
          botMessage,
        ]);
        setShowChatLoader(false);
        setLastDisplayMessage("");
        typeText(response, []);
      }
    } catch {
      handleExceptionAndMessages();
    }
  };

  const handleMksAIResponse = async (inputText) => {
    try {
      if (!inputText?.trim()) {
        return;
      }

      if (mksDefaultConfiguration !== undefined) {
        setIsSkillProcessing(false);
        setIsMKSProcessing(true);
        setShowChatLoader(true);
        setLastDisplayMessage("");
        setDisplayLastCitations([]);
        setDisableStopGenerateButton(true);
        setDisableRegenerateButton(true);
        let requestBody = mksDefaultConfiguration.body;
        const userMessage = {
          text: inputText,
          type: "user",
          imageURL: AppImages.profilePic,
          chatType: isMKSChat,
          skill: "",
        };
        setMessageToSession(userMessage);
        setMessages((prev) => [...prev, userMessage]);

        const aiResponse = {
          text: "loader",
          type: "reply",
          imageURL: AppImages.chatAIIcon,
          chatType: isMKSChat,
          skill: "",
        };
        setMessages((prev) => [...prev, aiResponse]);
        let updatedText = removeSpecialCharacters(inputText);
        const formatedInputText = updatedText
          .replace(/\n/g, "\\n") // Escape newlines
          .replace(/\r/g, "\\r") // Escape carriage returns
          .replace(/\\/g, "\\\\")
          .replace(/^"|"$/g, "");

        let userMessageModel = mksDefaultConfiguration.userMessage.replace(
          "$Message",
          `"${formatedInputText}"`
        );

        let lastMessageCount = mksDefaultConfiguration.lastMessageCount;
        // let parsedUserMessageModel = JSON.parse(userMessageModel);

        let oldmksUserMessages = [...mksUserMessages];
        const finalRequestBody = [];
        if (lastMessageCount > 0) {
          let previousSentMessages = filterTopMessagesV2(
            genericAPIUserMessages,
            parseInt(lastMessageCount),
            messages,
            mksDefaultConfiguration.assistantMessage,
            mksDefaultConfiguration.userMessage
          );

          if (previousSentMessages.length > 0) {
            previousSentMessages.forEach((msg) => {
              finalRequestBody.push(msg);
              if (mksUserMessages.length === 0) {
                oldmksUserMessages.push(msg);
              }
            });
          }
        }
        finalRequestBody.push(JSON.parse(userMessageModel));
        let finalString = JSON.stringify(finalRequestBody);
        finalString = finalString.substring(1, finalString.length - 1);
        requestBody = requestBody.replace("$UserMessage", finalString);

        oldmksUserMessages.push(JSON.parse(userMessageModel));

        let response = "";
        setIsMKSProcessing(true);
        try {
          const responseA = await fetch(
            removeTrailingSlash(mksDefaultConfiguration.url),
            {
              method: mksDefaultConfiguration.method,
              headers: mksDefaultConfiguration.headers,
              body: requestBody,
            }
          );

          if (!responseA.ok || !responseA.body) {
            handleApiError(new BOTAPIError("MKS Chat"));
            handleExceptionResponse();
            return;
          }
          const reader = responseA.body.getReader();
          const decoder = new TextDecoder();
          const loopRunner = true;
          while (loopRunner) {
            try {
              setIsMKSProcessing(true);
              // Read each chunk in the stream
              const { value, done } = await reader.read();
              if (done) {
                break;
              }

              // Decode the chunk and handle potential errors
              const decodedChunk = decoder.decode(value, { stream: true });
              let datas;

              try {
                // Process the decoded chunk
                datas = JSON.parse(
                  JSON.stringify(
                    decodedChunk
                      .replace("data: ", "")
                      .split("\n\ndata:")
                      .slice(0, -1)
                  )
                );
              } catch (decodeError) {
                console.log("Error processing decoded chunk:", decodeError);
                continue; // Skip this chunk if JSON parsing fails
              }

              // Process each item in the parsed data
              let counter = 0;
              datas.forEach((da) => {
                let jsonArray = null;
                try {
                  jsonArray = JSON.parse(da);
                  if (jsonArray.choices.length > 0) {
                    const text = jsonArray.choices[0].delta?.content || "";
                    if (counter < 2 && (text.match(/\n/) || []).length) {
                      return;
                    }
                    response = response + text;
                    setIsTyping(true);
                    counter++;
                  }
                } catch (parseError) {
                  console.log(
                    "Error parsing JSON array item:",
                    parseError,
                    " Raw Item ",
                    da
                  );
                }
              });
            } catch (readError) {
              break; // Exit the loop if reading fails
            }
          }
        } catch (fetchError) {
          handleExceptionAndMessages();
          return;
        }

        const botMessage = {
          text: response,
          type: "reply",
          imageURL: AppImages.chatAIIcon,
          citations: [],
          chatType: isMKSChat,
          skill: "",
        };

        let assistantMessageModel =
          mksDefaultConfiguration.assistantMessage.replace(
            "$replyMsg",
            `"${response}"`
          );
        let assistantMessage = {};
        try {
          assistantMessage = JSON.parse(assistantMessageModel);
        } catch (error) {
          assistantMessage = JSON.parse(
            mksDefaultConfiguration.assistantMessage.replace("$replyMsg", `"$"`)
          );
          if (assistantMessage.content[0].type === undefined) {
            assistantMessage.content = response;
            assistantMessage.role = "assistant";
          } else {
            assistantMessage.content[0].text = response;
            assistantMessage.role = "assistant";
          }
        }

        mksUserMessages.push(assistantMessage);
        setMksUserMessages([...mksUserMessages]);
        setMessageToSession(botMessage);
        setMessages((prev) => [
          ...prev.filter((p) => p.text !== "loader"),
          botMessage,
        ]);
        setShowChatLoader(false);
        setLastDisplayMessage("");
        typeText(response, []);
      }
    } catch {
      handleExceptionAndMessages();
    }
  };

  const removeTrailingSlash = (url) => {
    return url.endsWith("\\") ? url.slice(0, -1) : url;
  };

  const typeText = (replyText, citations) => {
    setCurrentMessage(replyText);
    setResumeCitations(citations);
    let index = 0;
    if (!isRunning) {
      setIsRunning(true);

      intervalRef.current = setInterval(() => {
        setLastDisplayMessage(
          (lastDisplayMessage) => lastDisplayMessage + replyText[index - 1]
        );
        setDisableStopGenerateButton(false);
        index++;
        setRunningIndex(index);
        if (isMKSChat) {
          setIsMKSProcessing(true);
          setIsSkillProcessing(false);
        } else {
          setIsMKSProcessing(false);
          setIsSkillProcessing(true);
        }

        if (index >= replyText.length) {
          clearInterval(intervalRef.current);
          setIsTyping(false);
          setIsRunning(false);
          setRunningIndex(replyText.length - 1);
          setDisplayLastCitations(citations);
          setDisableStopGenerateButton(true);
          setDisableRegenerateButton(true);
        }
      }, process.env.REACT_DEFAULT_TIME_INTERVAL);
    }
  };

  // useEffect(() => {
  //   typeTextV2(generatedResponse, generatedCitations);
  // }, [generatedResponse.length]);

  const pauseTimer = () => {
    if (isRunning) {
      setIsRunning(false);
      clearInterval(intervalRef.current);
      setIsMKSProcessing(false);
      setIsSkillProcessing(false);
      setDisableRegenerateButton(false);
      setDisableStopGenerateButton(true);
      setInit(true);
    }
  };

  const resumeResponse = () => {
    setDisableStopGenerateButton(false);
    setDisableRegenerateButton(true);
    let index = runningIndex;
    if (!isMKSChat) {
      setIsSkillProcessing(true);
      setIsMKSProcessing(false);
    } else {
      setIsSkillProcessing(false);
      setIsMKSProcessing(true);
    }

    if (
      !isRunning &&
      currentMessage &&
      lastDisplayMessage.length < currentMessage.length
    ) {
      setIsRunning(true);
      setDisableStopGenerateButton(false);

      intervalRef.current = setInterval(() => {
        if (currentMessage[index - 1] !== undefined) {
          setLastDisplayMessage((prev) => {
            if (prev.length < currentMessage.length) {
              return prev + currentMessage[index - 1];
            }
            return prev;
          });
          index++;
          setRunningIndex(index);
        }

        if (index >= currentMessage.length) {
          clearInterval(intervalRef.current);
          setIsTyping(false);
          setIsRunning(false);
          setDisableStopGenerateButton(true);
          setDisableRegenerateButton(true);
          setDisplayLastCitations(resumeCitations);
        }
      }, process.env.REACT_DEFAULT_TIME_INTERVAL);
    }
  };

  const getWelcomeMessage = () => {
    if (messages?.length === 0) {
      if (isMKSChat) {
        return (
          <>
            <h1 className="h1 color-span medium-font">Welcome to MKS Chat</h1>
            <p className="subtext">
              Get more contextual answers to problems you are solving with a
              conversational assistant tailored to specialized domains and
              functional areas.
            </p>
          </>
        );
      } else {
        return (
          <>
            <h1 className="h1 color-span medium-font">
              Welcome to{" "}
              {skill?.Name === "" ||
              skill?.Name === undefined ||
              defaultSkillWelMessage
                ? "Skills Chat"
                : skill?.Name + " Chat"}
            </h1>
            <p className="subtext">
              {skill?.ShortDescription === "" ||
              skill?.ShortDescription === undefined ||
              skill?.ShortDescription === null ||
              defaultSkillWelMessage
                ? "Get more contextual answers to problems you are solving with a conversational assistant tailored to specialized domains and functional areas."
                : skill?.ShortDescription}
            </p>
          </>
        );
      }
    }
  };

  return (
    <>
      <div className="chatbox">
        <div className="userintro">
          <p>Hi {fullName} </p>
          <div className="wavy-hand"></div>
        </div>

        {getWelcomeMessage()}
        <>
          {isFileTypeChat && (
            <FileChatbot
              showChatLoader={showChatLoader}
              messages={messages}
              initials={initials}
              isFileTypeChat={isFileTypeChat}
              lastDisplayMessage={lastDisplayFileMessage}
              uploadedFileDetails={uploadedFileDetails}
              isTyping={isTyping}
              blackBoxAdditives={blackBoxAdditives}
              isBlackBoxAdded={isBlackBoxAdded}
              setIsMKSProcessing={setIsMKSProcessing}
              setIsSkillProcessing={setIsSkillProcessing}
              isTranslateAdded={isTranslateAdded}
            ></FileChatbot>
          )}
          {!isFileTypeChat && (
            <TextChatbot
              showChatLoader={showChatLoader}
              messages={messages}
              initials={initials}
              isFileTypeChat={isFileTypeChat}
              displayLastCitations={displayLastCitations}
              lastDisplayMessage={lastDisplayMessage}
              setOpenPdfViewer={setOpenPdfViewer}
              setSelectedCitation={setSelectedCitation}
              isTyping={isTyping}
              setIsMKSProcessing={setIsMKSProcessing}
              setIsSkillProcessing={setIsSkillProcessing}
              setPdfClass={setPdfClass}
              pdfClass={pdfClass}
              showFollowupQuestion={showFollowupQuestion}
              disableFolloupButton={disableFolloupButton}
              handleSendMessage={handleAIGenericResponse}
            ></TextChatbot>
          )}
        </>
        {/* {!isFileTypeChat && (
          <div
            className={`chat-event-btn-wrapper ${
              isFileTypeChat ? "dropchat" : ""
            }`}
          >
            <div
              className={`chat-event-button stop-generate w-button ${
                disableStopGenerateButton === true ? "disabled-div" : ""
              }`}
              onClick={(e) => pauseTimer()}
            >
              Stop Generate
            </div>
            <div
              className={`chat-event-button regenerate w-button ${
                disableRegenerateButton === true ? "disabled-div" : ""
              }`}
              onClick={(e) => resumeResponse()}
            >
              Regenerate
            </div>
          </div>
        )} */}

        <div ref={bottomPanelRef}></div>
      </div>

      <ChatPrompt
        setInit={setInit}
        isMKSChat={isMKSChat}
        navigateFromHome={navigateFromHome}
        chatMessage={chatMessage}
        promptText={promptText}
        setSkill={setSkill}
        skill={skill}
        handleSendMessage={handleAIGenericResponse}
        fileName={fileName}
        setMessages={setMessages}
        setIsSkillChat={setIsSkillChat}
        setIsFileTypeChat={setIsFileTypeChat}
        isFileTypeChat={isFileTypeChat}
        setDefaultSkillWelMessage={setDefaultSkillWelMessage}
        setUploadedFilesFromChat={setUploadedFilesFromChat}
        uploadedFilesFromChat={uploadedFilesFromChat}
        clearUploadedFile={clearUploadedFile}
        setResetAdditives={setResetAdditives}
        setGenericAPIUserMessages={setGenericAPIUserMessages}
        isMKSProcessing={isMKSProcessing}
        setIsMKSProcessing={setIsMKSProcessing}
        isSkillProcessing={isSkillProcessing}
        setIsSkillProcessing={setIsSkillProcessing}
        setClearUploadedFile={setClearUploadedFile}
        selectedAdditives={selectedAdditives}
        setOpenPdfViewer={setOpenPdfViewer}
      />

      <ChatHistory
        isSkillChat={!isMKSChat}
        isMKSChat={isMKSChat}
        isFileType={isFileTypeChat}
        setSelectedAdditives={setSelectedAdditives}
        setIsClearChat={setIsClearChat}
        isChatVisible={isChatVisible}
        openPdfViewer={openPdfViewer}
        setOpenPdfViewer={setOpenPdfViewer}
        selectedCitation={selectedCitation}
        resetAdditives={resetAdditives}
        setResetAdditives={setResetAdditives}
        selectedAdditives={selectedAdditives}
        isMKSProcessing={isMKSProcessing}
        isSkillProcessing={isSkillProcessing}
        skill={skill}
        pdfClass={pdfClass}
        setPdfClass={setPdfClass}
      />
    </>
  );
};

export default ChatBox;
