import React, { useEffect, useState } from "react";
import BreadCrumb from "../_partials/breadCrumb";
import PromptSteps from "../_partials/promptSteps";
import PromptStepOne from "../_partials/promptStepOne";
import PromptStepTwo from "../_partials/promptStepTwo";
import PromptStepThree from "../_partials/promptStepThree";
import { useNavigate } from "react-router-dom";
import {
  defaultDisclaimerText,
  defaultConfidentialText,
} from "../../../../../constants/enums";
import { getDefaultChatbotConfig } from "../../../shared/defaultChatBotConfig/defaultConfigService";
import { PdfURLProvider } from "../../../../../context/pdfURL/pdfURLProvider";

const AddPrompt = () => {
  const [currentStep, setCurrentStep] = useState(1);

  const [promptResult, setPromptResult] = useState(null);
  const [promptTextData, setPromptTextData] = useState({});
  const [selectedPrompts, setSelectedPrompts] = useState(null);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedOptionsData, setSelectedOptionsData] = useState({});
  const [chatbotResponse, setChatbotResponse] = useState({
    description: "",
    citations: [],
  });
  const [isBackButtonClicked, setIsBackButtonClicked] = useState(false);
  const [promptForm, setPromptForm] = useState({
    IsDisclaimerAccepted: false,
    SkillStoreId: 0,
    DisclaimerText: defaultDisclaimerText,
    ConfidentialText: defaultConfidentialText,
    Title: "",
    ShortDescription: "",
    PromptBackground: "",
    DesiredPromptOutput: "",
    SourceOnInputOutput: "",
    ImageName: "",
    CardImage_Base64: "",
    imageURL: "",
    MimeType: "",
    inputWordCount: null,
    PromptSystemGeneratedInputs: [],
    IsConfidentialAccepted: false,
    isPromptPrivate: false,
    functions: [],
    subFunctions: [],
    LookupCreativityId: 0,
    tags: [],
    WordCount: 0,
    Result: "",
    SelectedPreference: "",
  });

  const [wordCount, setWordCount] = useState();
  const [selectedTagsOriginal, setSelectedTagsOriginal] = useState([]);
  const [promptMessage, setPromptMessage] = useState([]);
  const [mksDefaultConfiguration, setMksDefaultConfiguration] = useState();
  const [selectedPromptForTest, setSelectedPromptForTest] = useState("");
  const [isShowConfidentialPopup, setIsShowConfidentialPopup] = useState(false);
  const handleNextStepFromStepOne = (prompt) => {
    if (promptForm?.PromptSystemGeneratedInputs.length > 0) {
      prompt.PromptSystemGeneratedInputs =
        promptForm.PromptSystemGeneratedInputs;
    }
    prompt.SkillStoreId = promptForm?.SkillStoreId;
    setPromptForm(prompt);
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handleNextStepFromStepTwo = (prompt) => {
    setPromptForm(prompt);
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const promptResultRegenerted = (promptTextData, result) => {
    setPromptTextData(promptTextData);
    setPromptResult(result);
  };

  const handleBackStep = () => {
    if (promptForm?.PromptSystemGeneratedInputs.length > 0) {
      prompt.PromptSystemGeneratedInputs =
        promptForm.PromptSystemGeneratedInputs;
    }
    prompt.SkillStoreId = promptForm?.SkillStoreId;
    prompt.SourceOnInputOutput = promptForm?.SourceOnInputOutput;
    setIsBackButtonClicked(true);
    setCurrentStep((prevStep) => (prevStep > 1 ? prevStep - 1 : prevStep)); // Move to the previous step
  };

  const handleBackStepFromThree = (promptForm) => {
    prompt.tags = promptForm?.tags;
    prompt.LookupDepartmentId = promptForm?.LookupDepartmentId;
    prompt.functions = promptForm?.functions;
    prompt.subFunctions = promptForm?.subFunctions;
    setPromptForm(promptForm);
    setIsBackButtonClicked(true);
    setCurrentStep((prevStep) => (prevStep > 1 ? prevStep - 1 : prevStep)); // Move to the previous step
  };

  const handleAppendTags = (newTags) => {
    setSelectedTags((prevTags) => [...selectedTagsOriginal, ...newTags]);
    setSelectedTagsOriginal((prevTags) => [
      ...selectedTagsOriginal,
      ...newTags,
    ]);
  };

  useEffect(() => {
    getDefaultChatbotConfig().then((data) => {
      setMksDefaultConfiguration(data);
    });
  }, []);

  const handleBackButtonClicked = () => {
    setIsBackButtonClicked(false);
  };

  return (
    <PdfURLProvider>
      <div className="dashboard-wrapper">
        <BreadCrumb />
        <div className="section-wrapper">
          <PromptSteps currentStep={currentStep} />
          {currentStep === 1 && (
            <PromptStepOne
              onNextStep={handleNextStepFromStepOne}
              promptForm={promptForm}
            />
          )}
          {currentStep === 2 && (
            <PromptStepTwo
              promptTextData={promptForm}
              moveToLastStep={handleNextStepFromStepTwo}
              onBackStep={handleBackStep}
              generatedPrompt={promptResult}
              promptResult={promptResultRegenerted}
              prevSelectedPrompts={selectedPrompts}
              handleAppendTags={handleAppendTags}
              chatbotResponse={chatbotResponse}
              setChatbotResponse={setChatbotResponse}
              promptMessage={promptMessage}
              setPromptMessage={setPromptMessage}
              backButtonClicked={isBackButtonClicked}
              handleBackButtonClicked={handleBackButtonClicked}
              prevPromptMessage={promptMessage}
              setPromptFormChild={setPromptForm}
              mksDefaultConfiguration={mksDefaultConfiguration}
              currentStep={currentStep}
              selectedPromptForTest={selectedPromptForTest}
              setSelectedPromptForTest={setSelectedPromptForTest}
              setIsShowConfidentialPopup={setIsShowConfidentialPopup}
              isShowConfidentialPopup={isShowConfidentialPopup}
            />
          )}
          {currentStep === 3 && (
            <PromptStepThree
              mksDefaultConfiguration={mksDefaultConfiguration}
              onBackStep={handleBackStepFromThree}
              promptFromChild={promptForm}
              prevWordCount={wordCount}
              chatbotResponse={chatbotResponse}
              promptMessage={promptMessage}
              setPromptMessage={setPromptMessage}
              setChatbotResponse={setChatbotResponse}
              setPromptFormChild={setPromptForm}
              selectedPromptForTest={selectedPromptForTest}
              setSelectedPromptForTest={setSelectedPromptForTest}
              handleAppendTags={handleAppendTags}
              setIsShowConfidentialPopup={setIsShowConfidentialPopup}
              isShowConfidentialPopup={isShowConfidentialPopup}
            />
          )}
        </div>
      </div>
    </PdfURLProvider>
  );
};

export default AddPrompt;
