import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import samplecsv from "../../../../../styles/images/sample-csv.csv";
import {
  postBulkImportPromptV2,
  getUseSkills,
} from "../../services/promptManagementService";
import { Pager } from "../../../shared/pager";
import { Link } from "react-router-dom";
import DeleteImportConfirmation from "../_partials/deleteImportConfirmation";
import { hasGroupAccess } from "../../../../../services/commonService";
import { UserContext } from "../../../../../context/user/userContext";
import showMessage from "../../../../common/message";


const ImportPrompt = () => {
  const [fileName, setFileName] = useState("");
  const [uploadStatus, setUploadStatus] = useState("default");
  const [errorFreeEntries, setErrorFreeEntries] = useState([]);
  const [errorEntries, setErrorEntries] = useState([]);
  const [isImportDisabled, setIsImportDisabled] = useState(true);
  const [useSkills, setUseSkills] = useState([]);
  const [currentPageNbr, setCurentPageNbr] = useState(1);
  const [currentPageSize, setCurrentPageSize] = useState(10);
  const [importCountList, setImportCountList] = useState([]);
  const [originalPagedImportData, setOriginalPagedImportData] = useState([]);
  const [isShowDeleteConfirmation, setIsShowDeleteConfirmation] = useState(false);
  const [selectedPromptToDelete, setSelectedPromptToDelete] = useState(null);
  const { state } = useContext(UserContext);
  const securityGroups = state?.user?.userProfile?.msalSecurityGroup;

  useEffect(() => {
    getUseSkillsData();
  }, []);

  // Parse CSV content into an array of objects
  const parseCsv = (csvContent) => {
    const [headerLine, ...rows] = csvContent.split("\n").filter(Boolean);
    const headers = headerLine.split(",").map((header) => header?.trim());

    return rows
      .map((row) => {
        // Parse the row using a CSV parser logic that respects quotes
        const values = [];
        let current = "";
        let inQuotes = false;

        for (let char of row) {
          if (char === '"' && !inQuotes) {
            inQuotes = true; // Start of a quoted value
          } else if (char === '"' && inQuotes) {
            inQuotes = false; // End of a quoted value
          } else if (char === "," && !inQuotes) {
            // Split value on comma if not inside quotes
            values.push(current.trim());
            current = "";
          } else {
            current += char; // Add character to the current value
          }
        }
        // Push the last value
        values.push(current.trim());

        if (values.length !== headers.length) return null; // Skip malformed rows

        return headers.reduce((acc, header, index) => {
          acc[header] = values[index] || ""; // Assign empty string if missing
          return acc;
        }, {});
      })
      .filter(Boolean); // Remove null rows
  };

  const validateRow = (row) => {
    const errors = [];
    if (!row.Title?.trim()) errors.push("Title is required.");
    if (!row.Description?.trim()) errors.push("Description is required.");
    return errors;
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file && file.type === "text/csv") {
      setFileName(file.name);
      setUploadStatus("default");
      setErrorFreeEntries([]);
      setOriginalPagedImportData([]);
      setErrorEntries([]);
      setIsImportDisabled(true);
      processFile(file);

      // Reset file input
      event.target.value = null;
    } else {
      setUploadStatus("error");
      setIsImportDisabled(true);
      toast.error("Please upload a valid CSV file.");
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file && file.type === "text/csv") {
      setFileName(file.name);
      setUploadStatus("default");
      setErrorFreeEntries([]);
      setOriginalPagedImportData([]);
      setErrorEntries([]);
      setIsImportDisabled(true);
      processFile(file);
    } else {
      toast.error("Please drop a valid CSV file.");
    }
  };

  const getIconClass = () => {
    switch (uploadStatus) {
      case "success":
        return "success-icon";
      case "error":
        return "error-icon";
      default:
        return "uplod-icon";
    }
  };

  const processFile = (file) => {
    const reader = new FileReader();

    // Clear states before starting file processing
    setErrorFreeEntries([]);
    setOriginalPagedImportData([]);
    setErrorEntries([]);
    setUploadStatus("default");

    reader.onload = () => {
      try {
        const content = reader.result;
        const rows = parseCsv(content);

        // Add validation for empty file
        if (rows.length === 0) {
          showMessage("There are no prompt(s) to import.",-2);
          setUploadStatus("error");
          setFileName("");
          return;
        }

        const validEntries = [];
        const invalidEntries = [];
        const defaultSkill = useSkills.filter((item) => item.HasAccess === true);
        if(defaultSkill ===  undefined || defaultSkill ===  null || defaultSkill.length === 0)
        {
          showMessage("You don't have access to any skill bot", -2);
          setUploadStatus("default");
          setFileName("");
        }
        else {
          rows.forEach((row, index) => {
            const errors = validateRow(row);
            if (errors.length > 0) {
              invalidEntries.push({ rowNumber: index + 1, row, errors });
            } else {
              validEntries.push({
                Id: index + 1,
                Title: row.Title,
                Description: row.Description,
                SkillStoreId: useSkills.filter((item) => item.HasAccess === true)[0].Id,
              });
            }
          });
  
          const importLimit = parseInt(process.env.REACT_APP_IMPORT_LIMIT);
          if (validEntries.length > importLimit) {
            validEntries.length = importLimit;
          }
          setErrorFreeEntries(validEntries);
          setOriginalPagedImportData(validEntries);
          setErrorEntries(invalidEntries);
  
          if (invalidEntries.length > 0) {
            setUploadStatus("error");
            setIsImportDisabled(true);
            toast.error("Some rows have errors. Please review them.");
          } else {
            setUploadStatus("success");
            setIsImportDisabled(false);
            toast.success("All rows are valid and ready for import.");
          }
        }
        
      }
      catch (error){
        toast.error("Failed to import data. Please try again.");
      }
      
    };

    reader.onerror = () => {
      setUploadStatus("error");
      setIsImportDisabled(true);
      toast.error("Failed to read the file. Please try again.");
    };

    reader.readAsText(file);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleImport = async () => {
    if (errorFreeEntries.length > 0 && errorEntries?.length === 0) {
      const formattedEntries = errorFreeEntries.map((entry) => ({
        Title: entry.Title,
        ShortDescription: entry.Description,
        SkillStoreId: entry.SkillStoreId,
      }));

      try {
        const response = await postBulkImportPromptV2(formattedEntries);
        if (response) {
          toast.success(response?.Status);
          setUploadStatus("default");
          setFileName("");
          setIsImportDisabled(true);
          setErrorFreeEntries([]);
          setOriginalPagedImportData([]);
        }
      } catch (error) {
        toast.error("Failed to import data. Please try again.");
      }
    } else {
      toast.error("No valid entries to import.");
    }
  };

  const templateDownlod = () => {
    const filepath = samplecsv;
    const a = document.createElement("a");
    a.href = filepath;
    a.download = "Prompt_Template.csv";
    a.click();
  };

  const getUseSkillsData = () => {
    getUseSkills().then((resp) => {      
      setUseSkills(resp.map((item) => {
        const access = hasGroupAccess(securityGroups, item.SecurityGroupId);
        item.HasAccess = access;
        return item;
      }));
    });
  };

  useEffect(() => {
    const filterDataImport = () => {
      if (errorEntries?.length === 0) {
        let filteredData = errorFreeEntries;

        setImportCountList(filteredData);
        const totalPages = Math.ceil(filteredData?.length / currentPageSize);
        if (currentPageNbr > 1) {
          if (currentPageNbr > totalPages) {
            setCurentPageNbr(1);
          }
        }

        if (parseInt(currentPageSize) === -1) {
          setOriginalPagedImportData([...filteredData]);
        } else {
          const startIndex = (currentPageNbr - 1) * parseInt(currentPageSize);
          if (filteredData?.length <= startIndex) {
            setOriginalPagedImportData([...filteredData]);
          } else {
            if (filteredData !== undefined) {
              setOriginalPagedImportData([
                ...filteredData?.slice(
                  startIndex,
                  startIndex +
                    parseInt(
                      parseInt(currentPageSize) === -1
                        ? filteredData?.length
                        : currentPageSize
                    )
                ),
              ]);
            }
          }
        }
      }

      if(errorFreeEntries.length === 0)
      {
        setUploadStatus("default");
        setFileName("");
        setIsImportDisabled(true);
      }
    };

    filterDataImport();
  }, [
    errorFreeEntries,
    originalPagedImportData.length,
    currentPageNbr,
    currentPageSize,
  ]);

  const onPageNumberChange = (newPageNumber) => {
    setCurentPageNbr(newPageNumber);
  };

  const onPageSizeChange = (event) => {
    const pageSizeValue = event.target.value;
    setCurentPageNbr(1);
    setCurrentPageSize(parseInt(pageSizeValue));
  };

  const getPagerArea = () => {
    return (
      <Pager
        pageRecords={
          originalPagedImportData === undefined
            ? 0
            : originalPagedImportData.length
        }
        totalRecords={importCountList.length}
        pageNumber={currentPageNbr}
        pageSize={currentPageSize}
        onPageNumberChange={onPageNumberChange}
        onPageChangeSize={onPageSizeChange}
        pageSizeOptions={[10, 20, 50, 100]}
        selectStyleClass={"no-border"}
        hidePageNumber={false}
        paginationRangeSeparator={"-"}
        keepAllPageOptions={true}
      ></Pager>
    );
  };

  const handleSelectedSkillChange = (entry, skillId) => {
    const skillAccess = useSkills.filter((item) => item.Id === parseInt(skillId));
    if(skillAccess !== undefined && skillAccess[0].HasAccess === true)
    {
      entry.SkillStoreId = skillId;
      setErrorFreeEntries(
        errorFreeEntries.map((item) => {
          if (item.Id === entry.Id) {
            item.SkillStoreId = skillId;
            return item;
          } else {
            return item;
          }
        })
      );
    }
    else {
      showMessage("You don't have access to " + skillAccess[0].Name + " bot.", -2);
    }    
  };

  const handleDeleteConfirmation = (entry) => {
    setIsShowDeleteConfirmation(true); 
    setSelectedPromptToDelete(entry); 
  };

  const removePrompt = (entry) => {
    const filteredPrompts = errorFreeEntries.filter(
      (item) => item.Id !== entry.Id
    );
    setErrorFreeEntries(filteredPrompts);
    handleClosePopup();
  };

  const handleClosePopup = () => {
    setIsShowDeleteConfirmation(false);
  };

  return (
    <>
      <div className="import-prompt-box">
        <div className="flex-row">
          <div className="w-50">
            Instructions:
            <ul className="instruction-lines">
              <li className="mb-0 f-10 line-height-normal font-semibold"><em>Please note that you are allowed to submit only 30 prompts.</em></li>
              <li className="mb-0 f-10 line-height-normal font-semibold"><em>Only .csv file accepted.</em></li>
            </ul>
            
          </div>
          <div className="download-text" onClick={templateDownlod}>
            <div className="download-arrow"></div>
            <span>Download Template</span>
          </div>
        </div>
      </div>

      <div className="dropbox-wrapper-main">
        <div className="dropbox-wrapper" onDrop={handleDrop}
                  onDragOver={handleDragOver}>
          <div className="flex-line">
            <label className="linkcss" aria-label="Upload CSV file">
              <div className="prompt-img">
                <div
                  className={getIconClass()}
                  onDrop={handleDrop}
                  onDragOver={handleDragOver}
                >
                  {uploadStatus === "default" && ""} {/* Default icon */}
                  {uploadStatus === "error" && ""} {/* Error icon */}
                  {uploadStatus === "success" && ""} {/* Success icon */}
                </div>
              </div>
              <span
                className="bluelink"
                onDrop={handleDrop}
                onDragOver={handleDragOver}
              >
                Click to upload
              </span>
              <input
                type="file"
                accept=".csv"
                style={{ display: "none" }}
                onChange={handleFileSelect}
              />
              Or Drag and drop
            </label>
          </div>
          
          {fileName && <p className="light-text">{fileName}</p>}
        </div>
        <div className="import-section">
          {errorEntries.length > 0 && (
            <div>
              <h4>Invalid Rows:</h4>
              <ul className="error-rows">
                {errorEntries.map((entry, index) => (
                  <li key={index}>
                    Row {entry.rowNumber}: {entry.errors.join(", ")}
                  </li>
                ))}
              </ul>
            </div>
          )}
          <div className="flex-col">
            {originalPagedImportData.length > 0 &&
              errorEntries.length === 0 && (
                <div className="w-100 excel-box-wrapper">
                  <div className="desc-row gap-2 heading-gray">
                    <div className="w-100">Title</div>
                    <div className="w-100">Description</div>
                    <div className="w-100">Skill</div>
                    <div className="w-100">
                      <div className="close-icon visibilty-hidden"></div>
                    </div>
                  </div>
                  <div className="description-rows">
                    {originalPagedImportData.map((entry, index) => (
                      <div key={index} className=" gap-2 desc-row">
                        <div className="w-100 excel-title">{entry.Title}</div>
                        <div className="w-100 excel-dec">
                          {entry.Description}
                        </div>
                        <div className="w-100 excel-skill">
                          <select
                            key={index}
                            value={entry.SkillStoreId}
                            className="prompt-skill"
                            onChange={(e) => {
                              handleSelectedSkillChange(entry, e.target.value);
                            }}
                          >
                            {useSkills?.map((item) => (
                              <option
                                key={index + "" + item.Id}
                                value={item.Id}
                              >
                                {item.Name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className=" mobile-absolute-icon">
                          <div
                            key={index}
                            className="close-icon"
                            onClick={() => handleDeleteConfirmation(entry)}
                          ></div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            {originalPagedImportData.length > 0 &&
              errorEntries.length === 0 && (
                <div className="pagination-custom">{getPagerArea()}</div>
              )}
          </div>
          <div className="flex flex-end gap-2 mt-2">
            <Link className="cancel-btn small-size-bn" to="/promptmanagement">
              Cancel
            </Link>
            <button
              className={`${
                isImportDisabled
                  ? "w-button button w-button-disabled"
                  : "w-button button"
              }`}
              onClick={handleImport}
              disabled={isImportDisabled}
            >
              Import
            </button>
          </div>
        </div>
      </div>

      {isShowDeleteConfirmation && (
        <DeleteImportConfirmation
          handleClosePopup={handleClosePopup}
          handleDelete={removePrompt}
          selectedPrompt={selectedPromptToDelete}
        />
      )}
    </>
  );
};

export default ImportPrompt;
