import React, { useEffect, useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import loaderContext from "../../../context/loader/loaderContext";
import { UserContext } from "../../../context/user/userContext";
import { setStorageToken } from "../../../services/tokenService";
import { UserCorpLogin, UserLogin } from "../../../services/authService";
import { getPageTitle } from "../../../services/commonService";
import { getAccessToken } from "../../../services/localStorageService";
import { useMsal } from "@azure/msal-react";

const Login = () => {
  const { instance, accounts } = useMsal();
  const location = useLocation();
  const { updateLoaderStatus } = useContext(loaderContext);
  const [formData, setFormData] = useState({ userName: "", password: "" });
  const [loginResp, setLoginResp] = useState(null);
  const [errors, setErrors] = useState({
    userName: false,
    password: false,
    credentials: "",
  });
  const { replaceUrl } = useContext(UserContext);
  let shouldReplaceUrl = false;

  useEffect(() => {
    updateLoaderStatus(false);
    const token = getAccessToken();
    if (token !== null && token !== undefined) {
      CheckToken(token);
      if (shouldReplaceUrl) replaceUrl();
    }
  }, []);

  useEffect(() => {
    if (accounts.length > 0) {
      const accesstoken = getMSALAccessToken();
      Login(true, accesstoken);
    }
  }, [accounts]);
  useEffect(() => {
    updateLoaderStatus(false);
    // Add this line to set the page title
    document.title = getPageTitle(location.pathname);
  }, [location.pathname]);

  const getMSALAccessToken = () => {
    return JSON.parse(
      sessionStorage.getItem(
        JSON.parse(
          sessionStorage.getItem(
            process.env.REACT_APP_MSAL_TOKEN_KEY
          )
        ).accessToken[0]
      )
    ).secret;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    setErrors((prevState) => ({
      ...prevState,
      [name]: false,
      credentials: "",
    }));
  };

  const handleEntraIdLogin = async () => { 
    try {
      updateLoaderStatus(true, "login");
      instance
        .loginRedirect({
          scopes: ["User.Read"],
        })
        .then((loginResponse) => {
          setLoginResp(setLoginResp);
          console.log("Login Successful!", loginResponse);
          updateLoaderStatus(false);
        })
        .catch((error) => {
          console.error("Login Failed", error);
        });
    } catch (error) {
      updateLoaderStatus(false);
      console.error(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    Login(false, null);
  };

  const Login = async (isCorp, token) => {
    try {      
      updateLoaderStatus(true, "login");
      let response;
      if (isCorp) {
        response = await UserCorpLogin(token);
      } else {
        const { userName, password } = formData;
        const newErrors = {
          userName: !userName,
          password: !password,
          credentials: "",
        };

        if (!userName || !password) {
          setErrors(newErrors);
          return;
        }

        const requestBody = {
          userName: formData.userName,
          Password: formData.password,
        };

        response = await UserLogin(requestBody);
      }

      if (response.status === 200) {
        const token = response.data;
        CheckToken(token);
      } else {
        setErrors((prevState) => ({
          ...prevState,
          credentials: "Incorrect credentials",
        }));
        console.error("Error:", response.statusText);
      }
    } catch (err) {
      console.error("Error:", err);
      setErrors((prevState) => ({
        ...prevState,
        credentials: "Something went wrong. Please contact to site owner",
      }));
    } finally {
      updateLoaderStatus(false);
    }

    if (shouldReplaceUrl) replaceUrl();    
  };

  const CheckToken = (token) => {
    shouldReplaceUrl = true;
    setStorageToken(token);
  };

  return (
    <>
      <div className="flexbox">
        <div className="workspace-dashboard flex-end">
          <div className="login-form-logo">
            <div className="login-logo"></div>
          </div>
          <div className="login-form-wrapper">
            <h1>Sign-in to your account</h1>
            <div className="form-wrapper">
              <form
                name="email-form"
                aria-label="Email Form"
                onSubmit={handleSubmit}
              >
                <div className="text-left">
                  <label htmlFor="userName" className="field-label">
                    Username
                  </label>
                  <input
                    className={`input-field w-input ${
                      errors.userName ? "input-error" : ""
                    }`}
                    maxLength={256}
                    name="userName"
                    type="text"
                    onChange={handleChange}
                  />
                </div>
                <div className="text-left">
                  <label htmlFor="password" className="field-label">
                    Password
                  </label>
                  <input
                    className={`input-field w-input ${
                      errors.password ? "input-error" : ""
                    }`}
                    maxLength={256}
                    name="password"
                    type="password"
                    onChange={handleChange}
                  />
                </div>
                <input
                  type="submit"
                  className="submit-button w-button full-width"
                  value="Submit"
                />
                {errors.credentials && (
                  <div className="error-message">{errors.credentials}</div>
                )}
              </form>
              <div className="autl-logo-link">
                <button onClick={handleEntraIdLogin}>
                  <div className="login-small-text">Sign in with</div>
                  <div className="auth-logo-icon"></div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
