import axiosApiInstance from "../../../../api/axiosApiClient";
import { ExtractCurlParameters } from "../shared/curlConverter";

const BASE_MKSPROMPTMANAGEMENT_API_URL = process.env.REACT_APP_MKS_API_URL;
const BASE_MKSAUTH_API_URL = process.env.REACT_APP_AUTHAPI_URL;

export const getPromptManagementStoreData = async () => {
  const isApiCall = true;
  if (isApiCall) {
    const response = await axiosApiInstance.get(
      `${BASE_MKSPROMPTMANAGEMENT_API_URL}prompt/GetPromptUserInputs`
    );
    if (response.status === 200) {
      return response.data;
    }
  }
};

export const setLikeByUserSelection = async (data) => {
  try {
    const response = await axiosApiInstance.put(
      `${BASE_MKSPROMPTMANAGEMENT_API_URL}prompt/UpdateLikeStatus`,
      data
    );

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(`Unexpected response status: ${response.status}`);
    }
  } catch (error) {
    console.error("Error updating like status:", error);
    throw error; // Optionally re-throw the error to be handled elsewhere
  }
};

export const savePromptData = async (data) => {
  try {
    const response = await axiosApiInstance.post(
      `${BASE_MKSPROMPTMANAGEMENT_API_URL}prompt/CreatePrompt`,
      data
    );
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const updatePromptData = async (data, Id) => {
  try {
    const response = await axiosApiInstance.put(
      `${BASE_MKSPROMPTMANAGEMENT_API_URL}prompt/EditPrompt?promptId=${parseInt(
        Id
      )}`,
      data
    );
    if (response?.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const getPromptData = async (id) => {
  try {
    const response = await axiosApiInstance.get(
      `${BASE_MKSPROMPTMANAGEMENT_API_URL}prompt/GetPromptUserInputById?PromptUserInputId=${id}`
    );
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const setFavouriteByUserSelection = async (data) => {
  try {
    const response = await axiosApiInstance.put(
      `${BASE_MKSPROMPTMANAGEMENT_API_URL}prompt/UpdateStaredStatus`,
      data
    );

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(`Unexpected response status: ${response.status}`);
    }
  } catch (error) {
    console.error("Error updating like status:", error);
    throw error; // Optionally re-throw the error to be handled elsewhere
  }
};

export const getUseSkills = async () => {
  try {
    const response = await axiosApiInstance.get(
      `${BASE_MKSPROMPTMANAGEMENT_API_URL}prompt/GetSkills`
    );
    if (response.status === 200) {
      response.data.map((skill) => {
        let params = ExtractCurlParameters(
          skill.ChatbotConfiguration.CurlRequestString
        );
        skill.aiUrl = params.url;
        skill.headers = params.headers;
        skill.apiKey = "";
        skill.messageBody = params.body;
        return skill;
      });
      return response.data;
    } else {
      throw new Error(`Unexpected response status: ${response.status}`);
    }
  } catch (error) {
    console.error("Error updating like status:", error);
    throw error; // Optionally re-throw the error to be handled elsewhere
  }
};

export const generatePromptResult = async (data) => {
  let promptdata = [];
  data.forEach((prmp) => {
    let prompt = {
      promtId: prmp.Id,
      promptMessage: prmp.PromptText,
    };
    promptdata.push(prompt);
  });

  try {
    return promptdata;
  } catch (error) {
    const result = [
      { promtId: 1, promptMessage: "Prompt Message One" },
      { promtId: 2, promptMessage: "Prompt Message two" },
      { promtId: 3, promptMessage: "Prompt Message three" },
      { promtId: 4, promptMessage: "Prompt Message four" },
    ];
    return result;
  }
};

export const regeneratePromptResult = async (data) => {
  try {
    throw "no data for generating";
  } catch (error) {
    const result = [
      { promtId: 1, promptMessage: "Prompt Message One" },
      { promtId: 2, promptMessage: "Prompt Message two" },
      { promtId: 3, promptMessage: "Prompt Message three" },
      { promtId: 4, promptMessage: "Prompt Message four" },
      { promtId: 5, promptMessage: "Prompt Message five" },
    ];
    return result;
  }
};

export const getSkillDepartmentData = async () => {
  try {
    const response = await axiosApiInstance.get(
      `${BASE_MKSPROMPTMANAGEMENT_API_URL}common/GetDepartments`
    );

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(`Unexpected response status: ${response.status}`);
    }
  } catch (error) {
    console.error("Error fetching skill area data:", error);
    throw error;
  }
};

export const getSkillFunctionData = async (departmentId) => {
  try {
    const response = await axiosApiInstance.get(
      `${BASE_MKSPROMPTMANAGEMENT_API_URL}common/GetFunction?LookupDepartmentId=${departmentId}`
    );

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(`Unexpected response status: ${response.status}`);
    }
  } catch (error) {
    console.error("Error fetching skill area data:", error);
    throw error;
  }
};

export const getSkillSubFunctionData = async (functionIds) => {
  try {
    const response = await axiosApiInstance.post(
      `${BASE_MKSPROMPTMANAGEMENT_API_URL}common/GetSubFunction`,
      functionIds
    );

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(`Unexpected response status: ${response.status}`);
    }
  } catch (error) {
    console.error("Error fetching skill area data:", error);
    throw error;
  }
};

export const getCreativityData = async () => {
  try {
    const response = await axiosApiInstance.get(
      `${BASE_MKSPROMPTMANAGEMENT_API_URL}common/GetCreativity`
    );

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(`Unexpected response status: ${response.status}`);
    }
  } catch (error) {
    console.error("Error fetching Creativity data:", error);
    throw error;
  }
};

export const getSkillScopeData = async () => {
  try {
    const response = await axiosApiInstance.get(
      `${BASE_MKSPROMPTMANAGEMENT_API_URL}common/GetSkillScope`
    );

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(`Unexpected response status: ${response.status}`);
    }
  } catch (error) {
    console.error("Error fetching skill status data:", error);
    throw error;
  }
};

export const getTagsData = async () => {
  try {
    const response = await axiosApiInstance.get(
      `${BASE_MKSPROMPTMANAGEMENT_API_URL}common/GetTags`
    );

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(`Unexpected response status: ${response.status}`);
    }
  } catch (error) {
    console.error("Error getting tags:", error);
    throw error;
  }
};

export const addComments = async (data) => {
  try {
    const response = await axiosApiInstance.post(
      `${BASE_MKSPROMPTMANAGEMENT_API_URL}prompt/AddComments`,
      data
    );
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(`Unexpected response status: ${response.status}`);
    }
  } catch (error) {
    console.error("Error during adding comments:", error);
    throw error;
  }
};

export const updateViewStatusById = async (id) => {
  try {
    const response = await axiosApiInstance.put(
      `${BASE_MKSPROMPTMANAGEMENT_API_URL}prompt/UpdateViewStatus?PromptUserInputId=${id}`
    );
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error("Error updating used status:", error);
    throw error; // Optionally re-throw the error to be handled elsewhere
  }
};

export const getShareUserList = async () => {
  try {
    const response = await axiosApiInstance.get(
      `${BASE_MKSAUTH_API_URL}usermanagement/GetUserList`
    );
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(`Unexpected response status: ${response.status}`);
    }
  } catch (error) {
    console.error("Error occured during getuserslist:", error);
    throw error; // Optionally re-throw the error to be handled elsewhere
  }
};

export const sharePromptWithUsers = async (id, data) => {
  try {
    const response = await axiosApiInstance.put(
      `${BASE_MKSPROMPTMANAGEMENT_API_URL}prompt/SharePromptWithUsers?PromptUserInputId=${id}`,
      data
    );
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error("Error updating used status:", error);
    throw error; // Optionally re-throw the error to be handled elsewhere
  }
};

export const postBulkImportPrompt = async (body) => {
  try {
    let url = `${BASE_MKSPROMPTMANAGEMENT_API_URL}prompt/BulkImportPrompt`;
    const response = await axiosApiInstance.post(url, body);
    return await response.data;
  } catch (error) {
    console.error("Error during Capture User Feedback:", error);
    throw error;
  }
};
