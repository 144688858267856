import React, { useEffect, useContext } from "react";
import { UserContext } from "../../../context/user/userContext";
import loaderContext from "../../../context/loader/loaderContext";

import {
  isGivenTokenExpired,
  isGivenTokenExpiring,
  setStorageToken,
} from "../../../services/tokenService";
export const ExternalLogin = () => {
  const { login, logout, removeAll, replaceUrl } = useContext(UserContext);
  const search = window.location.search;
  const queryToken = new URLSearchParams(search).get("token");
  const { updateLoaderStatus } = useContext(loaderContext);
  useEffect(() => {
    const checkIfTokenValid = () => {
      updateLoaderStatus(true);
      let shouldReplaceUrl = false;
      try {
        const isValidQueryToken = () => {
          return (
            queryToken !== null &&
            queryToken !== "" &&
            queryToken !== undefined &&
            queryToken !== "null"
          );
        };

        if (isValidQueryToken()) {
          if (
            isGivenTokenExpiring(queryToken) ||
            isGivenTokenExpired(queryToken)
          ) {
            logout();
          } else {
            removeAll();
            setStorageToken(queryToken);
            shouldReplaceUrl = true;
          }
        } else {
          logout();
        }

        if (shouldReplaceUrl) {
          replaceUrl();
        }
        updateLoaderStatus(false);
      } catch (err) {
        console.log(err);
        logout();
        updateLoaderStatus(false);

      }
    };
    checkIfTokenValid();
  }, [queryToken, login, logout, replaceUrl]);

  return <div className="overlay-loading"></div>;
};
