import React from "react";

const SearchByDepartment = (props) => {
  const { onSearchDepartmentSelection, searchDepartmentData } = props;
  const selectDepartmentHandler = (e) => {
    const selectedVal = e.target.value;
    onSearchDepartmentSelection(selectedVal);
  };
  
  return (
    <select
      className="skill-filter-field w-select"
      onChange={(e) => selectDepartmentHandler(e)}
    >
      <option value="">Select Department</option>
      {searchDepartmentData?.map((item) => (
        <option key={item.Id} value={item.Id}>
          {item.Name}
        </option>
      ))}
    </select>
  );
};

export default SearchByDepartment;
