import React, { useEffect, useRef, useState } from "react";
import {
  getTagsData,
  getSkillDepartmentData,
  getSkillFunctionData,
  getSkillSubFunctionData,
  savePromptData,
} from "../../../services/promptManagementService";
import SelectByDepartment from "../selectByDepartment";
import SelectByFunction from "../selectByFunction";
import SelectBySubFunciton from "../selectBySubFunction";
import showMessage from "../../../../../common/message";
import PrivateSharedPopup from "../privateSharedPopup";
import { useNavigate } from "react-router-dom";
import PromptResults from "../promptResults";
import GeneratedPrompts from "../generatedPrompts";

const PromptStepThree = (props) => {
  const {
    onBackStep,
    onNextStep,
    promptFromChild,
    chatbotResponse,
    setChatbotResponse,
    promptMessage,
    setPromptMessage,
    setPromptFormChild,
  } = props;

  const [promptForm, setPromptForm] = useState(promptFromChild);
  const [inputVal, setInputVal] = useState("");
  const [tagsData, setTagsData] = useState([]);
  const [filteredTags, setFilteredTags] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [departmentData, setDepartmentData] = useState([]);
  const [selectDeparment, setSelectDepartment] = useState(
    promptFromChild?.LookupDepartmentId
  );
  const [functionData, setFunctionData] = useState([]);
  const [selectFunction, setSelectFunction] = useState(
    promptFromChild?.functions
  );
  const [subFunctionData, setSubFunctionData] = useState([]);
  const [selectSubFunction, setSubSelectFunction] = useState(
    promptFromChild?.subFunctions
  );
  const [isShowPrivateSharedPopup, setIsShowPrivateSharedPopup] =
    useState(false);
  const [showTab, setShowTab] = useState(1);
  const promptTextInputref = useRef("");
  const divDepartmentRef = useRef(null);
  const divFunctionRef = useRef(null);
  const divSubFunctionRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const getDataOfTags = async () => {
      const tagsData = await getTagsData();
      const namesOnly = tagsData.map((tag) => tag.Name);
      setTagsData(namesOnly);
    };

    const getDataOfSkillDep = async () => {
      const departmentData = await getSkillDepartmentData();
      setDepartmentData(departmentData);
      if (promptFromChild?.LookupDepartmentId > 0)
        bindFunctionData(promptFromChild?.LookupDepartmentId);
      if (promptFromChild?.functions.length > 0) {
        bindSubFunctionData(promptFromChild?.functions);
      }
    };

    getDataOfTags();
    getDataOfSkillDep();
  }, []);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (filteredTags.length > 0) {
        if (e.key === "ArrowDown") {
          e.preventDefault();
          setSelectedIndex(
            (prevIndex) => (prevIndex + 1) % filteredTags.length
          );
        } else if (e.key === "ArrowUp") {
          e.preventDefault();
          setSelectedIndex(
            (prevIndex) =>
              (prevIndex - 1 + filteredTags.length) % filteredTags.length
          );
        } else if (e.key === "Enter" && selectedIndex >= 0) {
          e.preventDefault();
          const selectedTag = filteredTags[selectedIndex];
          handleTagSelect(selectedTag);
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [selectedIndex, filteredTags]);

  const bindFunctionData = async (departmentId) => {
    const functionData = await getSkillFunctionData(departmentId);
    const filteredData = functionData.filter((func) => func.Name !== "All");
    setFunctionData(filteredData);
  };

  const bindSubFunctionData = async (functionIds) => {
    const subFunctionData = await getSkillSubFunctionData(functionIds);
    setSubFunctionData(subFunctionData);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputVal(value);
    promptTextInputref.current.classList.remove("border-red");

    // Filter tags based on input value
    const filtered = tagsData.filter((tag) =>
      tag.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredTags(filtered);
  };

  const handleTagSelect = (tag) => {
    setPromptForm({ ...promptForm, tags: [...promptForm.tags, tag] });
    // setTags([...tags, tag]);
    setTagsData(tagsData.filter((tagItem) => tagItem !== tag));
    setInputVal("");
    setFilteredTags([]);
  };

  const handleRemoveTag = (tag) => {
    let tagsData = promptForm.tags.filter((tagItem) => tagItem !== tag);

    setPromptForm((prev) => ({
      ...promptForm,
      tags: tagsData,
    }));
  };

  const handleAddTag = () => {
    if (inputVal && !promptForm.tags.includes(inputVal)) {
      setPromptForm({ ...promptForm, tags: [...promptForm.tags, inputVal] });
      setInputVal("");
      setFilteredTags([]);
      promptTextInputref.current.classList.remove("border-red");
    }
  };

  const onDepartmentSelection = async (departmentId) => {
    divDepartmentRef.current.classList.remove("border-red");
    setPromptForm((prev) => ({ ...prev, LookupDepartmentId: departmentId }));
    bindFunctionData(departmentId);
    setSelectFunction([]);
    setSubFunctionData([]);
    setSubSelectFunction([]);
  };

  const onFunctionSelection = async (functionIds) => {
    divFunctionRef.current.classList.remove("border-red");
    setPromptForm((prev) => ({ ...prev, functions: functionIds }));
    setSubSelectFunction([]);
    bindSubFunctionData(functionIds);
  };

  const onSubFunctionSelection = (subFunctionIds) => {
    divSubFunctionRef.current.classList.remove("border-red");
    setPromptForm((prev) => ({ ...prev, subFunctions: subFunctionIds }));
    setSubSelectFunction(subFunctionIds);
  };

  const handleSaveTagsandFunctions = () => {
    if (promptForm.tags?.length === 0) {
      showMessage("Please Add Tags", -2);
      promptTextInputref.current.classList.add("border-red");
    } else if (
      promptForm.LookupDepartmentId === null ||
      promptForm.LookupDepartmentId === undefined ||
      promptForm.LookupDepartmentId === "-1"
    ) {
      showMessage("Please select Department", -2);
      divDepartmentRef.current.classList.add("border-red");
    } else if (
      promptForm.functions === null ||
      promptForm.functions === undefined ||
      promptForm.functions?.length === 0
    ) {
      showMessage("Please select Function", -2);
      divFunctionRef.current.classList.add("border-red");
    }
    //else if (
    //   promptForm.subFunctions === null ||
    //   promptForm.subFunctions === undefined ||
    //   promptForm.subFunctions?.length === 0
    // ) {
    //   showMessage("Please select Sub Function", -2);
    //   divSubFunctionRef.current.classList.add("border-red");
    // }
    else {
      setIsShowPrivateSharedPopup(true);
    }
  };

  const handleBackClick = () => {
    onBackStep(promptForm);
  };

  const handleSavePromptStatus = (value) => {
    setIsShowPrivateSharedPopup(false);
    setIsLoading(true);
    let formData = { ...promptForm };
    formData.IsPublic = value;
    if (chatbotResponse !== null && chatbotResponse !== undefined)
      formData.Result = chatbotResponse.description;
    savePromptData(formData).then((resp) => {
      if (resp?.Status === "Prompt Created Successfully.") {
        showMessage("Prompt Created Successfully.");
        navigate("/promptmanagement");
      } else {
        showMessage("Could not save prompt. Please try again", -2);
      }
    });
  };

  const handleClosePopup = () => {
    setIsShowPrivateSharedPopup(false);
  };

  return (
    <>
      <GeneratedPrompts
        isPromptVisible={true}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        onBackStep={onBackStep}
        promptFromChild={promptForm}
        setChatbotResponse={setChatbotResponse}
        promptMessage={promptMessage}
        setPromptMessage={setPromptMessage}
        setPromptForm={setPromptForm}
        hideBackButton={true}
        setPromptFormChild={setPromptFormChild}
      ></GeneratedPrompts>
      <PromptResults
        showTab={showTab}
        setShowTab={setShowTab}
        chatbotResponse={chatbotResponse}
        promptStepOneDataOriginal={promptForm}
      ></PromptResults>
      <div className="dash-section p20 prompt-step-two">
        <div className="prompt-tags">
          <div className="tag-flex">
            <h3 className="prompt-title">
              Tags <span className="red">*</span>
            </h3>
          </div>

          <div className="prompt-tags-wrap">
            {promptForm.tags.length > 0 &&
              promptForm.tags.map((item, index) => (
                <div className="p-tag" key={index}>
                  {item}
                  <div
                    className="close-icon"
                    onClick={() => handleRemoveTag(item)}
                  ></div>
                </div>
              ))}
          </div>
        </div>
        <div className="addtag-input">
          <input
            type="text"
            className="input-tag"
            value={inputVal}
            ref={promptTextInputref}
            onChange={handleInputChange}
          />
          <button onClick={handleAddTag}>Add</button>

          {filteredTags.length > 0 && (
            <div className="suggestions mks-suggestion-prompt">
              <div className="suggestion-item-heading">Suggestions</div>
              {filteredTags.map((tag, index) => (
                <div
                  key={index}
                  className={`suggestion-item ${
                    index === selectedIndex ? "selected" : ""
                  }`}
                  onClick={() => handleTagSelect(tag)}
                >
                  {tag}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      <div className="dash-section p20 prompt-step-two">
        <div className="prompt-selects">
          <div className="prompt-function">
            <h3 className="prompt-title">
              Department <span className="red">*</span>
            </h3>

            <div ref={divDepartmentRef}>
              <SelectByDepartment
                onDepartmentSelection={onDepartmentSelection}
                departmentData={departmentData}
                selectDepartment={selectDeparment}
              />
            </div>
          </div>
          <div className="prompt-function">
            <h3 className="prompt-title">
              Function<span className="red">*</span>
            </h3>

            <div ref={divFunctionRef}>
              <SelectByFunction
                onFunctionSelection={onFunctionSelection}
                functionData={functionData}
                selectedFunction={selectFunction}
              />
            </div>
          </div>
          <div className="prompt-function">
            <h3 className="prompt-title">Sub Function</h3>

            <div ref={divSubFunctionRef}>
              <SelectBySubFunciton
                onSubFunctionSelection={onSubFunctionSelection}
                subFunctionData={subFunctionData}
                selectedSubFunction={selectSubFunction}
              />
            </div>
          </div>
        </div>

        <div className="h-line"></div>
        <div className="prompt-button-wrapper">
          <button className="backbtn" onClick={() => handleBackClick()}>
            Back
          </button>

          <div className="flex-block">
            <button
              className="genrate-btn"
              onClick={() => handleSaveTagsandFunctions()}
            >
              Submit Prompt
            </button>
          </div>
        </div>
      </div>
      {isShowPrivateSharedPopup && (
        <PrivateSharedPopup
          handleSavePromptStatus={handleSavePromptStatus}
          handleClosePopup={handleClosePopup}
        ></PrivateSharedPopup>
      )}
    </>
  );
};

export default PromptStepThree;
