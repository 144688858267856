import React, { useRef, useState } from "react";

const SearchByInput = (props) => {
  const { onInputSearch } = props;
  const refInputVal = useRef(null);
  const [isCross, setIsCross] = useState(false);

  const searchHander = () => {
    const searchValue = refInputVal.current.value;
    // const searchValue = e.target.value;
    if (searchValue.length === 0) setIsCross(false);
    else setIsCross(true);
    onInputSearch(searchValue);
  };

  const resetInputBoxValue = () => {
    refInputVal.current.value = null;
    setIsCross(false);
    onInputSearch();
  };

  return (
    <div className="search-cross-icon-common">
      <input
        ref={refInputVal}
        className="skill-filter-field w-input"
        maxLength="256"
        placeholder="Search..."
        type="text"
        onKeyUp={() => searchHander()}
      />

      {isCross === false ? (
        <div className="search-tile-icon-common"></div>
      ) : (
        <div className="cross-tile-box-common" onClick={resetInputBoxValue}></div>
      )}
    </div>
  );
};

export default SearchByInput;
