import React, { useEffect, useState, useCallback } from "react";
import DraggableTile from "../../../DraggableTile";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import { changeWorkspaceCardOrderData } from "../../../services/myWorkspaceService";
import { getStorageToken } from "../../../../../../services/tokenService";
import { encodeSVG } from "../../../../../../services/commonService";

const AppTiles = ({ appsTiles }) => {
  const [tiles, setTiles] = useState([]);
  const backend = "ontouchstart" in window ? TouchBackend : HTML5Backend;

  useEffect(() => {
    setTiles(appsTiles.items);
  }, [appsTiles]);

  const activeTiles = tiles.filter((tile) => tile.IsActive);

  const moveTile = useCallback((dragIndex, hoverIndex) => {
    setTiles((prevTiles) => {
      const activeTiles = prevTiles.filter((tile) => tile.IsActive);

      const draggedTile = activeTiles[dragIndex];
      const hoverTile = activeTiles[hoverIndex];

      const dragActualIndex = prevTiles.findIndex(
        (tile) => tile.Id === draggedTile.Id
      );
      const hoverActualIndex = prevTiles.findIndex(
        (tile) => tile.Id === hoverTile.Id
      );

      const newTiles = [...prevTiles];
      newTiles.splice(dragActualIndex, 1);
      newTiles.splice(hoverActualIndex, 0, draggedTile);

      handleChangeCardDashboardData(newTiles);
      return newTiles;
    });
  }, []);

  const handleChangeCardDashboardData = (updatedTiles) => {
    const newTilesOrder = updatedTiles.map((tile, index) => ({
      DashboardCardId: tile.Id,
      Ordinal: index + 1,
      IsActive: tile.IsActive,
    }));

    changeWorkspaceCardOrderData(newTilesOrder).then((resp) => {});
  };

  const convertToAppS = (category) => {
    return category.replace("Apps", "App(s)");
  };

  const renderTile = (tile, index) => {
    if (!tile || typeof tile !== "object") {
      console.warn("Invalid tile object:", tile);
      return null;
    }

    const cleanedSVG = tile.CardImage.slice(2, -1);
    const encodedSVG = encodeSVG(cleanedSVG);

    return (
      <DraggableTile
        key={tile.Order}
        index={index}
        id={tile.Order}
        cardId={tile.Id}
        redirectionLink={tile.redirectionLink}
        imgWidth={tile.imgWidth}
        imgPath={`data:${tile.MimeType};utf8,${encodedSVG}`}
        heading={tile.Heading}
        subheading={tile.SubHeading}
        moveTile={moveTile}
        LoginType={tile.LoginType}
        IsNewTab={tile.IsNewTab}
        token={getStorageToken()}
      />
    );
  };

  return (
    <>
      <div className="app-group-heading">
        <div>{appsTiles.AppCategory} </div>
      </div>
      <DndProvider backend={backend}>
        <div className="w-layout-grid app-links-grid list2">
          {activeTiles === null ||
          activeTiles === undefined ||
          activeTiles.length === 0 ? (
            <span className="app-group-norecordfound">
              No {convertToAppS(appsTiles.AppCategory)} found
            </span>
          ) : (
            activeTiles.map((tile, index) => renderTile(tile, index))
          )}
        </div>
      </DndProvider>
    </>
  );
};

export default React.memo(AppTiles);
