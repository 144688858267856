import XLSX from "sheetjs-style";
import { generateColumnBaseArray } from "../shared/tableConverter";

export const ExporttToExcelBlackBoxAdditives = (lastDisplayMessage) => {
  const workbook = XLSX.utils.book_new();

  lastDisplayMessage.forEach((msg) => {
    let datatable = generateColumnBaseArray(msg.jsonOutPut, msg.fileName);

    let additiveInfo = datatable.map((row) => ({
      "Chemical Additives": row.ChemicalAdditives,
      Count: row.Count,
      Type: row.Type,
      "Chemical Additives in Original Language":
        row.ChemicalAdditivesinOriginalLanguage,
    }));

    const additivesSheet = XLSX.utils.json_to_sheet(additiveInfo);
    const fontSize = {
      font: {
        name: "Calibri",
        sz: 11,
        color: { rgb: "000000" },
      },
    };

    const colWidthsExcel = [
      { wch: 18 },
      { wch: 26 },
      { wch: 46 },
      { wch: 20 },
      { wch: 12 },
      { wch: 19 },
      { wch: 22 },
      { wch: 22 },
      { wch: 22 },
      { wch: 12 },
      { wch: 12 },
    ];

    additivesSheet["!cols"] = colWidthsExcel;
    additivesSheet["!autofilter"] = { ref: "A1:C1" };

    const ref = XLSX.utils.decode_range(additivesSheet["!ref"]);
    for (let R = 0; R <= ref.e.r; ++R) {
      for (let C = 0; C <= ref.e.c; ++C) {
        const range = XLSX.utils.decode_range(additivesSheet["!ref"]);
        for (let i = range.s.r; i <= range.e.r; ++i) {
          const refColRef = XLSX.utils.encode_cell({ r: i, c: C });
          additivesSheet[refColRef].s = fontSize;
        }
      }
    }
    let sheetName = msg.fileName;
    if (sheetName.length > 31) {
      sheetName = sheetName.substring(0, 28) + "...";
    }
    XLSX.utils.book_append_sheet(workbook, additivesSheet, sheetName);
    // XLSX.utils.book_append_sheet(workbook, additivesSheet, msg.fileName);
  });

  let today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1;
  let yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  today = mm + "-" + dd + "-" + yyyy;

  XLSX.writeFile(workbook, "Research Chemical Additives_" + today + ".xlsx", {
    compression: true,
  });
};

export const ExporttToExcelRestAdditives = (additiveApiData) => {
  let allAdditives = [...new Set(additiveApiData.map((obj) => obj.Type))];

  const workbook = XLSX.utils.book_new();
  allAdditives.forEach((additive) => {
    let additiveInfo = additiveApiData
      .filter((s) => s.Type === additive)
      .map((row) => ({
        "Chemical Additives": row.ChemicalAdditives,
        "Occurrence in Percentage": row.OccurrenceInPercentage,
        "Chemical Additives in Original Language":
          row.ChemicalAdditivesinOriginalLanguage,
        "Patent Numbers": row.PatentNumbers,
      }));
    if (additiveInfo.length === 0) {
      additiveInfo.push({
        "Chemical Additives": "",
        "Occurrence in Percentage": "",
        "Chemical Additives in Original Language": "",
        "Patent Numbers": "",
      });
    }

    const additivesSheet = XLSX.utils.json_to_sheet(additiveInfo);
    const fontSize = {
      font: {
        name: "Calibri",
        sz: 11,
        color: { rgb: "000000" },
      },
    };

    const colWidthsExcel = [
      { wch: 18 },
      { wch: 26 },
      { wch: 46 },
      { wch: 20 },
      { wch: 12 },
      { wch: 19 },
      { wch: 22 },
      { wch: 22 },
      { wch: 22 },
      { wch: 12 },
      { wch: 12 },
    ];

    additivesSheet["!cols"] = colWidthsExcel;
    additivesSheet["!autofilter"] = { ref: "A1:C1" };

    const ref = XLSX.utils.decode_range(additivesSheet["!ref"]);
    for (let R = 0; R <= ref.e.r; ++R) {
      for (let C = 0; C <= ref.e.c; ++C) {
        const range = XLSX.utils.decode_range(additivesSheet["!ref"]);

        for (let i = range.s.r; i <= range.e.r; ++i) {
          const refColRef = XLSX.utils.encode_cell({ r: i, c: C });
          additivesSheet[refColRef].s = fontSize;
        }
      }
    }
    XLSX.utils.book_append_sheet(workbook, additivesSheet, additive);
  });
  let today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1;
  let yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  today = mm + "-" + dd + "-" + yyyy;

  XLSX.writeFile(workbook, "Research Chemical Additives_" + today + ".xlsx", {
    compression: true,
  });
};
