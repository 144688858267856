import axiosApiInstance from "../../../../api/axiosApiClient";
import { InternalAPIError } from "../../../../utils/apiErrors";
import { handleApiError } from "../../../../utils/globalErrorHandler";

const BASE_MKSSKILLSTORE_API_URL = process.env.REACT_APP_MKS_API_URL;

export const getUsersData = async () => {
    try {
      const response = await axiosApiInstance.get(
        `${BASE_MKSSKILLSTORE_API_URL}/GetUsers`
      );
  
      if (response.status === 200) {
        return response.data;
      } else {
        handleApiError(new InternalAPIError());
        
      }
    } catch (error) {
      //handleApiError(new InternalAPIError());
      const dummyUsers = [
        { id: 1, name: "John Doe", username: "johndoe", roleType: "Admin" },
        { id: 2, name: "Jane Smith", username: "janesmith", roleType: "User" },
        { id: 3, name: "Robert Brown", username: "robertb", roleType: "Editor" },
        { id: 4, name: "Emily Johnson", username: "emilyj", roleType: "User" },
        { id: 5, name: "Michael Wilson", username: "michaelw", roleType: "Admin" },
        { id: 6, name: "Sarah Davis", username: "sarahd", roleType: "Editor" },
        { id: 7, name: "David Miller", username: "davidm", roleType: "User" },
        { id: 8, name: "Emma Garcia", username: "emmag", roleType: "Admin" },
        { id: 9, name: "James Martinez", username: "jamesm", roleType: "Editor" },
        { id: 10, name: "Olivia Hernandez", username: "oliviah", roleType: "User" },
      ];
      return dummyUsers;
    }
  };