import React, { useEffect, useRef, useState } from "react";
import SkillApplicability from "../skillApplicability";
import SkillTagsDetail from "../skillTagsDetail";
import SkillContactDetails from "../skillContactDetails";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import BOTConfiguration from "../botConfiguration";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

const SkillUpdateHeaderDetails = (props) => {
  const { skillStoreForm, setskillStoreForm, handleUpdateSubmit } = props;
  const [isThirdPartyAITool, setIsThirdPartyAITool] = useState(false);
  const [initialFormState, setInitialFormState] = useState();
  const isInitialized = useRef(false);
  const [validationFieldName, setValidationFieldName] = useState("");
  const nameRef = useRef(null);
  const chatbotURLRef = useRef(null);
  const accuracyRef = useRef(null);
  const executionRef = useRef(null);
  const publishedonRef = useRef(null);

  useEffect(() => {
    // setShowBotConfiguration(skillStoreForm?.IsThirdPartyAITool);
    setIsThirdPartyAITool(skillStoreForm?.IsThirdPartyAITool);
  }, [skillStoreForm]);

  useEffect(() => {
    if (!isInitialized.current && skillStoreForm && skillStoreForm.Name) {
      setInitialFormState(skillStoreForm);
      isInitialized.current = true;
    }
  }, [skillStoreForm]);

  const handleSetGuideline = () => {
    let thirdPartyAITool = !isThirdPartyAITool;
    updateSkillStoreForm("IsThirdPartyAITool", thirdPartyAITool);

    if (thirdPartyAITool) {
      updateMultipleFields({
        IsChatbotTestable: null,
        ShowCitation: null,
        ShowFollowUpQuestions: null,
        InputType: null,
        OutputType: null,
        LastMessagesCount: null,
        ChatGPTVersion: null,
        CurlRequestString: null,
        UserAssistantMessageFormat: null,
        FileType: null,
        ChatbotUIView: null,
        DynamicProperties: [],
      });
    } else {
      updateMultipleFields({
        InputType: "",
        OutputType: "",
        LastMessagesCount: "",
        ChatGPTVersion: "",
        IsChatbotTestable: false,
        ShowCitation: false,
        ShowFollowUpQuestions: false,
        CurlRequestString: "",
        UserAssistantMessageFormat: "",
        FileType: "",
        ChatbotUIView: "",
        DynamicProperties: [],
      });
      updateSkillStoreForm("ChatBotURL","");
    }

    setIsThirdPartyAITool(!isThirdPartyAITool);
  };

  const updateMultipleFields = (updates) => {
    setskillStoreForm((prevState) => ({
      ...prevState,
      ...updates,
    }));
  };

  const handleExecutionChange = (e) => {
    const newValue = e.target.value;
    // Allow only numbers (optional: allow decimals)
    if (/^\d*\.?\d*$/.test(newValue)) {
      const numericValue = newValue === "" ? "" : parseFloat(newValue);
      if (numericValue !== "" && numericValue >= 0 && numericValue <= 1000) {
        executionRef.current.classList.remove("border-red");
        updateSkillStoreForm("AvgExecutionTime", parseInt(newValue));
      } else if (newValue?.trim() === "") {
        updateSkillStoreForm("AvgExecutionTime", "");
      }
    }
  };

  const handleAccuracyChange = (e) => {
    const newValue = e.target.value;
    // Allow only numbers (optional: allow decimals)
    if (/^\d*\.?\d*$/.test(newValue)) {
      const numericValue = newValue === "" ? "" : parseFloat(newValue);
      if (numericValue !== "" && numericValue >= 0 && numericValue <= 100) {
        accuracyRef.current.classList.remove("border-red");
        updateSkillStoreForm("Acurracy", parseInt(newValue));
      } else if (newValue?.trim() === "") {
        updateSkillStoreForm("Acurracy", "");
      }
    }
  };

  const isValidURL = (url) => {
    try {
      new URL(url);
      return true;
    } catch (error) {
      return false;
    }
  };

  const validateFields = () => {
    setValidationFieldName("");
    if (skillStoreForm.Name === "" || skillStoreForm?.Name?.trim() === "") {
      nameRef.current.classList.add("border-red");
      nameRef.current.focus();
      toast.error("Skill Name is required");
      return false;
    }

    if (
      skillStoreForm.DepartmentNameId === 0 ||
      skillStoreForm.DepartmentNameId === "-1"
    ) {
      setValidationFieldName("Department");
      toast.error("Department is required");
      return false;
    }

    if (skillStoreForm.functions?.length === 0) {
      setValidationFieldName("Function");
      toast.error("Function is required");
      return false;
    }
    if (skillStoreForm.subFunctions?.length === 0) {
      setValidationFieldName("SubFunction");
      toast.error("Sub Function is required");
      return false;
    }
    if (
      skillStoreForm.LookupSkillScopeId === 0 ||
      skillStoreForm.LookupSkillScopeId === "-1"
    ) {
      setValidationFieldName("Scope");
      toast.error("Scope is required");
      return false;
    }

    if (skillStoreForm.IsThirdPartyAITool) {
      if (
        skillStoreForm.ChatBotURL === "" ||
        skillStoreForm?.ChatBotURL?.trim() === ""
      ) {
        chatbotURLRef.current.classList.add("border-red");
        chatbotURLRef.current.focus();
        return false;
      } else if (!isValidURL(skillStoreForm.ChatBotURL)) {
        chatbotURLRef.current.classList.add("border-red");
        toast.error("Chatbot URL entered is invalid");
        chatbotURLRef.current.focus();
        return false;
      }
    }
    if (skillStoreForm.Acurracy === "") {
      accuracyRef.current.classList.add("border-red");
      accuracyRef.current.focus();
      toast.error("Accuracy is required");
      return false;
    }
    if (skillStoreForm.AvgExecutionTime === "") {
      executionRef.current.classList.add("border-red");
      executionRef.current.focus();
      toast.error("Avg. Execution Time is required");
      return false;
    }
    if (skillStoreForm.PublishOn === "") {
      publishedonRef.current.input.classList.add("border-red");
      publishedonRef.current.input.focus();
      toast.error("Publish On Date is required");
      return false;
    }

    if (skillStoreForm?.tags?.length === 0) {
      setValidationFieldName("Tags");
      toast.error("Tags are required");
      return false;
    }

    if (skillStoreForm?.Owner?.length === 0) {
      setValidationFieldName("Owner");
      toast.error("Owner is required");
      return false;
    }
    if (skillStoreForm?.Contact?.length === 0) {
      setValidationFieldName("Contact");
      toast.error("Contact is required");
      return false;
    }

    return true;
  };

  const updateSkillStoreForm = (field, value) => {
    setskillStoreForm((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleUpdateSkill = () => {
    if (validateFields()) {
      handleUpdateSubmit();
    }
  };

  const formatDate = (date) => {
    if (!date) return null;
    if (typeof date === "string") {
      const parsedDate = parseDateString(date);
      if (!parsedDate) return null;
      date = parsedDate;
    }

    if (!(date instanceof Date) || isNaN(date)) {
      console.error("Invalid date:", date);
      return null;
    }

    date.setHours(0, 0, 0, 0);
    return date;
  };

  const parseDateString = (dateString) => {
    const regex = /^(\d{2})\/(\d{2})\/(\d{4}) (\d{2}):(\d{2}):(\d{2}) (AM|PM)$/;
    const match = dateString.match(regex);

    if (!match) {
      console.error("Invalid date format:", dateString);
      return null;
    }

    let [, day, month, year, hours, minutes, seconds, period] = match;
    day = parseInt(day, 10);
    month = parseInt(month, 10) - 1;
    year = parseInt(year, 10);
    hours = parseInt(hours, 10);
    minutes = parseInt(minutes, 10);
    seconds = parseInt(seconds, 10);

    if (period === "PM" && hours < 12) hours += 12;
    if (period === "AM" && hours === 12) hours = 0;

    return new Date(year, month, day, hours, minutes, seconds);
  };

  const isFormChanged = () => {
    return JSON.stringify(skillStoreForm) !== JSON.stringify(initialFormState);
  };

  return (
    <>
      <div className="">
        <div className="skill-detail-wrapper">
          <div className="skill-detail-left create-skill-left">
            <div className="prompt-img-title">
              <div className="prompt-mobile-wrap">
                <div className="prompt-title-wrap">
                  <h3 className="prompt-title">
                    Name <span className="red">*</span>{" "}
                  </h3>
                  <input
                    maxLength={255}
                    type="textbox"
                    className="prompt-text"
                    value={skillStoreForm?.Name}
                    onInput={(e) => {
                      updateSkillStoreForm("Name", e.target.value);
                      nameRef.current.classList.remove("border-red");
                    }}
                    ref={nameRef}
                  />
                </div>
              </div>
            </div>
            <div className="description-wrap">
              <div className="prompt-desc-wrap w-full">
                <h3 className="prompt-title">Short Description</h3>
                <textarea
                  className="prompt-textarea prompt-pii"
                  value={skillStoreForm?.ShortDescription}
                  onInput={(e) =>
                    updateSkillStoreForm("ShortDescription", e.target.value)
                  }
                  maxLength={2000}
                ></textarea>
              </div>
              <div className="prompt-desc-wrap w-full">
                <h3 className="prompt-title">Description</h3>
                <textarea
                  className="prompt-textarea prompt-pii"
                  value={skillStoreForm?.Description}
                  onInput={(e) =>
                    updateSkillStoreForm("Description", e.target.value)
                  }
                  maxLength={2000}
                ></textarea>
              </div>
            </div>
            <div className="skill-update-box">
              <div className="prompt-title-wrap">
                <h3 className="prompt-title">
                  Accuracy <span className="red">*</span>{" "}
                </h3>
                <input
                  type="textbox"
                  className="prompt-text"
                  onChange={(e) => handleAccuracyChange(e)}
                  value={
                    skillStoreForm?.Acurracy === 0
                      ? ""
                      : skillStoreForm.Acurracy
                  }
                  ref={accuracyRef}
                />
              </div>
              <div className="prompt-title-wrap">
                <h3 className="prompt-title">
                  Avg. Execution Time <span className="red">*</span>{" "}
                </h3>
                <input
                  type="textbox"
                  className="prompt-text"
                  onChange={(e) => handleExecutionChange(e)}
                  value={
                    skillStoreForm?.AvgExecutionTime === 0
                      ? ""
                      : skillStoreForm.AvgExecutionTime
                  }
                  ref={executionRef}
                />
              </div>
              <div className="prompt-title-wrap publish-date-wrap">
                <h3 className="prompt-title">
                  Published On <span className="red">*</span>{" "}
                </h3>
                <DatePicker
                  className="input-date-text prompt-text"
                  onFocus={(e) => e.target.blur()} // Prevent manual input but allow date selection
                  minDate={new Date()}
                  dateFormat="dd/MM/yyyy"
                  selected={formatDate(skillStoreForm?.PublishOn)}
                  onChange={(date) => {
                    updateSkillStoreForm("PublishOn", formatDate(date));
                    publishedonRef.current.input.classList.remove("border-red");
                  }}
                  placeholderText="Select Date"
                  ref={publishedonRef}
                />
              </div>
            </div>
            <div className="skill-one-row">
              <div className="check-ackn">
                <label className="check-contain">
                  <input
                    type="checkbox"
                    checked={skillStoreForm?.IsThirdPartyAITool}
                    className="p-checkbox"
                    onChange={() => {
                      handleSetGuideline();
                    }}
                  />
                  <span className="checkmark-check"></span>
                </label>
                <p>Third Party AI Tool</p>
              </div>
              {isThirdPartyAITool && (
                <div className="prompt-title-wrap">
                  {/* <h3 className="prompt-title">
                        Chatbot URL <span className="red">*</span>{" "}
                      </h3> */}
                  <input
                    maxLength={256}
                    type="textbox"
                    placeholder="Chatbot URL"
                    className="prompt-text"
                    value={skillStoreForm?.ChatBotURL}
                    onInput={(e) => {
                      updateSkillStoreForm("ChatBotURL", e.target.value);
                      chatbotURLRef.current.classList.remove("border-red");
                    }}
                    ref={chatbotURLRef}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="skill-detaill-right autowidth">
            <SkillApplicability
              skillStoreForm={skillStoreForm}
              updateSkillStoreForm={updateSkillStoreForm}
              validationFieldName={validationFieldName}
              setValidationFieldName={setValidationFieldName}
            ></SkillApplicability>
          </div>
        </div>
        <div className="skill-fullwidth">
          <div className="skill-create-tags">
            <SkillTagsDetail
              skillStoreForm={skillStoreForm}
              updateSkillStoreForm={updateSkillStoreForm}
              validationFieldName={validationFieldName}
              setValidationFieldName={setValidationFieldName}
            ></SkillTagsDetail>
          </div>

          <div className="create-skill-window skill-update-box">
            <SkillContactDetails
              skillStoreForm={skillStoreForm}
              updateSkillStoreForm={updateSkillStoreForm}
              validationFieldName={validationFieldName}
            ></SkillContactDetails>
          </div>
        </div>
        <div className="skill-fullwidth ">
          {isThirdPartyAITool && (
            <div className="prompt-button-wrapper">
              <div>
                {" "}
                <Link
                  className="cancel-btn"
                  to={isThirdPartyAITool ? "/thirdpartyaitools" : "/skillstore"}
                >
                  Cancel
                </Link>
              </div>
              <button
                className="btn-save-bot genrate-btn"
                onClick={() => handleUpdateSkill()}
                disabled={!isFormChanged()}
              >
                Update
              </button>
            </div>
          )}
        </div>
      </div>

      {!isThirdPartyAITool && (
        <BOTConfiguration
          skillStoreForm={skillStoreForm}
          updateSkillStoreForm={updateSkillStoreForm}
          handleUpdateSkill={handleUpdateSubmit}
          validateFields={validateFields}
        ></BOTConfiguration>
      )}
    </>
  );
};

export default SkillUpdateHeaderDetails;
