import HomeIcon from "../../styles/images/mksnavicon/home.svg";
import ChatIcon from "../../styles/images/mksnavicon/chat.svg";
import HelpIcon from "../../styles/images/mksnavicon/help.svg";
import SettingsIcon from "../../styles/images/mksnavicon/settings.svg";
import MyworkSpaceIcon from "../../styles/images/mksnavicon/myworkspace.svg";
import SkillStoreIcon from "../../styles/images/skillnav.png";
import PromptManagementICon from "../../styles/images/mksnavicon/pormptmanagement.svg";
import NotificationHubIcon from "../../styles/images/mksnavicon/notificationhub.svg";
import AboutMKSIcon from "../../styles/images/mksnavicon/aboutmks.svg";
import GuidanceAndGuidelineIcon from "../../styles/images/mksnavicon/guidanceandguideline.svg";
import FeebackIcon from "../../styles/images/mksnavicon/feedback.svg";
import Logouticon from "../../styles/images/logout.svg";
import draggableActiveIcon from "../../styles/images/mksmyworkspace/draggable-icon-active.svg";
import draggableInactiveIcon from "../../styles/images/mksmyworkspace/draggable-icon-inactive.svg";
import listicon from "../../styles/images/list.svg";
import chatAIIcon from "../../styles/images/mkschat/chat-ai-icon.svg";
import profilePic from "../../styles/images/mkschat/profile-pic-11.jpg";
import sendicon from "../../styles/images/send-icon.svg";
import CommentIcon from "../../styles/images/mkspropmanagment/comments-icon.svg";
import CommentIconDark from "../../styles/images/mkspropmanagment/comments-icon-dark.svg";
import ImageCloudUpload from "../../styles/images/mkspropmanagment/cloud-upload.svg";
import ChatAIImage from "../../styles/images/skillset.png";
import CheckGreenImage from "../../styles/images/mksapp/check-green-icon.svg";
import ThumbLikeIconDark from "../../styles/images/mkspropmanagment/thumbs-like-icon-dark.svg";
import ThumbLikeIcon from "../../styles/images/mkspropmanagment/thumbs-like-icon.svg";
import calendericon from "../../styles/images/mksapp/edit_calendar.svg";
import publishIcon from "../../styles/images/mksapp/publish.svg";
import RecentUpdate from "../../styles/images/mksapp/recenetupdate.svg";
import person from "../../styles/images/mksapp/person.svg";
import usermgmt from "../../styles/images/user-m.svg";
import graphicimg from "../../styles/images/graphic-d.svg";
import adminseting from "../../styles/images/admin-setting.svg";
import onbaording from "../../styles/images/handshake.svg";
import skilladmin from "../../styles/images/manage_accounts.svg";
import shareIcon from "../../styles/images/mkspropmanagment/shareicon.svg";
import chatLoader from "../../styles/images/common/chat-loader.gif";
import ThirdPartyAIToolsIcon from "../../styles/images/third-party.svg";

const AppImages = {
  onbaording,
  skilladmin,
  usermgmt,
  graphicimg,
  HomeIcon,
  ChatIcon,
  HelpIcon,
  SettingsIcon,
  MyworkSpaceIcon,
  SkillStoreIcon,
  PromptManagementICon,
  ThirdPartyAIToolsIcon,
  NotificationHubIcon,
  AboutMKSIcon,
  GuidanceAndGuidelineIcon,
  FeebackIcon,
  Logouticon,
  draggableActiveIcon,
  draggableInactiveIcon,
  listicon,
  chatAIIcon,
  profilePic,
  sendicon,
  CommentIcon,
  CommentIconDark,
  ImageCloudUpload,
  ChatAIImage,
  CheckGreenImage,
  ThumbLikeIconDark,
  ThumbLikeIcon,
  calendericon,
  publishIcon,
  RecentUpdate,
  person,
  shareIcon,
  chatLoader,
  adminseting,
};

export default AppImages;
