import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import AppImages from "../../../../../../constants/images";
import showMessage from "../../../../../common/message";
import {
  setLikeByUserSelection,
  addComments,
  getPromptData,
  setFavouriteByUserSelection,
  sharePromptWithUsers,
} from "../../../services/promptManagementService";
import ShareUserList from "../shareUserList";
import CommentModal from "../commentModal";
import { ExtractCurlParameters } from "../../../../promptManagement/shared/curlConverter";
import {
  capitalizeFirstLetter,
  hasGroupAccess,
} from "../../../../../../services/commonService";
import { UserContext } from "../../../../../../context/user/userContext";

const PromptCompleteSteps = (props) => {
  const { pageHeaderValues, isReadonlyAccess } = props;
  const { state } = useContext(UserContext);
  const securityGroups = state?.user?.userProfile?.msalSecurityGroup;
  const [like, setLike] = useState();
  let [likeCount, setLikeCount] = useState(0);
  const [commentsCount, setCommentsCount] = useState(0);
  const [showAddComment, setShowAddComment] = useState(false);
  const [comments, setComments] = useState([]);
  const [comment, setComment] = useState("");
  const [isStared, setIsStared] = useState();
  const [viewCount, setViewCount] = useState(0);
  const [isShowShareUserPopUp, setIsShowShareUserPopUp] = useState(false);
  const [isCommented, setIsCommented] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (pageHeaderValues !== undefined) {
      if (pageHeaderValues?.Id !== undefined) {
        setIsStared(pageHeaderValues?.isStared);
        getRefreshedData(pageHeaderValues?.Id);
      }
    }
  }, [pageHeaderValues]);

  const getRefreshedData = async (id) => {
    const promptData = await getPromptData(id);
    if (
      promptData?.comments !== null &&
      promptData?.comments !== undefined &&
      promptData?.comments?.length > 0
    ) {
      setComments(promptData?.comments);
    }
    setCommentsCount(promptData?.CommentCount);
    setLikeCount(promptData?.LikeCount);
    setLike(promptData?.IsLiked);
    setViewCount(promptData?.ViewCount);
    setIsCommented(promptData?.IsCommented);
  };

  const handleFavouriteClick = (val) => {
    const data = {
      PromptUserInputId: pageHeaderValues?.Id,
      Status: !val,
    };

    setFavouriteByUserSelection(data);
    setIsStared(!val);
    getRefreshedData(pageHeaderValues?.Id);
  };

  // Add Comment Section Start Here
  const handleAddComment = () => {
    setShowAddComment(true);
  };

  const handleLikeClick = (val) => {
    const data = {
      PromptUserInputId: pageHeaderValues?.Id,
      Status: !val,
    };
    setLike(!val);
    setLikeByUserSelection(data);
    likeCount = val ? likeCount - 1 : likeCount + 1;
    setLikeCount(likeCount);
  };

  const handleCancelClick = () => {
    setShowAddComment(false);
  };

  const handleSaveComment = () => {
    const data = {
      PromptUserInputId: pageHeaderValues?.Id,
      Comment: comment === "" || comment === undefined ? "" : comment,
    };

    if (data.Comment !== "" && data.Comment !== undefined) {
      addComments(data).then((resp) => {
        getRefreshedData(pageHeaderValues?.Id);
      });
    }
  };

  const handelOnChange = (val) => {
    setComment(val);
  };

  const handleSaveChildComment = (id, comment) => {
    const data = {
      ParentCommentId: id,
      PromptUserInputId: pageHeaderValues?.Id,
      Comment: comment === "" || comment === undefined ? "" : comment,
    };

    if (data.Comment !== "" && data.Comment !== undefined) {
      addComments(data).then((resp) => {
        getRefreshedData(pageHeaderValues?.Id);
      });
    }
  };

  // Helper function to nest comments based on ParentCommentId
  const nestComments = (comments) => {
    const commentMap = {};
    if (comments === undefined) comments = [];
    // Map each comment by its ID
    comments.forEach((comment) => {
      commentMap[comment.Id] = { ...comment, replies: [] };
    });

    const nestedComments = [];

    // Arrange the comments as parent and children
    comments.forEach((comment) => {
      if (comment.ParentCommentId === null) {
        nestedComments.push(commentMap[comment.Id]);
      } else {
        commentMap[comment.ParentCommentId]?.replies.push(
          commentMap[comment.Id]
        );
      }
    });

    return nestedComments.sort(
      (a, b) => new Date(b.CommentDate) - new Date(a.CommentDate)
    );
  };

  const handleShareIconClick = () => {
    setIsShowShareUserPopUp(true);
  };

  const handelShareUserListPopUp = () => {
    setIsShowShareUserPopUp(false);
  };

  const onSaveSharedUser = async (ids) => {
    setIsShowShareUserPopUp(false);
    const res = await sharePromptWithUsers(pageHeaderValues?.Id, ids);
    if (res !== null && res !== undefined) {
      showMessage(res?.Status, 1);
    }
  };
  const formatDate = (datetimeString) => {
    if (datetimeString !== undefined) {
      // Replace "PM" with an empty string to avoid conflicts
      const sanitizedString = datetimeString.replace(" PM", "");
      const date = new Date(sanitizedString);

      if (isNaN(date)) {
        return ""; // Handle invalid dates
      }

      return date.toLocaleDateString("en-GB", {
        day: "numeric",
        month: "long",
        year: "numeric",
      });
    } else {
      return "";
    }
  };

  const handleSkillChatButtonClick = (prompt) => {
    const access = hasGroupAccess(securityGroups, prompt.SecurityGroupId);
    if (!access) {
      showMessage(
        "You don't have access to " + prompt.SkillStoreName + " bot.",
        -2
      );
      return;
    }
    navigate("/chat", {
      state: {
        isMKSChat: prompt?.IsImportedPrompt ? true : false,
        chatMessage: prompt?.IsImportedPrompt
          ? prompt?.PromptSystemGeneratedInputs?.length > 0
            ? prompt?.PromptSystemGeneratedInputs[0].PromptText
            : prompt?.PromptSystemGeneratedInputs?.PromptText
          : prompt?.SkillStoreName,
        skill: getSkillData(prompt),
        navigateFromHome: false,
        isAdditivesFound: getIsAdditives(prompt),
        isFileType: getIsFile(prompt),
        promptText:
          prompt?.PromptSystemGeneratedInputs?.length > 0
            ? prompt?.PromptSystemGeneratedInputs[0].PromptText
            : prompt?.PromptSystemGeneratedInputs?.PromptText,
      },
    });
  };

  const getSkillData = (prompt) => {
    const params = ExtractCurlParameters(
      prompt?.ChatbotConfiguration?.CurlRequestString
    );

    const skill = {
      Id: prompt.SkillStoreId,
      Name: prompt.SkillStoreName,
      ChatbotConfiguration: prompt.ChatbotConfiguration,
      aiUrl: params.url,
      headers: params.headers,
      aiFlag: "",
      apiKey: "",
      messageBody: params.body,
      ShortDescription: prompt.SkillStoreShortDescription,
      userMessage: prompt?.ChatbotConfiguration?.UserMessageFormat,
      AssistantMessageFormat: prompt?.ChatbotConfiguration?.AssistantMessageFormat,
    };
    return skill;
  };

  const getIsAdditives = (prompt) => {
    if (
      prompt?.ChatbotConfiguration?.ChatbotUIView?.toLowerCase() === "additives"
    )
      return true;
    else return false;
  };

  const getIsFile = (prompt) => {
    if (prompt?.ChatbotConfiguration?.InputType?.toLowerCase() === "file")
      return true;
    else return false;
  };
  return (
    <>
      <div className="prompt-steps">
        <div className="prompt-step">
          <div className="prompt-step-name">Write a Prompt</div>
          <div className="prompt-step-desc">
            Explain your use case and set your preference
          </div>
          <div className="p-dot-wrapper p-done">
            <div className="p-dot"></div>
          </div>
        </div>
        <div className="prompt-step">
          <div className="prompt-step-name">Prompt Review</div>
          <div className="prompt-step-desc">Review generated prompt</div>
          <div className="p-dot-wrapper p-done">
            <div className="p-dot"></div>
          </div>
        </div>
        <div className="prompt-step">
          <div className="prompt-step-name">Test Prompt</div>
          <div className="prompt-step-desc">Review prompt response</div>
          <div className="p-dot-wrapper p-done">
            <div className="p-dot"></div>
          </div>
        </div>
        <div className="prompt-step">
          <div className="prompt-step-name">Finalize prompt</div>
          <div className="prompt-step-desc">Finalize and save the prompt</div>
          <div className="p-dot-wrapper p-done">
            <div className="p-dot"></div>
          </div>
        </div>
      </div>

      <div className="edit-prompt-header">
        <div className="edit-prompt-info">
          <div className="flex-row">
            <div className="edit-col">
              <div className="flex gap-1">
                <div
                  className={
                    pageHeaderValues?.isPublic ? "public-icon" : "private-icon"
                  }
                ></div>{" "}
                {pageHeaderValues?.isPublic ? "Public" : "Private"}
              </div>
              <div className="verticleline">|</div>
            </div>
            <div className="edit-col">
              <div className="flex gap-1">
                <span className="semibold-text">Owner:</span>{" "}
                {capitalizeFirstLetter(
                  pageHeaderValues?.CreatedByFirstName +
                    " " +
                    pageHeaderValues?.CreatedByLastName
                )}
                <div className="verticleline">|</div>
              </div>
            </div>
            <div className="edit-col">
              <div>
                <span className="semibold-text">Created Date:</span>{" "}
                {formatDate(pageHeaderValues?.createdDate)}
              </div>
              <div className="verticleline">|</div>
            </div>

            <div className="edit-col">
              <div>
                <span className="semibold-text">Last Updated:</span>{" "}
                {formatDate(pageHeaderValues?.lastUpdated)}
              </div>
            </div>
            <div className="edit-col">
              <div className="prompt-badge">
                {pageHeaderValues?.isImportedPrompt ? "Imported" : "Generated"}
              </div>
            </div>
          </div>
          <div className="button-use-promp">
            <div
              onClick={() =>
                handleSkillChatButtonClick(pageHeaderValues?.prompt)
              }
              className="button light w-button text-center"
            >
              Use Prompt
            </div>
          </div>
        </div>

        <div className="edit-prompt-buttons">
          <div className="action-btn-wrapper">
            <div className="edit-common-icons">
              <div
                onClick={() => handleLikeClick(like)}
                className="prompt-action-link w-inline-block"
              >
                <img
                  src={
                    like ? AppImages.ThumbLikeIconDark : AppImages.ThumbLikeIcon
                  }
                  alt=""
                  className="prompt-action-icon"
                />
              </div>
              <div className="prompt-action-count">{likeCount}</div>
            </div>
            <div className="edit-common-icons no-pointer">
              <div className="view-active-background no-pointer">
                <div className="views-icon no-pointer"></div>
              </div>
              <div>{viewCount}</div>
            </div>
            {!pageHeaderValues?.isPublic && (
              <div className="edit-common-icons">
                <div className="flexible-icon">
                  <img
                    src={AppImages.shareIcon}
                    alt=""
                    height="15px"
                    onClick={handleShareIconClick}
                  />
                </div>
              </div>
            )}
            {/* {!isReadonlyAccess && (
              <div
                className={
                  isReadonlyAccess
                    ? "edit-common-icons disabled-element"
                    : "edit-common-icons"
                }
              >
                {!pageHeaderValues?.IsPublic && (
                  <div className="flexible-icon">
                    <img
                      src={AppImages.shareIcon}
                      alt=""
                      height="15px"
                      onClick={handleShareIconClick}
                    />
                  </div>
                )}
              </div>
            )} */}
            <div className="edit-common-icons">
              <div
                key=""
                onClick={() => handleAddComment()}
                className="prompt-action-link w-inline-block"
              >
                {isCommented ? (
                  <img
                    src={AppImages.CommentIconDark}
                    alt=""
                    className="prompt-action-icon"
                  />
                ) : (
                  <img
                    src={AppImages.CommentIcon}
                    alt=""
                    className="prompt-action-icon"
                  />
                )}
                <div className="prompt-action-count">{commentsCount}</div>
              </div>
            </div>
            <div className="star-end">
              <div
                onClick={() => handleFavouriteClick(isStared)}
                className={`${
                  isStared ? "favourite-button-active" : "favourite-button"
                } w-inline-block`}
              ></div>
            </div>
          </div>
        </div>
      </div>
      <CommentModal
        isOpen={showAddComment}
        onClose={handleCancelClick}
        onSave={handleSaveComment}
        commentData={nestComments(comments)}
        onChange={handelOnChange}
        onSaveChildComment={handleSaveChildComment}
      />
      {isShowShareUserPopUp && (
        <ShareUserList
          handelAppPopUp={handelShareUserListPopUp}
          onSaveSharedUser={onSaveSharedUser}
          prompt={pageHeaderValues?.prompt}
        />
      )}
    </>
  );
};

export default PromptCompleteSteps;
