import React, { useContext } from "react";
import { hasGroupAccess } from "../../../../../../../services/commonService";
import { UserContext } from "../../../../../../../context/user/userContext";
import showMessage from "../../../../../../common/message";

const PromptTableRows = (props) => {
  const {
    item,
    handleSetSelectedPrompt,
    setName,
    setShowPromptManagement,
    isMKSChat,
  } = props;
  const { state } = useContext(UserContext);
  const securityGroups = state?.user?.userProfile?.msalSecurityGroup;
  const handleSelectedPrompt = (item) => {
    if (!isMKSChat) {
      const access = hasGroupAccess(securityGroups, item.SecurityGroupId);
      if (!access) {
        showMessage(
          "You don't have access to " + item.SkillStoreName + " bot.",
          -2
        );
      } else {
        handleSetSelectedPrompt(item);
        if (item?.PromptSystemGeneratedInputs[0]?.PromptText !== undefined)
          setName(item?.PromptSystemGeneratedInputs[0]?.PromptText);
        else setName("");
        setShowPromptManagement(false);
      }
    } else {
      handleSetSelectedPrompt(item);
      setName(item?.PromptSystemGeneratedInputs[0]?.PromptText);
      setShowPromptManagement(false);
    }
  };
  return (
    <>
      <div
        className="prompt-grid-row"
        onClick={() => handleSelectedPrompt(item)}
      >
        <div className="prompt-head">
          <span>{item?.Title}</span>
        </div>
        <div className="view-prompt-col">
          <div className="views-icon"></div>
          <span>{item?.ViewCount}</span>
        </div>
        <div className="thumbs-up-col">
          <div className="thumbs-up-icon"></div>
          <span>{item?.LikeCount}</span>
        </div>
        <div className="thumbs-up-col">
          <div className="comment-icon"></div>
          <span>{item?.CommentCount}</span>
        </div>
      </div>
    </>
  );
};
export default PromptTableRows;
