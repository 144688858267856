import axiosApiInstance from '../../../../api/axiosApiClient';
const BASE_AUTH_API_URL=process.env.REACT_APP_AUTHAPI_URL;

export const getWorkspaceCardsData=async()=>{
  const response = await axiosApiInstance.get(
      `${BASE_AUTH_API_URL}account/GetUserDashboardCards`
    );
    if (response.status === 200) {
      return response.data;
    }
}

export const changeWorkspaceCardOrderData = async (body) => {
  let url = `${BASE_AUTH_API_URL}usermanagement/ChangeDashboardCardOrder`;
  const response = await axiosApiInstance.patch(url, body);
  return await response.data;
};

export const enableDashboardCard = async (body) => {
  let url = `${BASE_AUTH_API_URL}usermanagement/EnableDashboardCard`;
  const response = await axiosApiInstance.patch(url, body);
  return await response.data;
};