import React, { useState, useRef, useEffect, useContext } from "react";
import { useLocation, Link } from "react-router-dom";
import { useUserName } from "../customHooks/useUserName/index.js";
import WelcomeSection from "../welcomeSection";
import { getScreenName } from "../../../services/commonService.js";
import { Outlet } from 'react-router-dom';
import UserImage from "../userImage/index.js";
import { UserContext } from "../../../context/user/userContext.js";

const DashboardSetting = () => {
  const { state } = useContext(UserContext);
  const imagebytes = state?.user?.userProfile?.userImage;
  const imagetype = state?.user?.userProfile?.userImagemimeType;
  const [showMenu, setShowMenu] = useState(false);
  const location = useLocation();
  const { fullName, initials } = useUserName();
  const menuRef = useRef(null);
  const toggleButtonRef = useRef(null);

  const handleMenuToggle = () => {
    setShowMenu((prevShowMenu) => !prevShowMenu);
  };

  useEffect(() => {
    const handleClickOutside = (e) => {
      // Check if the click is outside both the menu and toggle button
      if (
        showMenu && 
        menuRef.current && 
        !menuRef.current.contains(e.target) &&
        toggleButtonRef.current &&
        !toggleButtonRef.current.contains(e.target)
      ) {
        setShowMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showMenu]);

  

  return (
    <>
      <div className="dash-header">
      
        <WelcomeSection screenName={getScreenName(location.pathname)}></WelcomeSection>
        <div className="left-user-settings hide-on-mobile">
          <div className="user-profile-menu w-inline-block">
            {/* Reference the toggle button */}
            <div ref={toggleButtonRef} className="user-profile-initial" onClick={handleMenuToggle}>
              <UserImage userImageBytes={imagebytes} userImageMimeType={imagetype} userInitials={initials}></UserImage>
            </div>
          </div>
          {/* Reference the menu popup */}
          <div ref={menuRef}>
            {showMenu && (
              <>
                <div className="popup-arrow"></div>
                <div className="popup-menu">
                  <div className="full-name-row">
                    <div className="full-name-text">{fullName}</div>
                  </div>
                  <div className="popup-row">
                    <div className="logout-icon"></div>
                    <Link to="/logout">Logout</Link>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardSetting;
