import React, { useEffect, useRef, useState } from "react";
import {
  getTagsData,
  getSkillDepartmentData,
  getSkillFunctionData,
  getSkillSubFunctionData,
} from "../../../services/promptManagementService";
import SelectByDepartment from "../selectByDepartment";
import SelectByFunction from "../selectByFunction";
import SelectBySubFunction from "../selectBySubFunction";

const UpdatePromptStepThree = (props) => {
  const {
    selectedTags,
    selectedOptions,
    handleSelectTags,
    handleRemoveTags,
    handleTagAdd,
    handleOptionsChange,
    updatePromptData,
    validationField,
    isReadonlyAccess,
    isImportedPrompt,
  } = props;

  const [tags, setTags] = useState([]);
  const [inputVal, setInputVal] = useState("");
  const [tagsData, setTagsData] = useState([]);
  const [filteredTags, setFilteredTags] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [departmentData, setDepartmentData] = useState([]);
  const [selectDeparment, setSelectDepartment] = useState();
  const [functionData, setFunctionData] = useState([]);
  const [selectFunction, setSelectFunction] = useState();
  const [subFunctionData, setSubFunctionData] = useState([]);
  const [selectSubFunction, setSubSelectFunction] = useState();
  const promptTextInputref = useRef("");
  const divDepartmentRef = useRef(null);
  const divFunctionRef = useRef(null);
  const divSubFunctionRef = useRef(null);

  useEffect(() => {
    const getDataOfTags = async () => {
      const tagsData = await getTagsData();
      const namesOnly = tagsData?.map((tag) => tag.Name);
      setTagsData(namesOnly);
    };

    const getDataOfSkillDep = async () => {
      const departmentData = await getSkillDepartmentData();
      setDepartmentData(departmentData);
    };

    getDataOfTags();
    getDataOfSkillDep();
  }, [selectedTags]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (filteredTags?.length > 0) {
        if (e.key === "ArrowDown") {
          e.preventDefault();
          setSelectedIndex(
            (prevIndex) => (prevIndex + 1) % filteredTags.length
          );
        } else if (e.key === "ArrowUp") {
          e.preventDefault();
          setSelectedIndex(
            (prevIndex) =>
              (prevIndex - 1 + filteredTags.length) % filteredTags.length
          );
        } else if (e.key === "Enter" && selectedIndex >= 0) {
          e.preventDefault();
          const selectedTag = filteredTags[selectedIndex];
          handleTagSelect(selectedTag);
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [selectedIndex, filteredTags]);

  useEffect(() => {
    setData();
  }, [departmentData, selectedOptions]);

  useEffect(() => {
    if (
      validationField !== null &&
      validationField !== undefined &&
      validationField !== ""
    ) {
      setPromptValidation();
    }
  }, [validationField]);

  const setPromptValidation = () => {
    if (validationField === "tags") {
      promptTextInputref.current.classList.add("border-red");
    }
    if (validationField === "selectDeparment") {
      divDepartmentRef.current.classList.add("border-red");
    }
    if (validationField === "selectFunction") {
      divFunctionRef.current.classList.add("border-red");
    }
    if (validationField === "selectSubFunction") {
      divSubFunctionRef.current.classList.add("border-red");
    }
  };

  const setData = () => {
    if (
      selectedTags !== null &&
      selectedTags !== undefined &&
      selectedTags?.length > 0
    ) {
      setTags(selectedTags);
      const data = tagsData?.filter(
        (tagItem) => !selectedTags.includes(tagItem)
      );
      setTagsData(data);
    } else {
      setTags([]);
    }

    if (selectedOptions?.selectDeparment) {
      setSelectDepartment(selectedOptions.selectDeparment);
      bindFunctionData(selectedOptions.selectDeparment);
    }
    if (selectedOptions?.selectFunction?.length > 0) {
      setSelectFunction(selectedOptions.selectFunction);
      bindSubFunctionData(selectedOptions.selectFunction);
    }

    if (selectedOptions?.selectSubFunction?.length > 0) {
      setSubSelectFunction(selectedOptions.selectSubFunction);
    }
  };

  const bindFunctionData = async (departmentId) => {
    const functionData = await getSkillFunctionData(departmentId);
    const filteredData = functionData?.filter((func) => func.Name !== "All");
    setFunctionData(filteredData);
  };

  const bindSubFunctionData = async (functionIds) => {
    const subFunctionData = await getSkillSubFunctionData(functionIds);
    setSubFunctionData(subFunctionData);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputVal(value);
    promptTextInputref.current.classList.remove("border-red");

    // Filter tags based on input value
    const filtered = tagsData?.filter((tag) =>
      tag.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredTags(filtered);
  };

  const handleTagSelect = (tag) => {
    handleSelectTags(tag);
    setInputVal("");
    setFilteredTags([]);
  };

  const handleRemoveTag = (tag) => {
    handleRemoveTags(tag);
  };

  const handleAddTag = () => {
    if (inputVal && !tags.includes(inputVal)) {
      handleTagAdd(inputVal);
      setInputVal("");
      setFilteredTags([]);
      promptTextInputref.current.classList.remove("border-red");
    }
  };

  const onDepartmentSelection = async (departmentId) => {
    divDepartmentRef.current.classList.remove("border-red");
    handleOptionsChange(departmentId, "selectDeparment");
    setSelectDepartment([]);
    setSelectFunction([]);
    setSubFunctionData([]);
    setSubSelectFunction([]);
    setFunctionData([]);
  };

  const onFunctionSelection = async (functionIds) => {
    divFunctionRef.current.classList.remove("border-red");
    setSelectFunction([]);
    handleOptionsChange(functionIds, "selectFunction");
    setSubSelectFunction([]);
    setSubFunctionData([]);
  };

  const onSubFunctionSelection = (subFunctionIds) => {
    divSubFunctionRef.current.classList.remove("border-red");
    setSubSelectFunction([]);
    handleOptionsChange(subFunctionIds, "selectSubFunction");
  };

  const handleUpdatePrompt = () => {
    updatePromptData();
  };

  return (
    <>
      <div className="dash-section p20 prompt-step-two">
        <div className="prompt-tags">
          <div className="tag-flex">
            <h3 className="prompt-title">
              Tags {!isImportedPrompt && <span className="red">*</span>}
            </h3>
          </div>

          <div className="prompt-tags-wrap">
            {tags.length > 0 &&
              tags.map((item, index) => (
                <div className="p-tag" key={index}>
                  {item}
                  <div
                    className={isReadonlyAccess ? "" : "close-icon"}
                    onClick={() => handleRemoveTag(item)}
                  ></div>
                </div>
              ))}
          </div>
        </div>
        {isReadonlyAccess === false && (
          <div
            className={
              isReadonlyAccess ? "addtag-input disabled-parent" : "addtag-input"
            }
          >
            <input
              disabled={isReadonlyAccess}
              type="text"
              className={
                isReadonlyAccess ? "input-tag disabled-element" : "input-tag "
              }
              value={inputVal}
              ref={promptTextInputref}
              onChange={handleInputChange}
            />
            <button
              disabled={isReadonlyAccess}
              className={isReadonlyAccess ? "disabled-element" : ""}
              onClick={handleAddTag}
            >
              Add
            </button>

            {filteredTags?.length > 0 && (
              <div className="suggestions mks-suggestion-prompt">
                <div className="suggestion-item-heading">Suggestions</div>
                {filteredTags.map((tag, index) => (
                  <div
                    key={index}
                    className={`suggestion-item ${
                      index === selectedIndex ? "selected" : ""
                    }`}
                    onClick={() => handleTagSelect(tag)}
                  >
                    {tag}
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>

      <div className="dash-section p20 prompt-step-two">
        <div className="prompt-selects">
          <div className="prompt-function">
            <h3 className="prompt-title">
              Department {!isImportedPrompt && <span className="red">*</span>}
            </h3>

            <div ref={divDepartmentRef}>
              <SelectByDepartment
                onDepartmentSelection={onDepartmentSelection}
                departmentData={departmentData}
                selectDepartment={selectDeparment}
                isReadonlyAccess={isReadonlyAccess}
              />
            </div>
          </div>
          <div className="prompt-function">
            <h3 className="prompt-title">
              Function{!isImportedPrompt && <span className="red">*</span>}
            </h3>

            <div ref={divFunctionRef}>
              <SelectByFunction
                onFunctionSelection={onFunctionSelection}
                functionData={functionData}
                selectedFunction={selectFunction}
                isReadonlyAccess={isReadonlyAccess}
              />
            </div>
          </div>
          <div className="prompt-function">
            <h3 className="prompt-title">Sub Function</h3>

            <div ref={divSubFunctionRef}>
              <SelectBySubFunction
                onSubFunctionSelection={onSubFunctionSelection}
                subFunctionData={subFunctionData}
                selectedSubFunction={selectSubFunction}
                isReadonlyAccess={isReadonlyAccess}
              />
            </div>
          </div>
        </div>

        <div className="h-line"></div>
        {isReadonlyAccess === false && (
          <div className="prompt-button-wrapper">
            <div></div>
            <div className="flex-block">
              <button
                disabled={isReadonlyAccess}
                className={
                  isReadonlyAccess
                    ? "genrate-btn disabled-element"
                    : "genrate-btn"
                }
                onClick={(e) => handleUpdatePrompt()}
              >
                Update Prompt
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default UpdatePromptStepThree;
