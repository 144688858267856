import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import AppImages from "../../../constants/images";
import DashboardMobileHeader from "../dashboardMobileHeader";
import MksChatContext from "../../mks-applications/context/mksContext";
import { usePermissions } from "../customHooks/usePermissions";

const DashboardNavBar = ({ setMenuClassMobile }) => {
  const location = useLocation();
  let currentPath = location.pathname;
  var context = useContext(MksChatContext);
  const { isMksChatEnable, isSkillChatEnable } = context;
  const [isAdminDropdownOpen, setAdminDropdownOpen] = useState(false);
  const menuRef = useRef(null);
  const toggleButtonRef = useRef(null);
  if (currentPath.includes("skillstoredetail")) currentPath = "/skillstore";
  if (currentPath.includes("updateSkill"))currentPath = "/skillstore";
  if (currentPath.includes("addprompt")) currentPath = "/promptmanagement";
  if (currentPath.includes("thirdpartyaitoolsdetail"))currentPath = "/thirdpartyaitools";
  if (currentPath.includes("updatethirdpartyaitools"))currentPath = "/thirdpartyaitools";
  if (currentPath.includes("updatePrompt")) currentPath = "/promptmanagement";
 const { isSuperAdmin } = usePermissions(0);

  useEffect(() => {
    const handleClickOutside = (e) => { 
      // Check if the click is outside both the menu and toggle button
      if (
        isAdminDropdownOpen && 
        menuRef.current && 
        !menuRef.current.contains(e.target) &&
        toggleButtonRef.current &&
        !toggleButtonRef.current.contains(e.target)
      ) {
        setAdminDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isAdminDropdownOpen]);

  const handleNavClick = () => {
    if (window.innerWidth < 768) {
      // Adjust the breakpoint as needed
      setMenuClassMobile(!true); // Ensures menu closes only on mobile
    }
  };
  return (
    <div className="left-menu-nav-wrapper">
      <div className="mobile-header">
        <DashboardMobileHeader />
      </div>

      <Link
        to="/home"
        aria-current="page"
        data-title="Home"
        className={`left-menu-link w-inline-block ${
          currentPath === "/home" ? "w--current" : ""
        }`}
        onClick={handleNavClick}
      >
        <img
          src={AppImages.HomeIcon}
          loading="lazy"
          alt=""
          className="menu-icon"
        />
        <div className="menu-link-text">Home</div>
      </Link>
      {(isMksChatEnable || isSkillChatEnable) && (
        <Link
          to="/chat"
          data-title="Chat"
          className={`left-menu-link w-inline-block ${
            currentPath === "/chat" ? "w--current" : ""
          }`}
          onClick={handleNavClick}
        >
          <img
            src={AppImages.ChatIcon}
            loading="lazy"
            alt=""
            className="menu-icon"
          />
          <div className="menu-link-text">Chat</div>
        </Link>
      )}

      {/* <Link
        to="/myworkspace"
        data-title="My Workspace"
        className={`left-menu-link w-inline-block ${
          currentPath === "/myworkspace" ? "w--current" : ""
        }`}
      >
        <img
          src={AppImages.MyworkSpaceIcon}
          loading="lazy"
          alt=""
          className="menu-icon"
        />
        <div className="menu-link-text">My Workspace</div>
      </Link> */}

      <Link
        to="/skillstore"
        data-title="Skill Library"
        className={`left-menu-link w-inline-block ${
          currentPath === "/skillstore" ? "w--current" : ""
        }`}
        onClick={handleNavClick}
      >
        <img
          src={AppImages.SkillStoreIcon}
          loading="lazy"
          alt=""
          className="menu-icon"
        />
        <div className="menu-link-text">Skill Library</div>
      </Link>
      <Link
        to="/thirdpartyaitools"
        data-title="Third Party AI Tools"
        className={`left-menu-link w-inline-block ${
          currentPath === "/thirdpartyaitools" ? "w--current" : ""
        }`}
      >
        <img
          src={AppImages.ThirdPartyAIToolsIcon}
          loading="lazy"
          alt=""
          className="menu-icon"
        />
        <div className="menu-link-text">Third Party AI Tools</div>
      </Link>

      <Link
        to="/promptmanagement"
        data-title="Prompt Management"
        className={`left-menu-link w-inline-block ${
          currentPath === "/promptmanagement" ? "w--current" : ""
        }`}
        onClick={handleNavClick}
      >
        <img
          src={AppImages.PromptManagementICon}
          loading="lazy"
          alt=""
          className="menu-icon"
        />
        <div className="menu-link-text">Prompt Management</div>
      </Link>
      
      {
        isSuperAdmin && (<div ref={toggleButtonRef} 
          className={` w-inline-block dropdown-items ${
            isAdminDropdownOpen ? "open" : ""
          }`}
          onClick={() => setAdminDropdownOpen(!isAdminDropdownOpen)}
        >
          <div
            className="left-menu-link w-full flex-links justify-between dropdown-label"
            data-title="Administration"
          >
            <div className="flex-links">
              <img
                src={AppImages.adminseting}
                loading="lazy"
                alt=""
                className="menu-icon"
              />
              <div className="menu-link-text ">Administration</div>
            </div>
            <div className="down-arrow"></div>
          </div>
          <div ref={menuRef}>
          {isAdminDropdownOpen && (
            <div className="dropdown-nav">
              <Link to="/users" className="dropdown-item" onClick={handleNavClick}>
                {" "}
                <img
                  src={AppImages.usermgmt}
                  loading="lazy"
                  alt=""
                  className="menu-icon-nested"
                />
                User Management
              </Link>
              <Link to="/" className="dropdown-item" onClick={handleNavClick}>
                {" "}
                <img
                  src={AppImages.graphicimg}
                  loading="lazy"
                  alt=""
                  className="menu-icon-nested"
                />
                Demographics-Monitor{" "}
              </Link>
              <Link to="/" className="dropdown-item" onClick={handleNavClick}>
                {" "}
                <img
                  src={AppImages.onbaording}
                  loading="lazy"
                  alt=""
                  className="menu-icon-nested"
                />
                MKS.AI User Onboarding
              </Link>
              <Link
                to="/createskill"
                className="dropdown-item"
                onClick={handleNavClick}
              >
                {" "}
                <img
                  src={AppImages.skilladmin}
                  loading="lazy"
                  alt=""
                  className="menu-icon-nested"
                />
                Skill Admin
              </Link>
            </div>
          )}
        </div>
        </div>)
      }
          
      <Link
        to="/notificationhub"
        data-title="Notification Center"
        className={`left-menu-link w-inline-block ${
          currentPath === "/notificationhub" ? "w--current" : ""
        }`}
        onClick={handleNavClick}
      >
        <img
          src={AppImages.NotificationHubIcon}
          loading="lazy"
          alt=""
          className="menu-icon"
        />
        <div className="menu-link-text">Notification Center</div>
      </Link>

      {/* <Link
        to="/aboutmks"
        data-title="About MKS.ai"
        className={`left-menu-link w-inline-block ${
          currentPath === "/aboutmks" ? "w--current" : ""
        }`}
      >
        <img
          src={AppImages.AboutMKSIcon}
          loading="lazy"
          alt=""
          className="menu-icon"
        />
        <div className="menu-link-text">About MKS.ai</div>
      </Link> */}

      {/* <Link
        to="/guidance"
        data-title="Guidance & Guidelines"
        className={`left-menu-link w-inline-block ${
          currentPath === "/guidance" ? "w--current" : ""
        }`}
      >
        <img
          src={AppImages.GuidanceAndGuidelineIcon}
          loading="lazy"
          alt=""
          className="menu-icon"
        />
        <div className="menu-link-text">Guidance &amp; Guidelines</div>
      </Link> */}

      <Link
        to="/feedback"
        data-title="Feedback"
        className={`left-menu-link w-inline-block ${
          currentPath === "/feedback" ? "w--current" : ""
        }`}
        onClick={handleNavClick}
      >
        <img
          src={AppImages.FeebackIcon}
          loading="lazy"
          alt=""
          className="menu-icon"
        />
        <div className="menu-link-text">Feedback</div>
      </Link>

      {/* <Link
        to="/help"
        data-title="Help"
        className={`left-menu-link w-inline-block ${
          currentPath === "/help" ? "w--current" : ""
        }`}
      >
        <img
          src={AppImages.HelpIcon}
          loading="lazy"
          alt=""
          className="menu-icon"
        />
        <div className="menu-link-text">Help</div>
      </Link> */}

      <Link
        to="/settings"
        data-title="Settings"
        className={`left-menu-link w-inline-block ${
          currentPath === "/settings" ? "w--current" : ""
        }`}
        onClick={handleNavClick}
      >
        <img
          src={AppImages.SettingsIcon}
          loading="lazy"
          alt=""
          className="menu-icon"
        />
        <div className="menu-link-text">Settings</div>
      </Link>
      <div className="hide-on-desk">
        <Link
          className="left-menu-link w-inline-block"
          to="/logout"
          onClick={handleNavClick}
        >
          {" "}
          <img
            src={AppImages.Logouticon}
            loading="lazy"
            alt=""
            className="menu-icon"
          />
          <div className="menu-link-text">Logout</div>
        </Link>
      </div>
    </div>
  );
};

export default DashboardNavBar;
