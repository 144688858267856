import { useContext } from "react";

const UserImage = ({userImageBytes, userImageMimeType, userInitials}) => {
  const isImagePresent = (userImageBytes !== null && userImageBytes !== "" && userImageBytes !== undefined)

  return (
    <span>
      {isImagePresent && (
        <img src={`data:${userImageMimeType};base64,${userImageBytes}`} className="user-image" />
      )}
      {!isImagePresent && (
        <span>{userInitials}</span>
      )}
    </span>
  );
};

export default UserImage;
