export const getMultiFileClassByExtension = (fileName) => {
  const extension = fileName.split(".").pop();
  if (extension.toLowerCase() === "pdf") {
    return "pdf";
  } else if (extension.toLowerCase() === "txt") {
    return "file";
  } else if (
    extension.toLowerCase() === "img" ||
    extension.toLowerCase() === "jpeg" ||
    extension.toLowerCase() === "jpg" ||
    extension.toLowerCase() === "png" ||
    extension.toLowerCase() === "svg"
  ) {
    return "img";
  } else if (
    extension.toLowerCase() === "xlsx" ||
    extension.toLowerCase() === "xls"
  ) {
    return "excel";
  } else {
    return "deafult";
  }
};
