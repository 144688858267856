import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const AutoLogout = ({ children }) => {
  let timer;
  const handleLogoutTimer = (events) => {
    timer = setTimeout(() => {
      resetTimer();
      Object.values(events).forEach((item) => {
        window.removeEventListener(item, resetTimer);
      });
      logoutAction();
    }, 900000);
  };

  const resetTimer = () => {
    if (timer) clearTimeout(timer);
  };

  useEffect(() => {
    const events = [
      "load",
      "mousemove",
      "mousedown",
      "click",
      "scroll",
      "keypress",
    ];

    const fn = () => {
      Object.values(events).forEach((item) => {
        window.addEventListener(item, () => {
          resetTimer();
          handleLogoutTimer(events);
        });
      });
    };
    fn();
  });

  const navigate = useNavigate();

  const logoutAction = () => {
    navigate("/logout");
  };

  return children;
};

export default AutoLogout;
