import React from "react";
import { Link } from "react-router-dom";

const BreadCrumb = ({ IsThirdPartyAITool, skillStoreName }) => {
  return (
    <div className="breadcrumb">
      <Link className="visited home-link" to="/home"></Link>
      {!IsThirdPartyAITool && (
        <Link className="visited normal-link" to="/skillstore">
          Skill Library
        </Link>
      )}
      {IsThirdPartyAITool && (
        <Link className="visited normal-link" to="/thirdpartyaitools">
          Third Party AI Tools
        </Link>
      )}
      <div className="normal-link">{skillStoreName}</div>
    </div>
  );
};

export default BreadCrumb;
