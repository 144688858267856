import React from "react";

import ChatData from "./views/_partials/chatData";
import SkillsAndPrompt from "./views/_partials/skillsAndPrompt";

const Home = () => {
  return (
    <>
      <div className="dashboard-wrapper">
        <div className="section-wrapper">
          <ChatData />
          <SkillsAndPrompt showViewAllButton={true} />
        </div>
      </div>
    </>
  );
};

export default Home;
